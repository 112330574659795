import React, { useEffect, useCallback } from "react";
import TextField from "@mui/material/TextField";
import useSWR from "swr";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormContext } from "react-hook-form";
import ls from "local-storage";

import useDebounce from "../UseDebounce";
import { useProfile } from "../DataHooks/useProfile";
import { keywordParser } from "../Keyword/common";
import { Box } from "@mui/material";
import "./customKeywordSearch.css";

const CUSOTM_SEARCH_ITEM_KEYWORD = "custom_search_item_keyword";

const Comp = (props) => {
  const { source, label } = props;
  const { setValue: setFormValue } = useFormContext();
  const cacheSearchterm = ls.get(CUSOTM_SEARCH_ITEM_KEYWORD);
  const [value, setValue] = React.useState(
    cacheSearchterm ? cacheSearchterm : ""
  );
  const debouncedSearchTerm = useDebounce(value, 800);
  const { profile } = useProfile();
  const update2 = useCallback(
    (data) => {
      const ids = data.map((d) => d.id);
      setFormValue(source, ids);
    },
    [setFormValue, source]
  );
  const target =
    profile && profile.page && profile.page._id && debouncedSearchTerm
      ? `${process.env.REACT_APP_CMS_HOST}/keywords/?key_containss=${debouncedSearchTerm}&page=${profile.page._id}&_sort=createdAt:DESC&_start=0&_limit=20`
      : null;
  const { data, error } = useSWR(target, {
    revalidateOnFocus: false,
    refreshInterval: 0,
    onSuccess: update2,
  });
  const loading = !data && !error && value;

  useEffect(() => {
    if (!debouncedSearchTerm) {
      setFormValue(source, undefined);
      ls.set(CUSOTM_SEARCH_ITEM_KEYWORD, "");
    }
    ls.set(CUSOTM_SEARCH_ITEM_KEYWORD, debouncedSearchTerm);
  }, [debouncedSearchTerm, setFormValue, source]);
  return (
    <TextField
      className="customkeywordsearch"
      label={label}
      value={value}
      variant="filled"
      onChange={(e) => setValue(keywordParser(e.target.value))}
      InputProps={{
        endAdornment: loading ? (
          <InputAdornment position="end">
            <CircularProgress size="1rem" />
          </InputAdornment>
        ) : null,
      }}
      helperText={
        value && data && data.length > 0 ? (
          <Box>{`${data.length} keywords found`}</Box>
        ) : value && data && data.length === 0 ? (
          <Box sx={{ color: "red" }}>{`No keywords found`}</Box>
        ) : error ? (
          <Box sx={{ color: "red" }}>{`Error, try change keyword`}</Box>
        ) : null
      }
    />
  );
};

export default Comp;
