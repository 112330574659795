import React from "react";
import {
  List,
  Datagrid,
  NumberField,
  Pagination,
  TextField,
  useRecordContext,
} from "react-admin";

import { getPageId } from "../../helpers/getPageId";

import {
  // useIsEnterprisePlan,
  useIsBusinessPlan,
} from "../../helpers/subscriptionPlan";
import CustomDateField from "../Common/CustomDateField";
import TextLinkField from "../Common/TextLinkField";
import exporter from "./Exporter";
import ListActions from "./ListActions";

const CreditLogsList = (props) => {
  const pageId = getPageId();
  // const isEnterprisePlan = useIsEnterprisePlan();
  const isBusinessPlan = useIsBusinessPlan();

  if (!isBusinessPlan) {
    return <h4>Please upgrade plan to use credit feature.</h4>;
  }

  //temporary custom numberField to convert points to dollar, newer version RA doesnt need this
  const CustomNumberField = (props) => {
    const record = useRecordContext(props);
    return record ? <span>{Number(record.amount) / 100}</span> : null;
  };
  return (
    <>
      <br />
      <h4>Entry will be removed after 90 days</h4>
      <List
        {...props}
        actions={<ListActions />}
        exporter={exporter}
        filter={{ page: pageId }}
        // filters={defaultFilters}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} />}
        sort={{ field: "createdAt", order: "DESC" }}
      >
        <Datagrid bulkActionButtons={false}>
          <TextLinkField
            source="contact.name"
            resourceName="contacts"
            resourceKeyId="contact._id"
            label="Name"
          />
          <CustomNumberField source="amount" label="$" />
          <TextField source="updatedBy.username" label={"Updated by"} />
          <CustomDateField source="createdAt" showTime />
          <CustomDateField source="updatedAt" showTime />
        </Datagrid>
      </List>
    </>
  );
};

export default CreditLogsList;
