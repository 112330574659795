import { useIsBusinessPlan } from "../../helpers/subscriptionPlan";
import { useProfile } from "./useProfile";

export const useIsShowImageInDo = () => {
  const { profile } = useProfile();
  const isBusinessPlan = useIsBusinessPlan();
  return (
    isBusinessPlan && profile && profile.params && profile.params.showImageInDO
  );
};
