import React from "react";
import { useController } from "react-hook-form";
import { DownshiftInputDescription } from "../DownshiftInput";
import TextField from "@mui/material/TextField";

// const required = (value) => (value ? undefined : "Required");

const BoundedTextField = (props) => {
  return (
    <DownshiftInputDescription
      // {...field}
      name="item"
      label="Item (min 2 letters)"
      source="item"
      // component={DownshiftInputDescription}
      // validate={required}
    />
  );
  // const itemDescriptionInput = useController({ name: "itemDescription" });
  const { field } = useController({
    name: "item",
  });
  return (
    <TextField
      {...field}
      name="item"
      label="Item (min 2 letters)"
      source="item"
      component={DownshiftInputDescription}
      // validate={required}
    />
  );
};

export default BoundedTextField;
