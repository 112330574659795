import React, { useEffect } from "react";
import {
  Datagrid,
  // ListBase,
  // ListToolbar,
  // BulkActionsToolbar,
  Pagination,
  useListContext,
  TextField,
  NumberField,
  DateField,
  useRecordContext,
  // useGetList,
  // TextInput,
  // ListContextProvider,
  // Title,
  // FilterForm,
  List,
  // useRecordSelection,
  // useGetMany,
  useUnselectAll,
} from "react-admin";

// import classNames from "classnames";
import Decimal from "decimal.js-light";
// import Card from "@mui/material/Card";
import OrderItemBulkUpdateStatusButton from "./OrderItemBulkUpdateStatusButton";
import SplitOrderDialog from "../components/OrderItem/SplitOrderDialog";
import BulkUpdateTagsButton from "../components/OrderItem/BulkUpdateTagsButton";
import BulkRemovePrefix from "../components/OrderItem/BulkRemovePrefix";

import OrderItemActions from "../components/OrderItem/OrderItemActions";
import EditOrderItem from "../components/OrderItem/EditOrderItem";
import EmptyOrderItem from "../components/OrderItem/EmptyOrderItem";
import CustomChipArrayField from "../components/CustomChipArrayField";

import InboxLink from "./InboxLink";

import { withDisabledIfCancelled } from "../helpers/withDisabled";
import { useAvailableCurrencies } from "../components/useAvailableCurrencies";
import {
  CANCELLED,
  ORDER_ITEM_CANCELLED,
  SHIPPED,
} from "../components/Constant";
import CustomItemDescriptionWithImage from "./CustomItemDescriptionWithImage";
import { useCustomAllRecords } from "../components/Common/useCustomAllRecords";
import { useIsOrderEditable } from "../components/DataHooks/useIsOrderEditable";

// import { useIsEnterprisePlan } from "../helpers/subscriptionPlan";

const ShowSelectedItemsSubtotal = (props) => {
  const { selectedIds, isLoading } = useListContext();
  const allRecords = useCustomAllRecords();

  const { currency } = props;

  if (isLoading) {
    return null;
  }
  const records = allRecords.filter((record) =>
    selectedIds.includes(record._id)
  );

  const subTotalWeightDecimal = records.reduce(
    (acc, { weight = 0 }) => acc.plus(weight),
    new Decimal(0)
  );

  const subTotalDecimal = records.reduce(
    (acc, cur) => acc.plus(cur.price),
    new Decimal(0)
  );

  return (
    <span>
      Subtotal: {`${subTotalWeightDecimal.toNumber()} kg`}{" "}
      {`${currency} ${subTotalDecimal.toFixed(2)}`}
    </span>
  );
};

const OrderItemBulkActionButtons = (props) => {
  return (
    <>
      <ShowSelectedItemsSubtotal {...props} />
      <OrderItemBulkUpdateStatusButton {...props} />
      <BulkUpdateTagsButton {...props} />
      <SplitOrderDialog {...props} />
      <BulkRemovePrefix {...props} />
    </>
  );
};

const OrderItemTextField = withDisabledIfCancelled(TextField);
const OrderItemNumberField = withDisabledIfCancelled(NumberField);
const OrderItemDateField = withDisabledIfCancelled(DateField);

const ItemPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 200]} {...props} />
);

const OrderItemList = () => {
  const record = useRecordContext();
  const isOrderEditable = useIsOrderEditable();
  const orderId = record._id;
  const filter = { order: orderId };
  const { defaultCurrency } = useAvailableCurrencies();
  const currency = record.currencyCode ? record.currencyCode : defaultCurrency;
  const unselectAll = useUnselectAll("orderitems");

  // this is required to clear selected items when view another order
  useEffect(() => {
    return () => unselectAll();
  }, [unselectAll]);
  if (!orderId) {
    return null;
  }
  return (
    <List
      pagination={<ItemPagination />}
      filter={filter}
      resource={"orderitems"}
      perPage={50}
      actions={
        isOrderEditable ? (
          <OrderItemActions
            currency={currency}
            order={orderId}
            disabled={record.status === CANCELLED || record.status === SHIPPED}
          />
        ) : null
      }
      empty={<EmptyOrderItem orderId={orderId} />}
    >
      <Datagrid
        isRowSelectable={(record) =>
          !(!isOrderEditable || record.status === ORDER_ITEM_CANCELLED)
        }
        bulkActionButtons={<OrderItemBulkActionButtons currency={currency} />}
      >
        {/* <OrderItemTextField source="description" /> */}
        <CustomItemDescriptionWithImage
          source="description"
          disableLink={true}
        />
        <OrderItemTextField
          source="keyword.key"
          label="Keyword"
          sortable={false}
        />
        <OrderItemTextField source="status" />
        <OrderItemNumberField source="price" />
        <OrderItemTextField source="channel" />
        <OrderItemNumberField source="weight" label="Weight(Kg)" />
        <OrderItemDateField source="createdAt" showTime />
        <OrderItemDateField source="updatedAt" showTime />
        <CustomChipArrayField source="tags" />
        <EditOrderItem />
        <InboxLink />
      </Datagrid>
    </List>
  );
};
// below are old codes commented, just to keep in view.
// const OrderItemList_Old = (props) => {
//   const record = useRecordContext();
//   const filter = { order: record.id };
//   const orderId = record.id;
//   const { defaultCurrency } = useAvailableCurrencies();
//   const currency = record.currencyCode ? record.currencyCode : defaultCurrency;
//   return (
//     <MyList
//       filter={filter}
//       perPage={25}
//       resource={`orderItems`}
//       actions={
//         <OrderItemActions
//           currency={currency}
//           disabled={record.status === CANCELLED}
//         />
//       }
//       empty={<EmptyOrderItem orderId={orderId} />}
//     >
//       <Datagrid
//         isRowSelectable={(record) => record.status !== CANCELLED}
//         bulkActionButtons={<OrderItemBulkActionButtons currency={currency} />}
//       >
//         {/* <OrderItemTextField source="description" /> */}
//         <CustomItemDescriptionWithImage
//           source="description"
//           disableLink={true}
//         />
//         <OrderItemTextField
//           source="keyword.key"
//           label="Keyword"
//           sortable={false}
//         />
//         <OrderItemTextField source="status" />
//         <OrderItemNumberField source="price" />
//         <OrderItemTextField source="channel" />
//         <OrderItemNumberField source="weight" label="Weight(Kg)" />
//         <OrderItemDateField source="createdAt" showTime />
//         <OrderItemDateField source="updatedAt" showTime />
//         <CustomChipArrayField source="tags" />
//         <EditOrderItem />
//         <InboxLink />
//       </Datagrid>
//     </MyList>
//   );
// };

// const MyList = (props) => {
//   const { filter, children } = props;
//   return (
//     <ListBase {...props}>
//       {/* <h1>{props.title}</h1> */}
//       <ListToolbar
//         // filters={props.filters}
//         permanentFilter={filter}
//         actions={props.actions}
//       />
//       <BlockLoadingGrid {...props} />
//       <Card>{children}</Card>
//       <Pagination />
//     </ListBase>
//   );
// };

// const useStyles = makeStyles({
//   hidden: {
//     display: "none",
//   },
// });

// const rowsPerPageOptions = [25, 50, 100, 200];
// const BlockLoadingGrid = (props) => {
//   const classes = useStyles();
//   const {
//     loaded, // boolean that is false until the data is available
//     loading, // boolean that is true on mount, and false once the data was fetched
//   } = useListContext();
//   return (
//     <Card className={classNames({ [classes.hidden]: !loaded || loading })}>
//       <BulkActionsToolbar>{props.bulkActionButtons}</BulkActionsToolbar>
//       {props.children}
//       <Pagination rowsPerPageOptions={rowsPerPageOptions} />
//     </Card>
//   );
// };

export default OrderItemList;
