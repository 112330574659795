import React from "react";
import Button from "@mui/material/Button";
import { useProfile } from "../components/DataHooks/useProfile";

const keys = [
  // "shippingName",
  // "shippingPhoneNumber",
  "shippingAddress1",
  "shippingAddress2",
  "shippingCity",
  "shippingVillage",
  "shippingSubDistrict",
  "shippingProvince",
  "shippingPostcode",
  "shippingState",
];

const generateQueryString = ({ record, hoeThyeForm }) => {
  const fullShippingAddress = keys
    .filter((k) => !!record[k])
    .map((k) => record[k])
    .join(",\n");
  const obj = [
    {
      key: "orderNumber",
      value: record.orderNumber,
    },
    { key: "shippingName", value: record.shippingName },
    { key: "shippingPhoneNumber", value: record.shippingPhoneNumber },
    { key: "fullShippingAddress", value: fullShippingAddress },
    { key: "shippingPostcode", value: record.shippingPostcode },
  ];
  const queryStringArr = obj.map((o) => {
    return `${hoeThyeForm[o.key]}=${
      o.value ? encodeURIComponent(o.value) : ""
    }`;
  });
  return queryStringArr.join("&");
};

const Comp = (props) => {
  const { record } = props;
  const { profile } = useProfile();
  const { params } = profile;
  let str = "";
  let url = "";
  try {
    str = generateQueryString({ record, hoeThyeForm: params.hoeThyeForm });
    url = `${params.hoeThyeForm.formUrl}?${str}`;
  } catch (e) {
    return null;
  }

  return (
    <Button
      href={url}
      color="secondary"
      target="_blank"
      rel="noopener noreferrer"
    >
      Hoe Thye
    </Button>
  );
};

export default Comp;

/*
example
  "hoeThyeForm": {
    "formUrl": "https://docs.google.com/forms/d/e/1FAIpQLSce3cPRcwWpKbS4xCmKf0tYtZvzBVM81_pHfqFH0p5F4BYXFA/viewform",
    "orderNumber": "entry.15807717",
    "shippingName": "entry.758083890",
    "shippingPhoneNumber": "entry.1547533974",
    "fullShippingAddress": "entry.591556804",
    "shippingPostcode": "entry.537660359"
  }
*/
