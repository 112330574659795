import React from "react";
import MaterialTable from "@material-table/core";
import stc from "string-to-color";
import TextField from "@mui/material/TextField";
import { store, DELETE_ORDER, UPDATE_ORDER } from "./store";

// import { withEnableFlag } from "./withEnableFlag";

const EditItem = (props) => (
  <TextField
    multiline
    value={props.value}
    onChange={(e) => props.onChange(e.target.value)}
    rowsMax={4}
  />
);

const MaterialTableDemo = (props) => {
  const { dispatch, state } = React.useContext(store);
  const [columns, setColumns] = React.useState([
    {
      title: "Name",
      field: "customerName",
      editable: "never",
      render: (rowData) => (
        <strong style={{ color: stc(rowData.psid) }}>
          {rowData.customerName}
        </strong>
      ),
    },
    { title: "Item", field: "description", editComponent: EditItem },
    { title: "Price", field: "price", type: "numeric" },
    { title: "Qty", field: "quantity", type: "numeric" },
    {
      title: "createdAt",
      field: "createdAt",
      hidden: true,
      editable: "never",
      defaultSort: "desc",
      type: "time",
      render: (rowData) => {
        const dateWithouthSecond = new Date(rowData.createdAt);
        const time = dateWithouthSecond.toLocaleTimeString(navigator.language, {
          hour: "2-digit",
          minute: "2-digit",
        });
        return <span>{time}</span>;
      },
    },
  ]);
  const { orders } = state;
  const onDelete = React.useCallback(
    (data) => {
      dispatch({ type: DELETE_ORDER, payload: data });
    },
    [dispatch]
  );
  const onUpdate = React.useCallback(
    (newData, oldData) => {
      dispatch({
        type: UPDATE_ORDER,
        payload: {
          newData,
          oldData,
        },
      });
    },
    [dispatch]
  );

  React.useEffect(() => {
    const newColumns = columns.map((col) => {
      if (col.field === "quantity") {
        const totalQuantity = orders.reduce(
          (total, cur) => total + parseInt(cur.quantity),
          0
        );
        return {
          ...col,
          title: `Qty (${totalQuantity})`,
        };
      } else if (col.field === "customerName") {
        return { ...col, title: `Name (${orders.length})` };
      } else {
        return col;
      }
    });
    setColumns(newColumns);
  }, [orders, setColumns]);

  return (
    <MaterialTable
      title="Orders"
      columns={columns}
      data={orders}
      options={{
        search: false,
        actionsColumnIndex: 4,
        paging: false,
        padding: "dense",
        maxBodyHeight: 300,
      }}
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve) => {
            onUpdate(newData, oldData);
            resolve();
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve) => {
            onDelete(oldData);
            resolve();
          }),
      }}
    />
  );
};

// export default withEnableFlag(MaterialTableDemo);
export default MaterialTableDemo;
