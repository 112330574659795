import React from "react";
import { makeStyles } from "@mui/styles";
import { useRecordContext } from "react-admin";

const useStyles = makeStyles({
  cancelled: {
    color: "rgba(40,40,40,.3)",
    "text-decoration-line": "line-through",
  },
});

export const withDisabledIfCancelled = (WrappedComponent) => (props) => {
  const record = useRecordContext();
  const classes = useStyles();
  const isCancelled = record && record.status && record.status === "cancelled";

  return (
    <WrappedComponent
      {...props}
      {...(isCancelled && { className: classes.cancelled })}
    />
  );
};
