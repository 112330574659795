import React, { useState } from "react";
import {
  required,
  Button,
  SaveButton,
  useUpdate,
  useNotify,
  useRefresh,
  Form,
  BooleanInput,
  TextInput,
  useUpdateMany,
  useRecordContext,
  Edit,
} from "react-admin";
import IconContentEdit from "@mui/icons-material/Edit";
import IconCancel from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";

import { withShowIfOfflinePayment } from "../components/Payment/withShowIfOffline";
import { format, parse } from "../components/Payment/Edit";
import { receiptImageStyle, ORDER_ITEM_NEW } from "../components/Constant";
import { useGetOutstandingPaymentInclShipping } from "./OutstandingPaymentInclShippingField";
import { isNaNOrNull } from "../helpers/methods";
import MatButton from "@mui/material/Button";
import { replaceImageSource } from "../helpers/withReplaceImageSource";
import { ButtonGroup } from "@mui/material";
import DecimalInput from "../components/Common/DecimalInput";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PaymentEditButton = (props) => {
  const { orderData = {} } = props;
  const [rotation, setRotation] = useState(0);
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const [showDialog, setShowDialog] = useState(false);
  const [showSetPaidDialog, setShowSetPaidDialog] = useState(false);
  const balanceDecimal = useGetOutstandingPaymentInclShipping({
    record: orderData,
  });
  const balance = balanceDecimal.times(100).toNumber();

  const { orderItems = [] } = orderData;
  const itemsInNew = orderItems.filter((i) => {
    return i.status === ORDER_ITEM_NEW;
  });
  const newItemIds = itemsInNew.map((i) => {
    return i.id;
  });
  const newItemComp = itemsInNew.map((i) => {
    return <li key={i._id}>{`item: ${i.description}, price: ${i.price}`}</li>;
  });

  const isPdf =
    record.receipt &&
    record.receipt.fileUrl &&
    record.receipt.fileUrl.toLowerCase().indexOf(".pdf") > -1;
  const shouldAutoFill =
    isNaNOrNull(record.paidAmount) || record.paidAmount === 0;
  const defaultPaidAmount = shouldAutoFill ? balance : record.paidAmount;

  const [updateMany, { loading: loadingOrderItem }] = useUpdateMany(
    `orderItems`,
    { ids: newItemIds, data: { status: "paid" } },
    {
      onSuccess: () => {
        notify("Set paid successfully");
        refresh();
      },
      onFailure: (error) => notify("Failed to update", "warning"),
    }
  );
  const onSetPaid = React.useCallback(() => {
    updateMany();
    setShowSetPaidDialog(false);
  }, [updateMany]);

  const [update, { isLoading, error }] = useUpdate();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleOpenSetPaidClick = React.useCallback(() => {
    setShowSetPaidDialog(true);
  }, []);

  const handleCloseSetPaidClick = () => {
    setShowSetPaidDialog(false);
    refresh();
  };

  const handleSubmit = React.useCallback(
    async (values) => {
      const { paidAmount, paid, remarks } = values;
      const isPaidBalance = shouldAutoFill
        ? paidAmount === balance
        : paidAmount === defaultPaidAmount + balance; //full payment
      if (isPaidBalance && itemsInNew.length > 0) {
        handleOpenSetPaidClick();
      }

      const onSuccess =
        isPaidBalance && itemsInNew.length > 0
          ? ({ data }) => {
              setShowDialog(false);
              notify(`Payment updated`, "info");
            }
          : ({ data }) => {
              setShowDialog(false);
              notify(`Payment updated`, "info");
              refresh();
            };

      update(
        "payments",
        {
          id: record._id,
          data: { paidAmount, paid, remarks },
          previousData: record,
        },
        {
          onSuccess: onSuccess,
          onFailure: ({ error }) => {
            notify(error.message, "error");
          },
          //mutationMode: "undoable",
        }
      );
      setShowDialog(false);
    },
    [
      shouldAutoFill,
      balance,
      defaultPaidAmount,
      itemsInNew,
      update,
      record,
      handleOpenSetPaidClick,
      notify,
      refresh,
    ]
  );

  return (
    <>
      <Button onClick={handleClick}>
        <IconContentEdit />
      </Button>
      <Dialog
        fullWidth
        open={showSetPaidDialog}
        // onClose={handleCloseSetPaidClick}
        aria-label="Set new items As Paid?"
      >
        <DialogTitle>Set these items as Paid?</DialogTitle>
        <DialogContent>{<ul>{newItemComp}</ul>}</DialogContent>
        <DialogActions>
          <Button
            label="ra.action.cancel"
            onClick={handleCloseSetPaidClick}
            disabled={loadingOrderItem}
          >
            <IconCancel />
          </Button>
          <MatButton
            variant="contained"
            color="primary"
            onClick={onSetPaid}
            disabled={loadingOrderItem}
          >
            Set Paid
          </MatButton>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Edit payment"
      >
        <DialogTitle>Edit payment</DialogTitle>
        <DialogContent>
          {!isPdf && record.receipt && record.receipt.fileUrl && (
            <img
              style={{
                ...receiptImageStyle,
                transform: `rotate(${rotation}deg) scaleX(${scaleX}) scaleY(${scaleY})`,
              }}
              alt="receipt_image"
              src={replaceImageSource(record.receipt.fileUrl)}
            />
          )}
          {isPdf && record.receipt && record.receipt.fileUrl && (
            <Document file={record.receipt.fileUrl}>
              <Page pageNumber={1} />
            </Document>
          )}
        </DialogContent>
        <Edit actions={false}>
          <Form
            defaultValues={{
              paid: true,
              paidAmount: defaultPaidAmount,
              remarks: record.remarks,
            }}
            onSubmit={handleSubmit}
          >
            <DialogContent>
              <DecimalInput
                label="Paid amount"
                source="paidAmount"
                validate={required()}
                fullWidth
                format={format}
                parse={parse}
                helperText={
                  shouldAutoFill ? `Auto filled with outstanding balance` : ``
                }
              />
              <BooleanInput source="paid" />
              <TextInput source="remarks" />
            </DialogContent>
            <DialogActions>
              <ButtonGroup variant="outlined" aria-label="image tools">
                <MatButton
                  color="primary"
                  variant="outlined"
                  onClick={() => setRotation(rotation + 90)}
                >
                  Rotate
                </MatButton>
                <MatButton onClick={() => setScaleX((prev) => prev * -1)}>
                  Flip X
                </MatButton>
                <MatButton onClick={() => setScaleY((prev) => prev * -1)}>
                  Flip Y
                </MatButton>
              </ButtonGroup>

              <Button
                label="ra.action.cancel"
                onClick={handleCloseClick}
                disabled={isLoading}
              >
                <IconCancel />
              </Button>
              <SaveButton alwaysEnable />
            </DialogActions>
          </Form>
        </Edit>
      </Dialog>
    </>
  );
};

export default withShowIfOfflinePayment(PaymentEditButton);
