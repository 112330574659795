import React from "react";
import {
  List,
  // SimpleList,
  Datagrid,
  TextField,
  Pagination,
  BulkDeleteWithConfirmButton,
  AutocompleteArrayInput,
  NumberField,
} from "react-admin";

import { getPageId } from "../../helpers/getPageId";

// import {
//   useIsEnterprisePlan,
//   useIsBusinessPlan,
// } from "../../helpers/subscriptionPlan";

import CustomDateField from "../Common/CustomDateField";
import { EXPORT_STATUSES, PENDING } from "./Constant";
import DownloadField from "./DownloadField";
import UserField from "./UserField";
import ExportParamsField from "./ExportParamsField";

const defaultFilters = [
  <AutocompleteArrayInput
    source="status_in"
    label={`Status`}
    choices={EXPORT_STATUSES}
    alwaysOn
    //allowEmpty={false}
  />,
];

// const BulkActionButtons = (props) => (
//   <>
//     <BulkFbFollowUpMessageButton {...props} />
//     {/* Add the default bulk delete action */}
//     <CustomBulkDeleteButton {...props} />
//   </>
// );

// const WLHelp = () => (
//   <KbLinkButton
//     url={`https://learn.boxify.io/kb/08-4-21-feature-of-waitlists/`}
//   />
// );

const ExportBulkActionButtons = () => (
  <>
    <BulkDeleteWithConfirmButton />
  </>
);

const ExportsList = (props) => {
  const pageId = getPageId();
  // const isEnterprisePlan = useIsEnterprisePlan();
  // const isBusinessPlan = useIsBusinessPlan();
  //   const { isMultiCurrency } = useAvailableCurrencies();
  // const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <>
      <h5>Jobs will be deleted after 7 days from creation date.</h5>
      <List
        {...props}
        actions={false}
        // exporter={exporter}
        filter={{ page: pageId }}
        filters={defaultFilters}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50]} />}
        sort={{ field: "createdAt", order: "DESC" }}
      >
        <Datagrid
          isRowSelectable={({ status }) => status !== PENDING}
          bulkActionButtons={<ExportBulkActionButtons />}
        >
          <TextField source="_id" label="Id" />
          <ExportParamsField label="Params" />
          <NumberField source="recordCount" label="Count" />
          <TextField source="status" />
          <DownloadField label="Download" />
          <UserField label="Username" />
          <CustomDateField source="createdAt" showTime />
          <CustomDateField source="updatedAt" showTime />
        </Datagrid>
      </List>
    </>
  );
};

export default ExportsList;
