import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Rating from "@mui/material/Rating";
import { useSWRConfig } from "swr";

import { useIsHobbyistPlan } from "../../helpers/subscriptionPlan";
import { useUpdateContact } from "../DataHooks/useGetFbProfileImage";
import { CACHE_CONTACTS } from "../Constant";

const Comp = (props) => {
  const { record } = props;
  const { rating = 0, _id } = record;
  const { mutate: cacheMutate } = useSWRConfig();

  // const [loading, setLoading] = useState(false);
  const isHobbyist = useIsHobbyistPlan();
  const { trigger: updateContact, isMutating } = useUpdateContact(_id, {
    onSuccess: (data) => {
      cacheMutate(
        `${CACHE_CONTACTS}/${data.psid}`,
        (prev) => ({
          ...prev,
          rating: data.rating,
        }),
        { revalidate: false }
      );
    },
  });

  const onChange = React.useCallback(
    (event, newValue) => {
      if (isHobbyist) {
        alert("Please upgrade your plan to use this feature.");
        return;
      }
      updateContact({ rating: newValue * 2 });
    },
    [isHobbyist, updateContact]
  );

  if (isMutating) {
    return <CircularProgress size={15} />;
  }

  return (
    <Rating
      size="small"
      name="customer-rating-input"
      value={rating / 2}
      onChange={onChange}
      precision={0.5}
    />
  );
};

export default Comp;
