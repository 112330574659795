import React, { useReducer } from "react";
import addDays from "date-fns/addDays";
import {
  SelectInput,
  Create,
  BooleanInput,
  TextInput,
  SimpleForm,
  required,
  Toolbar,
  SaveButton,
  useCreate,
  useRedirect,
  useNotify,
  minValue,
  FormDataConsumer,
} from "react-admin";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";

import { getPageId } from "../../helpers/getPageId";
// import { useAvailableCurrencies } from "../useAvailableCurrencies";
import {
  stripSpecialtoUpper,
  discountTypes,
  DISCOUNT_TYPE_VALUE,
} from "./common";
// import { useIsBusinessPlan } from "../../helpers/subscriptionPlan";
import CustomPickerInput from "../OrderItem/CustomPickerInput";
import { PositiveIntegerInput } from "../Common/IntegerInput";
import { PositiveDecimalInput } from "../Common/DecimalInput";

const initialState = { quantity: 1, prefix: "", customCode: false };
const CHANGE_QUANTITY = "CHANGE_QUANTITY";
const CHANGE_PREFIX = "CHANGE_PREFIX";
const TOGGLE_CUSTOM_CODE = "TOGGLE_CUSTOM_CODE";
const MAX_QUANTITY = 500;
export const store = React.createContext(initialState);

const { Provider } = store;
const VoucherContextProvider = (props) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case CHANGE_QUANTITY:
        return { ...state, quantity: action.payload };
      case CHANGE_PREFIX:
        return { ...state, prefix: action.payload };
      case TOGGLE_CUSTOM_CODE:
        return { ...state, customCode: !state.customCode };
      default:
        return state;
    }
  }, initialState);
  return <Provider value={{ state, dispatch }}>{props.children}</Provider>;
};

export const largerThanMaxUsagePerCustomer = (v, record) => {
  if (`${v}` === "0") {
    return undefined;
  }
  return Number(v) < Number(record.maxUsagePerCustomer)
    ? "Max usage must be 0 (unlimited) or, equal or larger than max usage per customer."
    : undefined;
};

const CustomQuantity = (props) => {
  const { state, dispatch } = React.useContext(store);
  const { quantity } = state;
  const onChange = React.useCallback(
    (e) => {
      const qty = Number(e.target.value);
      if (qty > MAX_QUANTITY) {
        dispatch({ type: CHANGE_QUANTITY, payload: MAX_QUANTITY });
      } else {
        dispatch({ type: CHANGE_QUANTITY, payload: e.target.value });
      }
    },
    [dispatch]
  );
  return (
    <TextField
      type="number"
      label={`Quantity (max ${MAX_QUANTITY})`}
      value={quantity}
      onChange={onChange}
    />
  );
};

const CustomPrefix = (props) => {
  const { state, dispatch } = React.useContext(store);
  const { prefix } = state;
  const onChange = React.useCallback(
    (e) =>
      dispatch({
        type: CHANGE_PREFIX,
        payload: stripSpecialtoUpper(e.target.value),
      }),
    [dispatch]
  );
  return (
    <TextField
      label="Prefix (Max 8 letters)"
      value={prefix}
      onChange={onChange}
      inputProps={{ maxLength: 8 }}
    />
  );
};

const CustomCodeCheckbox = (props) => {
  const { state, dispatch } = React.useContext(store);
  const onClick = React.useCallback(
    (e) => dispatch({ type: TOGGLE_CUSTOM_CODE }),
    [dispatch]
  );
  const { customCode } = state;

  return (
    <FormControlLabel
      control={
        <Checkbox checked={customCode} onChange={onClick} name="customCode" />
      }
      label="Use custom code"
    />
  );
};

const unlimitText = `0 is unlimited.`;
const VoucherCreate = (props) => {
  const pageId = getPageId();

  const { state } = React.useContext(store);
  const { quantity = 1, prefix = "", customCode } = state;
  const [create, { isLoading, error }] = useCreate();
  const redirectTo = useRedirect();
  const notify = useNotify();

  const handleSave = React.useCallback(
    (values) => {
      // const values = getValues();
      const url = customCode
        ? `vouchers`
        : `vouchers?quantity=${quantity}&prefix=${prefix}`;
      const { code, maxDiscount, ...others } = values;
      const newMaxDiscount = values.discountType === "value" ? 0 : maxDiscount;
      const newValues = customCode
        ? {
            ...values,
            maxDiscount: newMaxDiscount,
          }
        : {
            ...others,
            maxDiscount: newMaxDiscount,
          };
      create(
        url,
        {
          data: { ...newValues },
        },
        {
          onSuccess: ({ data: newRecord }) => {
            notify("ra.notification.created", "info");
            redirectTo(`/vouchers`);
          },
        }
      );
    },
    [customCode, quantity, prefix, create, notify, redirectTo]
  );

  // const {
  //   availableCurrencies,
  //   isMultiCurrency,
  //   defaultCurrency,
  // } = useAvailableCurrencies();
  // const isBusinessPlan = useIsBusinessPlan();
  const thirtyDaysLater = addDays(new Date(), 30);
  return (
    <Create {...props} redirect="list">
      <SimpleForm
        onSubmit={handleSave}
        toolbar={
          <Toolbar>
            <SaveButton disabled={isLoading} />
          </Toolbar>
        }
        defaultValues={{
          page: pageId,
          expiredAt: thirtyDaysLater,
          value: "",
        }}
      >
        <CustomCodeCheckbox />
        {customCode && (
          <TextInput
            source="code"
            parse={stripSpecialtoUpper}
            validate={required()}
          />
        )}
        {!customCode && <CustomQuantity />}
        {!customCode && <CustomPrefix />}
        <SelectInput
          source="discountType"
          emptyText={DISCOUNT_TYPE_VALUE}
          emptyValue={DISCOUNT_TYPE_VALUE}
          defaultValue={DISCOUNT_TYPE_VALUE}
          choices={discountTypes.filter(({ id }) => id !== DISCOUNT_TYPE_VALUE)}
        />
        <PositiveDecimalInput source="value" validate={required()} />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              formData.discountType === "percentage" && (
                <PositiveDecimalInput
                  source="maxDiscount"
                  label="Max discount amount"
                  validate={[required(), minValue(0, "Must be 0 or positive")]}
                  defaultValue={0}
                  helperText={unlimitText}
                />
              )
            );
          }}
        </FormDataConsumer>
        <PositiveDecimalInput
          source="minPurchaseAmount"
          validate={[required(), minValue(0, "Must be 0 or positive")]}
          defaultValue={0}
          helperText={unlimitText}
        />
        <PositiveIntegerInput
          source="maxUsage"
          validate={[
            required(),
            minValue(0, "Must be 0 or positive"),
            largerThanMaxUsagePerCustomer,
          ]}
          defaultValue={1}
          helperText={unlimitText}
        />
        <PositiveIntegerInput
          source="maxUsagePerCustomer"
          validate={[required(), minValue(0, "Must be 0 or positive")]}
          defaultValue={1}
          helperText={unlimitText}
        />

        <BooleanInput
          source="discountAfterShipping"
          defaultValue={true}
          label="Discount Include Shipping"
        />
        <CustomPickerInput source="expiredAt" />
        <BooleanInput source="isActive" defaultValue={true} />
      </SimpleForm>
    </Create>
  );
};

const VoucherCreateWrapper = (props) => {
  return (
    <VoucherContextProvider>
      <VoucherCreate {...props} />
    </VoucherContextProvider>
  );
};

export default VoucherCreateWrapper;
