import React, { useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone, { getFilesFromEvent } from "react-dropzone-uploader";
import imageCompression from "browser-image-compression";

// import { useProfile } from "../DataHooks/useProfile";
import { usePresignedUrl } from "../DataHooks/fileUpload";
import { useNotify } from "react-admin";

// const s3Bucket = "https://img-product.boxify.io/";

// standard file paths are
// /receipts/file_name
// /products/file_name
// /sellers/file_name

const resize = async (e) => {
  const files = getFilesFromEvent(e);
  const options = {
    maxSizeMB: 0.2,
    maxWidthOrHeight: 1920,
  };

  const promises = files.map((f) => {
    try {
      if (f.size > 200000) {
        return imageCompression(f, options);
      }
      return f;
    } catch (error) {
      // console.log(error);
      return f;
    }
  });
  const compressedFiles = await Promise.all(promises);
  return compressedFiles;
};

const Uploader = (props) => {
  const { onChangeStatus = () => {}, disabled = false, maxFiles = 10 } = props;
  // const { profile } = useProfile();
  const { trigger: getPresignedUploadParams } = usePresignedUrl();
  const notify = useNotify();
  const [upload, setUpload] = useState([]);
  // const [files, setFiles] = useState([]);

  // specify upload params and url for your files
  const getUploadParams = React.useCallback(
    async (fileWithMeta) => {
      const { file, meta } = fileWithMeta;
      const date = new Date();
      const timestamp = date.getTime();
      const metaName = encodeURIComponent(meta.name);
      const fileName = `products/${timestamp}-${metaName}`;
      try {
        const [{ getUrl, putUrl }] = await getPresignedUploadParams([fileName]);

        setUpload((upload) => [
          ...upload,
          {
            name: getUrl,
            originalName: meta.name,
            id: meta.id,
            uploaded: false,
          },
        ]);

        return {
          method: "PUT",
          body: file,
          meta: { getUrl },
          url: putUrl,
        };

        // return {
        //   fields: {
        //     key: fileName,
        //     "Content-Type": meta.type,
        //   },
        //   url: s3Bucket,
        // };
      } catch (e) {
        notify(`Failed to upload, please try again later.`, { type: "error" });
      }
    },
    [getPresignedUploadParams, notify]
  );

  // called every time a file's `status` changes
  const handleChangeStatus = React.useCallback(
    (fileWithMeta, status) => {
      const { meta } = fileWithMeta;
      const theFile = upload.find((f) => f.id === meta.id);
      onChangeStatus(theFile, status);
      // console.log(fileWithMeta, status);
      if (status === "done") {
        setUpload((upload) =>
          upload.map((f) => {
            if (f.id === meta.id) {
              return {
                ...f,
                uploaded: true,
              };
            }
            return f;
          })
        );
      }
    },
    [onChangeStatus, upload]
  );

  const completed = upload.filter((f) => f.uploaded === true);
  const inProgress = upload.filter((f) => f.uploaded === false);
  return (
    <>
      <Dropzone
        // styles={{
        //   dropzone: { width: width * 0.85, height: width * 0.65 },
        //   dropzoneActive: { borderColor: "green" }
        // }}
        disabled={disabled}
        accept="image/*"
        inputContent="Upload product photo"
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        getFilesFromEvent={resize}
        maxFiles={maxFiles}
        // multiple={false}
        // canRemove={false}
      />
      <ul>
        {inProgress.length > 0 &&
          inProgress.map((c) => (
            <li key={c.id}>{`${c.originalName} uploading.....`}</li>
          ))}
        {completed.length > 0 &&
          completed.map((c) => (
            <li key={c.id}>{`${c.originalName} uploaded successfully`}</li>
          ))}
      </ul>
    </>
  );
};

export default Uploader;
