import React, { useState, useCallback } from "react";
import useSWR from "swr";
import { useRefresh, useNotify, useUnselectAll } from "react-admin";
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { deleteRequest } from "../../helpers/fetchHandleError";
import { useIsSuperAdminMode } from "../DataHooks/superAdminMode";
import Loader from "../Loader";
import { CANCELLED } from "../Constant";
import { withAdminOnly } from "../../helpers/withPermission";
import { useCustomAllRecords } from "../Common/useCustomAllRecords";

const RedSpan = styled.span`
  color: red;
`;

const MAX_CONCURRENT = 30;

const BulkDelete = (props) => {
  const { resource, selectedIds, setOpen } = props;
  const isSuperAdmin = useIsSuperAdminMode();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll(resource);
  const [error, setError] = useState(false);
  const [agree, setAgree] = useState(isSuperAdmin);
  const allRecords = useCustomAllRecords();

  const [orderIds, setOrderIds] = useState(selectedIds);
  const orders = allRecords.filter((record) => orderIds.includes(record._id));

  const [requests, setRequests] = useState([]);
  const { isValidating } = useSWR(
    requests.length > 0
      ? `${
          process.env.REACT_APP_CMS_HOST
        }/orders/remove?orderids=${requests.join(",")}`
      : null,
    deleteRequest,
    {
      revalidateOnFocus: false,
      onSuccess: () => {
        if (orders.length === 0) {
          refresh();
          setOpen(false);
          notify("Operation successful.");
        } else if (orders.length > 0) {
          const ids = orders.slice(0, MAX_CONCURRENT).map(({ _id }) => _id);
          setRequests(ids);
          setOrderIds(orders.slice(MAX_CONCURRENT).map(({ _id }) => _id));
        }
        unselectAll();
      },
      onError: (err) => {
        notify(" Something went wrong, please try again later.", {
          type: "warning",
        });

        setError(err);
        setRequests([]);
        setOrderIds([]);
        unselectAll();
      },
    }
  );

  if (isValidating) {
    return <Loader open />;
  }
  const nonCancelledOrders = orders.filter(
    ({ status }) => status !== CANCELLED
  );
  const merchantHasNonCancelledOrder =
    !isSuperAdmin && nonCancelledOrders.length > 0;

  return (
    <Dialog open>
      <DialogTitle>{"Are you sure to delete these orders ?"}</DialogTitle>
      <DialogContent>
        {merchantHasNonCancelledOrder && (
          <p>
            <RedSpan>{`Only cancelled orders can be deleted. You have ${nonCancelledOrders.length} orders are not "cancelled" status.`}</RedSpan>
          </p>
        )}
        {nonCancelledOrders.length === 0 && (
          <p>
            <RedSpan>{`Use this at your own risk. Deleted orders cannot be restored and will be deleted permanently. The assiociated payments, order items will be deleted.`}</RedSpan>
          </p>
        )}
        <div>
          <FormControlLabel
            control={
              <Checkbox
                disabled={merchantHasNonCancelledOrder}
                checked={agree}
                onChange={(e) => setAgree(e.target.checked)}
                color="primary"
              />
            }
            label="I agree to delete orders at my own risk."
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (window.confirm("Are you sure ?")) {
              const ids = orders.slice(0, MAX_CONCURRENT).map(({ _id }) => _id);
              setRequests(ids);
              setOrderIds(orders.slice(MAX_CONCURRENT).map(({ _id }) => _id));
            }
          }}
          disabled={
            !agree || isValidating || error || merchantHasNonCancelledOrder
          }
        >
          Delete
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOrderIds([]);
            setOpen(false);
          }}
          color="secondary"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const BulkDeleteButton = (props) => {
  const [open, setOpen] = useState(false);
  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <>
      <Button onClick={handleClick}>
        <RedSpan>
          <DeleteForeverIcon />
        </RedSpan>
        Delete
      </Button>
      {open && <BulkDelete {...props} setOpen={setOpen} />}
    </>
  );
};

export default withAdminOnly(BulkDeleteButton);
