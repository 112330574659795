import React from "react";
import IconButton from "@mui/material/IconButton";
import BlockIcon from "@mui/icons-material/Block";
import Tooltip from "@mui/material/Tooltip";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import { useSWRConfig } from "swr";

import { useIsHobbyistPlan } from "../../helpers/subscriptionPlan";
import { useUpdateContact } from "../DataHooks/useGetFbProfileImage";
import { CACHE_CONTACTS } from "../Constant";

const BlockSpan = styled.span`
  color: ${(props) => (props.isBlacklisted ? "red" : "inherit")};
`;

const BlacklistButton = (props) => {
  const { contact } = props;
  const isHobbyist = useIsHobbyistPlan();
  const { mutate: cacheMutate } = useSWRConfig();

  const { trigger: updateContact, isMutating } = useUpdateContact(contact._id, {
    onSuccess: (data) => {
      cacheMutate(
        `${CACHE_CONTACTS}/${data.psid}`,
        (prev) => ({
          ...prev,
          isBlacklisted: data.isBlacklisted,
        }),
        { revalidate: false }
      );
    },
  });

  if (isMutating) {
    return <CircularProgress size={15} />;
  } else if (isHobbyist) {
    return (
      <BlockSpan isBlacklisted={contact.isBlacklisted}>
        <BlockIcon />
      </BlockSpan>
    );
  }
  return (
    <BlockSpan isBlacklisted={contact.isBlacklisted}>
      <Tooltip
        title={
          contact.isBlacklisted ? `Press to Un-blacklist` : "Press to blacklist"
        }
      >
        <IconButton
          color="inherit"
          onClick={() => {
            updateContact({ isBlacklisted: !contact.isBlacklisted });
          }}
          edge="start"
          size="small"
        >
          <BlockIcon />
        </IconButton>
      </Tooltip>
    </BlockSpan>
  );
};

export default BlacklistButton;
