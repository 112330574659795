import React from "react";
import Card from "@mui/material/Card";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { makeStyles } from "@mui/styles";

import Typography from "@mui/material/Typography";
import { Loading, Error } from "react-admin";
import useSWR from "swr";
import { isArray } from "../../helpers/methods";
import CardIcon from "./CardIcon";

const errorOrderFilter = { status_in: ["error_payment_mismatch"] };
const url = `/#/orders?filter=${encodeURIComponent(
  JSON.stringify(errorOrderFilter)
)}`;

const useStyles = makeStyles({
  main: {
    flex: "1",
    marginLeft: "1em",
    marginTop: 20,
  },
  card: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    minHeight: 52,
  },
  title: { color: "red" },
});

const Comp = (props) => {
  const { profile } = props;
  // const translate = useTranslate();
  const classes = useStyles();
  const {
    isValidating,
    error,
    data: orders,
  } = useSWR(
    `${process.env.REACT_APP_CMS_HOST}/orders?status_in=error_payment_mismatch&page=${profile.page._id}&_sort=createdAt:DESC&_limit=200`
  );
  // {
  //   type: "getList",
  //   resource: "orders",
  //   payload: {
  //     filter: {
  //       page: profile.page._id,
  //       status: "error_payment_mismatch",
  //     },
  //     sort: { field: "createdAt", order: "DESC" },
  //     pagination: { page: 1, perPage: 200 },
  //   },
  // }
  if (isValidating) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }
  const showOrders = isArray(orders) ? orders : [];
  if (showOrders.length <= 0) {
    return null;
  }
  return (
    <div className={classes.main}>
      <CardIcon Icon={ShoppingCartIcon} bgColor="red" />
      <Card className={classes.card}>
        <Typography className={classes.title} color="textSecondary">
          {`Orders require attention`}
        </Typography>
        <Typography variant="h5" component="a" target="_blank" href={url}>
          {`Error payment mismatch ${showOrders.length}`}
        </Typography>
        {/* {Object.keys(ordersByStatus).map((k) => (
          <span key={k}>
            <Typography className={classes.title} color="textSecondary">
              {k}
            </Typography>
            <Typography variant="h5" component="h2">
              {ordersByStatus[k].length}
            </Typography>
          </span>
        ))} */}
      </Card>
    </div>
  );
};

export default Comp;
