import useSWRMutation from "swr/mutation";

import { fetchHandleError, sendRequest } from "../../helpers/fetchHandleError";
import { getFormData, fetch_retry } from "../../helpers/methods";
import { useNotify } from "react-admin";

export const createComment = ({ value, video, profile }) => {
  const data = getFormData({ message: value });
  return fetch(
    `${process.env.REACT_APP_GRAPH_API_URL}/${video.id}/comments?access_token=${profile.page.token}`,
    {
      method: "post",
      body: data,
    }
  ).then(fetchHandleError);
};

export const createOrderItem = ({ payload, token }) =>
  fetch_retry(
    `${process.env.REACT_APP_CMS_HOST}/orderitems/manual-create`,
    {
      retries: 3,
      retryDelay: 2000,
      method: "post",
      body: JSON.stringify(payload),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    },
    3
  ).then(fetchHandleError);

export const useMimicFBComment = (
  url = `${process.env.REACT_APP_CMS_HOST}/orderitems/manual-create`,
  option
) => {
  const notify = useNotify();
  return useSWRMutation(url, sendRequest, {
    onError: (e) => {
      notify(`Submit error: ${e.err}`, { type: "error" });
    },
    ...option,
  });
};
