import React from "react";
import {
  List,
  // SimpleList,
  Datagrid,
  TextField,
  // NumberField,
  // BooleanField,
  Pagination,
  Filter,
  TextInput,
  // BulkDeleteButton,
  // useRefresh,
  // useNotify,
} from "react-admin";

// import classnames from "classnames";
// import { useMediaQuery } from "@mui/material";
import { getPageId } from "../../helpers/getPageId";
import { useIsEnterprisePlan } from "../../helpers/subscriptionPlan";
import CustomDateField from "../Common/CustomDateField";
import CustomPickerInput from "../OrderItem/CustomPickerInput";

// import { exporter } from "./Exporter";
// import BulkUpdateActiveButton from "./BulkUpdateActiveButton";
// import { useAvailableCurrencies } from "../useAvailableCurrencies";
import ListActions from "./ListActions";
// import CustomBulkDeleteButton from "./CustomBulkDeleteButton";
// import CopyTemplateButton from "./CopyTemplateButton";

// const BulkActionButtons = (props) => (
//   <>
//     <BulkUpdateActiveButton {...props} />
//     {/* Add the default bulk delete action */}
//     <CustomBulkDeleteButton {...props} />
//   </>
// );

// const tinyFont = { fontSize: "10px" };
// const redStyle = { color: "red" };
// const normalStyle = { color: "inherit" };

// const useStyles = makeStyles({
//   amber: { color: "#FFCC00" },
//   red: { color: "red" },
// });

// const withStockStyle = (Wrapped) => (props) => {
//   const classes = useStyles();
//   const { record, source } = props;
//   const className = classnames({
//     [classes.amber]:
//       record && record["stock"] / 4 > record[source] && record[source] > 0,
//     [classes.red]: record && record[source] <= 0,
//   });
//   return <Wrapped {...props} className={className} />;
// };

// const withActiveStyle = (Wrapped) => (props) => {
//   const classes = useStyles();
//   const { record, source } = props;
//   const className = classnames({
//     [classes.red]: record && !record[source],
//   });
//   return <Wrapped {...props} className={className} />;
// };

const DefaultFilters = (props) => (
  <Filter {...props}>
    <TextInput
      label="Label"
      source="label_contains"
      alwaysOn
      //parse={(v) => v.toUpperCase()}
    />
    <TextInput label="Description" source="description_contains" alwaysOn />
    <CustomPickerInput source="createdAt_gte" label="CreatedAt start" />
    <CustomPickerInput source="createdAt_lte" label="CreatedAt end" />
    <CustomPickerInput source="updatedAt_gte" label="UpdatedAt start" />
    <CustomPickerInput source="updatedAt_lte" label="UpdatedAt end" />
  </Filter>
);

const TagList = (props) => {
  const pageId = getPageId();
  const isEnterprise = useIsEnterprisePlan();
  //   const { isMultiCurrency } = useAvailableCurrencies();
  // const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <List
      {...props}
      //bulkActionButtons={<BulkActionButtons />}
      actions={<ListActions />}
      //exporter={exporter}
      filter={{ page: pageId }}
      filters={<DefaultFilters />}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
      sort={{ field: "createdAt", order: "DESC" }}
    >
      <Datagrid rowClick="show">
        <TextField source="label" label="Label" />
        <TextField source="description" label="Description" />
        <CustomDateField source="createdAt" showTime />
        <CustomDateField source="updatedAt" showTime />
      </Datagrid>
    </List>
  );
};

export default TagList;
