import useSWRMutation from "swr/mutation";
import { sendRequest } from "../../helpers/fetchHandleError";
import { useNotify } from "react-admin";

export const usePresignedUrl = (
  url = `${process.env.REACT_APP_CMS_HOST}/tools/presign-urls`,
  option = {}
) => {
  const notify = useNotify();
  return useSWRMutation(url, sendRequest, {
    onError: (e) => {
      notify(e.error.message, { type: "error" });
    },
    ...option,
  });
};
