import { useRecordContext } from "react-admin";
import { CANCELLED, SHIPPED } from "../Constant";
import { useProfile } from "./useProfile";

export const useIsOrderEditable = () => {
  const data = useRecordContext();
  const { profile } = useProfile();
  if (profile && profile.params && profile.params.enableEditCompletedOrders) {
    return true;
  } else if (data) {
    // if this is an order
    if (
      data.orderNumber &&
      data.status !== SHIPPED &&
      data.status !== CANCELLED
    ) {
      return true;
    }
    //if this is orderitem
    if (
      data.order &&
      data.order.orderNumber &&
      data.order.status &&
      data.order.status !== SHIPPED &&
      data.order.status !== CANCELLED
    ) {
      return true;
    }
  }
  return undefined;
};
