import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { useProfile } from "../DataHooks/useProfile";
import { store, SET_COMMENT_SCROLL, SET_REPLY_COMMENT_TO } from "./store";
import { fetchHandleError } from "../../helpers/fetchHandleError";
import { getFormData } from "../../helpers/methods";

const ReplyCommentModal = (props) => {
  const { profile } = useProfile();

  const { dispatch, state } = React.useContext(store);
  const { replyCommentTo } = state;
  const { commentId, customerName } = replyCommentTo;

  const [value, setValue] = React.useState("");

  const handleClose = React.useCallback(() => {
    dispatch({ type: SET_REPLY_COMMENT_TO, payload: null });
  }, [dispatch]);

  const onClickReply = useCallback(() => {
    const data = getFormData({ message: value });
    fetch(
      `${process.env.REACT_APP_GRAPH_API_URL}/${commentId}/comments?access_token=${profile.page.token}`,
      {
        method: "post",
        body: data,
      }
    )
      .then(fetchHandleError)
      .then((resp) => {})
      .catch((e) => {
        alert("Error posting comment");
      });
    handleClose();
    setValue("");
  }, [commentId, handleClose, profile, value]);

  React.useEffect(() => {
    dispatch({ type: SET_COMMENT_SCROLL, payload: false });
    return () => {
      dispatch({ type: SET_COMMENT_SCROLL, payload: true });
    };
  }, [dispatch]);

  return (
    <>
      <Dialog
        open={!!replyCommentTo}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {`Reply ${customerName}`}
        </DialogTitle>
        <DialogContent>
          <TextField
            multiline
            autoFocus
            fullWidth
            label={`Comment`}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={onClickReply}
            color="primary"
            disabled={!value.trim()}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReplyCommentModal;
