import React from "react";
import get from "lodash/get";
import { useRecordContext } from "react-admin";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Tooltip from "@mui/material/Tooltip";
import queryString from "query-string";
import { Box } from "@mui/material";

const ExportParamsField = () => {
  const record = useRecordContext();
  const endpoint = get(record, "endpoint");
  const url = new URL(endpoint);
  const searchParams = url.searchParams;
  const parsed = queryString.parse(searchParams.toString());
  const params = Object.entries(parsed);
  const str = params
    .filter(([k]) => !["page", "_start"].includes(k))
    .map(([k, v]) => {
      if (k.includes("createdAt") || k.includes("updatedAt")) {
        return [k, new Date(v).toString()];
      }
      return [k, v];
    })
    .map(([k, v]) => `${k}: ${v}`)
    .join(",\n");

  return (
    <>
      <Tooltip title={<Box sx={{ whiteSpace: "pre-line" }}>{str}</Box>}>
        <span>
          <VisibilityIcon color="primary" />
        </span>
      </Tooltip>
    </>
  );
};

export default ExportParamsField;
