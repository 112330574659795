import React from "react";
import { TextField, useRecordContext } from "react-admin";
import Avatar from "@mui/material/Avatar";
import { makeStyles } from "@mui/styles";

import { withDisabledIfCancelled } from "../helpers/withDisabled";
import { ORDER_ITEM_CANCELLED } from "../components/Constant";
import TextLinkField from "../components/Common/TextLinkField";
import { replaceImageSource } from "../helpers/withReplaceImageSource";

const OrderItemTextField = withDisabledIfCancelled(TextField);
const OrderItemTextLinkField = withDisabledIfCancelled(TextLinkField);

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
}));

const Comp = (props) => {
  const classes = useStyles();
  const record = useRecordContext();
  const { disableLink, ...otherProps } = props;
  const { keyword, status } = record;
  if (status === ORDER_ITEM_CANCELLED) {
    return <OrderItemTextField {...otherProps} />;
  }
  return (
    <span className={classes.root}>
      <Avatar
        className={classes.avatar}
        src={
          keyword && keyword.images.length && keyword.images.length > 0
            ? replaceImageSource(keyword.images[0].name)
            : ""
        }
        style={{ width: 25, height: 25 }}
      />
      {disableLink && <OrderItemTextField {...otherProps} />}
      {!disableLink && (
        <OrderItemTextLinkField
          {...otherProps}
          source="description"
          resourceName="orderItems"
          resourceKeyId="_id"
        />
      )}
    </span>
  );
};

export default Comp;
