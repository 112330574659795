import React, { useEffect, useContext, useCallback } from "react";
import { useSidebarState } from "react-admin";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { makeStyles } from "@mui/styles";

import { useProfile } from "../DataHooks/useProfile";
import Player from "./Player";
import CommentList from "./CommentList";
// import ControlPanel from "./ControlPanel";
import SimpleTab from "./SimpleTab";
// import OrderTable from "./OrderTable";
import OrderTableNew from "./OrderTableNew";
import AddComment from "./AddComment";
import LiveCommentSubscriber from "./LiveCommentSubscriber";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  store,
  SET_PAGE,
  ADD_VIDEO,
  SET_COMMENT_SCROLL,
  SET_ENABLE_CUSTOMER_INFO,
  // SET_QUICK_CREATE_TARGET,
} from "./store";
import {
  store as commentStore,
  // ADD_COMMENT,
  CLEAR_COMMENT,
} from "./commentStore";
import QuickAddModal from "./QuickAddModal";
import ReplyCommentModal from "./ReplyCommentModal";
import Loader from "../Loader";

import SalesItemTable from "./Charts/SalesItemTable";
import { useFbLiveVideo } from "../DataHooks/useFbLiveVideo";
import { useFBProfile } from "../DataHooks/fbPageProfile";
import EditCommentModal from "./EditCommentModal";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  video: { height: 300 },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  redBackground: {
    backgroundColor: "red",
  },
}));

const LiveWindow = (props) => {
  const [open, setOpen] = useSidebarState();
  useProfile();
  const fbLiveVideo = useFbLiveVideo();
  const { data: fbProfile, error: fbProfileError } = useFBProfile();

  const { dispatch, state } = useContext(store);
  const {
    dispatch: commentDispatch,
    // state: commentState
  } = useContext(commentStore);
  // const { comments, video, loading, commentScroll } = state;
  const {
    video,
    loading,
    commentScroll,
    quickCreateTarget,
    replyCommentTo,
    enableCustomerInfo,
    editComment,
  } = state;

  const classes = useStyles();

  useEffect(() => {
    if (!!fbLiveVideo) {
      dispatch({ type: ADD_VIDEO, payload: fbLiveVideo });
      setOpen(false);
    }
  }, [dispatch, fbLiveVideo, setOpen]);

  useEffect(() => {
    if (fbProfile) {
      dispatch({ type: SET_PAGE, payload: fbProfile });
    }
  }, [dispatch, fbProfile]);

  // useEffect(() => {
  //   const id = setInterval(
  //     ({ comments: c, commentScroll }) => {
  //       if (!commentScroll) {
  //         return;
  //       }
  //       const num = Math.floor(Math.random() * 31);
  //       dispatch({
  //         type: ADD_COMMENT,
  //         payload: {
  //           id: "833306357412806_157605169077161",
  //           from: {
  //             name: `Name ${num}`,
  //             id: `Name ${num}`,
  //           },
  //           message: `aaaaaaaaaa
  //           ${c.length + 1}`,
  //         },
  //       });
  //     },
  //     1000,
  //     { comments, commentScroll }
  //   );
  //   return () => clearInterval(id);
  // }, [comments, commentScroll]);

  const onClearComment = useCallback(
    () => commentDispatch({ type: CLEAR_COMMENT }),
    [commentDispatch]
  );

  const onToggleCommentScroll = useCallback(() => {
    dispatch({ type: SET_COMMENT_SCROLL, payload: !commentScroll });
  }, [dispatch, commentScroll]);

  if (!fbLiveVideo || !video) {
    return (
      <div>No Live video found. Go Live first, then refresh this page.</div>
    );
  }

  return (
    <Container>
      <LiveCommentSubscriber />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <OrderTableNew />
          <SalesItemTable />
        </Grid>

        <Grid item xs={12} md={6}>
          <CommentList />
          <ButtonGroup
            color="secondary"
            aria-label="outlined secondary button group"
          >
            <Button
              variant="contained"
              className={classes.redBackground}
              startIcon={<DeleteIcon />}
              onClick={onClearComment}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              color="secondary"
              startIcon={commentScroll ? <PauseIcon /> : <PlayArrowIcon />}
              onClick={onToggleCommentScroll}
            >
              {commentScroll ? `Pause` : `Play`}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                dispatch({
                  type: SET_ENABLE_CUSTOMER_INFO,
                  payload: !enableCustomerInfo,
                });
              }}
            >
              {enableCustomerInfo ? `Hide info` : `Show info`}
            </Button>
          </ButtonGroup>
          <AddComment video={fbLiveVideo} />
        </Grid>
        <Grid item xs={12} md={8}>
          <SimpleTab />
        </Grid>
        <Grid item xs={6} md={4}>
          {/* <div className="player-wrapper" style={{ maxHeight: "30%" }}> */}
          <Player
            url={`https://www.facebook.com${fbLiveVideo.permalink_url}`}
          />
          {/* </div> */}
        </Grid>
      </Grid>
      {!!quickCreateTarget && <QuickAddModal />}
      {!!replyCommentTo && <ReplyCommentModal />}
      {!!editComment && <EditCommentModal />}
      <Loader open={loading}></Loader>
    </Container>
  );
};

export default LiveWindow;
