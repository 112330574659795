import React from "react";
import { ImageField } from "react-admin";

//TODO This is to replace fb-live-files the old s3 to new URL, and also files migrated to R2. THis function expect to be removed around year 2025

const newHost = "d1k1mqizaznpuc.boxify.io";
const oldHost = "fb-live-files.s3.amazonaws.com";

const OLD_IMG_PRODUCT_URL = "img-product.boxify.io";
const OLD_IMG_SELLER_URL = "img-seller.boxify.io";

const R2_MIGRATED_URL = "aws-fb-live-files.boxify.io";

export const replaceImageSource = (src = "") =>
  src
    .replace(oldHost, newHost)
    .replace(OLD_IMG_PRODUCT_URL, R2_MIGRATED_URL)
    .replace(OLD_IMG_SELLER_URL, R2_MIGRATED_URL);

export const withReplaceImageSource = (WrapComp) => (props) => {
  const { src, ...otherProps } = props;
  const newSrc = replaceImageSource(src);
  return <WrapComp src={newSrc} {...otherProps} />;
};

export const CustomSrcImageField = withReplaceImageSource(ImageField);
