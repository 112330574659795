import React from "react";
import {
  List,
  // SimpleList,
  Datagrid,
  TextField,
  //NumberField,
  // BooleanField,
  Pagination,
  Filter,
  TextInput,
  // BooleanInput,
  // BulkDeleteButton,
  // useRefresh,
  // useNotify,
} from "react-admin";

// import ListActions from "./ListActions";
// import { exporter } from "./Exporter";
import { getPageId } from "../../helpers/getPageId";
import CustomDateField from "../Common/CustomDateField";
import CustomPickerInput from "../OrderItem/CustomPickerInput";
import ColorCodeField from "./ColorCodeField";

const DefaultFilters = (props) => (
  <Filter {...props}>
    <TextInput label="Label" source="label_contains" alwaysOn />
    <TextInput label="Description" source="description_contains" alwaysOn />
    <CustomPickerInput source="createdAt_gte" label="CreatedAt start" />
    <CustomPickerInput source="createdAt_lte" label="CreatedAt end" />
  </Filter>
);

const VariantList = (props) => {
  const pageId = getPageId();

  return (
    <>
      <List
        {...props}
        //actions={<ListActions />}
        exporter={false}
        filter={{ page: pageId }}
        filters={<DefaultFilters />}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        sort={{ field: "createdAt", order: "DESC" }}
      >
        <Datagrid rowClick="show">
          <TextField source="label" label="Label" />
          <TextField source="description" />
          <ColorCodeField label="Color code" />
          <CustomDateField source="createdAt" showTime />
          <CustomDateField source="updatedAt" showTime />
        </Datagrid>
      </List>
    </>
  );
};

export default VariantList;
