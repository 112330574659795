import useSWRMutation from "swr/mutation";
import { sendRequest } from "../../helpers/fetchHandleError";
import { useNotify } from "react-admin";

export const useUpdateCredit = (contactId, option = {}) => {
  const url = contactId
    ? `${process.env.REACT_APP_CMS_HOST}/contacts/${contactId}/update-credit`
    : null;
  const notify = useNotify();
  return useSWRMutation(
    url,
    (url, config) => sendRequest(url, { ...config, method: "PUT" }),
    {
      onError: (e) => {
        notify(e.error.message, { type: "error" });
      },
      ...option,
    }
  );
};
