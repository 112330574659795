import React from "react";
import {
  useDelete,
  useRefresh,
  useNotify,
  useRecordContext,
} from "react-admin";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";

import DeleteIcon from "@mui/icons-material/Delete";

import { withShowIfOfflinePayment } from "../components/Payment/withShowIfOffline";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  red: { color: "red" },
}));

const PaymentDeleteButton = (props) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const classes = useStyles();
  const record = useRecordContext();
  const [deleteOne, { loading, error }] = useDelete(
    "payments",
    { id: record.id, previousData: record },
    {
      onSuccess: () => {
        refresh();
        notify("Payment deleted", "info", {}, true);
      },
      onFailure: (error) => notify("Payment deletion failed", "warning"),
    }
  );

  const handler = React.useCallback(() => {
    const result = window.confirm("Are you sure ?");
    if (result === true) {
      deleteOne();
    }
  }, []);
  return (
    <Button disabled={loading} onClick={handler} className={classes.red}>
      <DeleteIcon />
    </Button>
  );
};

export default withShowIfOfflinePayment(PaymentDeleteButton);
