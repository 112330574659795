import React from "react";
import { TextField, useRecordContext } from "react-admin";
import { CONTACT_CHANNEL_FACEBOOK, CONTACT_CHANNEL_OFFLINE } from "../Constant";

export const withDefaultChannel = (WrapComp) => (props) => {
  const { ...otherProps } = props;
  const record = useRecordContext();

  const { contact, ...otherProps2 } = record;

  if (contact) {
    return (
      <WrapComp
        {...otherProps}
        record={{
          ...otherProps2,
          contact: {
            ...contact,
            channel: contact.channel
              ? contact.channel
              : CONTACT_CHANNEL_FACEBOOK,
          },
        }}
      />
    );
  }
  return (
    <WrapComp
      {...otherProps}
      record={{
        ...otherProps2,
        contact: { ...contact, channel: CONTACT_CHANNEL_OFFLINE },
      }}
    />
  );
};

const ChannelField = withDefaultChannel(TextField);

export default ChannelField;
