import React from "react";
import { useRecordContext } from "react-admin";
import { makeStyles } from "@mui/styles";

import Badge from "@mui/material/Badge";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import { compose } from "redux";
import useSWR from "swr";
import groupBy from "lodash/groupBy";

import { withBusinessOnlyElseHide } from "../helpers/subscriptionPlan";
import { CANCELLED, SHIPPED } from "../components/Constant";
import { useProfile } from "../components/DataHooks/useProfile";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const RelatedOrders = (props) => {
  const record = useRecordContext();
  const { contact, _id } = record;
  const classes = useStyles();
  const { profile } = useProfile();
  const { data: orders, error } = useSWR(
    profile && contact && contact._id
      ? `${process.env.REACT_APP_CMS_HOST}/orders?_sort=createdAt:DESC&_start=0&_limit=100&contact=${contact._id}&status_nin=${CANCELLED}&status_nin=${SHIPPED}&_id_nin=${_id}&page=${profile.page._id}`
      : null,
    {
      revalidateOnFocus: false,
    }
  );

  if (!orders && !error) {
    return null;
  } else if (error) {
    return <p>Related orders error.</p>;
  }

  const grouped = groupBy(orders, (order) => order.status);
  const orderKeys = Object.keys(grouped);

  return (
    <div className={classes.root}>
      {orderKeys.map((key) => {
        const theOrders = grouped[key];
        const statusLabel = key.split("_").join(" ");
        return (
          <Tooltip
            key={key}
            title={`Customer has ${statusLabel} orders`}
            placement="top-start"
          >
            <Badge badgeContent={theOrders.length} color="secondary">
              <Chip
                size="small"
                label={statusLabel}
                variant="outlined"
                component="a"
                target="_blank"
                rel="noopener noreferrer"
                href={`/#/orders?filter=${encodeURIComponent(
                  JSON.stringify({
                    contact: contact._id,
                    _id_ne: _id,
                    page: profile.page._id,
                    status: key,
                  })
                )}`}
                clickable
              />
            </Badge>
          </Tooltip>
        );
      })}
    </div>
  );
};

const withContactExist = (WrapComp) => (props) => {
  const record = useRecordContext();
  if (record && record.contact && record.contact._id) {
    return <WrapComp {...props} />;
  }
  return null;
};

const enhance = compose(withBusinessOnlyElseHide, withContactExist);

export default enhance(RelatedOrders);
