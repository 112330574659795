import React from "react";
import { Labeled, useRecordContext } from "react-admin";
import get from "lodash/get";

export const withShowIfExist = (WrappedComponent) => (props) => {
  const { label, source = undefined, customLabel = "", ...otherProps } = props;

  const record = useRecordContext();
  const target = get(record, source);

  if (target) {
    //empty array
    if (target instanceof Array && target.length < 1) {
      return null;
    }

    //empty object
    if (Object.keys(target).length === 0 && target.constructor === Object) {
      return null;
    }
    return (
      <Labeled label={customLabel ? customLabel : label}>
        <WrappedComponent {...otherProps} source={source} />
      </Labeled>
    );
  }
  return null;
};
