import React, { useState, useCallback } from "react";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DialogActions from "@mui/material/DialogActions";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import {
  // Confirm,
  // crudUpdateMany,
  // startUndoable,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
} from "react-admin";

import { orderItemStatuses } from "../orders";
import { ORDER_ITEM_CANCELLED } from "../components/Constant";
import LoadingButton from "@mui/lab/LoadingButton";
import { useCustomAllRecords } from "../components/Common/useCustomAllRecords";

const OrderItemBulkUpdateStatusButton = (props) => {
  const { resource, selectedIds } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [recalculateStock, setRecalculateStock] = React.useState(true);
  const [value, setValue] = useState("");
  const allRecords = useCustomAllRecords();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll(resource);

  const selectedRecords = allRecords.filter((rec) =>
    selectedIds.includes(rec._id)
  );

  const selectedWithFlagIds =
    value === ORDER_ITEM_CANCELLED && recalculateStock
      ? selectedRecords.map(({ id, keyword }) => {
          if (keyword) {
            return `${id}?recalculateStock=true`;
          } else {
            return id;
          }
        })
      : selectedIds;
  const [updateMany, { isLoading }] = useUpdateMany(
    resource,
    { ids: selectedWithFlagIds, data: { status: value } },
    {
      onSuccess: () => {
        refresh();
        notify("Order items updated", "info", {}, true);
        setIsOpen(false);
        unselectAll();
      },
      onFailure: (error) => notify("Error: Order items not updated", "warning"),
      //mutationMode: "undoable",
    }
  );

  const handleClick = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const handleDialogClose = useCallback(() => {
    setIsOpen(false);
    unselectAll();
  }, [unselectAll]);

  const handleConfirm = () => {
    updateMany();
    // setIsOpen(false);
  };

  return (
    <>
      <Button
        startIcon={<EditIcon />}
        label="Update status"
        onClick={handleClick}
      >
        status
      </Button>
      <Dialog
        fullScreen={false}
        open={isOpen}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Select status"}
        </DialogTitle>
        <DialogContent>
          <Select
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            // onClose={handleDialogClose}
            inputProps={{
              name: "status",
            }}
          >
            {orderItemStatuses.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.name}
              </MenuItem>
            ))}
          </Select>
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={recalculateStock}
                onChange={() => {
                  setRecalculateStock((prev) => !prev);
                }}
                disabled={value !== ORDER_ITEM_CANCELLED}
                name="recalculateStock"
              />
            }
            label="Recalculate stock"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            color="secondary"
            disabled={isLoading}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isLoading}
            disabled={!value || isLoading}
            onClick={handleConfirm}
            color="primary"
            autoFocus
          >
            Update
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrderItemBulkUpdateStatusButton;
