import React from "react";
import { useRecordContext } from "react-admin";

const PaymentPaidAmount = (props) => {
  const { source } = props;
  const record = useRecordContext();
  const value = Number(record[source]);
  if (!isNaN(value)) {
    const v2 = value / 100;
    return <span>{v2.toFixed(2)}</span>;
  }
  return <span>0</span>;
};
export default PaymentPaidAmount;
