import React from "react";
import { useRecordContext } from "react-admin";
// import Button from "@mui/material/Button";
// import InboxIcon from "@mui/icons-material/Inbox";
import { compose } from "redux";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

// import { fetchHandleError } from "../helpers/fetchHandleError";
// import { useProfile } from "../components/DataHooks/useProfile";

const InboxLink = () => {
  const record = useRecordContext();
  // const { profile } = useProfile();

  const { commentId = undefined } = record;
  // const handler = useCallback(() => {
  //   fetch(
  //     `https://graph.facebook.com/${commentId}?access_token=${profile.page.token}&fields=private_reply_conversation`
  //   )
  //     .then(fetchHandleError)
  //     .then((data) => {
  //       if (
  //         data.private_reply_conversation &&
  //         data.private_reply_conversation.link
  //       ) {
  //         window.open(
  //           `https://www.facebook.com${data.private_reply_conversation.link}`
  //         );
  //       }
  //     })
  //     .catch((e) => {
  //       alert(
  //         "The comment and conversation were deleted, or not found (customer privacy setting)."
  //       );
  //     });
  // }, [commentId, profile.page.token]);

  if (commentId) {
    if (commentId.indexOf("_") > -1)
      //is FB comment
      return (
        <a
          href={`https://www.facebook.com/${commentId}`}
          target="_blank"
          rel="noreferrer"
        >
          comment
          <OpenInNewIcon fontSize="5" />
        </a>
      );
  } else {
    //is IG comment
  }
  return null;

  // return (
  //   <Button
  //     size="small"
  //     variant="outlined"
  //     startIcon={<InboxIcon />}
  //     onClick={handler}
  //   >
  //     Inbox
  //   </Button>
  // );
};

const withIfCommentIdExist = (WrappedComp) => (props) => {
  const record = useRecordContext();
  if (record && record.commentId) {
    return <WrappedComp {...props} />;
  }
  return null;
};

const enhance = compose(withIfCommentIdExist);

export default enhance(InboxLink);
