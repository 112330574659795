import React, { useState } from "react";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import endOfToday from "date-fns/endOfToday";
import subDays from "date-fns/subDays";
import differenceInDays from "date-fns/differenceInDays";
import addDays from "date-fns/addDays";
// import { DatePicker, MuiPickersUtilsProvider } from "@mui/x-date-pickers";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "@mui/material/TextField";
import {
  LocalizationProvider,
  DatePicker,
  DateTimePicker,
} from "@mui/x-date-pickers";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import { useNotify } from "react-admin";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const ChartWithFilter = (props) => {
  const {
    token = "",
    chartId = "",
    initialDays = 7,
    maxDays = 30,
    enableTimeSelector = false,
  } = props;
  const chartEl = React.useRef(null);
  const chartObj = React.useRef(null);
  const notify = useNotify();
  const [showTimeSelector, setShowTimeSelector] = useState(false);
  const ThePicker = showTimeSelector ? DateTimePicker : DatePicker;
  const [dateRange, setDateRange] = React.useState({
    endDate: endOfToday().toISOString(),
    startDate: startOfDay(subDays(endOfToday(), initialDays)),
  });

  const onChangeTimeSelector = React.useCallback((event) => {
    setShowTimeSelector(event.target.checked);
  }, []);

  const customStartOfDay = React.useCallback(
    (date) => (showTimeSelector ? date : startOfDay(date)),
    [showTimeSelector]
  );

  const customEndOfDay = React.useCallback(
    (date) => (showTimeSelector ? date : endOfDay(date)),
    [showTimeSelector]
  );

  const getUserToken = React.useCallback(() => token, [token]);
  const onChangeStartDate = React.useCallback(
    (d) => {
      setDateRange(({ startDate, endDate }) => {
        const startDateObj = new Date(d);
        const endDateObj = new Date(endDate);

        const days = differenceInDays(endDateObj, startDateObj);
        if (days > maxDays || startOfDay(startDateObj) > endOfDay(endDateObj)) {
          const newEndDate = addDays(startDateObj, maxDays);
          return {
            startDate: customStartOfDay(startDateObj),
            endDate: customEndOfDay(newEndDate),
          };
        } else {
          return {
            startDate: customStartOfDay(startDateObj),
            endDate: customEndOfDay(endDateObj),
          };
        }
      });
    },
    [customEndOfDay, customStartOfDay, maxDays]
  );

  const onChangeEndDate = React.useCallback(
    (d) => {
      setDateRange(({ startDate, endDate }) => {
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(d);
        const days = differenceInDays(endDateObj, startDateObj);
        if (days > maxDays || startOfDay(startDateObj) > endOfDay(endDateObj)) {
          const newStartDate = startOfDay(subDays(endDateObj, maxDays));
          return {
            startDate: newStartDate,
            endDate: customEndOfDay(endDateObj),
          };
        } else {
          return {
            startDate: customStartOfDay(startDate),
            endDate: customEndOfDay(endDateObj),
          };
        }
      });
    },
    [customEndOfDay, customStartOfDay, maxDays]
  );

  React.useEffect(() => {
    if (chartObj && chartObj.current) {
      const filter = {
        createdAt: {
          $gte: new Date(dateRange.startDate),
          $lte: new Date(dateRange.endDate),
        },
      };
      chartObj.current.setFilter(filter);
    }
  }, [dateRange]);

  React.useEffect(() => {
    const sdk = new ChartsEmbedSDK({
      baseUrl: process.env.REACT_APP_CHARTS_BASE_URL,
      getUserToken: getUserToken,
      showAttribution: false,
    });
    const chart = sdk.createChart({
      chartId,
      filter: {
        createdAt: {
          $gte: new Date(dateRange.startDate),
          $lte: new Date(dateRange.endDate),
        },
      },
    });
    chart
      .render(chartEl.current)
      .then((e) => {
        chartObj.current = chart;
      })
      .catch(() =>
        notify(`Chart ${chartId} initialize failed.`, { type: "warning" })
      );
  }, []);

  return (
    <div>
      <Card>
        <Container>
          <Box sx={{ mx: 1 }}>
            <LocalizationProvider dateAdapter={DateFnsUtils}>
              <ThePicker
                label={`Start date`}
                value={dateRange.startDate}
                onChange={onChangeStartDate}
                renderInput={(params) => <TextField {...params} />}
              />
              <ThePicker
                label={`End date`}
                value={dateRange.endDate}
                onChange={onChangeEndDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            {enableTimeSelector && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showTimeSelector}
                      onChange={onChangeTimeSelector}
                    />
                  }
                  label="Select time"
                />
              </FormGroup>
            )}
          </Box>
          <div style={{ height: "500px" }}>
            <div style={{ width: "100%", height: "100%" }} ref={chartEl}></div>
          </div>
        </Container>
      </Card>
    </div>
  );
};

export default ChartWithFilter;
