import React from "react";
import Toolbar from "@mui/material/Toolbar";

import CreateOrderItem from "./CreateOrderItem";
import MarkPaidDialog from "./MarkPaidDialog";

const Actions = (props) => {
  const { currency, order } = props;
  return (
    <Toolbar>
      <CreateOrderItem orderId={order} currency={currency} />
      <MarkPaidDialog {...props} />
    </Toolbar>
  );
};

export default Actions;
