import React from "react";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import CircularProgress from "@mui/material/CircularProgress";

import { getCookie } from "../../helpers/Cookies";
import { useLogout, useNotify } from "react-admin";
import { sendRequest } from "../../helpers/fetchHandleError";

export const useProfile = (url = "", args) => {
  const logout = useLogout();

  const profile = getCookie("profile");
  const target = !url
    ? `${process.env.REACT_APP_CMS_HOST}/profile/${profile}`
    : url;
  const { data, error, mutate } = useSWR(!!profile ? target : null, {
    revalidateOnFocus: false,
    refreshInterval: 1 * 60 * 1000,
    onError: (err) => {
      if (err === "Invalid token." || err === "Forbidden") {
        alert("Invalid session, logging out.");
        logout();
      }
    },
  });
  return {
    profile: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export const useUpdateProfile = (option = {}) => {
  const { mutate } = useProfile;
  const profile = getCookie("profile");
  const url = profile
    ? `${process.env.REACT_APP_CMS_HOST}/profile/${profile}`
    : null;
  const notify = useNotify();
  return useSWRMutation(
    url,
    (url, config) => sendRequest(url, { ...config, method: "PUT" }),
    {
      onError: (e) => {
        notify(e.error.message, { type: "error" });
      },
      onSuccess: () => {
        mutate();
      },
      ...option,
    }
  );
};

export const withProfile = (WrappedComponent) => (props) => {
  const { profile, isLoading, isError } = useProfile();
  if (profile) {
    return <WrappedComponent profile={profile} {...props} />;
  } else if (isLoading) {
    return <CircularProgress size="1rem" />;
  } else if (isError) {
    return "Failed to load";
  }
  return <WrappedComponent {...props} />;
};
