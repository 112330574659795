// import React from "react";
import Loadable from "react-loadable";

import { withBusinessOnly } from "../../helpers/subscriptionPlan";

const LoadableCustomUpdate = Loadable({
  loader: () => import("./CombineOrders.js"),
  loading: () => "Loading",
});

export default withBusinessOnly(LoadableCustomUpdate);
