import React, { useEffect, useState } from "react";
import { useController } from "react-hook-form";
import InfoIcon from "@mui/icons-material/Info";
import TextField from "@mui/material/TextField";

import ProductImageUpload from "./ProductImageUpload";
import {
  useIsBusinessPlan,
  useIsEnterprisePlan,
} from "../../helpers/subscriptionPlan";
import SortableGallery from "./SortableGallery";

const Comp = (props) => {
  const keywordImageInput = useController({ name: "images" });
  const [localImages, setLocalImages] = useState(keywordImageInput.field.value);
  const formImages = keywordImageInput.field.value;
  const isBusinessPlan = useIsBusinessPlan();
  const isEnterprisePlan = useIsEnterprisePlan();
  const maxImagePerProduct = isEnterprisePlan ? 10 : isBusinessPlan ? 5 : 1;
  const isOverMaxImagePerProduct = formImages.length >= maxImagePerProduct;

  useEffect(() => {
    keywordImageInput.field.onChange(localImages);
  }, [localImages]); // deps should only have local state else will loop update

  const onChangeStatus = React.useCallback(
    (theFile, status) => {
      // const images = keywordImageInput.field.value;
      if (status === "done") {
        const { name } = theFile;
        setLocalImages((prev) => [...prev, { name: encodeURI(name) }]);
        // keywordImageInput.field.onChange([
        //   ...images,
        //   { name: encodeURI(name) },
        // ]);
      } else if (status === "removed") {
        const newImages = localImages.filter(
          (img) => img.name !== encodeURI(theFile.name)
        );
        setLocalImages(newImages);
        // const newImages = images.filter(
        //   (img) => img.name !== encodeURI(theFile.name)
        // );
        // keywordImageInput.field.onChange(newImages);
      }
    },
    [localImages]
  );

  const onSortHandler = React.useCallback((items) => {
    // const newImages = items.map((i) => ({ name: i }));
    setLocalImages(items);
    // keywordImageInput.field.onChange(items);
  }, []);

  const onRemove = React.useCallback(
    (url) => {
      const newImages = localImages.filter((i) => i.name !== url);
      setLocalImages(newImages);
      // const newImages = formImages.filter((i) => i.name !== url);
      // keywordImageInput.field.onChange(newImages);
    },
    [localImages]
  );

  // const items = formImages.map((img) => img.name);
  const video = formImages.find((v) => v.type && v.type === "video");

  return (
    <span>
      <div>{`Maximum ${maxImagePerProduct} image.`}</div>
      {!isBusinessPlan && (
        <div>{`Maximum ${maxImagePerProduct} image, upgrade plan to get more.`}</div>
      )}
      <ProductImageUpload
        maxFiles={maxImagePerProduct}
        onChangeStatus={onChangeStatus}
        disabled={isOverMaxImagePerProduct}
      />
      <TextField
        label="Video 1"
        onChange={(e) => {
          let exist = false;
          if (!e.target.value) {
            // remove the video if empty
            const newImages = formImages.filter((d) => !d.type);
            setLocalImages(newImages);
            // keywordImageInput.field.onChange(newImages);
            return;
          }
          const newImages = formImages.map((d) => {
            if (d.type && d.type === "video") {
              exist = true;
              return { type: "video", name: e.target.value };
            }
            return d;
          });
          if (!exist) {
            setLocalImages([
              ...newImages,
              {
                type: "video",
                name: e.target.value,
              },
            ]);
            // keywordImageInput.field.onChange([
            //   ...newImages,
            //   {
            //     type: "video",
            //     name: e.target.value,
            //   },
            // ]);
          } else {
            keywordImageInput.field.onChange(newImages);
          }
        }}
        placeholder="video embed url"
        value={video ? video.name : ""}
        fullWidth
        margin="normal"
        //shrink
      />
      {/* <input
        {...keywordImageInput.field}
        name="images"
        component="input"
        type="hidden"
      /> */}
      <div>
        <InfoIcon />
        Drag and drop to sort images
      </div>
      <SortableGallery
        onSortHandler={onSortHandler}
        onRemove={onRemove}
        items={formImages}
      />
    </span>
  );
};
export default Comp;
