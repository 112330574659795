// import React from "react";
import Decimal from "decimal.js-light";

import { useReconciledPayment } from "../../Order/ReconciledPayment";
import { useGetDiscountValue } from "../Voucher/useGetDiscount";
import {
  getSum,
  getNonCancelledItems,
  getMaxZeroD,
} from "../../helpers/methods";
import { useGetCreditPayment } from "../Credit/hooks";

//this is clone function between FE UI
const calculateTaxExclWithShippingTax = (props) => {
  const {
    merchandiseTotal,
    shippingDecimal,
    discountValue,
    taxRate,
    totalReconciledPaidAmount,
    totalAppliedCreditDecimal,
    deductableCreditD,
  } = props;

  const total = merchandiseTotal
    .plus(shippingDecimal)
    .minus(discountValue)
    .minus(totalAppliedCreditDecimal)
    .minus(deductableCreditD);

  const taxableAmountD = getMaxZeroD(total.toNumber()); // if over apply credit or discount the tax will be zero

  const taxAmount = taxableAmountD.times(taxRate).dividedBy(100).todp(2);
  const totalBeforeTax = taxableAmountD;
  const totalAfterTax = taxableAmountD.plus(taxAmount);
  const balanceDecimal = total.plus(taxAmount).minus(totalReconciledPaidAmount);

  return {
    totalBeforeTax,
    taxAmount,
    totalAfterTax,
    balanceDecimal,
    taxableAmount: total,
  };
};

export const useTaxExclWithShippingTax = ({ record = {}, taxRate = 0 }) => {
  const { payments = [], orderItems = [], shippingCost = 0 } = record;
  const discountValue = useGetDiscountValue(record);
  const totalReconciledPaidAmount = useReconciledPayment(payments);
  const totalCreditPayment = useGetCreditPayment(payments);

  const items = orderItems.filter(getNonCancelledItems);
  const merchandiseTotal = new Decimal(getSum(items));
  const shippingDecimal = isNaN(parseInt(shippingCost))
    ? new Decimal(0)
    : new Decimal(shippingCost);

  const {
    totalBeforeTax,
    taxAmount,
    totalAfterTax,
    balanceDecimal,
    taxableAmount,
  } = calculateTaxExclWithShippingTax({
    merchandiseTotal,
    shippingDecimal,
    discountValue,
    taxRate,
    totalReconciledPaidAmount,
    totalAppliedCreditDecimal: new Decimal(totalCreditPayment),
    deductableCreditD: new Decimal(0),
  });
  return {
    merchandiseTotal: merchandiseTotal.toFixed(2),
    totalBeforeTax: totalBeforeTax.toFixed(2),
    shippingCost: shippingDecimal.toFixed(2),
    taxAmount: taxAmount.toFixed(2),
    totalAfterTax: totalAfterTax.toFixed(2),
    totalReconciledPaidAmount,
    outstandingPayment: balanceDecimal.toFixed(2),
    taxableAmount: taxableAmount.toFixed(2),
  };
};
export const getTaxExclWithShippingTax = useTaxExclWithShippingTax;

//this is clone function between FE UI
const calculateTaxExclWithoutShippingTax = (props) => {
  const {
    merchandiseTotal,
    shippingDecimal,
    discountValue,
    taxRate,
    totalReconciledPaidAmount,
    totalAppliedCreditDecimal,
    deductableCreditD,
  } = props;

  const taxableAmountD = getMaxZeroD(
    merchandiseTotal
      .minus(discountValue)
      .minus(totalAppliedCreditDecimal)
      .minus(deductableCreditD)
      .toNumber()
  );

  const taxAmount = taxableAmountD.times(taxRate).dividedBy(100);
  const total = merchandiseTotal
    .plus(shippingDecimal)
    .minus(discountValue)
    .minus(totalAppliedCreditDecimal)
    .minus(deductableCreditD);

  const totalBeforeTax = getMaxZeroD(total.toNumber());
  const totalAfterTax = getMaxZeroD(total.plus(taxAmount).toNumber());
  const balanceDecimal = total.plus(taxAmount).minus(totalReconciledPaidAmount);
  return {
    totalBeforeTax,
    taxAmount,
    totalAfterTax,
    balanceDecimal,
    taxableAmount: taxableAmountD,
  };
};

export const useTaxExclWithoutShippingTax = ({ record = {}, taxRate = 0 }) => {
  const { payments = [], orderItems = [], shippingCost = 0 } = record;
  const discountValue = useGetDiscountValue(record);
  const totalReconciledPaidAmount = useReconciledPayment(payments);
  const totalCreditPayment = useGetCreditPayment(payments);

  const items = orderItems.filter(getNonCancelledItems);
  const merchandiseTotal = new Decimal(getSum(items));
  const shippingDecimal = isNaN(parseInt(shippingCost))
    ? new Decimal(0)
    : new Decimal(shippingCost);
  const {
    totalBeforeTax,
    taxAmount,
    totalAfterTax,
    balanceDecimal,
    taxableAmount,
  } = calculateTaxExclWithoutShippingTax({
    merchandiseTotal,
    shippingDecimal,
    discountValue,
    taxRate,
    totalReconciledPaidAmount,
    totalAppliedCreditDecimal: new Decimal(totalCreditPayment),
    deductableCreditD: new Decimal(0),
  });

  return {
    merchandiseTotal: merchandiseTotal.toFixed(2),
    totalBeforeTax: totalBeforeTax.toFixed(2),
    shippingCost: shippingDecimal.toFixed(2),
    taxAmount: taxAmount.toFixed(2),
    totalAfterTax: totalAfterTax.toFixed(2),
    totalReconciledPaidAmount,
    outstandingPayment: balanceDecimal.toFixed(2),
    taxableAmount: taxableAmount.toFixed(2),
  };
};
export const getTaxExclWithoutShippingTax = useTaxExclWithoutShippingTax;
