import React from "react";
import { withStyles, makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { fetchHandleError } from "../../helpers/fetchHandleError";

const SPREADSHEET_ID = `1nVWx9WSYxHb3hQRoTHluv7rhEyxCaMBg2wl9Sn_A1Xs`;
const URL = `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/Sheet1?key=AIzaSyCA8z7htvMhJWh0vAUj9UKVtu_Ei8Plo2A`;

const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: theme.palette.common.grey,
    // color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const NotificationComp = () => {
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    fetch(URL)
      .then(fetchHandleError)
      .then((data) => {
        const dataRows = data.values.slice(1).map((row) => {
          return {
            date: row[0],
            announcement: row[1],
            link: row[2],
          };
        });
        setRows(dataRows);
      })
      .catch((err) => {
        console.warn(err);
      });
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell align="right">Updates</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.announcement}>
              <StyledTableCell component="th" scope="row">
                {row.date}
              </StyledTableCell>
              <StyledTableCell align="right">
                <a href={row.link} target="_blank" rel="noopener noreferrer">
                  {row.announcement}
                </a>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default NotificationComp;
