const orderDefaultListView = {
  "contact.id": false,
  "contact.remarks": false,
  customerName: true,
  status: true,
  shippingOption: true,
  merchandiseTotal: true,
  weight: false,
  outstandingPayment: true,
  shippingPostcode: true,
  currencyCode: false,
  createdAt: true,
  updatedAt: true,
  deliveryOrderPrinted: false,
  email: false,
  notes: false,
  description: false,
  invoiceNumber: false,
  orderNumber: false,
  overrideShippingCost: false,
  shippingAddress1: false,
  shippingAddress2: false,
  shippingCity: false,
  shippingCost: false,
  shippingCountry: false,
  shippingName: false,
  shippingPhoneNumber: false,
  shippingProvince: false,
  shippingState: false,
  shippingSubDistrict: false,
  shippingVillage: false,
  trackingNumber: false,
};

export default orderDefaultListView;
