import React from "react";
import {
  SimpleForm,
  Edit,
  TextInput,
  BooleanInput,
  required,
} from "react-admin";
import AttachSelections from "../AutocompleteAttach/AttachSelections";
import { PositiveIntegerInput } from "../Common/IntegerInput";

export const CategoryEdit = (props) => {
  return (
    <Edit title="Edit category" {...props}>
      <SimpleForm redirect="list">
        <TextInput source="label" validate={required()} />
        <TextInput multiline source="description" validate={required()} />
        <PositiveIntegerInput source="position" validate={required()} />
        <BooleanInput source="allowInWeb" />
        <AttachSelections source="selections" searchFieldName="label" />
      </SimpleForm>
    </Edit>
  );
};

export default CategoryEdit;
