import useSWRMutation from "swr/mutation";
import useSWR from "swr";

import { sendRequest } from "../../helpers/fetchHandleError";
import { fetchWithBearerToken } from "../Common/swrMiddlewares";
import { useProfile } from "../DataHooks/useProfile";

export const useCreateExport = (
  url = `${process.env.REACT_APP_CMS_HOST}/exports`,
  option = {}
) => {
  return useSWRMutation(url, sendRequest, {
    use: [fetchWithBearerToken],
    ...option,
  });
};

/*
request: POST /exports
{
    "endpoint": "http://localhost:1337/orders?_sort=_id:DESC&_start=0&_limit=5000&createdAt_gte=2019-01-01T00:00:00.000Z&createdAt_lte=2024-01-01T00:00:00.000Z&status_in=active&status_in=paid&status_in=waiting_for_payment&status_in=on_hold&page=5dc2309e5fccd600e07b3d26",
    "totalRecords": 5000
}

response:
{
    "status": "pending",
    "read": false,
    "_id": "63c1873df135fdf1d5b0b00d",
    "endpoint": "http://localhost:1337/orders?_sort=_id:DESC&_start=0&_limit=5000&createdAt_gte=2019-01-01T00:00:00.000Z&createdAt_lte=2024-01-01T00:00:00.000Z&status_in=active&status_in=paid&status_in=waiting_for_payment&status_in=on_hold&page=5dc2309e5fccd600e07b3d26",
    "totalRecords": 5000,
    "recordsPerPage": 500,
    "user": "5d8f18874bc5ab4839355b97",
    "page": "5dc2309e5fccd600e07b3d26",
    "__v": 0,
    "createdAt": "2023-01-13T16:30:53.827Z",
    "updatedAt": "2023-01-13T16:30:53.827Z",
    "id": "63c1873df135fdf1d5b0b00d"
}
------------------------------------
*/

export const useGetExport = () => {
  const { profile } = useProfile();
  return useSWR(
    profile
      ? `${process.env.REACT_APP_CMS_HOST}/exports?_sort=createdAt:DESC,_id:DESC&_start=0&_limit=20&page=${profile.page._id}`
      : null,
    {
      focusThrottleInterval: 5 * 60 * 1000,
      revalidateOnFocus: false,
    }
  );
};

export const useGetExportCount = (filters = []) => {
  const { profile } = useProfile();
  const str = filters.map((f) => `${f[0]}=${f[1]}`).join("&");
  return useSWR(
    profile
      ? `${
          process.env.REACT_APP_CMS_HOST
        }/exports/count?_sort=createdAt:DESC,_id:DESC&_start=0&_limit=20&page=${
          profile.page._id
        }${str ? "&" + str : ""}`
      : null,
    {
      focusThrottleInterval: 5 * 60 * 1000,
      revalidateOnFocus: false,
    }
  );
};
