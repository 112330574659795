import React from "react";
import Avatar from "@mui/material/Avatar";

import {
  useContactIgProfile,
  useGetFbProfileImage,
} from "../DataHooks/useGetFbProfileImage";
import { CONTACT_CHANNEL_INSTAGRAM } from "../Constant";

const AvatarField = ({ record, size = "25", className }) => {
  const [errored, setErrored] = React.useState(false);

  const isInstagram =
    record && record.channel && record.channel === CONTACT_CHANNEL_INSTAGRAM;

  const fbProfileImageUrl = useGetFbProfileImage(
    errored && !isInstagram ? record.psid : ""
  );

  const { data: igProfile } = useContactIgProfile(
    errored && isInstagram ? record.psid : ""
  );

  const imageUrl =
    errored && fbProfileImageUrl
      ? fbProfileImageUrl
      : errored && igProfile && igProfile.profile_pic
      ? igProfile.profile_pic
      : record.profilePic;
  return (
    <Avatar
      src={imageUrl}
      style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
      className={className}
      onError={() => {
        setErrored(true);
      }}
    />
  );
};

export default AvatarField;
