import { unparse as convertToCSV } from "papaparse/papaparse.min";
import Decimal from "decimal.js-light";

import { downloadCSVWithBom } from "../../helpers/methods";

export const exporter = (payments) => {
  const paymentsForExport = payments.map((payment) => {
    const {
      __v,
      updatedAt,
      _id,
      id,
      page,
      order = {},
      paidAmount,
      createdAt,
      ...paymentForExport
    } = payment; // omit backlinks and author
    const createdTime = new Date(createdAt);
    const updatedTime = new Date(updatedAt);
    const { customerName = "", orderNumber = "", currencyCode = "" } = order;
    const newPaidAmount = new Decimal(paidAmount).dividedBy(100).toNumber();
    return {
      ...paymentForExport,
      createdAtDate: createdTime.toLocaleDateString(),
      createdAtTime: createdTime.toLocaleTimeString(),
      updatedAtDate: updatedTime.toLocaleDateString(),
      updatedAtTime: updatedTime.toLocaleTimeString(),
      customerName,
      orderNumber,
      currencyCode,
      paidAmount: newPaidAmount,
      invoiceNumber: order.invoiceNumber,
      paymentId: id,
    };
  });
  const csv = convertToCSV({
    data: paymentsForExport,
    fields: [
      "paymentId",
      "customerName",
      "orderNumber",
      "type",
      "paid",
      "paidAmount",
      "remarks",
      "invoiceNumber",
      "currencyCode",
      "createdAtDate",
      "createdAtTime",
      "updatedAtDate",
      "updatedAtTime",
    ],
  });
  downloadCSVWithBom(csv, `payments_${new Date().toLocaleDateString()}`);
};
