import React from "react";
import { makeStyles } from "@mui/styles";

import WarningIcon from "@mui/icons-material/Warning";
import differenceInDays from "date-fns/differenceInDays";
import isFuture from "date-fns/isFuture";
import Box from "@mui/material/Box";

import { withProfile } from "../DataHooks/useProfile";

import { printDate } from "../../helpers/methods";

const useStyles = makeStyles({
  red: { color: "red" },
  blinkMe: {
    color: "red",
    animation: "$blinker 1s linear infinite",
  },
  "@keyframes blinker": {
    "50%": {
      opacity: 0,
    },
  },
});

const Blinker2 = ({ children, isBlink = false }) => {
  const classes = useStyles();

  return (
    <Box alignItems="center" display="flex">
      {isBlink && (
        <Box>
          <WarningIcon
            className={classes.blinkMe}
            color="primary"
            fontSize="large"
          />
        </Box>
      )}
      <Box>{children}</Box>
    </Box>
  );
};

const ExpiryText2 = (props) => {
  const { profile } = props;

  const isTrialValid =
    profile.page.isTrial && isFuture(new Date(profile.page.trialEndDate));
  const isSubscriptionValid =
    profile.page.isSubscribed &&
    isFuture(new Date(profile.page.subscriptionEndDate));

  if (isTrialValid) {
    const days = differenceInDays(
      new Date(new Date(profile.page.trialEndDate)),
      new Date()
    );
    return (
      <Blinker2 isBlink={days < 7}>{`Trial ends ${printDate(
        profile.page.trialEndDate
      )}`}</Blinker2>
    );
  } else if (isSubscriptionValid) {
    const days = differenceInDays(
      new Date(new Date(profile.page.subscriptionEndDate)),
      new Date()
    );
    return days <= 14 ? (
      <Blinker2 isBlink={days < 7}>{`Subscription ends ${printDate(
        profile.page.subscriptionEndDate
      )}`}</Blinker2>
    ) : null;
  } else if (isTrialValid === false && isSubscriptionValid === false) {
    return <Blinker2 isBlink>{`System expired`}</Blinker2>;
  }
  return null;
};

export default withProfile(ExpiryText2);
