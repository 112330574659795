import React from "react";
import { UserMenu, MenuItemLink } from "react-admin";
import SettingsIcon from "@mui/icons-material/Settings";
import { SELLER_ROLE } from "../../helpers/role";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import PaymentIcon from "@mui/icons-material/Payment";
import format from "date-fns/format";

import {
  useIsEnterprisePlan,
  useIsBusinessPlan,
} from "../../helpers/subscriptionPlan";
import { useSubscription } from "../DataHooks/useSubscription";
import { useUserRole } from "../DataHooks/user";
import MyLogoutButton from "./LogoutButton";
import { useIGProfile } from "../DataHooks/fbPageProfile";

const MyUserMenu = (props) => {
  const permissions = useUserRole();
  const isEnterprisePlan = useIsEnterprisePlan();
  const isBusinessPlan = useIsBusinessPlan();
  const {
    pageName,
    psid,
    igsid = "",
    isTrialValid,
    isPlanValid,
    isSubscriptionValid,
    trialEndDate,
    subscriptionEndDate,
  } = useSubscription();

  if (!isTrialValid && !isSubscriptionValid) {
  }

  const planText = isEnterprisePlan
    ? `Enterprise Plan`
    : isBusinessPlan
    ? `Business Plan`
    : `Hobbyist Plan`;
  const theDate = isTrialValid ? trialEndDate : subscriptionEndDate;

  const { data: igProfile } = useIGProfile(igsid !== "" ? igsid : undefined);

  return (
    <UserMenu label={`My profile`} {...props}>
      <Box m={1} paddingX={1}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <FacebookIcon color="primary" />
          <a
            href={`https://facebook.com/${psid}`}
            target="_blank"
            rel="noreferrer"
          >
            <h3>{pageName}</h3>
          </a>
        </div>
        {igProfile && igProfile.instagram_business_account && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <InstagramIcon color="primary" />
            <a
              href={`https://instagram.com/${igProfile.instagram_business_account.username}`}
              target="_blank"
              rel="noreferrer"
            >
              <h3>{igProfile.instagram_business_account.username}</h3>
            </a>
          </div>
        )}
      </Box>
      {(isTrialValid || isSubscriptionValid) && (
        <Box m={1} paddingX={1}>
          <Typography gutterBottom variant="subtitle1">
            {`${planText} ${isTrialValid ? "Trial" : ""} valid until`}
            <br />
            <span>{`${format(new Date(theDate), "PPPp")}`}</span>
          </Typography>
        </Box>
      )}
      {!isPlanValid && (
        <Box m={1} paddingX={1}>
          <Typography color="error" gutterBottom variant="subtitle1">
            Plan expired
          </Typography>
        </Box>
      )}
      {permissions === SELLER_ROLE && (
        <MenuItemLink
          to="/billing"
          primaryText="Billing"
          leftIcon={<PaymentIcon />}
        />
      )}
      {permissions === SELLER_ROLE && isPlanValid && (
        <MenuItemLink
          to="/settings"
          primaryText="Settings"
          leftIcon={<SettingsIcon />}
        />
      )}
      <MyLogoutButton />
    </UserMenu>
  );
};

export default MyUserMenu;
