import React, { useEffect } from "react";
import { Login, LoginForm, useLogin, useNotify } from "react-admin";
import { makeStyles } from "@mui/styles";

import SocialLink from "../SocialLink";
import { fetchHandleError } from "../../helpers/fetchHandleError";
import Cookies from "../../helpers/Cookies";
import { TERMS_OF_USE, PRIVACY_POLICY } from "../Constant";
import { useSearchParams } from "react-router-dom";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  terms: {
    position: "absolute",
    width: "auto",
    color: "white",
  },
}));

const MyLoginPage = (props) => {
  const classes = useStyles();
  // const login = useLogin();
  // const notify = useNotify();
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get("access_token");

  // const [provider, setProvider] = useState("");
  // const [redir, setRedir] = useState(false);
  // const [hasPage, setHasPage] = useState(null);
  useEffect(() => {
    if (accessToken) {
      fetch(
        `${process.env.REACT_APP_CMS_HOST}/auth/facebook/callback?access_token=${accessToken}`
      )
        .then(fetchHandleError)
        .then((data) => {
          const { jwt } = data;
          Cookies.setCookie("token", jwt, 7);
        });
    }
  }, [accessToken]);

  return (
    <div>
      <Login>
        <LoginForm />
        <SocialLink provider={"facebook"} />

        <div className={classes.terms}>
          <strong>
            By logging in, you are agreeing to our{" "}
            <a target="_blank" rel="noreferrer noopener" href={TERMS_OF_USE}>
              Terms of Use
            </a>{" "}
            and{" "}
            <a target="_blank" rel="noreferrer noopener" href={PRIVACY_POLICY}>
              Privacy Policy
            </a>
            .
          </strong>
        </div>
      </Login>
      {/* <div>
        <a
          href="https://forms.gle/3dqYMEB4firxwSjY9"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="nv banner"
            src="https://img-seller.boxify.io/5dc2309e5fccd600e07b3d26/info/tngwallet-1663225058195.png"
            width="100%"
          />
        </a>
      </div> */}
    </div>
  );
};

export default MyLoginPage;
