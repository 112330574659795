import React, { useState, useCallback } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { useTheme } from "@mui/material/styles";
import ReactToPrint from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  useUnselectAll,
  useUpdateMany,
  useRefresh,
  useNotify,
  useListContext,
} from "react-admin";
import Loadable from "react-loadable";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import { printMonthDay } from "../../../helpers/methods";
import { useIsBusinessPlan } from "../../../helpers/subscriptionPlan";
import { withTooltipBusinessTier } from "../../../helpers/withTooltipBusinessTier";
import ConfirmationDialog from "../ConfirmationDialog";
import { useCustomAllRecords } from "../../Common/useCustomAllRecords";
import { useOrderItemsKeywords } from "../../DataHooks/useOrderItemsKeywords";
import { useIsShowImageInDo } from "../../DataHooks/useIsShowImageInDo";

const Content = Loadable({
  loader: () => import("./Content"),
  loading: () => "Loading",
});

const BusinessButton = withTooltipBusinessTier(Button);
const BULK_PRINT_LABEL = "Bulk print DO";

const Comp = (props) => {
  const showImageInDO = useIsShowImageInDo();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const isBusinessPlan = useIsBusinessPlan();
  const [originalTitle] = useState(document.title);
  const { resource, selectedIds } = useListContext();
  const allRecords = useCustomAllRecords();
  const [isOpen, setIsOpen] = useState(false);
  const componentRef = React.useRef();

  const unselectAll = useUnselectAll(resource);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDialogClose = useCallback(() => {
    setIsOpen(false);
    unselectAll();
  }, [setIsOpen, unselectAll]);

  const refresh = useRefresh();
  const notify = useNotify();
  const [update] = useUpdateMany(
    resource,
    { ids: selectedIds, data: { deliveryOrderPrinted: true } },
    {
      onSuccess: () => {
        setIsOpen(false);
        setOpenConfirmDialog(false);
        unselectAll();
        refresh();
        notify("Bulk set delivery orders printed successful", "info", {}, true);
      },
      onFailure: (error) =>
        notify("Bulk set delivery orders printed failed", "warning"),
      mutationMode: "pessimistic",
    }
  );
  const afterPrintHandler = React.useCallback(() => {
    setOpenConfirmDialog(true);
  }, [setOpenConfirmDialog]);

  const onOk = React.useCallback(() => {
    document.title = originalTitle;
    setOpenConfirmDialog(false);
    update();
  }, [originalTitle, update]);

  const onCancel = React.useCallback(() => {
    document.title = originalTitle;
    setOpenConfirmDialog(false);
  }, [originalTitle]);

  React.useEffect(() => {
    return () => {
      unselectAll();
    };
  }, [unselectAll]);

  const selectedRecords = allRecords.filter((rec) =>
    selectedIds.includes(rec._id)
  );
  const check = useCallback(
    () => isOpen && showImageInDO,
    [isOpen, showImageInDO]
  );
  const {
    data: keywords = [],
    error: keywordsError,
    isLoading,
    isValidating,
  } = useOrderItemsKeywords({
    ready: check,
    orders: selectedRecords,
  });
  const disablePrint = isLoading || isValidating || keywordsError;
  if (!isBusinessPlan) {
    return (
      <BusinessButton disabled startIcon={<PrintIcon />}>
        {BULK_PRINT_LABEL}
      </BusinessButton>
    );
  }

  const alreadyPrintedOrders = selectedRecords.filter(
    (contact) => contact.deliveryOrderPrinted
  );
  const flattenKeywords = keywords.flat();
  const recordsWithKeywords =
    flattenKeywords.length > 0
      ? selectedRecords.map((order) => {
          return {
            ...order,
            orderItems: order.orderItems.map(({ keyword, ...otherProps }) => {
              return {
                ...otherProps,
                ...(keyword && {
                  keyword: flattenKeywords.find((k) => k._id === keyword),
                }),
              };
            }),
          };
        })
      : selectedRecords;
  return (
    <>
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
        startIcon={<PrintIcon />}
        variant="outlined"
      >
        {BULK_PRINT_LABEL}
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={isOpen}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {BULK_PRINT_LABEL}
        </DialogTitle>
        <DialogContent>
          {alreadyPrintedOrders.length > 0 && (
            <>
              <Typography variant="subtitle1">
                WARNING: These are already printed
              </Typography>
              <ul>
                {alreadyPrintedOrders.map(({ _id, customerName }) => (
                  <li key={_id}>
                    <a
                      href={`/#/orders/${_id}/show`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`${customerName}`}
                    </a>
                  </li>
                ))}
              </ul>
            </>
          )}
          {(isLoading || isValidating) && (
            <Box sx={{ display: "flex" }}>
              <p>Fetching product images...</p>
              <CircularProgress size="1rem" />
            </Box>
          )}
          {keywordsError && <p>Error fetching image, please retry again.</p>}
          {!disablePrint && (
            <Content
              {...props}
              componentRef={componentRef}
              records={recordsWithKeywords}
            />
          )}
        </DialogContent>
        <DialogActions>
          <ReactToPrint
            trigger={() => (
              <Button
                variant="contained"
                color="primary"
                startIcon={<PrintIcon />}
                disabled={disablePrint}
              >
                Print
              </Button>
            )}
            content={() => componentRef.current}
            onBeforePrint={() =>
              (document.title = `orders_${printMonthDay(new Date())}`
                .split(" ")
                .join("_"))
            }
            onAfterPrint={afterPrintHandler}
          />
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        title={`Confirmation`}
        content={`Bulk set orders to printed ?`}
        open={openConfirmDialog}
        onCancel={onCancel}
        onOk={onOk}
      />
    </>
  );
};

export default Comp;
