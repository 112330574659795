import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
// import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import Comment from "./Comment";
import { store } from "./store";
import { store as commentStore } from "./commentStore";

import { SizeContext, DEFAULT_HEIGHT } from "./SizeContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 400,
    // maxWidth: 300,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  paddingLeft: {
    marginLeft: "3em",
  },
  input: {
    width: 22,
  },
}));

const CallbackComp = ({ callback }) => {
  useEffect(() => callback(), [callback]);
  return null;
};

export default function VirtualizedList(props) {
  const { state } = useContext(store);
  const { state: commentState } = useContext(commentStore);
  const { comments } = commentState;

  const { commentScroll } = state;

  const classes = useStyles();
  const listRef = useRef();
  const [autoScroll, setAutoScroll] = useState(true);
  const sizeMap = React.useRef({});
  const setSize = React.useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
  }, []);
  const getSize = React.useCallback(
    (index) => {
      return sizeMap.current[index] || DEFAULT_HEIGHT;
    },
    [sizeMap]
  );
  const renderRowCallback = useCallback(
    (args) => {
      const { index, style, data, isScrolling } = args;
      const comment = data.comments[index];

      return (
        <div key={index} style={style}>
          <Comment index={index} comment={comment} />
          {isScrolling && autoScroll && (
            <CallbackComp
              callback={() => {
                setAutoScroll(false);
              }}
            />
          )}

          {!isScrolling && data.comments.length - index < 2 && (
            <CallbackComp
              callback={() => {
                setAutoScroll(true);
              }}
            />
          )}
        </div>
      );
    },
    [autoScroll, setAutoScroll]
  );

  useEffect(() => {
    if (
      autoScroll &&
      commentScroll &&
      comments.length > 0 &&
      listRef &&
      listRef.current &&
      listRef.current.scrollToItem
    ) {
      listRef.current.scrollToItem(comments.length - 1, "end");
    }
  }, [comments.length]);

  return (
    <div className={classes.root}>
      <SizeContext.Provider value={{ setSize, listRef }}>
        <AutoSizer>
          {({ height, width }) => (
            <List
              ref={listRef}
              useIsScrolling
              height={height}
              width={width}
              itemSize={getSize}
              itemCount={comments.length}
              itemData={{ comments }}
            >
              {renderRowCallback}
            </List>
          )}
        </AutoSizer>
      </SizeContext.Provider>
    </div>
  );
}
