import React, { useCallback, useEffect } from "react";
import { useNotify } from "react-admin";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { store, SET_COMMENT_SCROLL, SET_EDIT_COMMENT } from "./store";
import { Grid } from "@mui/material";
import { KeyboardDoubleArrowRight } from "@mui/icons-material";
import { useMimicFBComment } from "./sharedMethods";
// import { getCookie } from "../../helpers/Cookies";

// This allows human tell BOXIFY what is the expected comment and it can't change the comment by audience on Facebook.

const EditCommentModal = (props) => {
  // const token = getCookie("token");
  const { dispatch, state } = React.useContext(store);
  const notify = useNotify();

  const { editComment, page, video } = state;
  const { from, message, id } = editComment;
  const [value, setValue] = React.useState("");
  const { trigger: postOrders, isMutating } = useMimicFBComment(undefined, {
    onSuccess: (newData) => {
      notify(
        `Successful correct from "${message}" to "${newData.data.orders[0].order.newMessage}"`,
        {
          type: "info",
        }
      );
    },
  });

  const handleClose = React.useCallback(() => {
    dispatch({ type: SET_EDIT_COMMENT, payload: undefined });
  }, [dispatch]);

  const onClickChange = useCallback(() => {
    const payload = {
      data: {
        orders: [
          {
            originalComment: editComment,
            newMessage: value,
            psid: from.id,
            customerName: from.name,
            commentId: id,
            parentId: page.id + "_" + video.id,
            _useKeyword: true, //a flag for nodered to filter
          },
        ],
      },
    };
    postOrders(payload);
    handleClose();
  }, [editComment, from, id, page, postOrders, value, video, handleClose]);

  // const onClickChange = useCallback(() => {
  //   const payload = {
  //     data: {
  //       orders: [
  //         {
  //           description: value,
  //           psid: from.id,
  //           customerName: from.name,
  //           commentId: id,
  //           parentId: page.id + "_" + video.id,
  //           useKeyword: true, //a flag for nodered to filter
  //         },
  //       ],
  //     },
  //   };
  //   createOrderItem({ token, payload })
  //     .then((resp) => {
  //       notify("Edit successfully");
  //       dispatch({ type: SET_LOADING, payload: false });
  //       handleClose();
  //     })
  //     .catch((e) => {
  //       notify("Error: edit failed", "warning");
  //       dispatch({ type: SET_LOADING, payload: false });
  //     });
  //   handleClose();
  // }, [
  //   id,
  //   dispatch,
  //   from.id,
  //   from.name,
  //   handleClose,
  //   notify,
  //   page.id,
  //   token,
  //   value,
  //   video.id,
  // ]);

  useEffect(() => {
    dispatch({ type: SET_COMMENT_SCROLL, payload: false });
    return () => {
      dispatch({ type: SET_COMMENT_SCROLL, payload: true });
    };
  }, []);

  return (
    <>
      <Dialog
        open={!!editComment}
        onClose={handleClose}
        aria-labelledby="comment-modal"
      >
        <DialogTitle id="comment-modal">{`Edit this comment`}</DialogTitle>
        <DialogContent>
          <h4>Please input the right comment for BOXIFY only.</h4>
          <span>(Original FB comment remains.)</span>
          <Grid container alignItems={"center"}>
            <Grid item>
              <TextField value={message} disabled label="Original Comment" />
            </Grid>
            <Grid item>
              <KeyboardDoubleArrowRight />
            </Grid>
            <Grid item>
              <TextField
                multiline
                autoFocus
                fullWidth
                placeholder="A01+1"
                label={`New Comment`}
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={onClickChange}
            disabled={
              !value.trim() ||
              (value && value.indexOf("+") === -1) ||
              isMutating
            }
          >
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditCommentModal;
