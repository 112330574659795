import React from "react";
import { useRecordContext } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

import { useGetTaxes } from "../components/DataHooks/useGetTaxes";

export const TaxEdit = (props) => {
  const { label } = props;
  const record = useRecordContext();
  const { taxes = [] } = useGetTaxes();
  const { setValue } = useFormContext();
  const taxValue = useWatch({ name: "tax" });
  if (!record.tax && taxes.length === 0) {
    return null;
  }

  const value =
    taxValue && taxValue._id ? taxValue._id : taxValue ? taxValue : ""; //when retrieve, tax is object. Upon selection it is _id

  return (
    <TextField
      select
      label={label}
      value={value}
      variant="filled"
      onChange={(e) => {
        setValue("tax", e.target.value, { shouldDirty: true });
      }}
      sx={{ minWidth: 150 }}
    >
      {[{ id: "", label: "No tax", internalLabel: "No tax" }, ...taxes].map(
        (option) => (
          <MenuItem key={option.id} value={option.id}>
            {`${option.label} (${option.internalLabel})`}
          </MenuItem>
        )
      )}
    </TextField>
  );
};

export const TaxInput = (props) => {
  const { source, label } = props;
  const { taxes = [], tax, isLoading } = useGetTaxes();
  const taxValue = useWatch({ name: "tax" });

  const { setValue } = useFormContext();

  React.useEffect(() => {
    if (tax) {
      setValue("tax", tax._id);
    }
  }, [setValue, tax]);

  if ((!tax && taxes.length === 0) || isLoading) {
    return null;
  }

  return (
    <TextField
      select
      label={label}
      value={taxValue || ""}
      variant="filled"
      onChange={(e) => {
        setValue("tax", e.target.value);
      }}
      sx={{ minWidth: 150 }}
    >
      {[{ id: "", label: "No tax", internalLabel: "No tax" }, ...taxes].map(
        (option) => (
          <MenuItem key={option.id} value={option.id}>
            {`${option.label} (${option.internalLabel})`}
          </MenuItem>
        )
      )}
    </TextField>
  );
};
