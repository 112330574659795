// import React from "react";
import Decimal from "decimal.js-light";

import { useReconciledPayment } from "../../Order/ReconciledPayment";
import { useGetDiscountValue } from "../Voucher/useGetDiscount";
import {
  getSum,
  getNonCancelledItems,
  getMaxZeroD,
} from "../../helpers/methods";
import { useGetCreditPayment } from "../Credit/hooks";

//this is clone function between FE UI
const calculateTaxInclWithShippingTax = (props) => {
  const {
    merchandiseTotal,
    shippingDecimal,
    discountValue,
    taxRate,
    totalReconciledPaidAmount,
    totalAppliedCreditDecimal,
    deductableCreditD,
  } = props;

  const totalAfterDiscountCredit = merchandiseTotal
    .plus(shippingDecimal)
    .minus(discountValue)
    .minus(totalAppliedCreditDecimal)
    .minus(deductableCreditD);

  const total = getMaxZeroD(totalAfterDiscountCredit.toNumber());

  //reverse the tax calculation
  const totalBeforeTax = new Decimal(total)
    .times(100)
    .dividedBy(100 + taxRate)
    .todp(2);
  const taxAmount = total.minus(totalBeforeTax);
  const totalAfterTax = total;
  const balanceDecimal = totalAfterDiscountCredit.minus(
    totalReconciledPaidAmount
  );

  return {
    totalBeforeTax,
    taxAmount,
    totalAfterTax,
    balanceDecimal,
    taxableAmount: totalBeforeTax,
  };
};

export const useTaxInclWithShippingTax = ({ record = {}, taxRate = 0 }) => {
  const { payments = [], orderItems = [], shippingCost = 0 } = record;
  const discountValue = useGetDiscountValue(record);
  const totalReconciledPaidAmount = useReconciledPayment(payments);
  const totalCreditPayment = useGetCreditPayment(payments);

  const items = orderItems.filter(getNonCancelledItems);
  const merchandiseTotal = new Decimal(getSum(items));
  const shippingDecimal = isNaN(parseInt(shippingCost))
    ? new Decimal(0)
    : new Decimal(shippingCost);

  const {
    totalBeforeTax,
    taxAmount,
    totalAfterTax,
    balanceDecimal,
    taxableAmount,
  } = calculateTaxInclWithShippingTax({
    merchandiseTotal,
    shippingDecimal,
    discountValue,
    taxRate,
    totalReconciledPaidAmount,
    totalAppliedCreditDecimal: new Decimal(totalCreditPayment),
    deductableCreditD: new Decimal(0),
  });

  return {
    merchandiseTotal: merchandiseTotal.toFixed(2),
    totalBeforeTax: totalBeforeTax.toFixed(2),
    shippingCost: shippingDecimal.toFixed(2),
    taxAmount: taxAmount.toFixed(2),
    totalAfterTax: totalAfterTax.toFixed(2),
    totalReconciledPaidAmount,
    outstandingPayment: balanceDecimal.toFixed(2),
    taxableAmount: taxableAmount.toFixed(2),
  };
};
export const getTaxInclWithShippingTax = useTaxInclWithShippingTax;

//this is clone function between FE UI
const calculateTaxInclWithoutShippingTax = (props) => {
  const {
    merchandiseTotal,
    shippingDecimal,
    discountValue,
    taxRate,
    totalReconciledPaidAmount,
    totalAppliedCreditDecimal,
    deductableCreditD,
  } = props;

  const merchandiseTotalAfterDiscountAfterCredit = merchandiseTotal
    .minus(discountValue)
    .minus(totalAppliedCreditDecimal)
    .minus(deductableCreditD);

  const total = getMaxZeroD(
    merchandiseTotalAfterDiscountAfterCredit.toNumber()
  );

  const totalBeforeTaxWithoutShipping = total
    .times(100)
    .dividedBy(100 + taxRate)
    .todp(2);

  const taxAmount = total.minus(totalBeforeTaxWithoutShipping);

  const totalAfterTax = getMaxZeroD(
    merchandiseTotalAfterDiscountAfterCredit.plus(shippingDecimal).toNumber()
  ); //total after tax will be zero (rather than negative) if credit/discount > order value
  const totalBeforeTax = totalAfterTax.minus(taxAmount);
  const balanceDecimal = merchandiseTotalAfterDiscountAfterCredit
    .plus(shippingDecimal)
    .minus(totalReconciledPaidAmount);

  return {
    totalBeforeTax,
    taxAmount,
    totalAfterTax,
    balanceDecimal,
    taxableAmount: totalBeforeTaxWithoutShipping,
  };
};

export const useTaxInclWithoutShippingTax = ({ record = {}, taxRate = 0 }) => {
  const { payments = [], orderItems = [], shippingCost = 0 } = record;
  const discountValue = useGetDiscountValue(record);
  const totalReconciledPaidAmount = useReconciledPayment(payments);
  const totalCreditPayment = useGetCreditPayment(payments);

  const items = orderItems.filter(getNonCancelledItems);
  const merchandiseTotal = new Decimal(getSum(items));
  const shippingDecimal = isNaN(parseInt(shippingCost))
    ? new Decimal(0)
    : new Decimal(shippingCost);
  const {
    totalBeforeTax,
    taxAmount,
    totalAfterTax,
    balanceDecimal,
    taxableAmount,
  } = calculateTaxInclWithoutShippingTax({
    merchandiseTotal,
    shippingDecimal,
    discountValue,
    taxRate,
    totalReconciledPaidAmount,
    totalAppliedCreditDecimal: new Decimal(totalCreditPayment),
    deductableCreditD: new Decimal(0),
  });
  return {
    merchandiseTotal: merchandiseTotal.toFixed(2),
    totalBeforeTax: totalBeforeTax.toFixed(2),
    shippingCost: shippingDecimal.toFixed(2),
    taxAmount: taxAmount.toFixed(2),
    totalAfterTax: totalAfterTax.toFixed(2),
    totalReconciledPaidAmount,
    outstandingPayment: balanceDecimal.toFixed(2),
    taxableAmount: taxableAmount.toFixed(2),
  };
};
export const getTaxInclWithoutShippingTax = useTaxInclWithoutShippingTax;
