import React, { cloneElement } from "react";
import {
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
} from "react-admin";

const ListActions = (props) => {
  const { filters, maxResults } = props;
  const {
    sort,
    resource,
    displayedFilters,
    filterValues,
    //selectedIds,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateButton />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={sort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

export default ListActions;
