import { useMemo } from "react";

import ls from "local-storage";

const SUPER_ADMIN_MODE = "rONTERiTYLioNdwIGNOc";

export const useIsSuperAdminMode = () => {
  const res = useMemo(() => ls.get(SUPER_ADMIN_MODE), []);
  return !!res;
};
