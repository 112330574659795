import React from "react";
import {
  TextField,
  Labeled,
  Edit,
  SelectInput,
  TextInput,
  TabbedForm,
  FormTab,
  BooleanInput,
  FormDataConsumer,
  useInput,
  required,
} from "react-admin";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { OrderTitle, withoutActiveStatuses } from "../orders";
import Uploader from "../components/Uploader";
import Shipping from "./Shipping";
import EditOrderToolbar from "./EditOrderToolbar";
import StackableLabel from "./StackableLabel";
import AutoFillAddress from "./AutoFillAddress";
import { ACTIVE, CANCELLED } from "../components/Constant";
import { TaxEdit } from "./Tax";
import { useShippingCountries } from "../components/DataHooks/country";

const RecalculateStockInput = (props) => {
  const {
    field: { onChange, value },
  } = useInput({ name: "recalculateStock" });
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={value}
          onChange={(e) => {
            onChange(e.target.checked);
          }}
        />
      }
      label="Recalculate Stock"
      {...props}
    />
  );
};

const OrderEdit = (props) => {
  const newProps = { ...props, resource: "orders" };
  const allCountries = useShippingCountries({ showAll: false });
  const countries = allCountries.map(({ value }) => ({
    id: value,
    name: value,
  }));

  return (
    <Edit title={<OrderTitle />} {...newProps}>
      <TabbedForm
        toolbar={<EditOrderToolbar />}
        defaultValues={{ recalculateStock: true }}
      >
        <FormTab label="summary">
          <Labeled label="Order Number">
            <TextField source="orderNumber" />
          </Labeled>
          <br />
          <Labeled label="Nickname">
            <TextField source="customerName" />
          </Labeled>
          <br />
          <TextInput multiline source="description" />
          <StackableLabel />
          {/* <CustomSelectInput
            source="status"
            choices={statuses}
            label="Status"
            sx={{ minWidth: 150 }}
          /> */}
          <SelectInput
            isRequired
            emptyText={"Active"}
            emptyValue={ACTIVE}
            validate={required()}
            source="status"
            choices={withoutActiveStatuses}
            label="Status"
            sx={{ minWidth: 150 }}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              const { status } = formData;
              return <RecalculateStockInput disabled={status !== CANCELLED} />;
            }}
          </FormDataConsumer>
          <Shipping />
          <BooleanInput source="deliveryOrderPrinted" />
          <TaxEdit source="tax" label="Tax" />
          <TextInput source="trackingNumber" />
          <Uploader source="image" />
          <TextInput multiline source="notes" label="Customer notes" />
          <br />
          <br />
        </FormTab>
        <FormTab label="shipping">
          <AutoFillAddress />
          <TextInput source="shippingName" />
          <TextInput source="shippingPhoneNumber" />
          <TextInput source="shippingAddress1" />
          <TextInput source="shippingAddress2" />
          <TextInput source="shippingPostcode" />
          <TextInput source="shippingCity" />
          <TextInput source="shippingState" />
          <SelectInput
            label="Country"
            source="shippingCountry"
            choices={countries}
          />
          <TextInput source="email" type="email" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default OrderEdit;
