import React from "react";
// import {
//   useTranslate,
//   Toolbar,
//   Show,
//   SimpleShowLayout,
//   TextField,
//   DateField,
//   NumberField,
// } from "react-admin";
import { makeStyles, useTheme } from "@mui/styles";
import { useRecordContext } from "react-admin";

const useStyles = makeStyles((theme) => ({
  paid: (props) => ({
    color: theme.palette.success.main,
  }),
  unpaid: (props) => ({
    color: theme.palette.warning.main,
  }),
}));

const PaymentStatus = () => {
  const record = useRecordContext();
  const theme = useTheme();

  const { paid = false } = record;
  const classes = useStyles(theme);
  const text = paid ? "Paid" : "Unpaid";
  return <span className={paid ? classes.paid : classes.unpaid}>{text}</span>;
};

export default PaymentStatus;
