import React from "react";
import { useGetDiscountValue } from "../Voucher/useGetDiscount";
import { useTotalInclShippingAfterDiscount } from "../../Order/useTotalInclShippingAfterDiscount";

export const TotalInclShippingWithDiscount = (props) => {
  const { record } = props;
  const balance = useTotalInclShippingAfterDiscount(record);
  return <span>{balance}</span>;
};

export const DiscountValue = (props) => {
  const { record } = props;
  const discountValue = useGetDiscountValue(record);
  return <span>{discountValue}</span>;
};
