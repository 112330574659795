import { flatten, uniq } from "lodash";
import useSWRInfinite from "swr/infinite";

const MAX_CONCURRENT = 100;

export const useOrderItemsKeywords = ({ ready = () => false, orders = [] }) => {
  // console.time("allKeywordIds");
  const allKeywordIds = orders.map(({ orderItems = [] }) =>
    orderItems.filter(({ keyword }) => !!keyword).map(({ keyword }) => keyword)
  );
  const flattenAllKeywordIds = flatten(allKeywordIds);
  // console.timeEnd("allKeywordIds");

  // console.time("uniq-keywords");
  const keywordIds = uniq(flattenAllKeywordIds);
  // console.timeEnd("uniq-keywords");

  const getKey = (pageIndex, previousPageData) => {
    const startIndex = pageIndex * MAX_CONCURRENT;
    if (ready() && keywordIds.length > startIndex) {
      const str = keywordIds
        .slice(startIndex, startIndex + MAX_CONCURRENT)
        .map((id) => `_id_in=${id}`)
        .join("&");
      return `${process.env.REACT_APP_CMS_HOST}/keywords?${str}`;
    }
    return null;
  };

  // const { data, error, isLoading, isValidating, mutate, size, setSize } =
  return useSWRInfinite(getKey, {
    initialSize: Math.floor(keywordIds.length / MAX_CONCURRENT) + 1,
    revalidateOnFocus: false,
    revalidateFirstPage: false,
  });
};
