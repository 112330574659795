import React from "react";
import { useRecordContext } from "react-admin";
import { useGetCreditPayment } from "../components/Credit/hooks";

const CreditPayment = () => {
  const record = useRecordContext();
  const { payments = [] } = record;
  const credit = useGetCreditPayment(payments);
  return <span>{credit}</span>;
};

export default CreditPayment;
