import React from "react";
import Button from "@mui/material/Button";

const Comp = ({ record = {} }) => {
  const { url, paid = false } = record;
  if (!paid) {
    return null;
  }
  return (
    <Button
      component={"a"}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      // size="small"
    >
      See Receipt
    </Button>
  );
};

export default Comp;
