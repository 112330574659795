import React from "react";
import Decimal from "decimal.js-light";

import { getTotalInclShippingAfterDiscount } from "../../Order/useTotalInclShippingAfterDiscount";
import {
  SHIPPED,
  READY_TO_SHIP,
  CANCELLED,
  ON_HOLD,
  WAITING_FOR_PAYMENT,
  WAITING_FOR_ONLINE_PAYMENT,
  ACTIVE,
  PAID,
  PREORDER,
  LOCKED,
  ERROR_PAYMENT_MISMATCH,
  NO_STACK,
} from "../Constant";

const OrderSummary = (props) => {
  const { orders } = props;
  const completedOrders = orders.filter(
    (v) => [READY_TO_SHIP, SHIPPED].indexOf(v.status) > -1
  );
  const completedOrdersAmtDecimal = completedOrders.reduce((acc, order) => {
    const amt = getTotalInclShippingAfterDiscount(order);
    return acc.add(amt);
  }, new Decimal(0));
  const completedOrdersAmt = completedOrdersAmtDecimal.toNumber();

  const cancelledOrders = orders.filter((o) => o.status === CANCELLED);
  const cancelledOrdersAmtDecimal = cancelledOrders.reduce((acc, order) => {
    const amt = getTotalInclShippingAfterDiscount(order);
    return acc.add(amt);
  }, new Decimal(0));
  const cancelledOrdersAmt = cancelledOrdersAmtDecimal.toNumber();

  const unpaidOrders = orders.filter(
    (v) =>
      [
        ON_HOLD,
        WAITING_FOR_PAYMENT,
        WAITING_FOR_ONLINE_PAYMENT,
        ACTIVE,
        PAID,
        PREORDER,
        NO_STACK,
        LOCKED,
        ERROR_PAYMENT_MISMATCH,
      ].indexOf(v.status) > -1
  );
  const unpaidOrdersAmtDecimal = unpaidOrders.reduce((acc, order) => {
    const amt = getTotalInclShippingAfterDiscount(order);
    return acc.add(amt);
  }, new Decimal(0));
  const unpaidOrdersAmt = unpaidOrdersAmtDecimal.toNumber();

  return (
    <>
      {/* <div>Number of Orders</div> */}
      {/* <div>{orders.length}</div> */}
      <div>{`Cancelled Orders Amount(Total = ${cancelledOrders.length})`}</div>
      <div>{cancelledOrdersAmt}</div>
      <div>{`Completed Orders Amount(Total = ${completedOrders.length})`}</div>
      <div>{completedOrdersAmt}</div>
      <div>{`Unpaid Orders Amount(Total = ${unpaidOrders.length})`}</div>
      <div>{unpaidOrdersAmt}</div>
    </>
  );
};

export default OrderSummary;
