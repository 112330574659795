import React from "react";
import {
  useListContext,
  TopToolbar,
  ExportButton,
  FilterButton,
} from "react-admin";
import Tooltip from "@mui/material/Tooltip";

import { useMaxResultsByPlan } from "../../helpers/subscriptionPlan";

const ListActions = (props) => {
  const { filters } = props;
  const { sort, resource, filterValues, total } = useListContext();
  const maxResults = useMaxResultsByPlan();

  return (
    <TopToolbar>
      <FilterButton filters={filters} />
      <Tooltip title={`Max Export = ${maxResults} contacts`}>
        <span>
          <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={sort}
            filterValues={filterValues}
            maxResults={maxResults}
          />
        </span>
      </Tooltip>
    </TopToolbar>
  );
};

export default ListActions;
