import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CreateOrderItem from "./CreateOrderItem";

const Empty = (props) => (
  <Box textAlign="center" m={1}>
    <Typography variant="h6" paragraph>
      Empty order items
    </Typography>
    <CreateOrderItem orderId={props.orderId} />
  </Box>
);

export default Empty;
