import React from "react";
import CustomTextInput from "./CustomTextInput";

const DecimalInput = (props) => {
  return (
    <CustomTextInput
      inputProps={{
        inputMode: "decimal",
        pattern: "^-?[0-9]+([.][0-9]+)?",
      }}
      {...props}
    />
  );
};

export const PositiveDecimalInput = (props) => {
  return (
    <CustomTextInput
      inputProps={{
        inputMode: "decimal",
        pattern: "^[0-9]+([.][0-9]+)?",
      }}
      {...props}
    />
  );
};

export default DecimalInput;
