import React from "react";
import { useGetDiscountValue } from "../Voucher/useGetDiscount";
import ConfirmationDialog from "./ConfirmationDialog";
import {
  useRefresh,
  useNotify,
  useDelete,
  useRecordContext,
} from "react-admin";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";

const OrderDiscountField = (props) => {
  const record = useRecordContext();
  const { voucher = { id: null } } = record;
  const discountValue = useGetDiscountValue(record);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const [deleteVoucher, { isLoading, error }] = useDelete(
    "orders",
    { id: `${record.id}/detach-voucher/${voucher.id}` },
    {
      onSuccess: () => {
        refresh();
        notify("Voucher removed", "info", {}, true);
      },
      onFailure: (error) => {
        notify(
          "Failed to delete voucher, cannot delete for order has payment",
          "warning"
        );
      },
    }
  );
  const onOk = React.useCallback(() => {
    setOpenConfirmDialog(false);
    deleteVoucher();
  }, [deleteVoucher]);

  const onCancel = React.useCallback(() => {
    setOpenConfirmDialog(false);
  }, []);

  const removeOnClick = React.useCallback(() => {
    setOpenConfirmDialog(true);
  }, []);
  return (
    <div>
      <span>{`${discountValue}     `}</span>
      {voucher && voucher.id && (
        <>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`/#/vouchers/${voucher.id}/show`}
          >
            {`(${voucher.code})`}
          </a>
          <Button onClick={removeOnClick}>
            <DeleteIcon color="error" />
          </Button>
          <ConfirmationDialog
            title={`Confirmation`}
            content={`Remove this voucher ?`}
            open={openConfirmDialog}
            onCancel={onCancel}
            onOk={onOk}
          />
        </>
      )}
    </div>
  );
};

export default OrderDiscountField;
