import React from "react";
import {
  required,
  TextInput,
  useCreate,
  useNotify,
  useRefresh,
  SelectInput,
} from "react-admin";

import Box from "@mui/material/Box";
import { useController, useForm, FormProvider } from "react-hook-form";

import { getPageId } from "../../helpers/getPageId";
import QuantityInput from "./QuantityInput";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AutoCompleteKeyword from "./AutoCompleteKeyword";
import CombineDescriptionWithQuantity from "./CombineDescriptionWithQuantity";
import { ORDER_ITEM_NEW, ORDER_ITEM_PAID } from "../Constant";
import { Button } from "@mui/material";
import TextInputNoWheel from "../Common/TextInputNoWheel";
import NumberInputNoWheel from "../Common/TextInputNoWheel";

const choices = [ORDER_ITEM_NEW, ORDER_ITEM_PAID].map((c) => ({
  id: c,
  name: c,
}));

const CreateOrderItemByKeyword = ({ currency, orderId, onClose }) => {
  const pageId = getPageId();
  const refresh = useRefresh();
  // const onSubmit = (data) => console.log({ ...data });
  const [create, { isLoading }] = useCreate();

  const notify = useNotify();
  const formMethods = useForm({
    defaultValues: {
      description: "",
      status: ORDER_ITEM_NEW,
      keyword: {},
      recalculateStock: true,
      price: 0,
      weight: 0,
      cost: 0,
      page: pageId,
      currencyCode: currency,
    },
  });

  const handleSubmit = async (data) => {
    const { recalculateStock, keyword, key, item, ...values } = data;
    const resource = recalculateStock
      ? `orderitems?recalculateStock=true`
      : `orderitems`;
    create(
      resource,
      {
        data: {
          ...values,
          keyword: keyword.id,
          order: orderId,
        },
      },
      {
        onSuccess: ({ data }) => {
          notify(`Order item created`, "info");
          refresh();
          onClose();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  return (
    <form className="form" onSubmit={formMethods.handleSubmit(handleSubmit)}>
      <FormProvider {...formMethods}>
        <>
          <AutoCompleteKeyword />
          <TextInput
            source="item"
            validate={required()}
            fullWidth
            disabled
            label="Item name"
          />
          <br />
          <div>
            <QuantityInput useKeyword />

            <FormControlLabel
              control={<CustomCheckbox name="recalculateStock" />}
              label="Recalculate stock"
            />
          </div>
          <CombineDescriptionWithQuantity />
          <Box display="flex" flexWrap="wrap" justifyContent="space-between">
            <NumberInputNoWheel
              label="Price"
              source="price"
              validate={required()}
              disabled
            />
            <TextInputNoWheel
              label="Weight"
              source="weight"
              validate={required()}
              disabled
              defaultValue={0}
            />
            <NumberInputNoWheel
              label="Cost"
              source="cost"
              validate={required()}
              disabled
              defaultValue={0}
            />
            <SelectInput
              label="Status"
              source="status"
              choices={choices}
              validate={required()}
            />
          </Box>
          {/* <span className={classes.redText}>
              *You can only edit these after item created
            </span> */}
          <TextInput label="Currency" disabled source="currencyCode" />
          <div>
            <Button type="submit" variant="contained" disabled={isLoading}>
              Save
            </Button>
          </div>
        </>
      </FormProvider>
    </form>
  );
};

const CustomCheckbox = ({ control, name }) => {
  const { field } = useController({
    control,
    name,
  });
  return <Checkbox {...field} checked={field.value} type="checkbox" />;
};

export default CreateOrderItemByKeyword;
