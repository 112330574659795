import React from "react";
import {
  List,
  // SimpleList,
  Datagrid,
  TextField,
  NumberField,
  // BooleanField,
  Pagination,
  TextInput,
  // BooleanInput,
  // BulkDeleteButton,
  // useRefresh,
  // useNotify,
} from "react-admin";
// import { makeStyles } from '@mui/styles';

// import classnames from "classnames";
// import { useMediaQuery } from "@mui/material";
import { getPageId } from "../../helpers/getPageId";

import CustomBulkDeleteButton from "../Keyword/CustomBulkDeleteButton";
import BulkFbFollowUpMessageButton from "./BulkFbFollowUpMessageButton";
import {
  useIsEnterprisePlan,
  useIsBusinessPlan,
} from "../../helpers/subscriptionPlan";
import ListActions from "./ListActions";
import { exporter } from "./Exporter";
import KbLinkButton from "../Learn/KbLinkButton";
import CustomPickerInput from "../OrderItem/CustomPickerInput";
import CustomDateField from "../Common/CustomDateField";
import BulkConvertToOrders from "./BulkConvertToOrders";

const defaultFilters = [
  <TextInput
    label="key"
    source="key_contains"
    alwaysOn
    parse={(v) => v.toUpperCase()}
  />,
  <TextInput label="Description" source="description_contains" alwaysOn />,
  <CustomPickerInput source="createdAt_gte" label="CreatedAt start" />,
  <CustomPickerInput source="createdAt_lte" label="CreatedAt end" />,
];

const BulkActionButtons = (props) => (
  <>
    <BulkFbFollowUpMessageButton {...props} />
    <BulkConvertToOrders {...props} />
    {/* Add the default bulk delete action */}
    <CustomBulkDeleteButton {...props} />
  </>
);

const WLHelp = () => (
  <KbLinkButton
    url={`https://learn.boxify.io/kb/08-4-21-feature-of-waitlists/`}
  />
);

const WaitlistsList = (props) => {
  const pageId = getPageId();
  const isEnterprisePlan = useIsEnterprisePlan();
  const isBusinessPlan = useIsBusinessPlan();
  //   const { isMultiCurrency } = useAvailableCurrencies();
  // const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <>
      {isBusinessPlan && !isEnterprisePlan && (
        <h4>
          Your plan supports maximum 200 waitlists.
          <WLHelp />
        </h4>
      )}
      {!isBusinessPlan && !isEnterprisePlan && (
        <h4>
          Your plan supports maximum 50 waitlists.
          <WLHelp />
        </h4>
      )}
      <List
        {...props}
        actions={<ListActions />}
        exporter={exporter}
        filter={{ page: pageId }}
        filters={defaultFilters}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} />}
        sort={{ field: "createdAt", order: "DESC" }}
      >
        <Datagrid rowClick="show" bulkActionButtons={<BulkActionButtons />}>
          <TextField source="contact.name" label="Name" />
          <TextField source="description" />
          <TextField source="key" />
          <NumberField source="quantity" />
          <CustomDateField source="createdAt" showTime />
          <CustomDateField source="updatedAt" showTime />
        </Datagrid>
      </List>
    </>
  );
};

export default WaitlistsList;
