import React, { createContext, useReducer, useMemo } from "react";

export const ADD_ORDER = "ADD_ORDER";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const DELETE_ORDER = "DELETE_ORDER";

export const ADD_VIDEO = "ADD_VIDEO";

export const ADD_ITEM_DESC = "ADD_ITEM_DESC";
export const ADD_SPEC_DESC = "ADD_SPEC_DESC";
export const SET_UNIT_PRICE = "SET_UNIT_PRICE";
export const SET_LOADING = "SET_LOADING";
export const RESET = "RESET";
export const SET_PAGE = "SET_PAGE";
export const SET_COMMENT_SCROLL = "SET_COMMENT_SCROLL";

export const SET_OBS_INSTANCE = "SET_OBS_INSTANCE";
export const SET_OBS_KEYWORD = "SET_OBS_KEYWORD";

export const SET_QUICK_CREATE_TARGET = "SET_QUICK_CREATE_TARGET";
export const SET_REPLY_COMMENT_TO = "SET_REPLY_COMMENT_TO";
export const SET_EDIT_COMMENT = "SET_EDIT_COMMENT";

export const SET_ENABLE_CUSTOMER_INFO = "SET_ENABLE_CUSTOMER_INFO";

const initialState = {
  orders: [],
  video: null,
  page: null,
  itemDesc: "",
  specDesc: "",
  unitPrice: 0,
  loading: false,
  commentScroll: true,
  obs: null,
  obsKeyword: "",
  quickCreateTarget: null,
  replyCommentTo: null,
  enableCustomerInfo: true,
  editComment: undefined,
};

export const store = createContext(initialState);
const { Provider } = store;

export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case SET_ENABLE_CUSTOMER_INFO:
        return {
          ...state,
          enableCustomerInfo: action.payload,
        };
      case ADD_VIDEO:
        return {
          ...state,
          video: action.payload,
        };
      case ADD_ORDER:
        const found = state.orders.find(
          (order) => order.psid === action.payload.psid
        );

        if (found) {
          const newOrders = state.orders.map((order) => {
            if (order.psid === action.payload.psid) {
              const quantity =
                parseInt(order.quantity) + parseInt(action.payload.quantity);
              return {
                ...order,
                quantity,
                price: quantity * state.unitPrice,
              };
            } else {
              return order;
            }
          });
          return {
            ...state,
            orders: newOrders,
          };
        } else {
          const price = parseInt(action.payload.quantity) * state.unitPrice;
          const newData = {
            ...action.payload,
            price,
            createdAt: new Date().getTime(),
          };
          return {
            ...state,
            orders: [...state.orders, newData],
          };
        }

      case UPDATE_ORDER:
        const data = state.orders.map((order, index) => {
          if (index === action.payload.oldData.tableData.index) {
            return action.payload.newData;
          }
          return order;
        });
        return { ...state, orders: data };
      case DELETE_ORDER:
        const newOrders = state.orders.filter(
          (order, index) => index !== action.payload.tableData.index
        );
        return { ...state, orders: newOrders };
      case ADD_ITEM_DESC:
        return { ...state, itemDesc: action.payload };
      case ADD_SPEC_DESC:
        return { ...state, specDesc: action.payload };
      case SET_UNIT_PRICE:
        return { ...state, unitPrice: action.payload };
      case SET_LOADING:
        return { ...state, loading: action.payload };
      case RESET:
        return {
          ...state,
          orders: [],
          itemDesc: "",
          specDesc: "",
          unitPrice: 0,
        };
      case SET_PAGE:
        return { ...state, page: action.payload };
      case SET_COMMENT_SCROLL:
        return { ...state, commentScroll: action.payload };
      case SET_OBS_INSTANCE:
        return { ...state, obs: action.payload };
      case SET_OBS_KEYWORD:
        return { ...state, obsKeyword: action.payload };
      case SET_QUICK_CREATE_TARGET:
        return { ...state, quickCreateTarget: action.payload };
      case SET_REPLY_COMMENT_TO:
        return { ...state, replyCommentTo: action.payload };
      case SET_EDIT_COMMENT:
        return { ...state, editComment: action.payload };
      default:
        throw new Error();
    }
  }, initialState);
  const value = useMemo(() => ({ state, dispatch }), [state]);

  return <Provider value={value}>{children}</Provider>;
};
