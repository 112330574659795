import React from "react";
import Rating from "@mui/material/Rating";
import { useUpdate, useNotify } from "react-admin";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const RatingInput = (props) => {
  const { record } = props;
  const { rating = 0, id } = record;
  const [value, setValue] = React.useState({
    previous: rating,
    current: rating,
  });
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const notify = useNotify();
  const [update, { isLoading, error }] = useUpdate(
    "contacts",
    { id: id, data: { rating: value.current }, previousData: record },
    {
      onSuccess: () => {
        notify("Rating successful", "info", {}, true);
        setValue({ ...value, previous: value.current });
      },
      onFailure: (error) => {
        notify("Rating failed", "warning");
        setValue({ ...value, current: value.previous });
      },
      mutationMode: "pessimistic",
    }
  );

  const onChange = React.useCallback(
    (event, newValue) => {
      setValue({ ...value, current: newValue * 2 });
      setOpenConfirm(true);
    },
    [setValue, value]
  );

  const handleClose = React.useCallback(() => {
    setValue({ ...value, current: value.previous });
    setOpenConfirm(false);
  }, [setValue, value]);

  const handleConfirm = React.useCallback(() => {
    update();
    setOpenConfirm(false);
  }, [update]);

  return (
    <span>
      <Rating
        name="customer-rating-input"
        value={value.current / 2}
        onChange={onChange}
        precision={0.5}
      />
      {isLoading && <CircularProgress size={10} />}
      <Dialog open={openConfirm} onClose={handleClose}>
        <DialogTitle>Update rating</DialogTitle>
        <DialogContent>
          <DialogContentText>{`Confirm update rating ?`}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
};

export default RatingInput;
