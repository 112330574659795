import useSWR from "swr";
import { useGetToken } from "./useGetToken";
import { sendRequest } from "../../helpers/fetchHandleError";

export const useAnalyticsToken = () => {
  const token = useGetToken();

  return useSWR(
    token ? `${process.env.REACT_APP_CMS_HOST}/analytics/auth` : null,
    sendRequest,
    {
      revalidateOnFocus: false,
      refreshInterval: 3000 * 1000,
      dedupingInterval: 15 * 60 * 1000,
    }
  );
};
