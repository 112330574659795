import React, { useState, useRef } from "react";
import ReactToPrint from "react-to-print";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import PrintIcon from "@mui/icons-material/Print";
import CheckIcon from "@mui/icons-material/Check";
import Avatar from "@mui/material/Avatar";
import ButtonGroup from "@mui/material/ButtonGroup";

import { receiptImageStyle } from "../Constant";
import { replaceImageSource } from "../../helpers/withReplaceImageSource";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class ComponentToPrint extends React.Component {
  render() {
    return <div>{this.props.children}</div>;
  }
}

const ImgMui = ({ fileUrl }) => {
  const [open, setOpen] = useState(false);
  const componentRef = useRef();
  const [rotation, setRotation] = useState(0);
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);
  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      <div
        onClick={() => {
          setOpen(true);
        }}
      >
        <Avatar alt="receipt" src={fileUrl} size="tiny" variant="square" />
      </div>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <ComponentToPrint ref={componentRef}>
            <img
              style={{
                ...receiptImageStyle,
                transform: `rotate(${rotation}deg) scaleX(${scaleX}) scaleY(${scaleY})`,
              }}
              alt="receipt_image"
              src={fileUrl}
            />
          </ComponentToPrint>

          <DialogContentText></DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonGroup variant="outlined" aria-label="image tools">
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setRotation(rotation + 90)}
            >
              Rotate
            </Button>
            <Button onClick={() => setScaleX((prev) => prev * -1)}>
              Flip X
            </Button>
            <Button onClick={() => setScaleY((prev) => prev * -1)}>
              Flip Y
            </Button>
          </ButtonGroup>
          <ReactToPrint
            trigger={() => (
              <Button>
                <PrintIcon name="print" />
              </Button>
            )}
            content={() => componentRef.current}
          />
          <Button color="primary" onClick={() => setOpen(false)}>
            <CheckIcon name="checkmark" /> OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const PdfMui = ({ fileUrl }) => {
  const [open, setOpen] = useState(false);
  const componentRef = useRef();
  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  return (
    <>
      <div
        onClick={() => {
          setOpen(true);
        }}
      >
        <Document file={fileUrl}>
          <Page scale={0.2} pageNumber={1} />
        </Document>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <ComponentToPrint ref={componentRef}>
            <Document file={{ url: fileUrl }}>
              <Page pageNumber={1} />
            </Document>
          </ComponentToPrint>
          <DialogContentText></DialogContentText>
        </DialogContent>
        <DialogActions>
          <ReactToPrint
            trigger={() => (
              <Button>
                <PrintIcon name="print" />
              </Button>
            )}
            content={() => componentRef.current}
          />
          <Button color="primary" onClick={() => setOpen(false)}>
            <CheckIcon name="checkmark" /> OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ImageReceipt = ({ source, record = {}, src }) => {
  const { receipt: { fileUrl = "" } = {}, createdAt } = record;
  const isPdf = fileUrl.toLowerCase().indexOf(".pdf") > -1;
  if (fileUrl.trim() === "") {
    return <span>{`N/A`}</span>;
  }
  return (
    <span>
      {isPdf ? (
        <PdfMui fileUrl={replaceImageSource(fileUrl)} />
      ) : (
        <ImgMui fileUrl={replaceImageSource(fileUrl)} createdAt={createdAt} />
      )}
    </span>
  );
};

export default ImageReceipt;
