export const fetchHandleError = (response) => {
  if (response.ok) {
    // Or what ever you want to check
    return Promise.resolve(response.json()); // This will end up in SUCCESS part
  }
  return Promise.resolve(response.json()).then((responseInJson) => {
    // This will end up in ERROR part
    if (responseInJson.message) {
      return Promise.reject(responseInJson.message); //  responseInJson.message = "Some nasty error message!"
    } else {
      return Promise.reject(responseInJson); //  responseInJson.message = "Some nasty error message!"
    }
  });
};

export const fetcher = (...args) => fetch(...args).then(fetchHandleError);
// export const fetcher = (...args) => fetch(...args).then((r) => r.json());

export async function deleteRequest(url, { arg, ...requestConfig }) {
  return fetch(url, {
    ...requestConfig,
    headers: {
      ...(requestConfig.headers && requestConfig.headers),
      "Content-type": "application/json; charset=UTF-8",
    },
    method: "DELETE",
  }).then(fetchHandleError);
}

export async function sendRequest(
  url,
  { arg, method = "POST", ...requestConfig }
) {
  return fetch(url, {
    ...requestConfig,
    headers: {
      ...(requestConfig.headers && requestConfig.headers),
      "Content-type": "application/json; charset=UTF-8",
    },
    method,
    body: JSON.stringify(arg),
  }).then(fetchHandleError);
}

export const makeFetchWithToken =
  (token) =>
  (url, args = {}) => {
    const { headers = {}, ...others } = args;
    return fetch(url, {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
      ...others,
    }).then(fetchHandleError);
  };

// export const fetchHandleError = response => {
//   if (response.status < 200 || response.status >= 300) {
//     throw new Error(response.statusText);
//   }
//   return response.json();
// };
