import React from "react";
import { SimpleForm, Edit, TextInput, required } from "react-admin";
import ColorPickerField from "./ColorPickerField";

export const VariantEdit = (props) => {
  return (
    <Edit title="Edit Variant" {...props}>
      <SimpleForm>
        <TextInput source="label" validate={required()} />
        <TextInput multiline source="description" validate={required()} />
        <ColorPickerField source="colorCode" label="Color code" />
      </SimpleForm>
    </Edit>
  );
};

export default VariantEdit;
