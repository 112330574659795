import React from "react";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ls from "local-storage";
import Grid from "@mui/material/Grid";

import orderDefaultListView from "../helpers/orderDefaultListView";

const TOGGLE_VIEW = "TOGGLE_VIEW";
const LOAD_CACHE = "LOAD_CACHE";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const initialState = orderDefaultListView;
export const store = React.createContext(initialState);
const { Provider } = store;

export const StateProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case TOGGLE_VIEW:
        return {
          ...state,
          ...action.payload,
        };
      case LOAD_CACHE:
        return { ...action.payload };
      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const SelectableFields = () => {
  const [open, setOpen] = React.useState(false);
  const { state: listView, dispatch } = React.useContext(store);
  React.useEffect(() => {
    const cache = ls.get("listView");
    if (cache) {
      dispatch({ type: LOAD_CACHE, payload: cache });
    }
  }, [dispatch]);

  const onToggleHandler = React.useCallback(
    (e) => {
      dispatch({
        type: TOGGLE_VIEW,
        payload: { [e.target.name]: !listView[e.target.name] },
      });
    },
    [dispatch, listView]
  );

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = React.useCallback(() => {
    setOpen(false);
    ls.set("listView", listView);
  }, [listView]);

  const keys = Object.keys(listView);

  const handleResetDefault = React.useCallback(() => {
    dispatch({
      type: LOAD_CACHE,
      payload: initialState,
    });
  }, [dispatch]);

  return (
    <>
      <Button color="primary" onClick={handleClickOpen}>
        <ViewColumnIcon />
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="list-selectable-dialog-title"
        open={open}
      >
        <DialogTitle id="list-selectable-dialog-title" onClose={handleClose}>
          Customize List View
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            {keys.map((k) => {
              return (
                <Grid item xs={4} key={k}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        //disabled={!isBusinessPlan}
                        checked={listView[k]}
                        onChange={onToggleHandler}
                        name={k}
                        color="primary"
                      />
                    }
                    label={k}
                  />
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResetDefault}>Reset default</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelectableFields;
