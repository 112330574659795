import React from "react";
import Avatar from "@mui/material/Avatar";

import { replaceImageSource } from "../helpers/withReplaceImageSource";
import { useStyles } from "./CustomItemDescriptionWithImage";

const ItemDescriptionWithImageForDo = (props) => {
  const { images = [], description = "" } = props;
  const classes = useStyles();
  return (
    <span className={classes.root}>
      <Avatar
        className={classes.avatar}
        src={
          images && images.length && images.length > 0
            ? replaceImageSource(images[0].name)
            : ""
        }
        style={{ width: 25, height: 25 }}
      />
      {description}
    </span>
  );
};

export default ItemDescriptionWithImageForDo;
