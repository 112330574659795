import React from "react";
import { useRecordContext } from "react-admin";
import FullNameField from "./FullNameField";

const CustomerLinkField = (props) => {
  const record = useRecordContext();
  return record ? <FullNameField {...props} record={record} /> : null;
};

CustomerLinkField.defaultProps = {
  source: "name",
  addLabel: true,
};

export default CustomerLinkField;
