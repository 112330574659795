import React, { useContext, useState } from "react";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import Box from "@mui/material/Box";
import styled from "styled-components";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { store } from "../store";
import { useAnalyticsToken } from "../../DataHooks/useAnalyticsToken";
import {
  useIsBusinessPlan,
  useIsEnterprisePlan,
} from "../../../helpers/subscriptionPlan";
import { useProfile } from "../../DataHooks/useProfile";
import { ASSISTANT_ROLE } from "../../../helpers/role";
import { useUserRole } from "../../DataHooks/user";
import { useNotify } from "react-admin";

const CustomDiv = styled.div`
  height: 500px;
  width: 100%;
`;

const ToggleSpan = styled.span`
  display: ${(props) => (props.isVisible ? "block" : "none")};
`;

const Comp = (props) => {
  const isEnterprisePlan = useIsEnterprisePlan();
  const isBusinessPlan = useIsBusinessPlan();
  const { profile } = useProfile();
  const { params } = profile;
  const { disableAssistantViewSalesTable = false } = params;
  const userRole = useUserRole();
  const chartEl = React.useRef(null);
  const chartObj = React.useRef(null);
  const notify = useNotify();
  const [toggle, setToggle] = useState(true);
  const { data: analyticsToken, isLoading } = useAnalyticsToken();

  const { state } = useContext(store);
  const { video } = state;
  const videoCreatedTime = new Date(video.created_time);

  const getUserToken = React.useCallback(() => {
    return analyticsToken.jwt;
  }, [analyticsToken]);

  const handleToggle = React.useCallback((e) => {
    setToggle(e.target.checked);
  }, []);

  const chartRefreshSeconds = isEnterprisePlan
    ? 60
    : isBusinessPlan
    ? 120
    : 180;

  React.useEffect(() => {
    if (chartObj && chartObj.current) {
      chartObj.current.setAutoRefresh(toggle);
    }
  }, [toggle]);

  React.useEffect(() => {
    if (disableAssistantViewSalesTable && userRole === ASSISTANT_ROLE) {
      return;
    }
    if (!analyticsToken) {
      return;
    }

    const sdk = new ChartsEmbedSDK({
      baseUrl: process.env.REACT_APP_CHARTS_BASE_URL,
      getUserToken,
      showAttribution: false,
      autoRefresh: true,
      maxDataAge: chartRefreshSeconds,
    });
    const chart = sdk.createChart({
      chartId: process.env.REACT_APP_CHARTS_ID_SALES_TABLE_WITHOUT_PROFIT,
      filter: {
        createdAt: {
          $gte: new Date(videoCreatedTime),
        },
      },
    });
    chart
      .render(chartEl.current)
      .then(() => {
        chartObj.current = chart;
      })
      .catch((e) => {
        notify(`Sales table ${e.message}`, { type: "warning" });
      });
  }, [getUserToken, userRole]);

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (!analyticsToken) {
    return <div>Chart error</div>;
  }
  return disableAssistantViewSalesTable &&
    userRole === ASSISTANT_ROLE ? null : (
    <Box m={1} p={1} border={1}>
      <FormControlLabel
        control={<Switch checked={toggle} onChange={handleToggle} />}
        label="Sales item table"
      />
      <ToggleSpan isVisible={toggle ? true : undefined}>
        <h4>Sales since live started {videoCreatedTime.toLocaleString()}</h4>
        <h5>{`Auto Refresh Every ${chartRefreshSeconds}s`}</h5>
        <CustomDiv ref={chartEl}></CustomDiv>
      </ToggleSpan>
    </Box>
  );
};

export default Comp;
