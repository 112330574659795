import React from "react";
import { Button, useRefresh } from "react-admin";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";
import SyncIcon from "@mui/icons-material/Sync";
import Tooltip from "@mui/material/Tooltip";

import { useRecursiveTimeout } from "../useRecursiveTimeout";
import { useIsEnterprisePlan } from "../../helpers/subscriptionPlan";

const AutoSyncButton = () => {
  const isEnterprise = useIsEnterprisePlan();
  const refresh = useRefresh();
  const [enableSync, setEnableSync] = React.useState(false);
  const syncCallback = React.useCallback(() => {
    if (enableSync) {
      refresh();
    }
    return 0;
  }, [refresh, enableSync]);
  useRecursiveTimeout(syncCallback, 6000);

  const onClick = React.useCallback(() => {
    if (isEnterprise) {
      setEnableSync((prev) => !prev);
    }
  }, [setEnableSync, isEnterprise]);

  const title =
    isEnterprise && enableSync
      ? "Tap to stop"
      : isEnterprise && !enableSync
      ? "Tap to start"
      : "Requires enterprise plan";
  return (
    <Tooltip title={title}>
      <span>
        <Button disabled={!isEnterprise} onClick={onClick} label="Sync">
          {enableSync ? <SyncIcon /> : <SyncDisabledIcon />}
        </Button>
      </span>
    </Tooltip>
  );
};

export default AutoSyncButton;
