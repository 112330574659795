import React from "react";
import { useNotify, useRecordContext } from "react-admin";
import { CopyToClipboard } from "react-copy-to-clipboard";
import IconButton from "@mui/material/IconButton";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Tooltip from "@mui/material/Tooltip";

import { withProfile } from "../DataHooks/useProfile";

const CopyKeywordButton = ({ source, profile }) => {
  const record = useRecordContext();
  const notify = useNotify();

  const messageTemplate = profile.keywordTemplate
    .replace(/{{key}}/g, record.key)
    .replace(/{{currencyCode}}/g, `${profile.params.currency}`)
    .replace(/{{description}}/g, record.description)
    .replace(/{{price}}/g, record.price);

  const showMsg = React.useCallback(() => {
    notify("Copied successful");
  }, [notify]);

  const onClick = React.useCallback((event) => event.stopPropagation(), []);

  return (
    <Tooltip title={messageTemplate}>
      <IconButton variant="contained" onClick={onClick}>
        <CopyToClipboard text={messageTemplate} onCopy={showMsg}>
          <FileCopyIcon />
        </CopyToClipboard>
      </IconButton>
    </Tooltip>
  );
};

export default withProfile(CopyKeywordButton);
