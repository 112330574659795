import React from "react";
import Downshift from "downshift";
import matchSorter from "match-sorter";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import useSWR from "swr";

import useDebounce from "../UseDebounce";
import { useProfile } from "../DataHooks/useProfile";

import {
  // escapeRegExp,
  getOrderItemSearchStartDate,
} from "../../helpers/methods";

const itemToString = (item) => {
  if (item && item.description) {
    return item.description;
  } else if (item) {
    return item;
  }
  return "";
};
const DownshiftInput = ({
  input,
  meta,
  placeholder,
  label,
  itemDesc,
  setItemDesc,
  // price,
  setPrice,
  // items,
  ...rest
}) => {
  // const formApi = useForm();

  const debouncedSearchTerm = useDebounce(itemDesc, 500);
  const trimmedSearchTerm = debouncedSearchTerm && debouncedSearchTerm.trim();
  const { profile } = useProfile();

  const target =
    profile &&
    profile.page &&
    profile.page._id &&
    trimmedSearchTerm &&
    trimmedSearchTerm.length > 1
      ? `${
          process.env.REACT_APP_CMS_HOST
        }/orderItems/search/?descriptionSearch_containss=${encodeURIComponent(
          trimmedSearchTerm.toUpperCase()
        )}&page=${
          profile.page._id
        }&_sort=updatedAt:DESC&_start=0&_limit=15&createdAt_gte=${getOrderItemSearchStartDate().toISOString()}`
      : null;
  const { data, error, isValidating } = useSWR(target, {
    revalidateOnFocus: false,
    refreshInterval: 0,
  });

  const items = data
    ? data.filter(
        (v, i, a) => a.findIndex((t) => t.description === v.description) === i
      )
    : [];

  return (
    <Downshift
      onInputValueChange={(inputValue) => {
        setItemDesc(inputValue);
      }}
      onSelect={(selectedItem) => {
        if (selectedItem) {
          const { description, price = 0 } = selectedItem;
          setPrice(price);
          setItemDesc(description);
        }
      }}
      itemToString={itemToString}
      selectedItem={itemDesc}
    >
      {({
        getInputProps,
        getItemProps,
        // getLabelProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
      }) => {
        const filteredItems = matchSorter(items, inputValue, {
          keys: ["description"],
          maxRanking: matchSorter.rankings.STARTS_WITH,
        });
        return (
          <div className="downshift" style={{ position: "relative" }}>
            <TextField
              variant="filled"
              required
              fullWidth
              label={"Item (min 2 letters)"}
              InputProps={{
                endAdornment: isValidating ? (
                  <InputAdornment position="end">
                    <CircularProgress size="1rem" />
                  </InputAdornment>
                ) : null,
              }}
              {...getInputProps()}
            />
            {isOpen && !!filteredItems.length && (
              <div
                className="downshift-options"
                style={{
                  background: "white",
                  position: "absolute",
                  top: "100%",
                  left: 15,
                  right: 0,
                  zIndex: 10,
                }}
              >
                {filteredItems.map(({ description, price }, index) => (
                  <div
                    {...getItemProps({
                      key: `${description}-${index}`,
                      index,
                      item: { description, price },
                      style: {
                        backgroundColor:
                          highlightedIndex === index ? "lightgray" : "white",
                        fontWeight:
                          selectedItem === description ? "bold" : "normal",
                      },
                    })}
                  >
                    {`${description} (price: ${price})`}
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      }}
    </Downshift>
  );
};

export default DownshiftInput;
