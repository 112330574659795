import React from "react";
import ls from "local-storage";
import differenceInMinutes from "date-fns/differenceInMinutes";
import isFuture from "date-fns/isFuture";

import { useProfile } from "../DataHooks/useProfile";
import { isArray } from "../../helpers/methods";
import { getCookie } from "../../helpers/Cookies";
import { fetchHandleError } from "../../helpers/fetchHandleError";

const addressFieldsMY = [
  "shippingName",
  "shippingPhoneNumber",
  "shippingAddress1",
  "shippingCountry",
  "shippingPostcode",
  "shippingCity",
  "shippingState",
];

// const tokenExample = {
//   access_token: "6HKxGBkOIpa24g9Fbb5Kj0xrV9EeIx8G",
//   expires: 1623778136,
//   expires_in: 43200,
//   token_type: "bearer",
// };

export const useNinjaVanToken = (props) => {
  const { env = "", country_code: countryCode = "" } = props;
  const key = `_nv${env}_${countryCode}_session`;
  // const { profile } = useProfile();
  const [session, setSession] = React.useState(
    env && countryCode ? ls.get(key) : null
  );
  const [loading, setLoading] = React.useState(false);

  const isValid = React.useCallback((session) => {
    if (session.access_token && session.expires) {
      const tokenDate = new Date(session.expires * 1000);
      if (isFuture(tokenDate)) {
        const minutes = differenceInMinutes(tokenDate, new Date());
        return minutes > 5;
      }
    }
    return false;
  }, []);

  React.useEffect(() => {
    if (!env || !countryCode || loading) {
      return;
    }
    const cacheSession = ls.get(key);
    if (cacheSession && isValid(cacheSession)) {
      setSession(cacheSession);
    } else if (!loading) {
      setLoading(true);
      const strapiToken = getCookie("token");
      fetch(
        `${process.env.REACT_APP_CMS_HOST}/logistics/ninjavan/auth?env=${env}&country_code=${countryCode}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${strapiToken}`,
          },
        }
      )
        .then(fetchHandleError)
        .then((resp) => {
          setSession(resp);
          ls.set(key, resp);
          setLoading(false);
        })
        .catch((e) => {
          alert("Ninjavan Auth error, retry again later");
          ls.remove(key);
          setSession(null);
          // setLoading(false);
        });
    }
  }, [countryCode, env, isValid, key, loading]);

  if (session && isValid(session)) {
    return session;
  }

  return null;
};

export const useNinjaVanOptions = (env = "prod") => {
  const { profile } = useProfile();
  if (
    profile &&
    profile.ninjavan &&
    isArray(profile.ninjavan) &&
    profile.ninjavan.filter((n) => n.env === env).length > 0
  ) {
    return profile.ninjavan.filter((n) => n.env === env);
  } else {
    return [];
  }
};

export const checkOrderAddressMY = (order) => {
  const addressFieldsErrors = addressFieldsMY.filter((f) => !order[f]);
  return [...addressFieldsErrors];
};
