// authProvider.js

import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS,
} from "react-admin";
import queryString from "query-string";
import ls from "local-storage";

import Cookies from "./helpers/Cookies";

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request(
      `${process.env.REACT_APP_CMS_HOST}/auth/local`,
      {
        method: "POST",
        body: JSON.stringify({ password, identifier: username }),
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    );
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        Cookies.setCookie("token", response.jwt, 7);
        Cookies.setCookie("role", response.user.role.name, 7);
        Cookies.setCookie("pageId", response.user.page._id, 7);
        Cookies.setCookie("profile", response.user.page.profile, 7);
        // window.location.reload(false); //comment this because lib urql was removed and does not need refresh anymore
      });
  }

  if (type === AUTH_LOGOUT) {
    Cookies.deleteCookie("token");
    Cookies.deleteCookie("role");
    Cookies.deleteCookie("pageId");
    Cookies.deleteCookie("profile");
    //delete ninjavan cache tokens
    Object.keys(localStorage)
      .filter((key) => key.indexOf("_nv") > -1)
      .map((key) => ls.remove(key));
    return Promise.resolve();
  }

  if (type === AUTH_ERROR) {
    const status = params.status;
    if (status === 401 /*|| status === 403*/) {
      Cookies.deleteCookie("token");
      Cookies.deleteCookie("role");
      Cookies.deleteCookie("pageId");
      Cookies.deleteCookie("profile");
      return Promise.reject();
    }
    return Promise.resolve();
  }

  if (type === AUTH_CHECK) {
    // return Cookies.getCookie("token") && Cookies.getCookie("pageId")
    const parsedHash = queryString.parse(window.location.hash);
    if (parsedHash["raw[access_token]"]) {
      return Promise.resolve("");
    }
    return Cookies.getCookie("token") &&
      Cookies.getCookie("pageId") &&
      Cookies.getCookie("profile")
      ? Promise.resolve()
      : Promise.reject();
  }

  if (type === AUTH_GET_PERMISSIONS) {
    const role = Cookies.getCookie("role");
    const pageId = Cookies.getCookie("pageId");

    const parsedHash = queryString.parse(window.location.hash);
    if (parsedHash["raw[access_token]"]) {
      return Promise.resolve(undefined);
    }

    return role && pageId ? Promise.resolve(role) : Promise.reject();
  }
  return Promise.resolve();
};
