import React from "react";
import Decimal from "decimal.js-light";
import { Labeled, useRecordContext } from "react-admin";

import { getSum, getNonCancelledItems } from "../helpers/methods";

const TotalInclShipping = ({ label }) => {
  const record = useRecordContext();
  const { shippingCost = 0, orderItems = [] } = record;
  const total = useTotalInclShipping({ shippingCost, orderItems });
  return (
    <Labeled label={label ? label : ""}>
      <span>{total}</span>
    </Labeled>
  );
};
export default TotalInclShipping;

export const useMerchandiseTotal = ({ orderItems = [] }) => {
  const items = orderItems.filter(getNonCancelledItems);
  return getSum(items);
};

export const useTotalInclShipping = ({ shippingCost = 0, orderItems = [] }) => {
  const merchandiseTotal = useMerchandiseTotal({ orderItems });
  const num = isNaN(parseInt(shippingCost))
    ? new Decimal(0)
    : new Decimal(shippingCost);
  const total = num.add(merchandiseTotal).toFixed(2);
  return total;
};

export const getTotalInclShipping = useTotalInclShipping;
