import React from "react";
import isFuture from "date-fns/isFuture";
import { Link } from "react-router-dom";

import { useProfile } from "./useProfile";
import { useIsSuperAdminMode } from "./superAdminMode";

export const useSubscription = () => {
  const { profile, isLoading, isError } = useProfile();

  if (!profile) {
    return { isLoading, isError };
  } else {
    const isTrialValid =
      profile.page.isTrial && isFuture(new Date(profile.page.trialEndDate));
    const isSubscriptionValid =
      profile.page.isSubscribed &&
      isFuture(new Date(profile.page.subscriptionEndDate));
    return {
      isLoading: false,
      isError: false,
      pageName: profile.page.name,
      psid: profile.page.psid,
      igsid: profile.page.igsid,
      isPlanValid: isTrialValid || isSubscriptionValid,
      isTrialValid,
      isSubscriptionValid,
      trialEndDate: profile.page.trialEndDate,
      subscriptionEndDate: profile.page.subscriptionEndDate,
    };
  }
};

export const withValidPlanShow = (WrappedComponent) => (props) => {
  const { isLoading, isError, isPlanValid } = useSubscription();
  const isSuperAdmin = useIsSuperAdminMode();

  if (isPlanValid || isSuperAdmin) {
    return <WrappedComponent {...props} />;
  } else if (isLoading) {
    return <div>Loading...</div>;
  } else if (isError) {
    return <div>Could not reach server, try reload again.</div>;
  } else if (!isLoading && !isPlanValid) {
    return (
      <div>
        Your plan is expired,{" "}
        <Link
          to={`/billing`}
        >{`please press me to subscribe to continue.`}</Link>
      </div>
    );
  }
};
