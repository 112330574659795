import React, { useState, useCallback } from "react";
import { useDataProvider } from "react-admin";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import {
  useRefresh,
  useNotify,
  useUnselectAll,
  useListContext,
} from "react-admin";
import Loader from "../Loader";
import { useShippingMethod } from "../useShippingMethod";
import { useCustomAllRecords } from "../Common/useCustomAllRecords";

const MAX_CONCURRENT = 10;

const BulkUpdateOrderOthers = (props) => {
  const dataProvider = useDataProvider();
  const { data, resource, selectedIds } = useListContext();
  const allRecords = useCustomAllRecords();

  const [updateErrors, setupdateErrors] = useState([]);
  const [enabler, setEnabler] = React.useState({
    description: false,
    shippingOption: false,
    shippingCost: false,
    overrideShippingCost: false,
    doPrinted: false,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [shippingOption, setShippingOption] = useState("");
  const [shippingCost, setShippingCost] = useState(0);
  const [overrideShippingCost, setOverrideShippingCost] = useState(false);
  const [doPrinted, setDoPrinted] = useState(false);
  const [loading, setLoading] = useState(false);

  const [requests, setRequests] = React.useState([]);
  const [pending, setPending] = React.useState(0);

  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll("orders");
  const [shippingOptions] = useShippingMethod({ showAll: true });

  const toggleHandler = useCallback(
    (event) => {
      setEnabler({ ...enabler, [event.target.name]: event.target.checked });
    },
    [enabler]
  );

  const handleClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleDialogClose = useCallback(() => {
    unselectAll();
    refresh();
    setLoading(false);
    setIsOpen(false);
    setupdateErrors([]);
  }, [refresh, unselectAll]);

  React.useEffect(() => {
    return () => unselectAll();
  }, [unselectAll]);

  const handleConfirm = React.useCallback(() => {
    // const fields = Object.keys(enabler).filter((f) => !!enabler[f]);
    const originals = allRecords.filter((record) =>
      selectedIds.includes(record._id)
    );
    const payloads = originals.map((d) => {
      let p = { id: d.id };
      if (enabler.description) {
        p["description"] = description;
      }

      if (enabler.shippingOption) {
        p["shippingOption"] = shippingOption;
      }

      if (enabler.shippingCost) {
        p["shippingCost"] = shippingCost;
      }

      if (enabler.overrideShippingCost) {
        p["overrideShippingCost"] = overrideShippingCost;
      }

      if (enabler.doPrinted) {
        p["deliveryOrderPrinted"] = doPrinted;
      }
      return p;
    });
    setRequests(payloads);
    setLoading(true);
  }, [
    data,
    description,
    enabler.description,
    enabler.overrideShippingCost,
    enabler.shippingCost,
    enabler.shippingOption,
    enabler.doPrinted,
    overrideShippingCost,
    selectedIds,
    shippingCost,
    shippingOption,
    doPrinted,
  ]);

  React.useEffect(() => {
    if (pending === 0 && requests.length > 0) {
      const processSlice = requests.slice(0, MAX_CONCURRENT);
      const nextSlice = requests.slice(MAX_CONCURRENT);
      setPending(processSlice.length);
      setRequests(nextSlice);
      processSlice.forEach(({ id, ...values }) => {
        dataProvider
          .update("orders", { id: id, data: values })
          .then((resp) => {
            setPending((prev) => prev - 1);
          })
          .catch((e) => {
            setPending((prev) => prev - 1);
            setupdateErrors((prev) => [...prev, id]);
          });
      });
    } else if (
      pending === 0 &&
      requests.length === 0 &&
      loading &&
      updateErrors.length === 0
    ) {
      setLoading(false);
      notify("Operation successful.");
      handleDialogClose();
    }
  }, [
    dataProvider,
    handleDialogClose,
    loading,
    notify,
    pending,
    requests,
    updateErrors.length,
  ]);

  const validate = useCallback(() => {
    if (enabler.shippingCost) {
      if (shippingCost === "" || isNaN(shippingCost)) {
        return "Please enter correct shippingCost.";
      }
    }
    return null;
  }, [enabler.shippingCost, shippingCost]);
  const errorMsg = validate();

  return (
    <>
      <Button variant="outlined" label="edit" onClick={handleClick}>
        <EditIcon />
        Edit
      </Button>
      <Dialog
        // fullScreen={false}
        open={isOpen}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>{"Select one field to edit"}</DialogTitle>
        <DialogContent>
          {updateErrors.length > 0 && (
            <p>Some errors happened, please recheck after.</p>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={enabler.description}
                onChange={toggleHandler}
                name="description"
              />
            }
            label="description"
          />
          <TextField
            label={`description`}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            disabled={!enabler.description}
            required={enabler.description}
          />
          <br />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={enabler.shippingOption}
                onChange={toggleHandler}
                name="shippingOption"
              />
            }
            label="ShippingOption"
          />
          <Select
            value={shippingOption}
            onChange={(e) => {
              setShippingOption(e.target.value);
            }}
            disabled={!enabler.shippingOption}
            required={enabler.shippingOption}
          >
            {shippingOptions.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.name}
              </MenuItem>
            ))}
          </Select>
          <br />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={enabler.shippingCost}
                onChange={toggleHandler}
                name="shippingCost"
              />
            }
            label="shippingCost"
          />
          <TextField
            label={`shippingCost`}
            type="number"
            value={shippingCost}
            onChange={(e) => {
              setShippingCost(e.target.value);
            }}
            disabled={!enabler.shippingCost}
            required={enabler.shippingCost}
          />
          <br />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={enabler.overrideShippingCost}
                onChange={toggleHandler}
                name="overrideShippingCost"
              />
            }
            label="overrideShippingCost"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={overrideShippingCost}
                onChange={(e) => setOverrideShippingCost(e.target.checked)}
              />
            }
            label="Yes(Tick)/ No(Untick)"
            disabled={!enabler.overrideShippingCost}
          />
          <br />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={enabler.doPrinted}
                onChange={toggleHandler}
                name="doPrinted"
              />
            }
            label="DO/Inv printed"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={doPrinted}
                onChange={(e) => setDoPrinted(e.target.checked)}
              />
            }
            label="Yes(Tick)/ No(Untick)"
            disabled={!enabler.doPrinted}
          />
          <br />
          <br />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleConfirm}
            color="primary"
            disabled={
              !!errorMsg ||
              pending > 0 ||
              requests.length > 0 ||
              Object.keys(enabler).filter((k) => !!enabler[k]).length === 0
            }
          >
            Update
          </Button>
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Loader open={loading} />
    </>
  );
};

export default BulkUpdateOrderOthers;
