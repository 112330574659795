import React from "react";
import { FormDataConsumer } from "react-admin";

const getIsStackable = (status) => {
  const isStackable =
    ["active", "paid", "waiting_for_payment", "on_hold"].indexOf(status) > -1;
  return isStackable;
};

const Stackable = () => (
  <span>
    Order is <b>stackable</b>
  </span>
);
const NotStackable = () => (
  <span>
    Order is <b>NOT</b> stackable
  </span>
);

const StackableLabel = (props) => {
  return (
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        const isStackable = getIsStackable(formData.status);
        return isStackable ? <Stackable /> : <NotStackable />;
      }}
    </FormDataConsumer>
  );
};
export default StackableLabel;
