import React from "react";
import get from "lodash/get";
import { useRecordContext } from "react-admin";

const noPropagate = (e) => {
  e.stopPropagation();
};

const TextLinkField = (props) => {
  const {
    source,
    resourceName,
    resourceKeyId,
    className,
    onClickAction = "show",
  } = props;
  const record = useRecordContext();

  if (source && record) {
    const data = get(record, source);
    const resourceId = get(record, resourceKeyId);
    return (
      <span className={className}>
        <a
          href={`/#/${resourceName}/${resourceId}/${onClickAction}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={noPropagate}
        >
          {data}
        </a>
      </span>
    );
  }
  return null;
};

export default TextLinkField;
