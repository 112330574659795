// import React, { useState, useEffect } from "react";
// import { withProps, branch, compose, renderComponent } from "recompose";
// import Cookies from "./Cookies";

export const SELLER_ROLE = "Seller";
export const ASSISTANT_ROLE = "Assistant";

// export const isLogisticStaff = ({ role }) => role === "logistic staff";
// export const withRoleProps = (WrappedComponent) => (props) => {
//   const roleCookie = Cookies.getCookie("role");
//   const [role, setRole] = useState(roleCookie);
//   useEffect(() => {
//     setRole(roleCookie);
//   }, [roleCookie]);
//   return <WrappedComponent role={role} {...props} />;
// };
