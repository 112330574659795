import React from "react";
import {
  SimpleForm,
  Edit,
  TextInput,
  TextField,
  BooleanInput,
  Toolbar,
  SaveButton,
  useRecordContext,
  useInput,
  Labeled,
} from "react-admin";
import Rating from "@mui/material/Rating";

import { ContactCredit } from "./ContactCredit";

const ContactEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton
      transform={({ phoneNumber, isBlacklisted, rating, remarks }) => ({
        phoneNumber,
        isBlacklisted,
        rating,
        remarks,
      })}
    />
  </Toolbar>
);

const EditRating = (props) => {
  const {
    field: { onChange },
  } = useInput({ ...props, name: "rating" });
  const record = useRecordContext();
  const [ratingValue, setRatingValue] = React.useState(record.rating);

  return (
    <span>
      <Rating
        defaultValue={ratingValue / 2}
        onChange={(e) => {
          onChange(e.target.value * 2);
          setRatingValue(e.target.value * 2);
        }}
        value={ratingValue / 2}
        precision={0.5}
      />
    </span>
  );
};

export const ContactEdit = (props) => (
  <Edit title="Edit Contact" {...props}>
    <SimpleForm toolbar={<ContactEditToolbar />}>
      <Labeled label="Name">
        <TextField source="name" />
      </Labeled>
      <TextInput source="phoneNumber" />
      <TextInput source="remarks" />
      <BooleanInput source="isBlacklisted" />
      <Labeled label="Rating">
        <EditRating />
      </Labeled>
      <Labeled label="Available Credit">
        <ContactCredit />
      </Labeled>
    </SimpleForm>
  </Edit>
);

export default ContactEdit;
