import React from "react";
import {
  List,
  SimpleList,
  // SimpleShowLayout,
  // Show,
  Datagrid,
  TextField,
  // EditButton,
  NumberField,
  Edit,
  //TextInput,
  // Create,
  Pagination,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  SelectArrayInput,
  useUnselectAll,
  useRecordContext,
} from "react-admin";
import useMediaQuery from "@mui/material/useMediaQuery";
// import { getPageId } from "./helpers/getPageId";
// import { getCookie } from "./helpers/Cookies";

import { orderItemStatuses, orderItemChannels } from "./orders";
// import { Link } from "react-router-dom";
// import AutoCompleteDescription from "./components/OrderItem/AutoCompleteDescription";
import ListActions from "./components/OrderItem/ListActions";
import OrderItemEditForm from "./components/OrderItem/OrderItemEditForm";
import CustomPickerInput from "./components/OrderItem/CustomPickerInput";
import BulkUpdateTagsButton from "./components/OrderItem/BulkUpdateTagsButton";
import BulkRemovePrefix from "./components/OrderItem/BulkRemovePrefix";
// import { useIsEnterprisePlan } from "./helpers/subscriptionPlan";
import { getOrderItemSearchStartDate } from "./helpers/methods";
import { withDisabledIfCancelled } from "./helpers/withDisabled";
import BulkUpdateOrderItemButton from "./components/BulkUpdateOrderItem";
import { ORDER_ITEM_CANCELLED } from "./components/Constant";
import CustomChipArrayField from "./components/CustomChipArrayField";
import CustomDescriptionSearch from "./components/OrderItem/CustomDescriptionSearch";
import CustomItemDescriptionWithImage from "./Order/CustomItemDescriptionWithImage";
import TextLinkField from "./components/Common/TextLinkField";
import CustomKeywordSearch from "./components/OrderItem/CustomKeywordSearch";
import CustomDateField from "./components/Common/CustomDateField";

const OrderItemTextLinkField = withDisabledIfCancelled(TextLinkField);

const OrderItemTextField = withDisabledIfCancelled(TextField);
const OrderItemNumberField = withDisabledIfCancelled(NumberField);
const OrderItemDateField = withDisabledIfCancelled(CustomDateField);

const dateInputOption = {
  disableFuture: true,
};

const OrderItemBulkActionButtons = (props) => {
  return (
    <>
      <BulkUpdateOrderItemButton label="update orderItem" {...props} />
      <BulkUpdateTagsButton {...props} />
      <BulkRemovePrefix {...props} />
    </>
  );
};

export const OrderItemEdit = (props) => {
  const record = useRecordContext();

  return (
    <Edit {...props}>
      <OrderItemEditForm>
        <TextField
          record={record}
          source="order.customerName"
          label="Customer name"
        />
        <CustomDateField source="createdAt" showTime />
        <CustomDateField source="updatedAt" showTime />
      </OrderItemEditForm>
    </Edit>
  );
};

const orderItemFilter = [
  <CustomKeywordSearch
    alwaysOn
    label="Search by keyword"
    source="keyword_in"
  />,
  <CustomDescriptionSearch
    alwaysOn
    label="Item"
    source="descriptionSearch_containss"
  />,
  <ReferenceArrayInput
    label="Tags"
    source="tags"
    reference="tags"
    perPage={100}
  >
    <SelectArrayInput optionText="label" />
  </ReferenceArrayInput>,
  <AutocompleteArrayInput
    source="status_in"
    label={`Status`}
    choices={orderItemStatuses}
    alwaysOn
    //allowEmpty={false}
  />,
  <AutocompleteArrayInput
    source="channel_in"
    label={`Channel`}
    choices={orderItemChannels}
    //allowEmpty={false}
  />,
  <CustomPickerInput
    source="createdAt_gte"
    label="CreatedAt start"
    options={dateInputOption}
    alwaysOn
  />,
  <CustomPickerInput
    source="createdAt_lte"
    label="CreatedAt end"
    options={dateInputOption}
  />,
  <CustomPickerInput
    source="updatedAt_gte"
    label="UpdatedAt start"
    options={dateInputOption}
  />,
  <CustomPickerInput
    source="updatedAt_lte"
    label="UpdatedAt end"
    options={dateInputOption}
  />,
];

const tinyFont = { fontSize: "10px" };

export const OrderItemList = (props) => {
  const unselectAll = useUnselectAll("orderItems");
  // const pageId = getPageId();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  React.useEffect(() => {
    return () => unselectAll();
  }, [unselectAll]);

  return (
    <List
      debounce={2000}
      {...props}
      actions={<ListActions />}
      hasCreate={false}
      filterDefaultValues={{
        createdAt_gte: getOrderItemSearchStartDate().toISOString(),
      }}
      perPage={25}
      filters={orderItemFilter}
      pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
      sort={{ field: "createdAt", order: "DESC" }}
      emptyWhileLoading
    >
      {isSmall ? (
        <SimpleList
          // linkType="show"
          primaryText={(record) => record.description}
          secondaryText={(record) =>
            `${record.order ? record.order.customerName : ""}; price: ${
              record.price
            }; ${record.status}`
          }
          tertiaryText={(record) => (
            <>
              <span style={tinyFont}>createdAt</span>
              <br /> {`${new Date(record.createdAt).toLocaleDateString()}`}
            </>
          )}
        />
      ) : (
        <Datagrid
          rowClick="edit"
          isRowSelectable={({ status }) => status !== ORDER_ITEM_CANCELLED}
          bulkActionButtons={<OrderItemBulkActionButtons />}
        >
          <CustomItemDescriptionWithImage source="description" label="Item" />
          <OrderItemTextLinkField
            source="keyword.key"
            resourceName="keywords"
            resourceKeyId="keyword._id"
            label="Keyword"
            sortable={false}
          />
          <OrderItemTextField
            source="order.customerName"
            label="Customer"
            sortable={false}
          />
          <OrderItemTextField source="channel" label="channel" />
          <OrderItemTextField source="status" />
          <OrderItemNumberField source="price" />
          <OrderItemTextField
            source="order.status"
            label="Order status"
            sortable={false}
          />
          <CustomChipArrayField source="tags" />
          <OrderItemDateField source="createdAt" showTime />
          <OrderItemDateField source="updatedAt" showTime />
        </Datagrid>
      )}
    </List>
  );
};
