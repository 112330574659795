import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useController } from "react-hook-form";

const RecalculateStockInput = (props) => {
  const {
    field: { onChange, value },
  } = useController({ name: "recalculateStock" });
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={value}
          onChange={(e) => {
            onChange(e.target.checked);
          }}
        />
      }
      label="Recalculate Stock"
      {...props}
    />
  );
};

export default RecalculateStockInput;
