import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  // EditButton,
  // RichTextField,
  NumberField,
} from "react-admin";
import CustomDateField from "../Common/CustomDateField";

const KeywordShow = (props) => {
  return (
    <Show title="Show waitlist" {...props}>
      <SimpleShowLayout>
        <TextField source="contact.name" label="Name" />
        <TextField source="description" />
        <NumberField source="quantity" />
        <TextField source="key" />
        <CustomDateField source="createdAt" showTime />
        <CustomDateField source="updatedAt" showTime />
      </SimpleShowLayout>
    </Show>
  );
};

export default KeywordShow;
