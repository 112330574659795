import React, { cloneElement } from "react";
import { useListContext, TopToolbar, CreateButton, Button } from "react-admin";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";

import {
  useIsEnterprisePlan,
  useIsBusinessPlan,
} from "../../helpers/subscriptionPlan";

const ListActions = (props) => {
  const { filters } = props;
  const {
    resource,
    total,
    //loaded, // boolean that is false until the data is available
    showFilter,
    displayedFilters,
    filterValues,
    isLoading,
  } = useListContext();

  const isEnterprisePlan = useIsEnterprisePlan();
  const isBusinessPlan = useIsBusinessPlan();
  const max = isEnterprisePlan ? 1000 : isBusinessPlan ? 10 : 2;
  const showCreate = isLoading ? false : max > total;
  return (
    <TopToolbar>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}

      {showCreate && (
        <Tooltip title={`Plan max: ${max}`}>
          <span>
            <CreateButton resource={resource} />
          </span>
        </Tooltip>
      )}
      {!showCreate && (
        <Tooltip title={`You've reached your plan maximum ${max}.`}>
          <span>
            <Button disabled label="Create">
              <AddIcon />
            </Button>
          </span>
        </Tooltip>
      )}
    </TopToolbar>
  );
};

export default ListActions;
