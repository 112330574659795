import React from "react";
import { useTranslate, usePermissions } from "react-admin";
import TextField from "@mui/material/TextField";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import queryString from "query-string";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import NbNewOrders from "./NbNewOrders";
import NbErrorOrders from "./NbErrorOrders";
import Revenue from "./Revenue";
import Welcome from "./Welcome";
// import Customers from "./Customers";
import TodaySales from "./TodaySales";
import { SELLER_ROLE } from "../../helpers/role";
import { fetchHandleError } from "../../helpers/fetchHandleError";
import { getCookie } from "../../helpers/Cookies";
import { useProfile } from "../DataHooks/useProfile";

import { dateDiffInDays } from "../../helpers/methods";
import {
  SHIPPED,
  READY_TO_SHIP,
  ON_HOLD,
  WAITING_FOR_PAYMENT,
  WAITING_FOR_ONLINE_PAYMENT,
  ACTIVE,
  PAID,
  NO_STACK,
  PREORDER,
  LOCKED,
} from "../Constant";
import { Box } from "@mui/material";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "1em" },
  rightCol: { flex: 1, marginLeft: "1em" },
  singleCol: { marginTop: "2em", marginBottom: "2em" },
};

const Dashboard = (props) => {
  const { profile } = useProfile;
  const [dateRangeData, setDateRangeData] = React.useState({
    orders: [],
    orderItems: [],
  });
  const { permissions } = usePermissions();
  // const aMonthAgo = React.useMemo(() => {
  //   const aMonthAgo = new Date();
  //   aMonthAgo.setDate(aMonthAgo.getDate() - 30);
  //   return aMonthAgo;
  // }, []);
  const translate = useTranslate();

  const [dateRange, setDateRange] = React.useState({
    endDate: endOfDay(new Date()),
    startDate: (function () {
      const d = new Date();
      d.setDate(d.getDate() - 30);
      const start = startOfDay(d);
      return start;
    })(),
  });

  // const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  // const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isAdmin = permissions === SELLER_ROLE;

  const onChangeStartDate = React.useCallback(
    (d) => {
      setDateRange(({ startDate, endDate }) => {
        const start = startOfDay(d);
        const end = new Date(endDate.getTime());
        const days = dateDiffInDays(start, end);
        if (days > 30 || days < 0) {
          const newEndDate = endOfDay(start);
          newEndDate.setDate(start.getDate() + 30);
          return {
            startDate: start,
            endDate: newEndDate,
          };
        } else {
          return {
            startDate: start,
            endDate: end,
          };
        }
      });
    },
    [setDateRange]
  );

  const onChangeEndDate = React.useCallback(
    (d) => {
      setDateRange(({ startDate, endDate }) => {
        const end = endOfDay(d);
        const start = new Date(startDate.getTime());
        const days = dateDiffInDays(start, end);
        if (days > 30 || days < 0) {
          const newStartDate = startOfDay(end);
          newStartDate.setDate(end.getDate() - 30);
          return {
            startDate: newStartDate,
            endDate: end,
          };
        } else {
          return {
            startDate: start,
            endDate: end,
          };
        }
      });
    },
    [setDateRange]
  );

  React.useEffect(() => {
    const token = getCookie("token");
    const abortController = new AbortController();
    fetch(
      `${
        process.env.REACT_APP_CMS_HOST
      }/pages/dashboard?startDate=${dateRange.startDate.toISOString()}&endDate=${dateRange.endDate.toISOString()}`,
      {
        signal: abortController.signal,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then(fetchHandleError)
      .then((resp) => {
        //temp workaround to merge  currency until all objcts populated currency
        const mergedDetails = resp.orders
          .reduce((acc, order) => {
            return [...acc, ...order.detail];
          }, [])
          .reduce((stats, cur) => {
            const found = stats.find((s) => s.status === cur.status);
            if (!found) {
              stats.push(cur);
              return stats;
            } else {
              //found
              found.orderCount += cur.orderCount;
              found.orderItemCount += cur.orderItemCount;
              found.priceSum += cur.priceSum;
              found.shippingCostSum += cur.shippingCostSum;
              return stats;
            }
          }, []);
        const currencyCode = resp.orders.reduce(
          (v, cur) => (!!cur._id.currencyCode ? cur._id.currencyCode : v),
          null
        );
        if (mergedDetails.length > 0) {
          setDateRangeData({
            orders: [
              {
                _id: {
                  currencyCode: currencyCode,
                },
                detail: mergedDetails,
              },
            ],
          });
        } else {
          setDateRangeData({
            orders: [],
            orderItems: [],
          });
        }
        // setDateRangeData(resp); // when workaround removed, uncomment this line
      })
      .catch((e) => {
        console.log(e);
      });
    return () => abortController.abort();
  }, [dateRange]);

  const statistics = dateRangeData.orders.map((d) => {
    const currencyCode = d._id.currencyCode;
    const revenueStat = d.detail
      .filter((v) => [READY_TO_SHIP, SHIPPED].indexOf(v.status) > -1)
      .reduce(
        (stat, cur) => {
          stat.orderCount = stat.orderCount + cur.orderCount;
          stat.orderItemCount = stat.orderItemCount + cur.orderItemCount;
          stat.priceSum = stat.priceSum + cur.priceSum + cur.shippingCostSum;
          return stat;
        },
        {
          orderCount: 0,
          orderItemCount: 0,
          priceSum: 0,
        }
      );

    const pendingRevenueStat = d.detail
      .filter(
        (v) =>
          [
            ON_HOLD,
            WAITING_FOR_PAYMENT,
            WAITING_FOR_ONLINE_PAYMENT,
            ACTIVE,
            PAID,
            NO_STACK,
            PREORDER,
            LOCKED,
          ].indexOf(v.status) > -1
      )
      .reduce(
        (stat, cur) => {
          stat.orderCount = stat.orderCount + cur.orderCount;
          stat.orderItemCount = stat.orderItemCount + cur.orderItemCount;
          stat.priceSum = stat.priceSum + cur.priceSum + cur.shippingCostSum;
          return stat;
        },
        {
          orderCount: 0,
          orderItemCount: 0,
          priceSum: 0,
        }
      );
    return { currencyCode, revenueStat, pendingRevenueStat };
  });

  return (
    <div style={styles.flex}>
      <div style={styles.leftCol}>
        {/* <Box sx={{ width: "60%" }}>
          <a
            href="https://forms.gle/3dqYMEB4firxwSjY9"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="nv banner"
              src="https://aws-fb-live-files.boxify.io/5dc2309e5fccd600e07b3d26/info/tngwallet-1663225058195.png"
              width="100%"
            />
          </a>
        </Box> */}
        <div style={styles.flexColumn}>
          <Welcome />
          <p></p>
        </div>
        {isAdmin && (
          <div style={styles.flex}>
            <TodaySales />
          </div>
        )}
        <div style={styles.singleCol}>
          <p>Max 30 days range</p>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={`Start date`}
              value={dateRange.startDate}
              onChange={onChangeStartDate}
              renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker
              label={`End date`}
              value={dateRange.endDate}
              onChange={onChangeEndDate}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          {isAdmin && (
            <div style={styles.flex}>
              {statistics.map((stat, idx) => {
                const revenue = stat.revenueStat.priceSum.toLocaleString(
                  undefined,
                  {
                    style: "currency",
                    currency: stat.currencyCode,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  }
                );
                const pendingRevenue =
                  stat.pendingRevenueStat.priceSum.toLocaleString(undefined, {
                    style: "currency",
                    currency: stat.currencyCode,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  });
                return (
                  <React.Fragment key={idx}>
                    <Revenue
                      title={translate("pos.dashboard.monthly_revenue")}
                      value={revenue}
                      text={`${stat.revenueStat.orderCount} orders`}
                      text2={`~${stat.revenueStat.orderItemCount} items`}
                      launchLink={`/#/orders?${queryString.stringify({
                        displayedFilters: JSON.stringify({
                          createdAt_gte: true,
                          createdAt_lte: true,
                        }),
                        filter: JSON.stringify({
                          createdAt_gte: dateRange.startDate.toISOString(),
                          createdAt_lte: dateRange.endDate.toISOString(),
                          status_in: ["ready_to_ship", "shipped"],
                        }),
                        order: "DESC",
                        page: 1,
                        perPage: 25,
                        sort: "createdAt",
                      })}`}
                    />
                    <Revenue
                      title={translate("pos.dashboard.pending_revenue")}
                      value={pendingRevenue}
                      text={`${stat.pendingRevenueStat.orderCount} orders`}
                      text2={`~${stat.pendingRevenueStat.orderItemCount} items`}
                      launchLink={`/#/orders?${queryString.stringify({
                        displayedFilters: JSON.stringify({
                          createdAt_gte: true,
                          createdAt_lte: true,
                        }),
                        filter: JSON.stringify({
                          createdAt_gte: dateRange.startDate.toISOString(),
                          createdAt_lte: dateRange.endDate.toISOString(),
                          status_in: [
                            "active",
                            "paid",
                            "waiting_for_payment",
                            "waiting_for_online_payment",
                            "on_hold",
                            "no_stack",
                            "locked",
                            "preorder",
                          ],
                        }),
                        order: "DESC",
                        page: 1,
                        perPage: 25,
                        sort: "createdAt",
                      })}`}
                    />
                  </React.Fragment>
                );
              })}
            </div>
          )}
        </div>
        <div style={styles.singleCol}>
          {/* <OrderChart orders={recentOrders} /> */}
          {dateRangeData.orders.map((data, idx) => {
            return <NbNewOrders key={idx} orders={data.detail} />;
          })}
          {profile && <NbErrorOrders profile={profile} />}
        </div>
        <div style={styles.singleCol}>
          {/* <PendingOrders
            orders={pendingOrders}
            customers={pendingOrdersCustomers}
          /> */}
        </div>
      </div>
      {/* <div style={styles.rightCol}>
        <div style={styles.flex}>
          <PendingReviews
            nb={nbPendingReviews}
            reviews={pendingReviews}
            customers={pendingReviewsCustomers}
          />
          <Customers />
        </div>
      </div> */}
    </div>
  );
};

export default Dashboard;
