import useSWR from "swr";

export const useGetOrderItemsDetails = (orderId = undefined, option) => {
  const { data, error, ...others } = useSWR(
    !!orderId
      ? `${process.env.REACT_APP_CMS_HOST}/orderItems?_sort=createdAt:ASC&_start=0&_limit=1000&order=${orderId}`
      : null,
    option
  );

  return {
    ...others,
    data,
    isLoading: !error && !data,
  };
};
