import React from "react";
import {
  Toolbar,
  useUpdate,
  useRedirect,
  useNotify,
  useRecordContext,
  SaveButton,
} from "react-admin";
import { CANCELLED } from "../components/Constant";
import { useFormContext } from "react-hook-form";
import { dirtyValues } from "../helpers/methods";

const EditOrderToolbar = (props) => {
  const record = useRecordContext();
  const {
    getValues,
    formState: { dirtyFields, isValid },
  } = useFormContext();
  const [update, { isLoading, error }] = useUpdate();

  const redirect = useRedirect();
  const notify = useNotify();
  const handleSave = React.useCallback(
    (e) => {
      const values = getValues();
      const {
        recalculateStock = false,
        userRef,
        page,
        contact,
        ...otherValues
      } = values;
      update(
        "orders",
        {
          id:
            values.status === CANCELLED && recalculateStock
              ? `${record.id}?recalculateStock=true`
              : record.id,
          data: { ...dirtyValues(dirtyFields, otherValues) },
          previousData: record,
        },
        {
          onSuccess: (data) => {
            notify("Element Updated", "info");
            const redirectTo = e.target.getAttribute("data-redirect");
            const currentResource = window.location.hash.split("/")[1]; //eg '#/activeorders/63105a226f426d017f50961d'
            redirectTo === "show"
              ? redirect("show", currentResource, data.id, data)
              : redirect("list", currentResource);
          },
          onFailure: (err) => {
            notify(err.message, { type: "error", autoHideDuration: 30000 });
          },
        }
      );
    },
    [getValues, update, record, dirtyFields, notify, redirect]
  );

  return (
    <Toolbar>
      <SaveButton
        label={`Save & show`}
        onClick={handleSave}
        data-redirect="show"
        disabled={isLoading || !isValid}
        variant="contained"
      />
      <SaveButton
        label={`Save & list`}
        onClick={handleSave}
        data-redirect="list"
        disabled={isLoading || !isValid}
        variant="outlined"
      />
    </Toolbar>
  );
};

export default EditOrderToolbar;
