import React from "react";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import {
  SelectInput,
  TextInput,
  Create,
  TabbedForm,
  FormTab,
  AutocompleteInput,
  ReferenceInput,
  required,
  useGetOne,
} from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import Avatar from "../components/Avatar";
import { statuses } from "../orders";
import Uploader from "../components/Uploader";
import { getPageId } from "../helpers/getPageId";
import OrderToolbar from "./OrderToolbar";
import { useAvailableCurrencies } from "../components/useAvailableCurrencies";
import { useLocation } from "react-router-dom";
import StackableLabel from "./StackableLabel";
import { TaxInput } from "./Tax";
import { visuallyHidden } from "@mui/utils";
import { useGetTaxes } from "../components/DataHooks/useGetTaxes";

const CustomComp = (props) => {
  const { setValue } = useFormContext();
  const contactValue = useWatch({ name: "contact" });
  const { data: contact } = useGetOne(
    "contacts",
    {
      id: contactValue,
    },
    {
      enabled: !!contactValue,
      onSuccess: (data) => {
        setValue("customerName", data.name);
      },
    }
  );

  return (
    <>
      <AutocompleteInput
        {...props}
        label="Nickname"
        optionText={(record) => {
          if (record) {
            return record.name;
          }
        }}
        optionValue="id"
        translateChoice={false}
        filterToQuery={(searchText) => {
          if (!searchText) {
            return null;
          } else {
            return { name_contains: searchText };
          }
        }}
        sx={{ width: 200 }}
      />
      <div style={{ display: "flex" }}>
        <TextInput
          defaultValue={contact ? contact.name : ""}
          source="customerName"
        />
        {contact && <Avatar src={contact.profilePic} alt="customer" />}
        {contact && contact.canReply && <SpeakerNotesIcon color="primary" />}
        {contact && !contact.canReply && <SpeakerNotesOffIcon color="error" />}
      </div>
    </>
  );
};

const OrderCreate = (props) => {
  const newProps = { ...props, resource: "orders" };
  let location = useLocation();
  const { hash = "" } = location;
  const contactId = hash.split("#").length > 1 ? hash.split("#")[1] : "";

  const pageId = getPageId();
  const { availableCurrencies, isMultiCurrency, defaultCurrency } =
    useAvailableCurrencies();
  const { isLoading } = useGetTaxes();
  if (!defaultCurrency || isLoading) {
    return null;
  }

  return (
    <Create {...newProps} redirect="show">
      <TabbedForm
        toolbar={<OrderToolbar />}
        defaultValues={{ contact: contactId }}
      >
        {/* <Uploader source="customerName" label="FB name" /> */}
        <FormTab label="summary">
          <ReferenceInput reference="contacts" source="contact">
            <CustomComp />
          </ReferenceInput>
          <TextInput multiline source="description" />
          {isMultiCurrency && (
            <SelectInput
              defaultValue={defaultCurrency}
              validate={required()}
              source="currencyCode"
              choices={availableCurrencies.map((c) => ({ id: c, name: c }))}
            />
          )}
          {!isMultiCurrency && (
            <TextInput
              defaultValue={defaultCurrency}
              validate={required()}
              disabled
              source="currencyCode"
            />
          )}
          <StackableLabel />
          <SelectInput
            source="status"
            defaultValue="active"
            choices={statuses}
          />
          <TaxInput source="tax" label="Tax" />
          <TextInput source="trackingNumber" />
          <Uploader source="image" />
          <TextInput multiline source="notes" label="Customer notes" />
          <TextInput
            sx={visuallyHidden}
            disabled
            defaultValue={pageId}
            source="page"
          />
        </FormTab>
        <FormTab label="shipping">
          <TextInput source="shippingName" />
          <TextInput source="shippingPhoneNumber" />
          <TextInput source="shippingAddress1" />
          <TextInput source="shippingAddress2" />
          <TextInput source="shippingPostcode" />
          <TextInput source="shippingCity" />
          <TextInput source="shippingState" />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default OrderCreate;
