import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useRecordContext } from "react-admin";

const Comp = () => {
  const record = useRecordContext();
  const { colorCode = null } = record;

  if (!colorCode) {
    return <span>N/A</span>;
  }
  return (
    <span style={{ color: colorCode }}>
      <FiberManualRecordIcon style={{ color: colorCode }} />
    </span>
  );
};

export default Comp;
