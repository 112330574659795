import React from "react";
import { useInput } from "react-admin";
import { useController } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { ColorPicker, toColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";

const Comp = (props) => {
  const { source, label } = props;
  const {
    field: { onChange, value },
  } = useInput({ ...props, name: source, defaultValue: "" });
  //to turn some null code
  const [colorCode, setColorCode] = React.useState(value || "");
  return (
    <div>
      <TextField
        //name={props.input.name}
        label={label}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
          setColorCode(e.target.value);
        }}
        helperText={
          <span>
            {`If set, display as color dot. `}
            <a
              href="https://www.google.com/search?q=color+dot"
              target="_blank"
              rel="noopener noreferrer"
            >
              What is color dot ?
            </a>
          </span>
        }
      />

      <ColorPicker
        width={456}
        height={228}
        color={toColor("hex", colorCode)}
        value={colorCode}
        onChange={(color) => {
          const hexStr = color.hex;
          onChange(hexStr);
          setColorCode(hexStr);
        }}
        hideHSV
        // hideRGB
        dark
      />
    </div>
  );
};

export default Comp;
