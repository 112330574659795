import React from "react";
import { MenuItemLink, useResourceDefinitions } from "react-admin";
import DefaultIcon from "@mui/icons-material/ViewList";
import BuildIcon from "@mui/icons-material/Build";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CropFreeIcon from "@mui/icons-material/CropFree";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import MenuItem from "@mui/material/MenuItem";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import StorefrontIcon from "@mui/icons-material/Storefront";
import WorkIcon from "@mui/icons-material/Work";

import SubMenu from "./SubMenu";
import { useSubscription } from "../DataHooks/useSubscription";
import { useIsSuperAdminMode } from "../DataHooks/superAdminMode";

const MyMenu = ({ onMenuClick, logout }) => {
  const { isPlanValid } = useSubscription();
  const isSuperAdmin = useIsSuperAdminMode();
  const resourcesDefinitions = useResourceDefinitions();
  const [state, setState] = React.useState({
    menuOrders: true,
    menuWebstore: true,
    menuExtra: true,
  });
  const resources = Object.keys(resourcesDefinitions).map(
    (name) => resourcesDefinitions[name]
  );
  const contactResource = resources.find((r) => r.name === "contacts");
  const ordersResources = resources.filter((r) => r.name.includes("orders"));
  const orderItemResource = resources.find((r) => r.name === "orderItems");
  const paymentResource = resources.find((r) => r.name === "payments");
  const keywordResource = resources.find((r) => r.name === "keywords");
  const creditLogsResource = resources.find((r) => r.name === "creditlogs");

  const webstoreResources = resources.filter(
    (r) =>
      r.name.includes("variants") ||
      r.name.includes("selections") ||
      r.name.includes("categories")
  );
  const extraResources = resources.filter(
    (r) =>
      r.name.includes("tags") ||
      r.name.includes("vouchers") ||
      r.name.includes("waitlists") ||
      r.name.includes("exports")
  );

  if (!isPlanValid && !isSuperAdmin) {
    return (
      <div>
        <MenuItemLink
          to={`/`}
          primaryText="Dashboard"
          onClick={onMenuClick}
          leftIcon={<DashboardIcon />}
        />
      </div>
    );
  }

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <div>
      <br />
      <MenuItemLink
        to={`/`}
        primaryText="Dashboard"
        onClick={onMenuClick}
        leftIcon={<DashboardIcon />}
      />
      <MenuItemLink
        to={`/analytics`}
        primaryText="Analytics"
        onClick={onMenuClick}
        leftIcon={<ShowChartIcon />}
      />
      {contactResource && (
        <MenuItemLink
          to={{
            pathname: `/${contactResource.name}`,
            hash: contactResource.hash || "",
          }}
          primaryText={
            (contactResource.options && contactResource.options.label) ||
            contactResource.name
          }
          leftIcon={
            contactResource.icon ? <contactResource.icon /> : <DefaultIcon />
          }
          onClick={onMenuClick}
        />
      )}
      <SubMenu
        handleToggle={() => handleToggle("menuOrders")}
        isOpen={state.menuOrders}
        name="Orders"
        icon={<AttachMoneyIcon />}
        //dense={dense}
      >
        {ordersResources.map((resource, idx) => (
          <MenuItemLink
            key={idx}
            to={{ pathname: `/${resource.name}`, hash: resource.hash || "" }}
            primaryText={
              (resource.options && resource.options.label) || resource.name
            }
            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
            onClick={onMenuClick}
          />
        ))}
      </SubMenu>
      {paymentResource && (
        <MenuItemLink
          to={{
            pathname: `/${paymentResource.name}`,
            hash: paymentResource.hash || "",
          }}
          primaryText={
            (paymentResource.options && paymentResource.options.label) ||
            paymentResource.name
          }
          leftIcon={
            paymentResource.icon ? <paymentResource.icon /> : <DefaultIcon />
          }
          onClick={onMenuClick}
        />
      )}
      {orderItemResource && (
        <MenuItemLink
          to={{
            pathname: `/${orderItemResource.name}`,
            hash: orderItemResource.hash || "",
          }}
          primaryText={
            (orderItemResource.options && orderItemResource.options.label) ||
            orderItemResource.name
          }
          leftIcon={
            orderItemResource.icon ? (
              <orderItemResource.icon />
            ) : (
              <DefaultIcon />
            )
          }
          onClick={onMenuClick}
        />
      )}
      {keywordResource && (
        <MenuItemLink
          to={{
            pathname: `/${keywordResource.name}`,
            hash: keywordResource.hash || "",
          }}
          primaryText={
            (keywordResource.options && keywordResource.options.label) ||
            keywordResource.name
          }
          leftIcon={
            keywordResource.icon ? <keywordResource.icon /> : <DefaultIcon />
          }
          onClick={onMenuClick}
        />
      )}
      <SubMenu
        handleToggle={() => handleToggle("menuWebstore")}
        isOpen={state.menuWebstore}
        name="Webstore"
        icon={<StorefrontIcon />}
        //dense={dense}
      >
        {webstoreResources.map((resource, idx) => (
          <MenuItemLink
            key={idx}
            to={{ pathname: `/${resource.name}`, hash: resource.hash || "" }}
            primaryText={
              (resource.options && resource.options.label) || resource.name
            }
            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
            onClick={onMenuClick}
          />
        ))}
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuExtra")}
        isOpen={state.menuExtra}
        name="Others"
        icon={<WorkIcon />}
        //dense={dense}
      >
        {extraResources.map((resource, idx) => (
          <MenuItemLink
            key={idx}
            to={{ pathname: `/${resource.name}`, hash: resource.hash || "" }}
            primaryText={
              (resource.options && resource.options.label) || resource.name
            }
            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
            onClick={onMenuClick}
          />
        ))}
      </SubMenu>
      <MenuItemLink
        to={`/special-tools`}
        primaryText="Tools"
        onClick={onMenuClick}
        leftIcon={<BuildIcon />}
      />
      {creditLogsResource && (
        <MenuItemLink
          to={{
            pathname: `/${creditLogsResource.name}`,
            hash: creditLogsResource.hash || "",
          }}
          primaryText={
            (creditLogsResource.options && creditLogsResource.options.label) ||
            creditLogsResource.name
          }
          leftIcon={
            creditLogsResource.icon ? (
              <creditLogsResource.icon />
            ) : (
              <DefaultIcon />
            )
          }
          onClick={onMenuClick}
        />
      )}
      <MenuItemLink
        to={`/qr-scanner`}
        primaryText="QR scan"
        onClick={onMenuClick}
        leftIcon={<CropFreeIcon />}
      />
      <MenuItem
        component="a"
        href="https://docs.google.com/document/d/e/2PACX-1vT2eg15RJ70OjKvcw70VPL83N-2AgKQYZXNI1O-ovcrlGjJoPRu3TRPwdwUnAnI0lHAwkEkD74COb1_/pub"
        rel="noopener noreferrer"
        target="_blank"
      >
        Reward Program
      </MenuItem>
      <MenuItem
        component="a"
        href="https://forms.gle/bcqo2HncMHWX7d828"
        rel="noopener noreferrer"
        target="_blank"
      >
        Feature request
      </MenuItem>
      {/* <MenuItemLink
        to="/custom-route"
        primaryText="Miscellaneous"
        onClick={onMenuClick}
      /> */}
    </div>
  );
};

export default MyMenu;
