import React, { Component, useCallback, useState } from "react";
import { makeStyles } from "@mui/styles";

import ReactPlayer from "react-player/lazy";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";

const useStyles = makeStyles((theme) => ({
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%" /* Player ratio: 100 / (1280 / 720) */,
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
  },
}));

const Player = (props) => {
  const classes = useStyles();
  const [show, setShow] = useState(true);
  const handleChange = useCallback(() => {
    setShow((v) => !v);
  }, [setShow]);
  return (
    <>
      {/* <div className={classes.playerWrapper}> */}
      {show && (
        <ReactPlayer
          // className={classes.reactPlayer}
          controls
          url={props.url}
          config={{
            youtube: {
              playerVars: { showinfo: 1 },
            },
            facebook: {
              appId: "376041286589727",
            },
          }}
          muted
          playing
          width="100%"
          height="100%"
        />
      )}
      {/* </div> */}
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch checked={show} onChange={handleChange} name="show-video" />
          }
          label="Show video"
        />
      </FormGroup>
    </>
  );
};

export default Player;
