export const CANCELLED_ORDERS = "cancelledorders";
export const PAID_ORDERS = "paidorders";
export const WAITING_FOR_PAYMENT_ORDERS = "waitingforpaymentorders";
export const READY_TO_SHIP_ORDERS = "ready-to-ship-orders";
export const ACTIVE_ORDERS = "activeorders";
export const READY_TO_SHIP_NOW = "READY_TO_SHIP_NOW";
export const READY_TO_SHIP_SELF_COLLECT = "READY_TO_SHIP_SELF_COLLECT";

export const NOW = "now";
export const LATER = "later";
export const SELF_COLLECT = "self_collect";

//order status
export const ON_HOLD = "on_hold";
export const SHIPPED = "shipped";
export const READY_TO_SHIP = "ready_to_ship";
export const ACTIVE = "active";
export const PAID = "paid";
export const WAITING_FOR_PAYMENT = "waiting_for_payment";
export const WAITING_FOR_ONLINE_PAYMENT = "waiting_for_online_payment";
export const NEW = "new";
export const ERROR_PAYMENT_MISMATCH = "error_payment_mismatch";
export const CANCELLED = "cancelled";
export const PREORDER = "preorder";
export const NO_STACK = "no_stack";
export const LOCKED = "locked";

//order status label
export const LABEL_ERROR_PAYMENT_MISMATCH = "Error payment mismatch";
export const LABEL_WAITING_FOR_ONLINE_PAYMENT = "Waiting for online payment";

//order item status
export const ORDER_ITEM_NEW = "new";
export const ORDER_ITEM_CANCELLED = "cancelled";
export const ORDER_ITEM_PAID = "paid";

//order item channel
export const MANUAL_CHANNEL = "manual";
export const COMMENT_CHANNEL = "comment";
export const WEBSTORE_CHANNEL = "webstore";

export const CONTACT_CHANNEL_INSTAGRAM = "ig";
export const CONTACT_CHANNEL_FACEBOOK = "fb";
export const CONTACT_CHANNEL_OFFLINE = "offline";

export const receiptImageStyle = {
  maxWidth: "100%",
  objectFit: "contain",
};

export const TERMS_OF_USE = "https://boxify.io/terms-of-use/";
export const PRIVACY_POLICY = "https://boxify.io/privacy-policy/";

export const CACHE_CONTACTS = "cache/contacts";
export const MTY_FRAME = "https://aws-fb-live-files.boxify.io/mtyframe.png";
