import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip from "@mui/material/Tooltip";
import {
  useUpdateMany,
  useRefresh,
  useNotify,
  useListContext,
} from "react-admin";
import { ORDER_ITEM_NEW } from "../Constant";
import { LoadingButton } from "@mui/lab";

const FormDialog = (props) => {
  const { data } = useListContext();
  const [open, setOpen] = React.useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const newItems = data
    ? data.filter(({ status }) => status === ORDER_ITEM_NEW)
    : [];
  const newItemIds = newItems.map(({ _id }) => _id);
  const [updateMany, { isLoading }] = useUpdateMany(
    `orderItems`,
    { ids: newItemIds, data: { status: "paid" } },
    {
      onSuccess: () => {
        refresh();
        notify("Set paid successfully");
        setOpen(false);
      },
      onFailure: (error) => notify("Failed to update", "warning"),
    }
  );
  const onSetPaid = useCallback(() => {
    updateMany();
  }, [updateMany]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title="Set NEW items to PAID" placement="top-end">
        <span>
          <Button
            variant="outlined"
            size="small"
            onClick={handleClickOpen}
            disabled={newItemIds.length === 0 || isLoading}
          >
            Set all Paid
          </Button>
        </span>
      </Tooltip>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Set paid</DialogTitle>
        <DialogContent>
          <DialogContentText>Set these items to PAID ?</DialogContentText>
          <ul>
            {newItems.map((item) => (
              <li key={item._id}>{item.description}</li>
            ))}
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <LoadingButton
            loading={isLoading}
            onClick={onSetPaid}
            color="primary"
            variant="contained"
            disabled={newItemIds.length === 0 || isLoading}
          >
            Set paid
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FormDialog;
