import React, { useState, useCallback } from "react";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import DialogActions from "@mui/material/DialogActions";
// import { Form, Field } from "react-hook-form";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {
  useRefresh,
  useNotify,
  useListContext,
  useUnselectAll,
  useDataProvider,
  // FormDataConsumer,
} from "react-admin";
// import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import { useForm, FormProvider, Controller } from "react-hook-form";

import AttachSelections from "../AutocompleteAttach/AttachSelections";
import { uniqueByValue, isNaNOrNull } from "../../helpers/methods";
import Loader from "../Loader";
import { useCustomAllRecords } from "../Common/useCustomAllRecords";
// import TextInputNoWheel from "../Common/TextInputNoWheel";
import DecimalInput from "../Common/DecimalInput";

const OVERWRITE = "overwrite";
const APPEND = "append";
const REMOVE = "remove";
const MAX_CONCURRENT = 10;

const Comp = (props) => {
  const { resource, selectedIds } = useListContext();
  const allRecords = useCustomAllRecords();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requests, setRequests] = useState([]);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll(resource);
  const dataProvider = useDataProvider();

  const formMethods = useForm({
    defaultValues: {
      enableAllowInWeb: false,
      enableCategories: false,
      enableVariants: false,
      categories: [],
      variants: [],
      enableWebPrice: false,
      allowInWeb: false,
      webPrice: 0,
      updateRadio: OVERWRITE,
    },
  });
  const values = formMethods.watch();
  React.useEffect(() => {
    return () => {
      unselectAll();
      formMethods.reset();
    };
  }, [formMethods, resource, unselectAll]);

  const handleClick = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const handleDialogClose = useCallback(() => {
    setIsOpen(false);
    formMethods.reset();
    unselectAll();
  }, [formMethods, unselectAll]);

  const handleConfirm = React.useCallback(
    async ({
      updateRadio,
      categories,
      variants,
      enableCategories,
      enableVariants,
      enableAllowInWeb,
      allowInWeb,
      enableWebPrice,
      webPrice,
    }) => {
      const updateDatas =
        updateRadio === APPEND
          ? selectedIds
              .map((id) => {
                return allRecords.find((singleData) => {
                  return singleData.id === id;
                });
              })
              .map((d) => {
                const newCategories = [...d.categories, ...categories]
                  .map((c) => c.id)
                  .filter(uniqueByValue);
                const newVariants = [...d.variants, ...variants]
                  .map((v) => v.id)
                  .filter(uniqueByValue);
                return {
                  id: d.id,
                  ...(enableCategories && { categories: newCategories }),
                  ...(enableVariants && { variants: newVariants }),
                  ...(enableAllowInWeb && {
                    ...{ allowInWeb: allowInWeb },
                    ...(isNaNOrNull(d.webPrice) && { webPrice: d.price }),
                  }),
                  ...(enableWebPrice && { webPrice }),
                };
              })
          : updateRadio === OVERWRITE
          ? selectedIds
              .map((id) => {
                return allRecords.find((singleData) => {
                  return singleData.id === id;
                });
              })
              .map((d) => ({
                id: d.id,
                ...(enableCategories && { categories: categories }),
                ...(enableVariants && { variants: variants }),
                ...(enableAllowInWeb && {
                  ...{ allowInWeb: allowInWeb },
                  ...(isNaNOrNull(d.webPrice) && { webPrice: d.price }),
                }),
                ...(enableWebPrice && { webPrice }),
              }))
          : updateRadio === REMOVE
          ? selectedIds
              .map((id) => {
                return allRecords.find((singleData) => {
                  return singleData.id === id;
                });
              })
              .map((d) => ({
                id: d.id,
                ...(enableCategories && { categories: [] }),
                ...(enableVariants && { variants: [] }),
                ...(enableAllowInWeb && {
                  ...{ allowInWeb: allowInWeb },
                  ...(isNaNOrNull(d.webPrice) && { webPrice: d.price }),
                }),
                ...(enableWebPrice && { webPrice }),
              }))
          : [];
      setRequests(updateDatas);
    },
    [allRecords, selectedIds]
  );

  React.useEffect(() => {
    const update = async () => {
      const processSlice = requests.slice(0, MAX_CONCURRENT);
      const nextSlice = requests.slice(MAX_CONCURRENT);
      try {
        setLoading(true);
        await Promise.all(
          processSlice.map(({ id, ...others }) =>
            dataProvider.update("keywords", {
              id,
              data: { ...others },
            })
          )
        );
        setRequests(nextSlice);
        if (nextSlice.length === 0) {
          setLoading(false);
          notify("Keywords updated successfully", "info");
          unselectAll();
          setIsOpen(false);
          refresh();
        }
      } catch (e) {
        setRequests([]);
        setLoading(false);
        unselectAll();
        refresh();
        setIsOpen(false);
        notify("Keywords update failed.", "warning");
      }
    };
    if (requests.length > 0) {
      update();
    }
  }, [dataProvider, notify, refresh, requests, resource, unselectAll]);

  if (loading) {
    return (
      <Loader open>
        <span> {`Processing ${requests.length} updates`}</span>
      </Loader>
    );
  }

  return (
    <>
      <Button
        startIcon={<EditIcon />}
        label="Update Category And Variant"
        onClick={handleClick}
      >
        {`Category & Variant`}
      </Button>

      <Dialog
        // fullScreen={true}
        fullWidth
        open={isOpen}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>{"Edit Categories & Variants"}</DialogTitle>
        <form
          className="form"
          onSubmit={formMethods.handleSubmit(handleConfirm)}
        >
          <FormProvider {...formMethods}>
            <>
              <DialogContent>
                <Paper variant="outlined">
                  <div>
                    <div>
                      <div>
                        <FormControlLabel
                          control={
                            <Controller
                              name={"enableCategories"}
                              control={formMethods.control}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  checked={props.value}
                                  onChange={(e) =>
                                    props.onChange(e.target.checked)
                                  }
                                />
                              )}
                            />
                          }
                          label="Categories"
                        />
                      </div>
                      {values.enableCategories &&
                        values.updateRadio !== REMOVE && (
                          <AttachSelections
                            source="categories"
                            searchFieldName="label"
                          />
                        )}
                    </div>
                    <div>
                      <FormControlLabel
                        control={
                          <Controller
                            name={"enableVariants"}
                            control={formMethods.control}
                            render={({ field: props }) => (
                              <Checkbox
                                {...props}
                                checked={props.value}
                                onChange={(e) =>
                                  props.onChange(e.target.checked)
                                }
                              />
                            )}
                          />
                        }
                        label="Variants"
                      />
                    </div>
                    {values.enableVariants && values.updateRadio !== REMOVE && (
                      <AttachSelections
                        source="variants"
                        searchFieldName="label"
                      />
                    )}
                  </div>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Action</FormLabel>
                    <Controller
                      rules={{ required: true }}
                      control={formMethods.control}
                      name="updateRadio"
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <FormControlLabel
                            value={OVERWRITE}
                            control={<Radio />}
                            label="Overwrite"
                          />
                          <FormControlLabel
                            value={APPEND}
                            control={<Radio />}
                            label="Append"
                          />
                          <FormControlLabel
                            value={REMOVE}
                            control={<Radio />}
                            label="Remove"
                          />
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Paper>
                <br />
                <div>
                  <FormControlLabel
                    control={
                      <Controller
                        name={"enableAllowInWeb"}
                        control={formMethods.control}
                        render={(props) => (
                          <Checkbox
                            {...props}
                            checked={props.field.value}
                            onChange={(e) =>
                              props.field.onChange(e.target.checked)
                            }
                          />
                        )}
                      />
                    }
                    label={"Change Allow in web"}
                  />
                  {values.enableAllowInWeb && (
                    <FormControlLabel
                      control={
                        <Controller
                          name={"allowInWeb"}
                          control={formMethods.control}
                          render={(props) => (
                            <Switch
                              disabled={!values.enableAllowInWeb}
                              checked={props.field.value}
                              onChange={props.field.onChange}
                            />
                          )}
                        />
                      }
                      label={`Allow in web?(If no webPrice, system will auto assign webPrice=price when triggering this)`}
                    />
                  )}
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Controller
                        name={"enableWebPrice"}
                        control={formMethods.control}
                        render={(props) => (
                          <Switch
                            disabled={!values.enableAllowInWeb}
                            checked={props.field.value}
                            onChange={props.field.onChange}
                          />
                        )}
                      />
                    }
                    label="Change Web Price"
                  />
                  {values.enableWebPrice && (
                    <>
                      <br />
                      <DecimalInput source="webPrice" />
                      {/* <FormDataConsumer>
                        {({ formData }) => {
                          return (
                            <TextInputNoWheel
                              type="number"
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  formMethods.handleSubmit(
                                    handleConfirm(formData)
                                  );
                                }
                              }}
                              source="webPrice"
                            />
                          );
                        }}
                      </FormDataConsumer> */}
                    </>
                  )}
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose} color="secondary">
                  Cancel
                </Button>
                <Button type="submit" variant="contained">
                  Save
                </Button>
              </DialogActions>
            </>
          </FormProvider>
        </form>
      </Dialog>
    </>
  );
};

export default Comp;
