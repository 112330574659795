import React, { useMemo } from "react";
import { DateField, resolveBrowserLocale } from "react-admin";

const CustomDateField = (props) => {
  const locale = useMemo(
    () => resolveBrowserLocale(undefined, { fullLocale: true }),
    []
  );
  return <DateField locales={locale} {...props} />;
};

export default CustomDateField;
