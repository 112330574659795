import React, { useState } from "react";
import Downshift from "downshift";
import matchSorter from "match-sorter";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import useSWR from "swr";
import { useFormContext, useController } from "react-hook-form";
import { useProfile } from "./DataHooks/useProfile";

import useDebounce from "./UseDebounce";
import {
  escapeRegExp,
  comboRegex,
  getOrderItemSearchStartDate,
} from "../helpers/methods";

const itemToString = (item) => {
  if (item && item.description) {
    const spacelessDescription = item.description.replace(/\s/g, " ").trim();
    const matches = comboRegex.exec(spacelessDescription);
    if (matches) {
      const [fullString, productDescription, quantity] = matches;
      return productDescription.trim();
    } else return item.description;
  } else if (item) {
    return item;
  }
  return "";
};

const keywordToString = (keyword) => {
  if (keyword && keyword.key) {
    //return keyword
    return keyword.key;
  } else if (keyword) {
    return keyword;
  }
  return "";
};

export const DownshiftInputDescription = ({
  // input,
  meta,
  placeholder,
  label,
  // items,
  ...rest
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { field } = useController({ name: "item" });
  const { setValue } = useFormContext();
  const debouncedSearchTerm = useDebounce(field.value, 800);
  const { profile } = useProfile();
  const trimmedSearchTerm = debouncedSearchTerm && debouncedSearchTerm.trim();

  const target =
    profile &&
    profile.page &&
    profile.page._id &&
    trimmedSearchTerm &&
    trimmedSearchTerm.length > 1
      ? `${
          process.env.REACT_APP_CMS_HOST
        }/orderItems/search?descriptionSearch_containss=${encodeURIComponent(
          trimmedSearchTerm.toUpperCase()
        )}&page=${
          profile.page._id
        }&_sort=updatedAt:DESC&_start=0&_limit=15&createdAt_gte=${getOrderItemSearchStartDate().toISOString()}`
      : null;
  const { data, error, isValidating } = useSWR(target);

  const items = data
    ? data.filter(
        (v, i, a) => a.findIndex((t) => t.description === v.description) === i
      )
    : [];
  return (
    <Downshift
      isOpen={menuIsOpen}
      onOuterClick={() => setMenuIsOpen(false)}
      onSelect={(selectedItem) => {
        if (selectedItem) {
          const { price = 0, description = "", weight, cost } = selectedItem;
          const spacelessDescription = description.replace(/\s/g, " ").trim();
          const matches = comboRegex.exec(spacelessDescription);
          const priceNumber = Number(price);
          const weightNumber = Number(weight);
          const costNumber = Number(cost);

          if (matches) {
            const [fullString, productDescription, quantity] = matches;
            setValue("item", productDescription.trim());
            setValue("quantity", parseInt(quantity));
          }
          if (!isNaN(priceNumber)) {
            setValue("price", priceNumber);
          }
          if (!isNaN(weightNumber)) {
            setValue("weight", weightNumber);
          }
          if (!isNaN(costNumber)) {
            setValue("cost", costNumber);
          }
        }
        setMenuIsOpen(false);
      }}
      itemToString={itemToString}
      //selectedItem={input.value}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
      }) => {
        const filteredItems = matchSorter(items, field.value, {
          keys: ["description"],
          maxRanking: matchSorter.rankings.STARTS_WITH,
        });
        //basically we use Downshift to render the selections only. Inputs are handled by react hook form
        return (
          <div className="downshift" style={{ position: "relative" }}>
            <TextField
              variant="filled"
              required
              fullWidth
              label={label}
              InputProps={{
                endAdornment: isValidating ? (
                  <InputAdornment position="end">
                    <CircularProgress size="1rem" />
                  </InputAdornment>
                ) : null,
              }}
              onChange={(e) => {
                setMenuIsOpen(true);
                field.onChange(e.target.value);
              }}
              value={field.value}
            />
            {isOpen && !!filteredItems.length && (
              <div
                className="downshift-options"
                style={{
                  background: "white",
                  position: "absolute",
                  top: "100%",
                  left: 15,
                  right: 0,
                  zIndex: 4,
                }}
              >
                {filteredItems.map(
                  ({ description, price, weight, cost }, index) => (
                    <div
                      {...getItemProps({
                        key: `${description}-${index}`,
                        index,
                        item: { description, price, weight, cost },
                        style: {
                          backgroundColor:
                            highlightedIndex === index ? "lightgray" : "white",
                          fontWeight:
                            selectedItem === description ? "bold" : "normal",
                        },
                      })}
                    >
                      {`${description} (price: ${price})`}
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        );
      }}
    </Downshift>
  );
};

export const DownshiftKeyword = ({
  // input,
  meta,
  placeholder,
  label,
  // items,
  ...rest
}) => {
  const { field } = useController({ name: "key" });

  const { setValue } = useFormContext();
  const { profile } = useProfile();

  const searchInactive =
    profile &&
    profile.params &&
    profile.params.enableManualAddItemInactiveKeyword;

  const debouncedSearchTerm = useDebounce(field.value, 800);
  const target =
    profile && profile.page && profile.page._id && debouncedSearchTerm
      ? `${
          process.env.REACT_APP_CMS_HOST
        }/keywords/?key_containss=${encodeURIComponent(
          escapeRegExp(debouncedSearchTerm)
        )}&page=${profile.page._id}&_sort=key:ASC&_start=0&_limit=20${
          searchInactive ? "" : "&isActive=true"
        }`
      : null;

  const { data, error, isValidating } = useSWR(target);

  // const keywords = data
  //   ? data.filter((v, i, a) => a.findIndex((t) => t.key === v.key) === i)
  //   : [];
  const keywords = data ? data : [];

  return (
    <Downshift
      onInputValueChange={(inputValue) => {
        field.onChange(inputValue.toUpperCase());
      }}
      onSelect={(selectedItem) => {
        if (selectedItem) {
          const {
            price = 0,
            key = "",
            description = "",
            id = "",
            weight = 0,
            cost = 0,
            stockLeft,
          } = selectedItem;
          if (id !== "") {
            // setValue("quantity", "");
            setValue("keyword", {
              ...selectedItem,
            });
          }
          if (description !== "") {
            setValue("item", `${description}`);
          }
        }
      }}
      itemToString={keywordToString}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
      }) => {
        const filteredKeywords = matchSorter(keywords, inputValue, {
          keys: ["key"],
          maxRanking: matchSorter.rankings.STARTS_WITH,
        });
        return (
          <div className="downshift" style={{ position: "relative" }}>
            <TextField
              variant="filled"
              required
              fullWidth
              placeholder="A001"
              label={label}
              InputProps={{
                endAdornment: isValidating ? (
                  <InputAdornment position="end">
                    <CircularProgress size="1rem" />
                  </InputAdornment>
                ) : null,
              }}
              {...getInputProps({
                name: field.name,
                placeholder,
              })}
            />
            {isOpen && !!filteredKeywords.length && (
              <div
                className="downshift-options"
                style={{
                  background: "white",
                  position: "absolute",
                  top: "100%",
                  left: 15,
                  right: 0,
                  zIndex: 4,
                }}
              >
                {filteredKeywords.map(
                  (
                    {
                      description,
                      price,
                      key,
                      id,
                      weight,
                      cost,
                      stockLeft,
                      isActive,
                    },
                    index
                  ) => (
                    <div
                      {...getItemProps({
                        key: `${description}-${index}`,
                        index,
                        item: {
                          description,
                          price,
                          key,
                          id,
                          weight,
                          cost,
                          stockLeft,
                        },
                        style: {
                          backgroundColor:
                            highlightedIndex === index ? "lightgray" : "white",
                          fontWeight: selectedItem === key ? "bold" : "normal",
                        },
                      })}
                    >
                      {`${key}${
                        searchInactive && isActive
                          ? "(Active)"
                          : searchInactive && !isActive
                          ? "(Inactive)"
                          : ""
                      }: ${description} (price: ${price})`}
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        );
      }}
    </Downshift>
  );
};
