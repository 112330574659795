import Decimal from "decimal.js-light";

import { CREDIT } from "../Payment/types";

export const getCreditPayment = (payments) => {
  const creditIntegerFormat = payments
    .filter(({ type }) => type === CREDIT)
    .reduce((acc, cur) => {
      const amount = new Decimal(cur.paidAmount);
      return acc.plus(amount);
    }, new Decimal(0));

  return creditIntegerFormat.dividedBy(100).toFixed(2);
};

export const useGetCreditPayment = (payments) => {
  return getCreditPayment(payments);
};
