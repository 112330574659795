import { fetchUtils, GET_LIST, GET_MANY_REFERENCE } from "react-admin";
import simpleRestProvider from "../../ra-strapi-rest";
import { getCookie } from "../../helpers/Cookies";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = getCookie("token");

  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

// const graphqlClient = (url, options = {}) => {
//   const token = getCookie("token");
//   return createClient({
//     url: `${url}/graphql`,
//     fetchOptions: () => ({
//       headers: { Authorization: `Bearer ${token}`, Accept: "application/json" },
//       method: "POST"
//     })
//   });
// };

const dataProviderRest = simpleRestProvider(
  process.env.REACT_APP_CMS_HOST,
  httpClient
);

// const graphqlDataProvider = graphqlProvider(
//   process.env.REACT_APP_CMS_HOST,
//   graphqlClient
// );

export default (type, resource, params) => {
  // if (
  //   resource === "orderItems" &&
  //   [GET_LIST, GET_MANY_REFERENCE].indexOf(type) > -1
  // ) {
  //   return graphqlDataProvider(type, resource, params);
  // }
  return dataProviderRest(type, resource, params);
};
