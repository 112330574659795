import React, { memo } from "react";
import { makeStyles } from "@mui/styles";

import AvatarField from "./AvatarField";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
}));

const FullNameField = ({ record, size, source, label }) => {
  const classes = useStyles();
  return record ? (
    <div className={classes.root}>
      <AvatarField className={classes.avatar} record={record} size={size} />
      {record[source]}
    </div>
  ) : null;
};

FullNameField.defaultProps = {
  source: "name",
  label: "Name",
};

export default memo(FullNameField);
