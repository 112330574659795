import React from "react";
import Card from "@mui/material/Card";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { makeStyles } from "@mui/styles";

import Typography from "@mui/material/Typography";
import { useTranslate } from "react-admin";
// import groupBy from "lodash/groupBy";

import CardIcon from "./CardIcon";

const useStyles = makeStyles({
  main: {
    flex: "1",
    marginLeft: "1em",
    marginTop: 20,
  },
  card: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    minHeight: 52,
  },
  title: {},
});

const NbNewOrders = ({ value, orders = [] }) => {
  const translate = useTranslate();
  const classes = useStyles();
  // const ordersByStatus = groupBy(orders, (o) => o.status);
  return (
    <div className={classes.main}>
      <CardIcon Icon={ShoppingCartIcon} bgColor="#ff9800" />
      <Card className={classes.card}>
        {orders.map((k) => (
          <span key={k.status}>
            <Typography className={classes.title} color="textSecondary">
              {k.status}
            </Typography>
            <Typography variant="h5" component="h2">
              {k.orderCount}
            </Typography>
          </span>
        ))}
      </Card>
    </div>
  );
};

export default NbNewOrders;
