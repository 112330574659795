import React from "react";
import { useRecordContext } from "react-admin";
import { replaceImageSource } from "../../helpers/withReplaceImageSource";

const style = { maxWidth: "10%" };
const Comp = (props) => {
  const record = useRecordContext();
  const { images = [] } = record;
  return (
    <div>
      {images.map((img) => {
        if (img.type && img.type === "video") {
          return (
            <iframe
              key={img.name}
              width="200"
              height="200"
              src={img.name}
              title="video player"
              frameBorder="0"
              // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          );
        }
        return (
          <img
            style={style}
            alt="thumb"
            src={replaceImageSource(img.name)}
            key={img.name}
          />
        );
      })}
    </div>
  );
};

export default Comp;
