import React from "react";
import { useRecordContext } from "react-admin";
import ImageReceiptView from "./ImageReceiptView";
import BillplzReceipt from "./BillplzReceipt";
import { BILLPLZ, HITPAY } from "../Payment/types";

const Comp = (props) => {
  const record = useRecordContext();
  if (record.type === BILLPLZ || record.type === HITPAY) {
    return <BillplzReceipt {...props} record={record} />;
  }
  return <ImageReceiptView {...props} record={record} />;
};

export default Comp;
