import useSWR from "swr";

// import { useProfile } from "./useProfile";
// import { fetcher } from "../../helpers/fetchHandleError";
import { fetchWithFBToken } from "../Common/swrMiddlewares";

export const useFBProfile = (option) => {
  // const { profile } = useProfile();
  return useSWR(
    `${process.env.REACT_APP_GRAPH_API_URL}/me?fields=name,picture,subscribed_apps`,
    {
      use: [fetchWithFBToken],
      revalidateOnFocus: false,
      refreshInterval: 3600 * 1000,
      ...option,
    }
  );
};

export const useFBSubscribedFields = () => {
  const { data } = useFBProfile();
  try {
    if (data) {
      const app =
        data.subscribed_apps &&
        data.subscribed_apps.data &&
        data.subscribed_apps.data.find(({ id }) => id === "376041286589727"); // this is BOXIFY COMMERCE AP ID
      return app.subscribed_fields;
    }
  } catch (e) {
    return [];
  }

  return [];
};

export const useIGProfile = () => {
  // const { profile } = useProfile();
  return useSWR(
    `${process.env.REACT_APP_GRAPH_API_URL}/me?fields=instagram_business_account{id,name,profile_picture_url,username,website,followers_count,follows_count,biography}`,
    {
      use: [fetchWithFBToken],
      revalidateOnFocus: false,
      refreshInterval: 3600 * 1000,
    }
  );
};
