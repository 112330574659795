import React from "react";
import { useRecordContext } from "react-admin";
import { useShippingMethod } from "../useShippingMethod";

const ShippingOptionByName = () => {
  const record = useRecordContext();
  const [shippingOptions] = useShippingMethod({ showAll: true });
  const { shippingOption } = record;
  const selected = shippingOptions.find((o) => o.id === shippingOption);
  if (selected === undefined) {
    return <span></span>;
  }
  return <span>{selected.name}</span>;
};

export default ShippingOptionByName;
