import styled from "styled-components";

export const HiddenSpan = styled.span`
  display: none;
`;

export const PrintDiv = styled.div`
  margin: 0;
  padding: 0;
`;
