import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AutoCompleteTag from "./AutoCompleteTag";
import { useFormContext, useWatch } from "react-hook-form";

const spacer = { width: 200, height: 250 };

const OrderItemTagAttach = () => {
  const { setValue } = useFormContext();
  const values = useWatch();
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleOpenDialog = React.useCallback(() => {
    setOpenDialog(true);
  }, [setOpenDialog]);

  const handleCloseDialog = React.useCallback(() => {
    setOpenDialog(false);
  }, [setOpenDialog]);

  const handleAddTag = React.useCallback(() => {
    const { tags, temp_selectedTag = null } = values;
    if (!!temp_selectedTag) {
      const newTags = [...tags, temp_selectedTag].filter(
        (v, i, a) => a.findIndex((t) => t.id === v.id) === i
      );
      setValue("tags", newTags);
    }
    setOpenDialog(false);
  }, [setValue, values]);

  return (
    <span>
      <Button onClick={handleOpenDialog}>
        <AddIcon />
        Add new tag
      </Button>
      <Dialog fullScreen={false} open={openDialog}>
        <DialogTitle>{"Add new tag"}</DialogTitle>
        <DialogContent>
          <div style={spacer}>
            <AutoCompleteTag />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button color="primary" autoFocus onClick={handleAddTag}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
};

export default OrderItemTagAttach;
