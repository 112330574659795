import { unparse as convertToCSV } from "papaparse/papaparse.min";
import { downloadCSVWithBom } from "../../helpers/methods";

export const exporter = (keywords) => {
  const keywordsForExport = keywords.map((keyword) => {
    const {
      regex,
      __v,
      keywordsessions,
      updatedAt,
      _id,
      page,
      createdAt,
      stockLeft = 0,
      stock = 0,
      categories = [],
      variants = [],
      selections = [],
      images = [],
      ...keywordForExport
    } = keyword; // omit backlinks and author
    const date = new Date(createdAt);
    return {
      ...keywordForExport,
      stockSold: stock - stockLeft,
      stockLeft,
      stock,
      categories: categories.map((c) => c.description).join(","),
      variants: variants.map((v) => v.description).join(","),
      selections: selections.map((s) => s.description).join(","),
      createdAtDate: date.toLocaleDateString(),
      createdAtTime: date.toLocaleTimeString(),
      images: JSON.stringify(images),
    };
  });
  const csv = convertToCSV({
    data: keywordsForExport,
    fields: [
      "id",
      "key",
      "sku",
      "description",
      "price",
      "stock",
      "stockLeft",
      "stockSold",
      "weight",
      "cost",
      "detail",
      "allowBackorder",
      "isActive",
      "minQuantity",
      "maxQuantity",
      "allowInWeb",
      "webPrice",
      "variants",
      "selections",
      "categories",
      "createdAtDate",
      "createdAtTime",
      "images",
    ], // keyword fields in the export
  });

  downloadCSVWithBom(csv, `keywords_${new Date().toLocaleDateString()}`); // download as 'keywords.csv` file
};
