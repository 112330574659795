import React, { useMemo } from "react";
import TextField from "@mui/material/TextField";
import range from "lodash/range";
import { makeStyles } from "@mui/styles";
import { useSWRConfig } from "swr";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { CopyToClipboard } from "react-copy-to-clipboard";
import DialogContent from "@mui/material/DialogContent";
import uniqBy from "lodash/uniqBy";
import Grid from "@mui/material/Grid";

import { shuffle, getRandomInt } from "../../helpers/methods";
import { store } from "./store";
import { store as commentStore } from "./commentStore";
import useDebounce from "../UseDebounce";
import MediaCard from "./MediaCard";
import { CACHE_CONTACTS } from "../Constant";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Picker = (props) => {
  const classes = useStyles();
  const { cache } = useSWRConfig();

  const { state } = React.useContext(store);
  const { state: commentState } = React.useContext(commentStore);

  const { page } = state;
  const {
    comments,
    // contactHashTable
  } = commentState;

  const [keyword, setKeyword] = React.useState("");
  const debouncedKeyword = useDebounce(keyword, 500);
  const [showAnimation, setShowAnimation] = React.useState(true);
  const [excludeBlacklistedContact, setExcludeBlacklistedContact] =
    React.useState(true);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const [winners, setWinners] = React.useState([]);
  const [quantity, setQuantity] = React.useState(1);
  const onChangeKeyword = React.useCallback(
    (e) => {
      setKeyword(e.target.value);
    },
    [setKeyword]
  );
  const onChangeQuantity = React.useCallback(
    (e) => {
      setQuantity(e.target.value);
    },
    [setQuantity]
  );

  const winnersListText = `---WINNERS LIST---\n${winners
    .map((c) => c.from.name)
    .join(", ")}`;

  const candidates = useMemo(() => {
    if (debouncedKeyword) {
      const k = debouncedKeyword.toUpperCase();
      const allMatches = comments
        .filter((c) => c.from.id !== page.id)
        .filter((c) => {
          const msg = c.message.toUpperCase();
          return msg.indexOf(k) !== -1;
        })
        .filter((c) => {
          const cacheData = cache.get(`${CACHE_CONTACTS}/${c.from.id}`);
          if (!excludeBlacklistedContact) {
            return true;
          } else if (!cacheData || !cacheData.data) {
            return true; //new customer
          }
          return !cacheData.data.isBlacklisted;
        });
      const allUniqueMatches = uniqBy(allMatches, (c) => c.from.id);
      return allUniqueMatches;
    }
    return [];
  }, [
    cache,
    comments,
    // contactHashTable,
    debouncedKeyword,
    excludeBlacklistedContact,
    page.id,
  ]);

  const getList = React.useCallback(() => {
    const nbShuffles = range(getRandomInt(100) + 1);
    const newArr = nbShuffles.reduce((acc, cur) => shuffle(acc), candidates);
    const newList = newArr.slice(0, quantity);
    setWinners(newList);
    showAnimation && setOpen(true);
  }, [candidates, quantity, showAnimation]);

  return (
    <div>
      <form className={classes.root} noValidate autoComplete="off">
        <TextField
          label="keyword"
          required
          helperText={`# of possibilities ${candidates.length}`}
          onChange={onChangeKeyword}
          value={keyword}
        />
        <TextField
          margin="dense"
          label="number of pick"
          required
          onChange={onChangeQuantity}
          value={quantity}
          type="number"
        />
        <br />
        <FormControlLabel
          control={
            <Checkbox
              checked={showAnimation}
              onChange={(e) => setShowAnimation(e.target.checked)}
              // name="checkedB"
              // color="primary"
            />
          }
          label="Show animation"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={excludeBlacklistedContact}
              onChange={(e) => setExcludeBlacklistedContact(e.target.checked)}
            />
          }
          label="Exclude Blacklisted"
        />
        <TextField
          label={`Names(${winners.length})`}
          multiline
          rows={4}
          value={winnersListText}
        />

        <Button
          variant="contained"
          color="secondary"
          disabled={!quantity || quantity < 1 || candidates.length < 1}
          onClick={getList}
        >
          Get list
        </Button>
        <CopyToClipboard text={winnersListText}>
          <Button variant="contained">Copy</Button>
        </CopyToClipboard>
        <Button
          variant="contained"
          onClick={() => {
            setWinners([]);
          }}
        >
          Clear list
        </Button>
      </form>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Comment picker
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid container justify="center" spacing={2}>
            {winners.map((value) => (
              <Grid item key={value.from.id} xs={6} md={4} lg={3} xl={2}>
                <MediaCard value={value} />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Picker;
