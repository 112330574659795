import React from "react";
import {
  useRefresh,
  useNotify,
  useUnselectAll,
  useListContext,
  useDataProvider,
} from "react-admin";
import Button from "@mui/material/Button";
import BlockIcon from "@mui/icons-material/Block";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { uniqueByValue } from "../../helpers/methods";
import Loader from "../Loader";
import { useCustomAllRecords } from "../Common/useCustomAllRecords";

const BulkBlackList = (props) => {
  const dataProvider = useDataProvider();
  const { resource, selectedIds } = useListContext();
  const [processing, setProcessing] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const allRecords = useCustomAllRecords();
  const [selectedContacts, setSelectedContacts] = React.useState([]);

  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll(resource);
  const handleClick = React.useCallback(() => {
    setIsOpen(true);
    const orders = selectedIds.map((id) => {
      return allRecords.find((singleData) => {
        return singleData.id === id;
      });
    });

    const contacts = orders
      .filter((o) => !!o.contact)
      .map((o) => {
        return o.contact;
      });
    const uniqueIds = contacts.map((c) => c.id).filter(uniqueByValue);
    const uniqueContacts = uniqueIds.map((id) =>
      contacts.find((c) => c.id === id)
    );
    setSelectedContacts(uniqueContacts);
  }, [allRecords, selectedIds]);

  const handleDialogClose = React.useCallback(() => {
    unselectAll();
    setProcessing(false);
    setSelectedContacts([]);
    setIsOpen(false);
  }, [unselectAll]);

  const handleConfirm = React.useCallback(() => {
    const contactIds = selectedContacts.map((contact) => {
      return contact.id;
    });
    setIsOpen(false);
    setProcessing(true);
    dataProvider
      .updateMany("contacts", {
        ids: contactIds,
        data: { isBlacklisted: true },
      })
      .then((resp) => {
        refresh();
        notify(`Contacts updated`);
        unselectAll();
        setProcessing(false);
      })
      .catch((e) => {
        setProcessing(false);
        unselectAll();
        notify("Error: contacts not updated, stop now", "warning");
      });
  }, [notify, refresh, unselectAll, selectedContacts, dataProvider]);

  return (
    <>
      <Button variant="outlined" label="BlackList" onClick={handleClick}>
        <BlockIcon />
        BlackList
      </Button>
      <Dialog open={isOpen} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">
          {"Are you sure to blacklist them?"}
        </DialogTitle>
        <DialogContent>
          <ul>
            {selectedContacts.map((contact) => {
              return <li key={contact.id}>{contact.name}</li>;
            })}
          </ul>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleConfirm}
            color="primary"
            autoFocus
          >
            BlackList
          </Button>
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Loader open={processing}>
        {`DO NOT CLOSE THIS WINDOW !\n Processing...`}
      </Loader>
    </>
  );
};

export default BulkBlackList;
