import React from "react";
import { useRecordContext } from "react-admin";
import { useFormContext } from "react-hook-form";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const CustomSelectInput = ({ source, choices, label, sx, onChange }) => {
  const record = useRecordContext();
  const { setValue } = useFormContext();
  const [selectValue, setSelectValue] = React.useState(record[source]);

  return (
    <Box sx={sx}>
      <FormControl fullWidth>
        <InputLabel id="custom-select-input">{label}</InputLabel>
        <Select
          labelId="custom-select-input-label"
          id="custom-select-input"
          value={selectValue}
          label={label}
          onChange={(e) => {
            setSelectValue(e.target.value);
            setValue(source, e.target.value);
            if (!!onChange) onChange();
          }}
        >
          {choices.map((c) => {
            return (
              <MenuItem value={c.id} key={c.id}>
                {c.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default CustomSelectInput;
