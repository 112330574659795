import React, { useState, useCallback } from "react";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import {
  useNotify,
  useUnselectAll,
  useListContext,
  useUpdate,
} from "react-admin";
import Loader from "../Loader";
import { useCustomAllRecords } from "../Common/useCustomAllRecords";
import { TextField } from "@mui/material";

const BulkRemovePrefix = (props) => {
  const { resource, selectedIds } = useListContext();
  const [isOpen, setIsOpen] = useState(false);
  const [prefix, setPrefix] = useState("[PO]");
  const notify = useNotify();
  const unselectAll = useUnselectAll(resource);
  const allRecords = useCustomAllRecords();

  const [update, { isLoading, error }] = useUpdate(undefined, undefined, {
    onError: (err, variables) => {
      notify(`Failed: ${variables.data.description}`, { type: "warning" });
    },
  });

  const prefixKeywords = allRecords
    .filter((rec) => selectedIds.includes(rec._id))
    .filter((i) => {
      return i.description.startsWith(prefix);
    });

  const handleClick = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const handleDialogClose = useCallback(() => {
    unselectAll();
    setIsOpen(false);
  }, [unselectAll, setIsOpen]);

  const handleConfirm = useCallback(async () => {
    const payloads = prefixKeywords.map(({ description = "", id }) => {
      const newDescription = description.replace(prefix, "");
      return { id, description: newDescription };
    });

    const results = await Promise.all(
      payloads.map(({ id, description }) => {
        return update(resource, {
          id,
          data: { description },
        })
          .then(() => {
            return null;
          })
          .catch((e) => {
            return e;
          });
      })
    );

    unselectAll();
    setIsOpen(false);
  }, [prefix, prefixKeywords, resource, unselectAll, update]);

  return (
    <>
      <Button onClick={handleClick}>
        <EditIcon />
        {"Remove Prefix"}
      </Button>
      <Dialog
        // fullScreen={false}
        open={isOpen}
        aria-labelledby="remove-prefix-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Are you sure to remove <b>{prefix}</b> prefix from them?
        </DialogTitle>
        <DialogContent>
          <TextField
            value={prefix}
            onChange={(e) => setPrefix(e.target.value)}
            label="Prefix you wish to remove"
          />
          <ul>
            {prefixKeywords.map((i, idx) => {
              return <li key={i._id}>{`${i.description}`}</li>;
            })}
          </ul>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleConfirm}
            color="primary"
            autoFocus
            disabled={prefixKeywords.length === 0 || prefix.length === 0}
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Loader open={isLoading}></Loader>
    </>
  );
};

export default BulkRemovePrefix;
