import React, { useState, useCallback } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import SendIcon from "@mui/icons-material/Send";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import RefreshIcon from "@mui/icons-material/Refresh";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  useRefresh,
  // useNotify,
  useUnselectAll,
  useListContext,
} from "react-admin";

import { getCookie } from "../../helpers/Cookies";
import { fetchHandleError } from "../../helpers/fetchHandleError";
import { fetch_retry } from "../../helpers/methods";
import Loader from "../Loader";
import { withProfile } from "../DataHooks/useProfile";
import {
  useTakeThreadControl,
  useReleaseThreadControl,
} from "../DataHooks/threadControl";
import { useCustomAllRecords } from "../Common/useCustomAllRecords";
import { MTY_FRAME } from "../Constant";

const MAX_CONCURRENT = 3;
const TEXT_TEMPLATE = 0;
const GENERIC_TEMPLATE = 1;
const MEDIA_TEMPLATE = 2;
const GENERIC_MAX_LENGTH = 65;
const GENERIC_STRICT_MAX_LENGTH = 65;

const BulkSendWaitList = (props) => {
  const { profile } = props;
  const { resource, selectedIds } = useListContext();
  const allRecords = useCustomAllRecords();
  const [isOpen, setIsOpen] = useState(false);
  const [requests, setRequests] = React.useState([]);
  const [pending, setPending] = React.useState(0);
  const [startSend, setStartSend] = useState(false);
  const [errors, setErrors] = useState([]);
  const [text, setText] = useState("Hi {{name}}, this product has new stock.");
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [useTemplate, setUseTemplate] = useState(TEXT_TEMPLATE);
  const [imageUrl, setImageUrl] = useState(MTY_FRAME);
  const [buttonText, setButtonText] = useState("Go to Post");
  const [buttonUrl, setButtonUrl] = useState("");
  const refresh = useRefresh();
  const { trigger: take } = useTakeThreadControl();
  const { trigger: release } = useReleaseThreadControl();
  // const notify = useNotify();
  const unselectAll = useUnselectAll(resource);
  const loading = pending > 0 || requests.length > 0;
  // const { profile, isLoading, isError } = useProfile();

  const removeWaitlists = useCallback((id) => {
    const token = getCookie("token");
    return fetch_retry(
      `${process.env.REACT_APP_CMS_HOST}/waitlists/${id}`,
      {
        method: "DELETE",
        retries: 3,
        retryDelay: 2000,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      },
      3
    )
      .then(fetchHandleError)
      .then(() => {
        setPending((p) => p - 1);
      })
      .catch((e) => {
        setErrors((prev) => [
          ...prev,
          { message: "Unable to remove waitlist !" },
        ]);
        alert("Delete WL error");
      });
  }, []);

  const transformText = useCallback(
    ({ text, description = "", key = "", quantity = 1, name = "" }) => {
      return text
        .replace(/{{name}}/g, name)
        .replace(/{{description}}/g, description)
        .replace(/{{key}}/g, key)
        .replace(/{{quantity}}/g, quantity);
    },
    []
  );

  const handleClick = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const handleDialogClose = useCallback(() => {
    unselectAll();
    setErrors([]);
    setRequests([]);
    setStartSend(false);
    setIsOpen(false);
    refresh();
  }, [unselectAll, refresh]);

  const sendWaitlist = React.useCallback(
    async (token, m) => {
      return await fetch(
        `${process.env.REACT_APP_CMS_HOST}/waitlists/send-message`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            method: "POST",
            url: "/me/messages",
            data:
              useTemplate === MEDIA_TEMPLATE
                ? {
                    recipient: {
                      one_time_notif_token: m.otnToken,
                    },
                    message: {
                      attachment: {
                        type: "template",
                        payload: {
                          template_type: "media",
                          elements: [
                            {
                              media_type: buttonUrl.includes("videos")
                                ? "video"
                                : "image",
                              url: buttonUrl,
                              buttons: [
                                {
                                  type: "web_url",
                                  title: buttonText,
                                  url: buttonUrl,
                                },
                              ],
                            },
                          ],
                          sharable: true,
                        },
                      },
                    },
                  }
                : useTemplate === GENERIC_TEMPLATE
                ? {
                    recipient: {
                      one_time_notif_token: m.otnToken,
                    },
                    message: {
                      attachment: {
                        type: "template",
                        payload: {
                          template_type: "generic",
                          elements: [
                            {
                              title: m.title,
                              image_url: imageUrl,
                              subtitle: m.subtitle,
                              default_action: {
                                type: "web_url",
                                url: buttonUrl,
                              },
                              buttons: [
                                {
                                  type: "web_url",
                                  url: buttonUrl,
                                  title: buttonText,
                                },
                              ],
                            },
                          ],
                        },
                      },
                    },
                  }
                : {
                    recipient: {
                      one_time_notif_token: m.otnToken,
                    },
                    message: {
                      text: m.message,
                    },
                  },
          }),
        }
      )
        .then((response) => {
          if (response.ok) {
            removeWaitlists(m.id);
            return null;
            // Or what ever you want to check
            // return Promise.resolve(null); // This will end up in SUCCESS part
          }
          return Promise.resolve(response.json()).then((responseInJson) => {
            if (responseInJson.error.code === 100) {
              removeWaitlists(m.id);
            }
            // This will end up in ERROR part
            return Promise.reject(responseInJson.error); //  responseInJson.message = "Some nasty error message!"
          });
        })
        .catch((e) => {
          // console.log(e);
          setErrors((prev) => [...prev, e]);
          return e;
        });
    },
    [buttonText, buttonUrl, imageUrl, removeWaitlists, useTemplate]
  );

  React.useEffect(() => {
    if (pending === 0 && requests.length > 0) {
      const token = getCookie("token");
      const processSlice = requests.slice(0, MAX_CONCURRENT);
      const nextSlice = requests.slice(MAX_CONCURRENT);
      setPending(processSlice.length);
      setRequests(nextSlice);
      const process = async (token) => {
        await Promise.all(
          processSlice.map(async (m) => {
            await take({
              recipient: {
                id: `${m.contact.psid}`,
              },
            });
            await sendWaitlist(token, m);
            release({
              recipient: {
                id: `${m.contact.psid}`,
              },
            });
          })
        );
      };
      process(token);
    }
  }, [
    pending,
    removeWaitlists,
    requests,
    buttonText,
    buttonUrl,
    useTemplate,
    title,
    imageUrl,
    subtitle,
    sendWaitlist,
    profile,
    take,
    release,
  ]);

  const handleConfirm = useCallback(() => {
    const rows = allRecords.filter((record) =>
      selectedIds.includes(record._id)
    );
    const messages = rows.map((d) => {
      const msg = transformText({
        text,
        name: d.contact.name,
        description: d.description,
        key: d.key,
        quantity: d.quantity,
      });

      return {
        id: d._id,
        contact: d.contact,
        message: msg,
        otnToken: d.otnToken,
        ...(useTemplate === GENERIC_TEMPLATE && {
          title: transformText({
            text: title,
            name: d.contact.name,
            description: d.description,
            key: d.key,
            quantity: d.quantity,
          }),
          subtitle: transformText({
            text: subtitle,
            name: d.contact.name,
            description: d.description,
            key: d.key,
            quantity: d.quantity,
          }),
        }),
      };
    });
    setStartSend(true);
    setRequests(messages);
  }, [
    allRecords,
    selectedIds,
    subtitle,
    text,
    title,
    transformText,
    useTemplate,
  ]);

  let previewText = "";
  let previewTitle = "";
  let previewSubtitle = "";
  if (selectedIds.length > 0) {
    const previewData = allRecords.filter((d) => d.id === selectedIds[0]).pop();
    previewText = transformText({
      text,
      name: previewData.contact.name,
      description: previewData.description,
      key: previewData.key,
      quantity: previewData.quantity,
    });

    previewTitle = transformText({
      text: title,
      name: previewData.contact.name,
      description: previewData.description,
      key: previewData.key,
      quantity: previewData.quantity,
    });

    previewSubtitle = transformText({
      text: subtitle,
      name: previewData.contact.name,
      description: previewData.description,
      key: previewData.key,
      quantity: previewData.quantity,
    });
  }

  const handleTemplateChange = React.useCallback((e) => {
    //use combo box so future extensible
    setUseTemplate(parseInt(e.target.value));
  }, []);

  const isPreviewTitleTooLong = previewTitle.length > GENERIC_STRICT_MAX_LENGTH;
  const isPreviewSubtitleTooLong =
    previewSubtitle.length > GENERIC_STRICT_MAX_LENGTH;

  const refreshPreview = () => {
    const splitted = buttonUrl.split("?")[0].split("/");
    const postId = splitted.pop();
    const resp = fetch(
      `${process.env.REACT_APP_GRAPH_API_URL}/${profile.page.psid}_${postId}/attachments?access_token=${profile.page.token}`
    )
      .then(fetchHandleError)
      .then((data) => {
        let attactmentsData = data.data[0];
        let url =
          attactmentsData.media &&
          attactmentsData.media.image &&
          attactmentsData.media.image.src;
        setImageUrl(url);
        //TODO: needs to upload to messenger asset to avoid image broken days later.
      });
  };

  return (
    <>
      <Button onClick={handleClick}>
        <SendIcon />
        FB
      </Button>
      <Dialog
        // fullScreen={false}
        open={isOpen}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>{`Send FB message`}</DialogTitle>
        <DialogContent>
          <Grid component="label" container alignItems="center" spacing={2}>
            <Grid
              component="label"
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <div>
                  <FormControl>
                    <InputLabel htmlFor="waitlist-panel-native-simple">
                      Message type
                    </InputLabel>
                    <Select
                      native
                      value={useTemplate}
                      onChange={handleTemplateChange}
                      inputProps={{
                        name: "panel",
                        id: "waitlist-panel-native-simple",
                      }}
                    >
                      <option value={TEXT_TEMPLATE}>Text only</option>
                      <option
                        value={GENERIC_TEMPLATE}
                      >{`Image & text with Post`}</option>
                      <option
                        value={MEDIA_TEMPLATE}
                      >{`Image or video with Post`}</option>
                    </Select>
                  </FormControl>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <h5>Shortcode</h5>
              <ul>
                <li>{`Name: {{name}}`}</li>
                <li>{`Item description: {{description}}`}</li>
                <li>{`Keyword: {{key}}`}</li>
                <li>{`Quantity: {{quantity}}`}</li>
              </ul>
            </Grid>
            {useTemplate === TEXT_TEMPLATE && (
              <>
                <Grid item xs={12} md={6}>
                  <h5>Preview</h5>
                  <p>{previewText}</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Message editor"
                    multiline
                    rows={8}
                    value={text}
                    onChange={(e) => {
                      setText(e.target.value);
                    }}
                  />
                </Grid>
              </>
            )}
            {useTemplate === GENERIC_TEMPLATE && (
              <>
                <Grid item xs={12} md={6}>
                  <h5>Preview</h5>
                  <Button onClick={refreshPreview}>
                    <RefreshIcon />
                    Refresh image preview
                  </Button>
                  <Card>
                    <CardActionArea>
                      <CardMedia image={imageUrl} component="img" />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          {previewTitle}
                        </Typography>
                        <p>{previewSubtitle}</p>
                      </CardContent>
                    </CardActionArea>
                    <CardActions style={{ justifyContent: "center" }}>
                      <a
                        href={buttonUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {buttonText}
                      </a>
                    </CardActions>
                  </Card>
                </Grid>
                {isPreviewTitleTooLong && (
                  <p style={{ color: "red" }}>
                    The title is too long, some message could fail !
                  </p>
                )}
                {isPreviewSubtitleTooLong && (
                  <p style={{ color: "red" }}>
                    The subtitle is too long, some message could fail !
                  </p>
                )}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="FACEBOOK Post URL"
                    rows={1}
                    value={buttonUrl}
                    onChange={(e) => {
                      setButtonUrl(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    label={`Title(max ${GENERIC_MAX_LENGTH} chars)`}
                    fullWidth
                    helperText={` ${
                      title.length > GENERIC_MAX_LENGTH
                        ? `Too long - ${title.length} `
                        : ""
                    } `}
                    inputProps={{ maxLength: GENERIC_MAX_LENGTH }}
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    label={`Subtitle(max ${GENERIC_MAX_LENGTH} chars)`}
                    helperText={` ${
                      subtitle.length > GENERIC_MAX_LENGTH
                        ? `Too long - ${subtitle.length} `
                        : ""
                    } `}
                    inputProps={{ maxLength: GENERIC_MAX_LENGTH }}
                    fullWidth
                    value={subtitle}
                    onChange={(e) => {
                      setSubtitle(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    label="Button label"
                    fullWidth
                    rows={1}
                    value={buttonText}
                    onChange={(e) => {
                      setButtonText(e.target.value);
                    }}
                  />
                </Grid>
              </>
            )}

            {useTemplate === MEDIA_TEMPLATE && (
              <>
                <Grid item xs={12} md={6}>
                  <h5>Preview</h5>
                  <Button onClick={refreshPreview}>
                    <RefreshIcon />
                    Refresh image preview
                  </Button>
                  <Card>
                    <CardActionArea>
                      <CardMedia image={imageUrl} component="img" />
                    </CardActionArea>
                    <CardActions style={{ justifyContent: "center" }}>
                      <a
                        href={buttonUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {buttonText}
                      </a>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="FACEBOOK Post URL"
                    rows={1}
                    value={buttonUrl}
                    onChange={(e) => {
                      setButtonUrl(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    label="Button Editor"
                    fullWidth
                    rows={1}
                    value={buttonText}
                    onChange={(e) => {
                      setButtonText(e.target.value);
                    }}
                  />
                </Grid>
              </>
            )}
            {startSend &&
              requests.length === 0 &&
              pending === 0 &&
              errors.length === 0 && (
                <Grid item xs={12} md={12}>
                  <div>Operation completed successfully.</div>
                </Grid>
              )}

            {(requests.length > 0 || pending > 0) && errors.length === 0 && (
              <Grid item xs={12} md={12}>
                <div>{`Processing and remaining ${
                  requests.length + pending
                }...`}</div>
              </Grid>
            )}
            {errors.length > 0 && (
              <Grid item xs={12} md={12}>
                <h5>Errors</h5>
                <div>
                  Something went wrong, check the erros and retry later.
                </div>
                {errors.map((e, idx) => {
                  return <div key={idx}>{e.message}</div>;
                })}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleConfirm}
            color="primary"
            autoFocus
            disabled={startSend}
          >
            Send
          </Button>
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Loader open={loading}></Loader>
    </>
  );
};

export default withProfile(BulkSendWaitList);
