import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import { useIsBusinessPlan } from "../../../helpers/subscriptionPlan";
import RemovePreorderTag from "./RemovePrefix";
import SplitPreorder from "./SplitByPrefix";

const PreorderToolBox = (props) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const isBusinessPlan = useIsBusinessPlan();

  return (
    <>
      <Button variant="outlined" onClick={() => setOpenDialog(true)}>
        Prefix
      </Button>
      <Dialog open={openDialog} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">
          Prefix Related Function
        </DialogTitle>
        <DialogContent>
          {!isBusinessPlan && (
            <>
              <h4 style={{ color: "red" }}>
                Please upgrade plan to use these features.
              </h4>
              <li>Split By Prefix</li>
              <li>Remove Prefix</li>
            </>
          )}
          {isBusinessPlan && (
            <>
              <SplitPreorder {...props} />
              <br />
              <RemovePreorderTag {...props} />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setOpenDialog(false)}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PreorderToolBox;
