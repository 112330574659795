import React from "react";
import { SimpleForm, Edit, TextInput, required } from "react-admin";

import AttachSelections from "../AutocompleteAttach/AttachSelections";

export const SelectionEdit = (props) => {
  return (
    <Edit title="Edit Selection" {...props}>
      <SimpleForm redirect="list">
        <TextInput source="label" validate={required()} />
        <TextInput multiline source="description" validate={required()} />
        <AttachSelections source="variants" searchFieldName="label" />
      </SimpleForm>
    </Edit>
  );
};

export default SelectionEdit;
