import React from "react";
import {
  TextInput,
  Create,
  BooleanInput,
  SelectInput,
  SimpleForm,
  required,
} from "react-admin";
import { getPageId } from "../../helpers/getPageId";
import { useAvailableCurrencies } from "../useAvailableCurrencies";
import { keywordParser, convertToInteger, pwpHelperText } from "./common";
import { useIsBusinessPlan } from "../../helpers/subscriptionPlan";
import ProductImages from "./ProductImages";
import AttachSelections from "../AutocompleteAttach/AttachSelections";
import DecimalInput, { PositiveDecimalInput } from "../Common/DecimalInput";
import IntegerInput, { PositiveIntegerInput } from "../Common/IntegerInput";
// import NumberInputNoWheel from "../Common/NumberInputNoWheel";
// import TextInputNoWheel from "../Common/TextInputNoWheel";

const KeywordCreate = (props) => {
  const pageId = getPageId();
  const { availableCurrencies, isMultiCurrency, defaultCurrency } =
    useAvailableCurrencies();
  const isBusinessPlan = useIsBusinessPlan();
  return (
    <Create {...props} permissions="Seller" redirect="show">
      <SimpleForm
        defaultValues={{
          page: pageId,
          isActive: true,
          allowBackorder: false,
          images: [],
        }}
      >
        <TextInput
          multiline
          source="description"
          validate={required()}
          helperText={pwpHelperText}
          sx={{ width: 194 }}
        />
        <TextInput
          source="key"
          parse={keywordParser}
          label="Keyword"
          validate={required()}
        />
        <TextInput source="sku" label="SKU" />
        {isMultiCurrency && (
          <SelectInput
            defaultValue={defaultCurrency}
            validate={required()}
            source="currencyCode"
            choices={availableCurrencies.map((c) => ({ id: c, name: c }))}
            sx={{ width: 194 }}
          />
        )}
        <DecimalInput
          source="price"
          //defaultValue={0}
          validate={required()}
        />
        <DecimalInput
          source="webPrice"
          defaultValue={0}
          validate={required()}
        />
        <PositiveIntegerInput source="stock" validate={required()} />
        <PositiveDecimalInput
          source="weight"
          label="Weight(Kg)"
          defaultValue={0}
          validate={required()}
        />
        <DecimalInput source="cost" defaultValue={0} validate={required()} />
        <TextInput source="detail" label="Detail" multiline />
        <ProductImages />
        {isBusinessPlan && (
          <IntegerInput
            source="minQuantity"
            defaultValue={1}
            validate={required()}
            helperText="0 or -1 means no limit"
          />
        )}
        {isBusinessPlan && (
          <IntegerInput
            source="maxQuantity"
            defaultValue={-1}
            validate={required()}
            helperText="0 or -1 means no limit"
          />
        )}
        <BooleanInput source="isActive" />
        <BooleanInput source="allowBackorder" />
        <BooleanInput source="allowInWeb" />
        <AttachSelections source="categories" searchFieldName="label" />
        <AttachSelections source="variants" searchFieldName="label" />
      </SimpleForm>
    </Create>
  );
};

export default KeywordCreate;
