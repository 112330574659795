import React, { useState } from "react";
import {
  required,
  Button,
  SaveButton,
  TextInput,
  // TextField,
  useNotify,
  useRefresh,
  // useUpdate,
  useRedirect,
  Form,
  SelectInput,
  useRecordContext,
  FormDataConsumer,
} from "react-admin";
import IconContentEdit from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import { Typography, Box, Toolbar } from "@mui/material";

import CombineDescriptionWithQuantity from "./CombineDescriptionWithQuantity";
import EditItemDescription from "./EditItemDescription";
import QuantityInput from "./QuantityInput";
import RecalculateStockInput from "./RecalculateStockInput";
import { fetchHandleError } from "../../helpers/fetchHandleError";
import { getCookie } from "../../helpers/Cookies";
import {
  ORDER_ITEM_CANCELLED,
  ORDER_ITEM_NEW,
  ORDER_ITEM_PAID,
} from "../Constant";
import OrderItemTags from "./OrderItemTags";

import OrderItemTagAttach from "./OrderItemTagAttach";
import TextInputNoWheel from "../Common/TextInputNoWheel";
import { useIsOrderEditable } from "../DataHooks/useIsOrderEditable";

const choices = [
  { id: ORDER_ITEM_NEW, name: ORDER_ITEM_NEW },
  { id: ORDER_ITEM_PAID, name: ORDER_ITEM_PAID },
  { id: ORDER_ITEM_CANCELLED, name: ORDER_ITEM_CANCELLED },
];

const OrderItemOrderLink = ({ record = {} }) => {
  const { order } = record;
  const { orderNumber, status } = order;
  return (
    <a
      href={`/#/orders/${order.id}/show`}
    >{`Order number: ${orderNumber} : ${status}`}</a>
  );
};

const OrderItemOrderContactLink = ({ record = {} }) => {
  const { order } = record;
  const { contact, customerName } = order;
  return <a href={`/#/contacts/${contact}/show`}>{`${customerName}`}</a>;
};

const OrderItemEditForm = (props) => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const { status, keyword = undefined } = record;
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const isOrderEditable = useIsOrderEditable();

  const handleSubmit = React.useCallback(
    async (data) => {
      const token = getCookie("token");
      setLoading(true);
      const {
        description,
        quantity,
        price,
        weight,
        cost,
        status,
        tags,
        recalculateStock,
      } = data;
      fetch(
        `${process.env.REACT_APP_CMS_HOST}/orderitems/${record._id}${
          recalculateStock ? "?recalculateStock=true" : ""
        }`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify({
            description,
            quantity,
            price,
            weight,
            cost,
            status,
            tags,
          }),
        }
      )
        .then(fetchHandleError)
        .then(() => {
          setLoading(false);
          redirect("list", "orderitems");
          refresh();
          notify(`Order item updated`, "info");
        })
        .catch((err) => {
          setLoading(false);
          notify(err, "error");
        });
    },
    [record._id, redirect, refresh, notify]
  );
  return (
    <Form
      onSubmit={handleSubmit}
      defaultValues={{ recalculateStock: !!keyword }}
    >
      <Box p="1em">
        {!!keyword && <span>{`keyword: ${keyword.key}`}</span>}
        {status !== ORDER_ITEM_CANCELLED && isOrderEditable && (
          <>
            <EditItemDescription />
            <QuantityInput useKeyword={!!keyword} />
            {!!keyword && <RecalculateStockInput />}
            <CombineDescriptionWithQuantity />
          </>
        )}
        {(status === ORDER_ITEM_CANCELLED || !isOrderEditable) && (
          <>
            <br />
            <TextInput
              source="description"
              disabled
              label="Description"
              fullWidth
            />
            <TextInputNoWheel
              type="number"
              source="quantity"
              label="Quantity"
              disabled
            />
          </>
        )}
        <Box display="flex" flexWrap="wrap" justifyContent="space-between">
          <FormDataConsumer>
            {({ formData }) => {
              return (
                <>
                  <TextInputNoWheel
                    type="number"
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleSubmit(formData);
                      }
                    }}
                    label="Total price"
                    source="price"
                    validate={required()}
                    disabled={
                      status === ORDER_ITEM_CANCELLED || !isOrderEditable
                    }
                  />
                  <TextInputNoWheel
                    type="number"
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleSubmit(formData);
                      }
                    }}
                    source="weight"
                    label="Total weight"
                    disabled={
                      status === ORDER_ITEM_CANCELLED || !isOrderEditable
                    }
                  />
                  <TextInputNoWheel
                    type="number"
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleSubmit(formData);
                      }
                    }}
                    source="cost"
                    label="Total cost"
                    disabled={
                      status === ORDER_ITEM_CANCELLED || !isOrderEditable
                    }
                  />
                </>
              );
            }}
          </FormDataConsumer>
          <SelectInput
            source="status"
            choices={choices}
            disabled={status === ORDER_ITEM_CANCELLED || !isOrderEditable}
          />
          <TextInput source="channel" disabled label="Channel" />
        </Box>
        <TextInput source="currencyCode" disabled label="Currency" />
        <div>
          <span>{`Created at ${new Date(
            record.createdAt
          ).toLocaleString()}`}</span>
          <br />
          <span>{`Updated at ${new Date(
            record.updatedAt
          ).toLocaleString()}`}</span>
        </div>
        <br />
        <br />
        <Typography variant="h6" gutterBottom>
          {`Tags(Remember to save after editing tag)`}
        </Typography>
        {isOrderEditable && <OrderItemTagAttach {...props} />}
        <br />
        <OrderItemTags />
        <br />
        <br />

        <Typography variant="h6" gutterBottom>
          {`Customer & order info`}
        </Typography>
        <OrderItemOrderContactLink record={record} />
        <br />
        <OrderItemOrderLink record={record} />
        <Toolbar>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <SaveButton />
          </Box>
        </Toolbar>
      </Box>
    </Form>
  );
};

const withDisableEditOnCancelled = (WrappedComponent) => (props) => {
  const { record } = props;
  const { status = ORDER_ITEM_CANCELLED } = record;
  if (status === ORDER_ITEM_CANCELLED) {
    return (
      <Tooltip title="Cancelled are not editable">
        <span>
          <Button disabled label="ra.action.edit">
            <IconContentEdit />
          </Button>
        </span>
      </Tooltip>
    );
  }
  return <WrappedComponent {...props} />;
};

export default OrderItemEditForm;
