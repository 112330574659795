import React from "react";
// import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import { useIsBusinessPlan } from "./subscriptionPlan";

export const withTooltipBusinessTier = (WrappedComponent) => (props) => {
  const isBusinessPlan = useIsBusinessPlan();
  if (isBusinessPlan) {
    return <WrappedComponent {...props} />;
  }
  return (
    <Tooltip title="Upgrade to business to enable">
      <span>
        <WrappedComponent {...props} />
      </span>
    </Tooltip>
  );
};
