import React, { useCallback } from "react";
import { NumberInput, TextInput } from "react-admin";
import { useFormContext } from "react-hook-form";

const onWheel = (e) => e.target.blur();

export const withDisableOnWheel = (WrapComp) => (props) =>
  <WrapComp onWheel={onWheel} type="number" {...props} />;

const withDisableOnWheelDecimal = (WrapComp) => (props) =>
  (
    <WrapComp
      onWheel={onWheel}
      {...props}
      inputProps={{
        // inputmode: "decimal",
        // pattern: "^-?[0-9]+([.,][0-9]+)?",
        type: "number",
        step: "0.01",
      }}
    />
  );

export const withValidateOnKeyPress = (WrapComp) => (props) => {
  const { onKeyPress, ...otherProps } = props;
  const { trigger } = useFormContext();
  const handler = useCallback(
    async (e) => {
      const valid = await trigger();
      if (valid) {
        onKeyPress(e);
      }
    },
    [onKeyPress, trigger]
  );

  return <WrapComp {...otherProps} onKeyPress={handler} />;
};

// export default withDisableOnWheel(NumberInput);
export default withDisableOnWheelDecimal(TextInput);
