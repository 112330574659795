import React from "react";
import Button from "@mui/material/Button";
import { useTakeThreadControl } from "../DataHooks/threadControl";

const ThreadControlButton = (props) => {
  const { psid, onSuccess } = props;
  const { trigger, isMutating } = useTakeThreadControl(undefined, {
    onSuccess,
  });

  return (
    <Button
      onClick={() => {
        trigger({
          recipient: {
            id: `${psid}`,
          },
          // "metadata":"String to pass to the current controlling app"
        });
      }}
      disabled={isMutating}
      size="small"
      color="primary"
      variant="contained"
    >
      Take control
    </Button>
  );
};

export default ThreadControlButton;
