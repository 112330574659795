import React, { useMemo, useEffect, useCallback } from "react";
import useSWR from "swr";
import jwt_decode from "jwt-decode";
import addDays from "date-fns/addDays";

import { ASSISTANT_ROLE } from "../../helpers/role";
import { useGetToken } from "./useGetToken";
import { fetchHandleError } from "../../helpers/fetchHandleError";

export const useUserMe = () => {
  return useSWR(`${process.env.REACT_APP_CMS_HOST}/users/me`, {
    revalidateOnFocus: false,
    refreshInterval: 60 * 60 * 1000,
  });
};

export const useUserRole = () => {
  const { data } = useUserMe();
  if (!data) {
    return ASSISTANT_ROLE;
  }
  return data.role.name;
};

export const useUserExtension = () => {
  const token = useGetToken();

  const { data: userMe, mutate: userMeMutate } = useUserMe();
  const userExtension = useSWR(
    userMe && userMe.userextension
      ? `${process.env.REACT_APP_CMS_HOST}/userextensions/${userMe.userextension}`
      : null,
    {
      revalidateOnFocus: false,
      refreshInterval: 15 * 60 * 1000,
    }
  );

  const checkAndCreate = useCallback(() => {
    if (userMe && !userMe.userextension && !userExtension.data) {
      fetch(`${process.env.REACT_APP_CMS_HOST}/userextensions`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({}),
      })
        .then(fetchHandleError)
        .then((r) => userMeMutate())
        .catch((e) => {});
    }
  }, [token, userExtension.data, userMe, userMeMutate]);

  useEffect(() => {
    if (userMe && !userMe.userextension && !userExtension.data) {
      const timer = setTimeout(checkAndCreate, 20000);
      return () => clearTimeout(timer);
    }
  }, [checkAndCreate, userExtension.data, userMe]);

  return userExtension;
};

export const useShouldLogout = () => {
  const token = useGetToken();

  const { data: userExtension } = useUserExtension();
  const shouldLogout = useMemo(() => {
    if (token && userExtension && userExtension.lastPasswordChange) {
      const decoded = jwt_decode(token);
      const tokenExpiryDate = new Date(decoded.exp * 1000);
      const changeDatePlus7 = addDays(
        new Date(userExtension.lastPasswordChange),
        7
      );
      if (changeDatePlus7 >= tokenExpiryDate) {
        return true;
      }
    }
    return false;
  }, [token, userExtension]);

  return shouldLogout;
};
