import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import EjectIcon from "@mui/icons-material/Eject";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import {
  useListContext,
  useNotify,
  useRefresh,
  useUnselectAll,
} from "react-admin";
// import flatten from "lodash/flatten";
import Loader from "../Loader";
import { COMPLETED } from "../DataHooks/useThrottleCall";
import { useRemovePrefix } from "../DataHooks/useRemovePrefix";
import { useCustomAllRecords } from "../Common/useCustomAllRecords";
import { useIsBusinessPlan } from "../../helpers/subscriptionPlan";

const BulkRemovePrefix = (props) => {
  const { isOpen, setIsOpen } = props;
  const { selectedIds, resource } = useListContext();
  const allRecords = useCustomAllRecords();
  const isBusinessPlan = useIsBusinessPlan();
  const [prefix, setPrefix] = useState("[PO]");

  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll(resource);
  const { processing, status, terminate, faileds, removePrefix, requests } =
    useRemovePrefix(prefix);

  const handleDialogClose = React.useCallback(() => {
    setIsOpen(false);
    terminate();
    unselectAll();
    refresh();
  }, [refresh, setIsOpen, terminate, unselectAll]);

  const prefixItems = allRecords
    .filter((rec) => selectedIds.includes(rec._id))
    .filter((i) => {
      return i.description.startsWith(prefix);
    });

  const handleConfirm = React.useCallback(async () => {
    removePrefix(prefixItems);
  }, [prefixItems, removePrefix]);

  useEffect(() => {
    if (status === COMPLETED && faileds.length === 0) {
      notify("Successful");
      refresh();
      setIsOpen(false);
    } else if (faileds.length > 0) {
      notify("Some operations failed, please retry again later.", "warning");
    }
  }, [faileds.length, notify, refresh, setIsOpen, status]);

  return (
    <>
      <Loader open={processing}>
        {`DO NOT CLOSE THIS WINDOW !\n Processing...`}
      </Loader>
      <Dialog open={isOpen} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">
          Are you sure to remove <b>{prefix}</b> prefix from them?
        </DialogTitle>
        <DialogContent>
          <TextField
            value={prefix}
            onChange={(e) => setPrefix(e.target.value)}
            label="Prefix you wish to remove"
          />
          <h4>[PO]: Shipping rules can be applied on them after remove</h4>
          {!isBusinessPlan && (
            <h4 style={{ color: "red" }}>This is a business plan feature.</h4>
          )}
          {processing && `Remaining ${requests.length} requests`}
          {faileds.length > 0 && (
            <>
              <ul>
                {faileds.map((i, idx) => {
                  return <li key={i._id}>{`${i.description} failed`}</li>;
                })}
              </ul>
              <hr />
            </>
          )}
          <ul>
            {prefixItems.map((i, idx) => {
              return <li key={i._id}>{`${i.description}`}</li>;
            })}
          </ul>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleConfirm}
            color="primary"
            autoFocus
            disabled={
              prefixItems.length === 0 ||
              processing ||
              !isBusinessPlan ||
              prefix.length === 0
            }
          >
            START REMOVE
          </Button>
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const BulkRemovePrefixButton = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <EjectIcon />
        REMOVE Prefix
      </Button>
      {isOpen && (
        <BulkRemovePrefix {...props} isOpen={isOpen} setIsOpen={setIsOpen} />
      )}
    </>
  );
};

export default BulkRemovePrefixButton;
