import React, { useState, useCallback } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";
import { useIsBusinessPlan } from "../../helpers/subscriptionPlan";
import Loadable from "react-loadable";

const ExportDialog = Loadable({
  loader: () => import("./ExportDialog"),
  loading: () => null,
});

const EasyParcelDialog = Loadable({
  loader: () => import("./EasyParcelDialog"),
  loading: () => null,
});

const SkynetDialog = Loadable({
  loader: () => import("./Skynet/SkynetDialog"),
  loading: () => null,
});

const AutoCountDialog = Loadable({
  loader: () => import("./AutoCount/AutoCountDialog"),
  loading: () => null,
});

const PosLajuDialog = Loadable({
  loader: () => import("./PosLaju/PosLajuDialog"),
  loading: () => null,
});

const GdexDialog = Loadable({
  loader: () => import("./GDEX/GdexDialog"),
  loading: () => null,
});

const KexExpressDialog = Loadable({
  loader: () => import("./KEXExpress/KexExpressDialog"),
  loading: () => null,
});

const NinjaVanDialog = Loadable({
  loader: () => import("./NinjaVan/NinjaVanDialog"),
  loading: () => null,
});

export default function ExportMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openExportStandard, setOpenExportStandard] = useState(false);
  const [openExportEasyParcel, setOpenExportEasyParcel] = useState(false);
  const [openExportSkynet, setOpenExportSkynet] = useState(false);
  const [openExportAutoCount, setOpenExportAutoCount] = useState(false);
  const [openExportPosLaju, setOpenExportPosLaju] = useState(false);
  const [openExportGdex, setOpenExportGdex] = useState(false);
  const [openExportKexExpress, setOpenExportKexExpress] = useState(false);
  const [openNinjaVan, setOpenNinjaVan] = useState(false);

  const open = Boolean(anchorEl);

  const isBusinessPlan = useIsBusinessPlan();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExportStandard = useCallback(() => {
    setOpenExportStandard(true);
  }, []);

  return (
    <div>
      <IconButton onClick={handleClick}>
        <DownloadIcon />
      </IconButton>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleClose();
            handleExportStandard();
          }}
        >
          Export Standard
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenNinjaVan(true);
          }}
          disabled={!isBusinessPlan}
        >
          {`Ninja Van` + (isBusinessPlan ? "" : " (Required plan upgrade)")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenExportEasyParcel(true);
          }}
          disabled={!isBusinessPlan}
        >
          {`Easy parcel` + (isBusinessPlan ? "" : " (Required plan upgrade)")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenExportSkynet(true);
          }}
          disabled={!isBusinessPlan}
        >
          {`Skynet` + (isBusinessPlan ? "" : " (Required plan upgrade)")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenExportAutoCount(true);
          }}
          disabled={!isBusinessPlan}
        >
          {`AutoCount` + (isBusinessPlan ? "" : " (Required plan upgrade)")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenExportPosLaju(true);
          }}
          disabled={!isBusinessPlan}
        >
          {`PosLaju` + (isBusinessPlan ? "" : " (Required plan upgrade)")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenExportGdex(true);
          }}
          disabled={!isBusinessPlan}
        >
          {`GDEX` + (isBusinessPlan ? "" : " (Required plan upgrade)")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenExportKexExpress(true);
          }}
          disabled={!isBusinessPlan}
        >
          {`KEX Express` + (isBusinessPlan ? "" : " (Required plan upgrade)")}
        </MenuItem>
      </Menu>

      {openExportStandard && (
        <ExportDialog
          open={openExportStandard}
          setOpen={setOpenExportStandard}
        />
      )}
      {openExportEasyParcel && (
        <EasyParcelDialog
          open={openExportEasyParcel}
          setOpen={setOpenExportEasyParcel}
        />
      )}
      {openExportSkynet && (
        <SkynetDialog open={openExportSkynet} setOpen={setOpenExportSkynet} />
      )}
      {openExportAutoCount && (
        <AutoCountDialog
          open={openExportAutoCount}
          setOpen={setOpenExportAutoCount}
        />
      )}
      {openExportPosLaju && (
        <PosLajuDialog
          open={openExportPosLaju}
          setOpen={setOpenExportPosLaju}
        />
      )}
      {openExportGdex && (
        <GdexDialog open={openExportGdex} setOpen={setOpenExportGdex} />
      )}
      {openExportKexExpress && (
        <KexExpressDialog
          open={openExportKexExpress}
          setOpen={setOpenExportKexExpress}
        />
      )}
      {openNinjaVan && (
        <NinjaVanDialog open={openNinjaVan} setOpen={setOpenNinjaVan} />
      )}
    </div>
  );
}
