import { useMemo } from "react";

import { countryListAllIsoData } from "../../helpers/countryListAllIsoData";
import { useProfile } from "./useProfile";
import { isArray } from "../../helpers/methods";

export const systemAvailableCountryCodes = [
  "MY",
  "SG",
  "ID",
  "AU",
  "HK",
  "LA",
  "US",
  "GB",
  "TH",
  "PH",
  "IN",
  "VN",
  "SE",
  "IT",
  "CA",
  "DE",
  "NZ",
  "CN",
  "DK",
  "FR",
  "JM",
  "JP",
  "KR",
  "TR",
  "BN",
];

export const useSystemAvailableCountries = () => {
  const memoizedValue = useMemo(() => {
    const countries = countryListAllIsoData.filter((country) =>
      systemAvailableCountryCodes.find((code) => code === country.code)
    );
    return countries.map(({ name, code }) => ({
      key: name,
      text: name,
      value: name,
      code: code,
    }));
  }, []);
  return memoizedValue;
};

export const useShippingCountries = ({ showAll = false }) => {
  const { profile } = useProfile();
  const countries = useSystemAvailableCountries();

  const memoizedValue = useMemo(() => {
    let innerCountries = countries;
    if (
      !showAll &&
      profile &&
      profile.params &&
      profile.params.shippingCountries &&
      isArray(profile.params.shippingCountries) &&
      profile.params.shippingCountries.length > 0
    ) {
      innerCountries = innerCountries.filter((country) =>
        profile.params.shippingCountries.find((code) => code === country.code)
      );
    }
    return innerCountries;
  }, [countries, profile, showAll]);
  return memoizedValue;
};
