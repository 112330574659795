import React from "react";
import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  NumberField,
  BooleanField,
  Pagination,
  Filter,
  TextInput,
  BooleanInput,
  useRecordContext,
  // BulkDeleteButton,
  // useRefresh,
} from "react-admin";
import isPast from "date-fns/isPast";

import { useMediaQuery } from "@mui/material";
// import { getPageId } from "../../helpers/getPageId";
// import { exporter } from "./Exporter";
import BulkUpdateActiveButton from "./BulkUpdateActiveButton";
// import { useAvailableCurrencies } from "../useAvailableCurrencies";
import ListActions from "./ListActions";
import CustomBulkDeleteButton from "./CustomBulkDeleteButton";
import { isArray } from "../../helpers/methods";
import { exporter } from "./Exporter";
import CustomPickerInput from "../OrderItem/CustomPickerInput";
import CustomDateField from "../Common/CustomDateField";

const BulkActionButtons = (props) => (
  <>
    <BulkUpdateActiveButton {...props} />
    <CustomBulkDeleteButton {...props} />
  </>
);

const tinyFont = { fontSize: "10px" };
const redStyle = { color: "red" };
const normalStyle = { color: "inherit" };

const AMBER = "#FFCC00";
const withExpiredStyle = (Wrapped) => (props) => {
  const { source } = props;
  const record = useRecordContext();
  const color = record && isPast(new Date(record[source])) ? "red" : "inherit";
  return <Wrapped sx={{ color }} {...props} />;
};

const withActiveStyle = (Wrapped) => (props) => {
  const record = useRecordContext();
  const { source } = props;
  const color = record && !record[source] ? "red" : "inherit";

  return <Wrapped sx={{ color }} {...props} />;
};

const CustomUsedField = (props) => {
  const record = useRecordContext();
  if (record.orders && isArray(record.orders) && record.orders.length > 0) {
    return <span>{record.orders.length}</span>;
  }
  return <span sx={{ color: AMBER }}>0</span>;
};

const ExpiredAtField = withExpiredStyle(CustomDateField);
const ActiveBooleanField = withActiveStyle(BooleanField);

const dateFilters = [
  { source: "createdAt_gte", label: "CreatedAt start" },
  { source: "createdAt_lte", label: "CreatedAt end" },
  { source: "updatedAt_gte", label: "UpdatedAt start" },
  { source: "updatedAt_lte", label: "UpdatedAt end" },
].map(({ source, label }) => (
  <CustomPickerInput key={source} source={source} label={label} />
));

const DefaultFilters = (props) => (
  <Filter {...props}>
    <TextInput
      label="Code"
      source="code_contains"
      alwaysOn
      parse={(v) => v.toUpperCase()}
    />
    <BooleanInput label="Active" source="isActive" defaultValue={true} />
    {dateFilters}
  </Filter>
);
const VoucherList = (props) => {
  // const pageId = getPageId();
  // const { isMultiCurrency } = useAvailableCurrencies();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <List
      {...props}
      actions={<ListActions />}
      // exporter={exporter}
      // filter={{ page: pageId }}
      exporter={exporter}
      filters={<DefaultFilters />}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} />}
      sort={{ field: "createdAt", order: "DESC" }}
    >
      {isSmall ? (
        <SimpleList
          linkType="show"
          primaryText={(record) => (
            <span style={record.isActive ? normalStyle : redStyle}>
              {record.code}
              {!record.isActive && (
                <span style={tinyFont}>{"    (inactive)"}</span>
              )}
            </span>
          )}
          secondaryText={(record) => <span>{record.value}</span>}
          tertiaryText={(record) => (
            <>
              <span style={tinyFont}>expiredAt</span>
              <br /> {`${new Date(record.expiredAt).toLocaleDateString()}`}
            </>
          )}
        />
      ) : (
        <Datagrid rowClick="show" bulkActionButtons={<BulkActionButtons />}>
          <TextField source="code" />
          <NumberField source="value" />
          <ActiveBooleanField source="isActive" label="Active" />
          <TextField source="discountType" />
          <BooleanField
            source="discountAfterShipping"
            label="Discount Include Shipping"
          />
          <NumberField source="maxDiscount" />
          <NumberField source="minPurchaseAmount" />
          <NumberField source="maxUsage" />
          <NumberField source="maxUsagePerCustomer" />
          <CustomUsedField label={`Used#`} />
          <ExpiredAtField source="expiredAt" showTime />
          <CustomDateField source="createdAt" showTime />
          <CustomDateField source="updatedAt" showTime />
        </Datagrid>
      )}
    </List>
  );
};

export default VoucherList;
