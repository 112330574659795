import useSWRMutation from "swr/mutation";
import { sendRequest } from "../../helpers/fetchHandleError";
import { fetchWithFBToken } from "../Common/swrMiddlewares";
import { useNotify } from "react-admin";

export const useTakeThreadControl = (
  url = `${process.env.REACT_APP_GRAPH_API_URL}/me/take_thread_control?`,
  option = {}
) => {
  const notify = useNotify();
  return useSWRMutation(url, sendRequest, {
    use: [fetchWithFBToken],
    onError: (e) => {
      notify(e.error.message, { type: "error" });
    },
    ...option,
  });
};

export const useReleaseThreadControl = (
  url = `${process.env.REACT_APP_GRAPH_API_URL}/me/release_thread_control?`,
  option = {}
) => {
  const notify = useNotify();
  return useSWRMutation(url, sendRequest, {
    use: [fetchWithFBToken],
    onError: (e) => {
      notify(e.error.message, { type: "error" });
    },
    ...option,
  });
};
