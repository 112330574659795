import React, { useState } from "react";
import { Button } from "react-admin";
import IconContentAdd from "@mui/icons-material/Add";
import IconCancel from "@mui/icons-material/Cancel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CreateOrderItemByKeyword from "./CreateOrderItemByKeyword";
import CreateOrderItemByFreeText from "./CreateOrderItemByFreeText";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const OrderItemQuickCreateButtonWithPanel = ({ currency, orderId }) => {
  const [showDialog, setShowDialog] = useState(false);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClick}
        label="ra.action.create"
      >
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create order item"
      >
        {/* <DialogTitle>Create order item</DialogTitle> */}
        <DialogContent>
          <Tabs
            centered
            value={value}
            onChange={handleChange}
            aria-label="create order item tab"
          >
            <Tab label="Keyword with stock" {...a11yProps(0)} />
            <Tab label="Free text" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <CreateOrderItemByKeyword
              onClose={handleCloseClick}
              currency={currency}
              orderId={orderId}
            />
            {/* <SaveButton /> */}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <CreateOrderItemByFreeText
              currency={currency}
              orderId={orderId}
              onClose={handleCloseClick}
            />
          </TabPanel>
          <DialogActions>
            <Button label="ra.action.cancel" onClick={handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OrderItemQuickCreateButtonWithPanel;
