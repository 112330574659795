import React from "react";
import { useRecordContext } from "react-admin";
import { useFormContext } from "react-hook-form";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useSWR from "swr";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

const addressKeys = [
  "shippingName",
  "shippingPhoneNumber",
  "shippingAddress1",
  "shippingAddress2",
  "shippingPostcode",
  "shippingCity",
  "shippingState",
  "shippingCountry",
  "email",
];

const getAddress = (order) => {
  const a = addressKeys.reduce((acc, cur) => {
    if (order[cur]) {
      acc[cur] = order[cur];
    }
    return acc;
  }, {});
  return a;
};

export const getAddressLabel = (o) =>
  addressKeys
    .map((k) => (o[k] ? o[k] : ""))
    .filter((str) => !!str)
    .join(", ");

const AutoFillAddress = (props) => {
  const { setValue } = useFormContext();
  const record = useRecordContext();
  const { contact = null } = record;
  const [open, setOpen] = React.useState(false);
  const [radio, setRadio] = React.useState(null);

  const { data, error } = useSWR(
    open && !!contact
      ? `${process.env.REACT_APP_CMS_HOST}/orders?contact=${contact.id}&_limit=5&_sort=createdAt:DESC&_id_ne=${record.id}&shippingName_null=false`
      : null
  );

  const handleClickOpen = React.useCallback(() => {
    setOpen(true);
  }, []);
  const handleFill = React.useCallback(() => {
    const address = getAddress(data[radio]);
    //reset all to empty
    setValue("shippingName", "", { shouldDirty: true });
    setValue("shippingPhoneNumber", "", { shouldDirty: true });
    setValue("shippingAddress1", "", { shouldDirty: true });
    setValue("shippingAddress2", "", { shouldDirty: true });
    setValue("shippingPostcode", "", { shouldDirty: true });
    setValue("shippingCity", "", { shouldDirty: true });
    setValue("shippingState", "", { shouldDirty: true });
    setValue("shippingCountry", "", { shouldDirty: true });
    setValue("email", "", { shouldDirty: true });
    Object.keys(address).forEach((k) => {
      setValue(k, address[k], { shouldDirty: true });
    });
    setOpen(false);
  }, [data, radio, setValue]);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const handleChange = React.useCallback((event) => {
    setRadio(event.target.value);
  }, []);
  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Auto fill with historical address
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Select an address</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="address"
              name="address-auto-filler"
              value={radio}
              onChange={handleChange}
            >
              {!error && !data && <span>Loading...</span>}
              {data && data.length === 0 && <span>No historical orders</span>}
              {data &&
                data.map((d, idx) => {
                  return (
                    <FormControlLabel
                      key={idx}
                      value={`${idx}`}
                      control={<Radio />}
                      label={getAddressLabel(d)}
                    />
                  );
                })}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleFill}
            disabled={radio === null}
            color="primary"
          >
            Fill
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AutoFillAddress;
