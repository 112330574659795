import React from "react";
import { useNotify, useRecordContext } from "react-admin";
import { CopyToClipboard } from "react-copy-to-clipboard";

const CopyTextOnClick = ({ source }) => {
  const record = useRecordContext();
  const text = record[source];
  const notify = useNotify();
  const showMsg = React.useCallback(() => {
    notify("Copied succssful");
  }, [notify]);

  return (
    <CopyToClipboard text={text} onCopy={showMsg}>
      <span>{text}</span>
    </CopyToClipboard>
  );
};

export default CopyTextOnClick;
