import React from "react";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";

const Comp = (props) => {
  return (
    <IconButton
      component="a"
      href={props.url}
      color="primary"
      target="_blank"
      rel="noopener noreferrer"
    >
      <HelpIcon />
    </IconButton>
  );
};

export default Comp;
