import React, { cloneElement } from "react";
import { useListContext, TopToolbar, CreateButton, Button } from "react-admin";
import Tooltip from "@mui/material/Tooltip";
// import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

import {
  useIsEnterprisePlan,
  useIsBusinessPlan,
} from "../../helpers/subscriptionPlan";

const ListActions = (props) => {
  const { filters } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
    isLoading,
  } = useListContext();

  const isEnterprisePlan = useIsEnterprisePlan();
  const isBusinessPlan = useIsBusinessPlan();
  const maxNumberOfTag = isEnterprisePlan ? 1000 : isBusinessPlan ? 50 : 5;

  const showCreate = isLoading ? false : maxNumberOfTag > total;
  return (
    <TopToolbar>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {showCreate && (
        <Tooltip title={`Plan max: ${maxNumberOfTag} tags`}>
          <CreateButton />
        </Tooltip>
      )}
      {!showCreate && (
        <Tooltip
          title={`You've reached your plan maximum ${maxNumberOfTag} tags.`}
        >
          <span>
            <Button disabled label="Create">
              <AddIcon />
            </Button>
          </span>
        </Tooltip>
      )}
    </TopToolbar>
  );
};

export default ListActions;
