import React, { useContext, useState } from "react";
import useSWR, { useSWRConfig } from "swr";
import subHours from "date-fns/subHours";
import startOfHour from "date-fns/startOfHour";

import { store } from "./store";
import {
  store as commentStore,
  ADD_COMMENT,
  ADD_CONTACT,
} from "./commentStore";
import { fetchWithFBToken } from "../Common/swrMiddlewares";

import {
  useIsEnterprisePlan,
  useIsBusinessPlan,
} from "../../helpers/subscriptionPlan";
import { useRecursiveTimeout } from "../useRecursiveTimeout";
import { uniqueByValue } from "../../helpers/methods";
import { withProfile } from "../DataHooks/useProfile";
import { useFbLiveVideo } from "../DataHooks/useFbLiveVideo";
import { CACHE_CONTACTS } from "../Constant";

const Comp = (props) => {
  const { mutate: cacheMutate } = useSWRConfig();

  const video = useFbLiveVideo();
  const isEnterprisePlan = useIsEnterprisePlan();
  const isBusinessPlan = useIsBusinessPlan();
  const maxConcurrent = isEnterprisePlan ? 50 : isBusinessPlan ? 30 : 10;

  const { state } = useContext(store);
  const { dispatch: commentDispatch, state: commentState } =
    useContext(commentStore);
  const { contactHashTable } = commentState;
  const {
    // video,
    commentScroll,
    enableCustomerInfo,
  } = state;
  const [before, setBefore] = React.useState("");
  const [newPsids, setNewPsids] = useState([]);
  const [requests, setRequests] = useState([]);
  // const { profile } = useProfile();
  const { profile } = props;
  const { data: commentData, error } = useSWR(
    commentScroll
      ? `${process.env.REACT_APP_GRAPH_API_URL}/${video.id}/comments?filter=stream&order=reverse_chronological&limit=100&before=${before}&fields=created_time,from,message,id,can_comment,can_hide,parent`
      : null,
    {
      use: [fetchWithFBToken],
      refreshInterval: 4 * 1000,
      errorRetryCount: 30,
      onSuccess: (resp) => {
        // const arr = [
        //   ...newPsids,

        //   `${new Date().getTime()}${parseInt(Math.random() * 10)}`,
        //   `${new Date().getTime()}${parseInt(Math.random() * 10)}`,
        //   `${new Date().getTime()}${parseInt(Math.random() * 10)}`,
        //   `${new Date().getTime()}${parseInt(Math.random() * 10)}`,
        //   `${new Date().getTime()}${parseInt(Math.random() * 10)}`,
        //   `${new Date().getTime()}${parseInt(Math.random() * 10)}`,
        //   `${new Date().getTime()}${parseInt(Math.random() * 10)}`,
        //   `${new Date().getTime()}${parseInt(Math.random() * 10)}`,
        //   `${new Date().getTime()}${parseInt(Math.random() * 10)}`,
        //   `${new Date().getTime()}${parseInt(Math.random() * 10)}`,
        //   `${new Date().getTime()}${parseInt(Math.random() * 10)}`,
        //   `${new Date().getTime()}${parseInt(Math.random() * 10)}`,
        //   `${new Date().getTime()}${parseInt(Math.random() * 10)}`,
        //   `${new Date().getTime()}${parseInt(Math.random() * 10)}`,
        // ];

        // setNewPsids(arr);

        if (resp.data.length > 0) {
          const withFromComments = resp.data.filter((c) => !!c.from);

          const notFoundPsids = withFromComments
            .map((c) => c.from.id)
            .filter(uniqueByValue)
            .filter((id) => !contactHashTable[id])
            .filter((id) => newPsids.indexOf(id) === -1);

          setNewPsids([...newPsids, ...notFoundPsids]);

          const reverse = withFromComments.map(
            (e, i, a) => a[a.length - 1 - i]
          );
          commentDispatch({
            type: ADD_COMMENT,
            payload: reverse,
          });
          setBefore(resp.paging.cursors.before);
        }
      },
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Never retry on 404.
        if (error.status === 404) return;

        // Never retry for a specific key.
        if (key === "/api/user") return;

        // Only retry up to 30 times.
        if (retryCount >= 30) {
          if (retryCount % 10 === 0) {
            alert(
              "Error getting comments, please check your live video or refresh"
            );
          }
          return;
        }
        setTimeout(() => revalidate({ retryCount }), 10000);
      },
    }
  );

  // const queryStr = requests.map((id) => `psid_in=${id}`).join(",");
  const createdAt = startOfHour(
    subHours(
      new Date(),
      profile.params.hoursToOutstanding ? profile.params.hoursToOutstanding : 48
    )
  );

  const { data: contactData, error: contactError } = useSWR(
    enableCustomerInfo && requests.length > 0
      ? `${
          process.env.REACT_APP_CMS_HOST
        }/contacts/detail?psids=${requests.join(
          ","
        )}&createdAt_lt=${createdAt.toISOString()}
        `
      : null,
    {
      // dedupingInterval: 10 * 1000,
      revalidateOnFocus: false,
      onSuccess: (contacts) => {
        contacts.forEach((contact) => {
          cacheMutate(`${CACHE_CONTACTS}/${contact.psid}`, contact, {
            revalidate: false,
          });
        });

        const payload = contacts.reduce((acc, cur) => {
          //here instead of storing the contac, store boolean true to reduce mem use
          acc[cur.psid] = true;
          return acc;
        }, {});
        commentDispatch({
          type: ADD_CONTACT,
          payload: payload,
        });

        setRequests([]);
      },
    }
  );

  const syncCallback = React.useCallback(() => {
    if (requests.length === 0 && newPsids.length > 0) {
      const processSlice = newPsids.slice(0, maxConcurrent);
      const nextSlice = newPsids.slice(maxConcurrent);
      setNewPsids(nextSlice);
      setRequests(processSlice);
    }
  }, [maxConcurrent, newPsids, requests]);
  useRecursiveTimeout(syncCallback, 10000);

  return null;
};

export default withProfile(Comp);
