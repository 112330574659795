import { unparse as convertToCSV } from "papaparse/papaparse.min";
import { downloadCSVWithBom } from "../../helpers/methods";

const exporter = (logs) => {
  const logsForExport = logs.map((log) => {
    const {
      __v,
      page,
      createdAt,
      updatedAt,
      amount,
      contact,
      updatedBy,
      ...logForExport
    } = log; // omit backlinks and author

    return {
      ...logForExport,
      amount: Number(amount) / 100,
      contactId: contact._id,
      contactName: contact.name,
      updatedBy: updatedBy.username,
      createdAt: new Date(createdAt).toLocaleString(),
      updatedAt: new Date(updatedAt).toLocaleString(),
    };
  });
  const csv = convertToCSV({
    data: logsForExport,
    fields: [
      "id",
      "contactId",
      "contactName",
      "amount",
      "updatedBy",
      "createdAt",
      "updatedAt",
    ],
  });
  downloadCSVWithBom(csv, `creditLogs_${new Date().toLocaleDateString()}`);
};

export default exporter;
