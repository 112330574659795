import React from "react";
import { useNotify } from "react-admin";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { getCookie } from "../../helpers/Cookies";
import { fetchHandleError } from "../../helpers/fetchHandleError";
import { withProfile } from "../DataHooks/useProfile";
import { usePsidThreadOwner } from "../DataHooks/useGetFbProfileImage";
import {
  useTakeThreadControl,
  useReleaseThreadControl,
} from "../DataHooks/threadControl";

const FbSendPaymentLink = (props) => {
  const { data, profile } = props;
  const psid = data.contact.psid;
  const diff = (new Date() - new Date(data.contact.updatedAt)) / 36e5;
  const isWithin24h = diff <= 23;
  const [open, setOpen] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [threshold, setThreshold] = React.useState(0);
  const [isMessageTag, setIsMessageTag] = React.useState(false);
  const notify = useNotify();
  const { trigger: take, isMutating } = useTakeThreadControl();
  const { trigger: release } = useReleaseThreadControl(undefined, {
    onError: undefined,
  });

  const {
    data: threadOwner,
    mutate: threadOwnerMutate,
    isNotBoxify,
    isIdle,
  } = usePsidThreadOwner(psid);

  const sendMessenger = React.useCallback(async () => {
    const token = getCookie("token");
    if (isNotBoxify && !isIdle) {
      await take({
        recipient: {
          id: `${psid}`,
        },
      }).catch((e) => {});
    }

    await fetch(
      `${process.env.REACT_APP_CMS_HOST}/orders/${
        data.orderNumber
      }/notify-customer?standardMessaging=${!isMessageTag}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then(fetchHandleError)
      .then(() => {
        notify("FB message sent successful");
      })
      .catch((e) => {
        if (isMessageTag) {
          setDisabled(true);
        }
        notify("Failed to send FB message. Error: " + e, "warning");
      });
    release({
      recipient: {
        id: `${psid}`,
      },
    });
  }, [
    isNotBoxify,
    isIdle,
    data.orderNumber,
    isMessageTag,
    release,
    take,
    psid,
    notify,
  ]);

  return (
    <>
      <Button
        disabled={disabled}
        color="secondary"
        onClick={() => {
          setOpen(true);
        }}
      >
        <SendIcon />
        FB
      </Button>
      <Dialog open={open}>
        <DialogTitle>{"FB send cart"}</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!!profile.params.disableForceSendCart}
                checked={isMessageTag}
                onChange={() => {
                  setIsMessageTag((prev) => !prev);
                }}
                name="isMessageTag"
              />
            }
            label={`Force send (May risk Messenger block) ${
              profile.params.disableForceSendCart && "Disabled by Admin"
            }`}
          />
          <DialogContentText>
            {isWithin24h && (
              <span>{`Customer interacted recently, use standard messaging.`}</span>
            )}
            {!isWithin24h && (
              <span>{`Customer did not interact last 24H, use force send. Monitor your Page block rate to avoid getting block.`}</span>
            )}
            {isMessageTag && threshold >= 2 && (
              <b>
                {`You've attempted ${threshold} force send just now, use it with caution.`}
              </b>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (isMessageTag) {
                setThreshold((c) => c + 1);
              }
              setOpen(false);
              sendMessenger();
            }}
            color="primary"
            autoFocus
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withProfile(FbSendPaymentLink);
