import React from "react";
import Card from "@mui/material/Card";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { makeStyles } from "@mui/styles";

import Typography from "@mui/material/Typography";
// import { useTranslate } from "react-admin";
import LaunchIcon from "@mui/icons-material/Launch";

import CardIcon from "./CardIcon";
const useStyles = makeStyles({
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20,
  },
  card: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    minHeight: 52,
  },
  title: {},
});

const Revenue = ({ title, value, text, text2 = "", launchLink = "" }) => {
  // const translate = useTranslate();
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <CardIcon Icon={AttachMoneyIcon} bgColor="#31708f" />
      <Card className={classes.card}>
        {launchLink && (
          <a target="_blank" rel="noopener noreferrer" href={launchLink}>
            <LaunchIcon />
          </a>
        )}
        <Typography className={classes.title} color="textSecondary">
          {title}
        </Typography>
        <Typography variant="h5" component="h2">
          {value}
        </Typography>
        <Typography>{text}</Typography>
        <Typography>{text2}</Typography>
      </Card>
    </div>
  );
};

export default Revenue;
