import React, { useState } from "react";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";

import Avatar from "@mui/material/Avatar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import ReactToPrint from "react-to-print";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { useTheme } from "@mui/material/styles";
import { ButtonGroup } from "@mui/material";

import { receiptImageStyle } from "../Constant";
import { replaceImageSource } from "../../helpers/withReplaceImageSource";
import { useRecordContext } from "react-admin";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class ComponentToPrint extends React.Component {
  render() {
    return <div>{this.props.children}</div>;
  }
}

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const ImageReceipt = (props) => {
  const { fileUrl } = props;
  const componentRef = React.useRef();
  const [rotation, setRotation] = useState(0);
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div onClick={handleOpen}>
        <Avatar
          variant="square"
          alt="receipt"
          src={fileUrl}
          className={classes.large}
        />
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Receipt</DialogTitle>
        <DialogContent>
          <ComponentToPrint ref={componentRef}>
            <img
              style={{
                ...receiptImageStyle,
                transform: `rotate(${rotation}deg) scaleX(${scaleX}) scaleY(${scaleY})`,
              }}
              alt="receipt"
              src={fileUrl}
            />
          </ComponentToPrint>
        </DialogContent>
        <DialogActions>
          <ButtonGroup variant="outlined" aria-label="image tools">
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setRotation(rotation + 90)}
            >
              Rotate
            </Button>
            <Button onClick={() => setScaleX((prev) => prev * -1)}>
              Flip X
            </Button>
            <Button onClick={() => setScaleY((prev) => prev * -1)}>
              Flip Y
            </Button>
          </ButtonGroup>
          <ReactToPrint
            trigger={() => (
              <IconButton aria-label="print">
                <PrintIcon />
              </IconButton>
            )}
            content={() => componentRef.current}
          />
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const Pdf = ({ fileUrl: oriFileUrl }) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const fileUrl = replaceImageSource(oriFileUrl);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const componentRef = React.useRef();

  return (
    <>
      <div onClick={handleOpen}>
        <Document file={fileUrl}>
          <Page scale={0.2} pageNumber={1} />
        </Document>
      </div>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Receipt</DialogTitle>
        <DialogContent>
          <ComponentToPrint ref={componentRef}>
            <Document file={fileUrl}>
              <Page pageNumber={1} />
            </Document>
          </ComponentToPrint>
        </DialogContent>
        <DialogActions>
          <ReactToPrint
            trigger={() => (
              <IconButton aria-label="print">
                <PrintIcon />
              </IconButton>
            )}
            content={() => componentRef.current}
          />
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const Receipt = () => {
  const record = useRecordContext();
  const fileUrl = (record && record.receipt && record.receipt.fileUrl) || "";
  // const {
  //   receipt: { fileUrl = "" },
  // } = record;

  const isPdf = fileUrl.toLowerCase().indexOf(".pdf") > -1;
  const newFileUrl = replaceImageSource(fileUrl);
  if (isPdf) {
    return <Pdf fileUrl={newFileUrl} />;
  }
  return <ImageReceipt fileUrl={newFileUrl} />;
};

export default Receipt;
