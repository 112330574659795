import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecordContext } from "react-admin";
import { useUpdate } from "react-admin";
import IconButton from "@mui/material/IconButton";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useIsOrderEditable } from "../components/DataHooks/useIsOrderEditable";
import { CONTACT_CHANNEL_INSTAGRAM } from "../components/Constant";

const ContactLink = ({ source, label }) => {
  const record = useRecordContext();
  const [isUpdatingOrder, setIsUpdatingOrder] = useState(false);
  const { contact = undefined } = record;
  const customerName = record[source];
  const isOutdated =
    contact && contact.name && !(contact.name === customerName);
  const isOrderNamePsid = contact && customerName === contact.psid;
  const isContactNamePsid = contact && contact.psid === contact.name;
  const isOrderEditable = useIsOrderEditable();
  const shouldUpdate = (isOutdated || isOrderNamePsid) && isOrderEditable;
  const isInstagram = contact && contact.channel === CONTACT_CHANNEL_INSTAGRAM;

  const comp = contact ? (
    <Link to={`/contacts/${contact._id}/show`}>{customerName}</Link>
  ) : (
    <span>{customerName}</span>
  );

  const [update, { isLoading, error }] = useUpdate();

  useEffect(() => {
    if (shouldUpdate && !isUpdatingOrder && !isLoading && !error) {
      if (contact) {
        setIsUpdatingOrder(true);
        update("orders", {
          id: record.id,
          data: { customerName: contact.name },
          previousData: { record },
        });
      }
    }
  }, [
    contact,
    error,
    isLoading,
    isUpdatingOrder,
    record,
    shouldUpdate,
    update,
  ]);

  return (
    <div>
      {comp}
      {isInstagram && !isContactNamePsid && (
        <IconButton
          size="small"
          onClick={() => {
            window.open(`https://www.instagram.com/${contact.name}`, "_blank");
          }}
        >
          <InstagramIcon />
        </IconButton>
      )}
    </div>
  );
};

export default ContactLink;
