import React from "react";
import { useFormContext, useController } from "react-hook-form";
// import { comboRegex } from "../../helpers/methods";
// import { TextInput } from "react-admin";
import TextField from "@mui/material/TextField";

const CombineDescriptionWithQuantity = (props) => {
  const { setValue, watch } = useFormContext();
  const watchFields = watch(["item", "quantity"]);
  const { field } = useController({
    name: "description",
    defaultValue: "",
  });
  React.useEffect(() => {
    const [item = "", quantity = 1] = watchFields;
    if (item !== "") {
      setValue("description", `${item} x${quantity}`);
    } else {
      setValue("description", "");
    }
  }, [setValue, watchFields]);

  return (
    <TextField
      {...field}
      label="Full Description"
      disabled
      fullWidth
      InputLabelProps={{ shrink: true }}
    />
  );
};

export default CombineDescriptionWithQuantity;
