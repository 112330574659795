import React, { useState, useEffect, useCallback } from "react";
import { useInput } from "react-admin";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone, { getFilesFromEvent } from "react-dropzone-uploader";
// import { addField } from "ra-core";
import { Labeled } from "react-admin";
import imageCompression from "browser-image-compression";
import { usePresignedUrl } from "../DataHooks/fileUpload";

// const s3Bucket = "https://img-seller.boxify.io/";

const resize = async (e) => {
  const files = getFilesFromEvent(e);
  const options = {
    maxSizeMB: 0.2,
    maxWidthOrHeight: 1920,
  };
  const promises = files.map((f) => {
    if (f.size > 200000) {
      return imageCompression(f, options);
    }
    return f;
  });
  const compressedFiles = await Promise.all(promises);
  return compressedFiles;
};

const Uploader = ({ input, ...props }) => {
  const { trigger: getPresignedUploadParams } = usePresignedUrl();
  const {
    field: { onChange, value },
  } = useInput({ ...props, name: "image" });
  const [upload, setUpload] = useState([]);
  const [files, setFiles] = useState([]);

  // specify upload params and url for your files
  const getUploadParams = useCallback(
    async (fileWithMeta) => {
      const { file, meta } = fileWithMeta;
      const date = new Date();
      const timestamp = date.getTime();
      const metaName = encodeURIComponent(meta.name);
      const fileName = `seller_photo/${timestamp}-${metaName}`;
      try {
        const [{ getUrl, putUrl }] = await getPresignedUploadParams([fileName]);
        setUpload((upload) => [
          {
            name: getUrl,
            originalName: meta.name,
            id: meta.id,
            uploaded: false,
          },
        ]);

        return {
          method: "PUT",
          body: file,
          meta: { getUrl },
          url: putUrl,
        };

        // return {
        //   fields: {
        //     key: fileName,
        //     "Content-Type": meta.type,
        //   },
        //   url: s3Bucket,
        // };
      } catch (e) {}
    },
    [getPresignedUploadParams]
  );

  // called every time a file's `status` changes
  const handleChangeStatus = (fileWithMeta, status) => {
    const { meta, file, ...props } = fileWithMeta;
    if (status === "done") {
      setFiles((obj) => [...obj, fileWithMeta]);
      setUpload((upload) =>
        upload.map((f) => {
          if (f.id === meta.id) {
            return {
              ...f,
              uploaded: true,
            };
          }
          return f;
        })
      );
    }
  };

  useEffect(() => {
    onChange(upload);
  }, [upload]);

  useEffect(() => {
    // const { value = "" } = input;
    if (!value && upload.length > 0) {
      //reset
      setUpload([]);
      files.map((f) => f.remove());
      setFiles([]);
    }
  }, []);

  const completed = upload.filter((f) => f.uploaded === true);
  return (
    <>
      <Labeled label="Order image">
        <></>
      </Labeled>
      <Dropzone
        // styles={{
        //   dropzone: { width: width * 0.85, height: width * 0.65 },
        //   dropzoneActive: { borderColor: "green" },
        // }}
        maxFiles={1}
        inputContent="Upload product image"
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        getFilesFromEvent={resize}
      />
      <ul>
        {completed.length > 0 &&
          completed.map((c) => (
            <li key={c.id}>{`${c.originalName} uploaded successfully`}</li>
          ))}
      </ul>
    </>
  );
};

export default Uploader;
