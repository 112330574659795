import React from "react";
import { useProfileParams } from "./useProfileParams";
import { isArray } from "../helpers/methods";

export const useAvailableCurrencies = () => {
  const [params] = useProfileParams();
  const result = React.useMemo(() => {
    if (
      params &&
      params.availableCurrencies &&
      isArray(params.availableCurrencies)
    ) {
      return {
        availableCurrencies: params.availableCurrencies,
        isMultiCurrency: params.availableCurrencies.length > 1,
        defaultCurrency: params.currency,
      };
    } else {
      return {
        availableCurrencies: [],
        isMultiCurrency: false,
        defaultCurrency: params.currency,
      };
    }
  }, [params]);
  return result;
};
