import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import EjectIcon from "@mui/icons-material/Eject";
import flatten from "lodash/flatten";
import { useListContext, useNotify, useRefresh } from "react-admin";
// import { fetchHandleError } from "../../../helpers/fetchHandleError";
// import { useGetToken } from "../../DataHooks/useGetToken";
import Loader from "../../Loader";
import { useRemovePrefix } from "../../DataHooks/useRemovePrefix";
import { COMPLETED } from "../../DataHooks/useThrottleCall";
import { useCustomAllRecords } from "../../Common/useCustomAllRecords";
import { useIsBusinessPlan } from "../../../helpers/subscriptionPlan";
import { TextField } from "@mui/material";

const RemovePrefix = (props) => {
  const { selectedIds } = useListContext();
  const allRecords = useCustomAllRecords();
  const [isOpen, setIsOpen] = useState(false);
  const [prefix, setPrefix] = useState("[PO]");
  const isBusinessPlan = useIsBusinessPlan();

  const refresh = useRefresh();
  const notify = useNotify();
  const { processing, status, terminate, faileds, removePrefix } =
    useRemovePrefix(prefix);

  const orders = allRecords.filter((record) =>
    selectedIds.includes(record._id)
  );
  const localPrefixItems = orders.map((o) => {
    const items = o.orderItems;
    const filteredItems = items.filter((i) => i.description.startsWith(prefix));
    return filteredItems;
  });
  const prefixItems = flatten(localPrefixItems);
  const handleClick = React.useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleDialogClose = React.useCallback(() => {
    setIsOpen(false);
    terminate();
  }, [terminate]);

  const handleConfirm = React.useCallback(async () => {
    setIsOpen(false);

    removePrefix(prefixItems);
  }, [prefixItems, removePrefix]);

  useEffect(() => {
    if (status === COMPLETED && faileds.length === 0) {
      notify("Successful");
      refresh();
      setIsOpen(false);
    } else if (faileds.length > 0) {
      notify("Some operations failed, please retry again later.", "warning");
    }
  }, [faileds.length, notify, refresh, setIsOpen, status]);

  return (
    <>
      <Button label="RemovePOTag" onClick={handleClick}>
        <EjectIcon />
        REMOVE PREFIX
      </Button>
      <Dialog open={isOpen} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">
          Are you sure to remove <b>{prefix}</b> prefix from them?
          {!isBusinessPlan && (
            <h4 style={{ color: "red" }}>This is a business plan feature.</h4>
          )}
        </DialogTitle>
        <DialogContent>
          <TextField
            value={prefix}
            onChange={(e) => setPrefix(e.target.value)}
            label="Prefix you wish to remove"
          />
          <h4>[PO]: Shipping rules can be applied on them after remove</h4>
          <ul>
            {prefixItems.map((i, idx) => {
              return <li key={i._id}>{`${i.description}`}</li>;
            })}
          </ul>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleConfirm}
            color="primary"
            autoFocus
            disabled={
              prefixItems.length === 0 ||
              processing ||
              !isBusinessPlan ||
              prefix.length === 0
            }
          >
            START REMOVE
          </Button>
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Loader open={processing}>
        {`DO NOT CLOSE THIS WINDOW !\n Processing...`}
      </Loader>
    </>
  );
};

export default RemovePrefix;
