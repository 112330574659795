import React from "react";
import { useListContext } from "react-admin";
import TextField from "@mui/material/TextField";
import { useFormContext } from "react-hook-form";

import useDebounce from "../UseDebounce";

const Comp = (props) => {
  const { filterValues } = useListContext();
  const { label, source } = props;
  const { setValue: setFormValue } = useFormContext();

  const [value, setValue] = React.useState(
    filterValues[source] ? filterValues[source] : ""
  );
  const debouncedSearchTerm = useDebounce(value, 800);

  React.useEffect(() => {
    setFormValue(source, debouncedSearchTerm);
  }, [debouncedSearchTerm, setFormValue, source]);

  return (
    <TextField
      label={label}
      value={value}
      variant="filled"
      onChange={(e) => setValue(e.target.value)}
    />
  );
};
export default Comp;
