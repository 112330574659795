import React from "react";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";

import { useFbLiveVideo } from "../DataHooks/useFbLiveVideo";

export const LiveVideoCheckerComp = () => {
  const fbLiveVideo = useFbLiveVideo();
  return fbLiveVideo ? (
    <Tooltip title={"Live is going on, tap me to go to Live Window"}>
      <Button to="/live-window" component={Link}>
        <LiveTvIcon color="action" fontSize="large" />
      </Button>
    </Tooltip>
  ) : null;
};

export default LiveVideoCheckerComp;
