import React from "react";
import Loadable from "react-loadable";

import { withProfile } from "../DataHooks/useProfile";
import { useSubscription } from "../DataHooks/useSubscription";

const MY = Loadable({
  loader: () => import("./MY"),
  loading: () => "Loading",
});

const SG = Loadable({
  loader: () => import("./SG"),
  loading: () => "Loading",
});

const Comp = (props) => {
  const { profile } = props;
  const { isTrialValid } = useSubscription();

  if (
    !isTrialValid &&
    profile &&
    profile.page &&
    profile.page.coo === "MY" &&
    profile.page.zohoContactId
  ) {
    return <MY />;
  } else if (
    !isTrialValid &&
    profile &&
    profile.page &&
    profile.page.coo === "SG" &&
    profile.page.zohoContactId
  ) {
    return <div>Please contact support with your billing information.</div>;
    // return <SG />;
  } else if (isTrialValid) {
    return (
      <div>
        Currently trial on business tier. If you would like to subscribe, please
        contact support via the widget.
      </div>
    );
  }
  return <div>Please contact support with your billing information.</div>;
};
export default withProfile(Comp);
