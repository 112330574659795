import React from "react";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Decimal from "decimal.js-light";
import { isNaNOrNull } from "../helpers/methods";
import DeliveryOrderNumber from "./DeliveryOrderNumber";
import "./Order/BulkPrintDeliveryOrder/pagebreak.css";

const thickWidth = { width: "70%" };
const fontWeight = { fontWeight: "bold" };
const comboRegex = /(.*)x(\d{1,}$)/i;
const getUnitPriceFromSubtotal = ({ price, quantity = "", ...others }) => {
  const unit = (price * 100) / parseInt(quantity) / 100;
  if (!isNaN(unit)) {
    //it has proper quantity
    return {
      unitPrice: unit.toFixed(2),
      price,
      quantity,
      ...others,
    };
  } else {
    return {
      unitPrice: "",
      price,
      quantity,
      ...others,
    };
  }
};
class ComponentToPrint extends React.Component {
  render() {
    const {
      record,
      companyName = "",
      sortItemsAlphabetically = false,
    } = this.props;
    const {
      orderItems = [],
      orderNumber,
      customerName,
      invoiceNumber,
    } = record;
    const beforeSortFiltered = orderItems.filter(
      (i) => i.status !== "cancelled"
    );

    const filtered = sortItemsAlphabetically
      ? beforeSortFiltered.sort(function compare(a, b) {
          if (a.description.toUpperCase() > b.description.toUpperCase())
            return 1;
          else if (a.description.toUpperCase() < b.description.toUpperCase())
            return -1;
          else return 0;
        })
      : beforeSortFiltered;
    const itemWithDescriptions = filtered
      .reduce((acc, cur, idx, src) => {
        const { description = "", createdAt, price: priceStr = "" } = cur;
        const price = isNaNOrNull(priceStr)
          ? "0.00"
          : new Decimal(priceStr).toFixed(2);
        const spacelessDescription = description.replace(/\s/g, " ").trim();
        const matches = comboRegex.exec(spacelessDescription);
        if (matches) {
          const [fullString, productDescription, quantity] = matches;
          const splitted = productDescription.split(/,|，/).map((str, idx) => {
            if (idx === 0) {
              return {
                description: `${str}`,
                createdAt,
                price,
                quantity,
              };
            }
            return { description: `- ${str.trim()}`, createdAt };
          });
          return [...acc, ...splitted];
        } else {
          const splitted = spacelessDescription
            .split(/,|，/)
            .map((str, idx) => {
              if (idx === 0) {
                return { description: str.trim(), createdAt, price };
              } else {
                return { description: `- ${str.trim()}`, createdAt };
              }
            });
          return [...acc, ...splitted];
        }
      }, [])
      .map(getUnitPriceFromSubtotal);

    return (
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box>
              <Grid
                container
                justifyContent="center"
                alignContent="center"
                alignItems="flex-start"
              >
                <Grid item justifyContent="center">
                  <h1>{companyName}</h1>
                  <span style={fontWeight}>
                    {`Customer FB: ` + customerName}
                  </span>
                  <br />
                  <span style={fontWeight}>
                    {`Order Number: ` + orderNumber}
                  </span>
                  <br />
                  <span style={fontWeight}>
                    <DeliveryOrderNumber invoiceNumber={invoiceNumber} />
                  </span>
                  <br />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <TableContainer component={Paper}>
                <Table border={1} aria-label="item table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox" size="small">
                        <span style={fontWeight}>#</span>
                      </TableCell>
                      <TableCell style={thickWidth}>
                        <span style={fontWeight}>Description</span>
                      </TableCell>
                      <TableCell>
                        <span style={fontWeight}>Qty</span>
                      </TableCell>
                      <TableCell align="center">
                        <span style={fontWeight}>Check</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {itemWithDescriptions.map(
                      (
                        {
                          description,
                          createdAt,
                          quantity = "",
                          price = "",
                          unitPrice,
                        },
                        idx
                      ) => (
                        <TableRow key={idx}>
                          <TableCell align="center" padding="none" size="small">
                            <span style={fontWeight}>{idx + 1}</span>
                          </TableCell>
                          <TableCell>
                            <span style={fontWeight}>{description}</span>
                          </TableCell>
                          <TableCell>
                            <span style={fontWeight}>{quantity}</span>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default ComponentToPrint;
