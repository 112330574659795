import React, { useState, useCallback } from "react";
import { useListContext } from "react-admin";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

import {
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
} from "react-admin";

import Loader from "../Loader";

const BulkUpdateActiveButton = (props) => {
  const { resource, selectedIds } = useListContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(true);

  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll(resource);
  const [updateMany, { isLoading }] = useUpdateMany(
    resource,
    { ids: selectedIds, data: { isActive: isActive } },
    {
      onSuccess: () => {
        unselectAll();
        refresh();
        notify("Updated successfully");
        setIsOpen(false);
      },
      onFailure: (error) => notify("Update failed", "warning"),
    }
  );

  const onActiveChange = useCallback(() => {
    setIsActive((v) => !v);
  }, [setIsActive]);

  const handleClick = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const handleDialogClose = useCallback(() => {
    unselectAll();
    setIsOpen(false);
  }, [unselectAll, setIsOpen]);

  const handleConfirm = useCallback(() => {
    updateMany();
    setIsOpen(false);
  }, [updateMany, setIsOpen]);

  return (
    <>
      <Button onClick={handleClick}>
        <EditIcon />
        Activate
      </Button>
      <Dialog
        // fullScreen={false}
        open={isOpen}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{`Update deactive/active`}</DialogTitle>
        <DialogContent>
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>Deactivate</Grid>
            <Grid item>
              <Switch
                checked={isActive}
                onChange={onActiveChange}
                name="checkedC"
              />
            </Grid>
            <Grid item>Activate</Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleConfirm}
            color="primary"
            autoFocus
          >
            Update
          </Button>
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Loader open={isLoading}></Loader>
    </>
  );
};

export default BulkUpdateActiveButton;
