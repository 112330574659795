import React from "react";
import { NumberField, useRecordContext } from "react-admin";

const ShippingCostField = () => {
  const record = useRecordContext();
  const { overrideShippingCost } = record;
  return (
    <span>
      <NumberField source="shippingCost" record={record} />
      {overrideShippingCost ? "  (overriden)" : ""}
    </span>
  );
};
export default ShippingCostField;
