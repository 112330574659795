import React from "react";
import {
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  FilterButton,
} from "react-admin";
import AutoSyncButton from "./AutoSyncButton";
import { useMaxResultsByPlan } from "../../helpers/subscriptionPlan";
import Tooltip from "@mui/material/Tooltip";
import ListSelectableFields from "./ListSelectable";
import { useMediaQuery } from "@mui/material";

const ListActions = () => {
  const { sort, resource, filterValues, total } = useListContext();
  const maxResults = useMaxResultsByPlan();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <TopToolbar>
      <FilterButton />
      {!isSmall && <AutoSyncButton />}
      <CreateButton />
      {!isSmall && (
        <Tooltip title={`Max Export = ${maxResults} keywords`}>
          <span>
            <ExportButton
              disabled={total === 0}
              resource={resource}
              sort={sort}
              filterValues={filterValues}
              maxResults={maxResults}
            />
          </span>
        </Tooltip>
      )}
      {!isSmall && <ListSelectableFields />}
    </TopToolbar>
  );
};

export default ListActions;
