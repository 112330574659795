import useSWR from "swr";

import { useProfile } from "../DataHooks/useProfile";
// import { useRecursiveTimeout } from "../useRecursiveTimeout";
import { useGetToken } from "../DataHooks/useGetToken";
import { fetchWithFBToken } from "../Common/swrMiddlewares";

export const requiredFields = [
  "messages",
  "messaging_postbacks",
  // "messaging_optins",
  // "messaging_referrals",
  "feed",
];

export const useDebugFbToken = (
  url = `${process.env.REACT_APP_CMS_HOST}/pages/debug-token`,
  options
) => {
  const token = useGetToken();
  return useSWR(token ? url : null, {
    revalidateOnFocus: false,
    refreshInterval: 10 * 60 * 1000,
    ...options,
  });
};

export const useCheckSubscribedApps = (
  url = `${process.env.REACT_APP_GRAPH_API_URL}/me/subscribed_apps?`,
  options
) => {
  const { profile } = useProfile();

  return useSWR(profile && profile.page.token ? url : null, {
    revalidateOnFocus: false,
    refreshInterval: 10 * 60 * 1000,
    use: [fetchWithFBToken],
    ...options,
  });
};
