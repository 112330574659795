import React from "react";
import Decimal from "decimal.js-light";
import { makeStyles } from "@mui/styles";

import { Labeled, useRecordContext } from "react-admin";

import { useTotalInclShipping } from "./TotalInclShipping";
import { useReconciledPayment } from "./ReconciledPayment";
import { useGetDiscountValue } from "../components/Voucher/useGetDiscount";
import { AmountWithTaxColorField } from "./AmountWithTaxField";
import { getTaxCalculation } from "../components/Taxation/withTaxCalculation";
import { useGetCreditPayment } from "../components/Credit/hooks";

const useStyles = makeStyles({
  amber: { color: "orange" },
  red: { color: "red" },
  green: { color: "#4caf50" },
});

export const useGetOutstandingPaymentInclShipping = ({ record = {} }) => {
  const { payments = [], tax } = record;
  const discountValue = useGetDiscountValue(record);
  const totalInclShipping = useTotalInclShipping(record);
  const totalReconciledPaidAmount = useReconciledPayment(payments);
  const totalCreditPayment = useGetCreditPayment(payments);

  if (tax) {
    const { outstandingPayment } = getTaxCalculation(record);
    return new Decimal(outstandingPayment);
  } else {
    const balanceDecimal = new Decimal(totalInclShipping)
      .minus(totalReconciledPaidAmount)
      .minus(discountValue)
      .minus(totalCreditPayment);
    return balanceDecimal;
  }
};

const OutstandingPaymentInclShippingField = ({ record = {}, label }) => {
  const classes = useStyles();
  const balanceDecimal = useGetOutstandingPaymentInclShipping({ record });
  const balance = balanceDecimal.toFixed(2);
  const colorClass = balanceDecimal.isZero()
    ? classes.green
    : balanceDecimal.isPositive()
    ? classes.red
    : classes.amber;
  if (label) {
    return (
      <Labeled label={label ? label : ""}>
        <span className={colorClass}>{balance}</span>
      </Labeled>
    );
  }
  return <span className={colorClass}>{balance}</span>;
};

export const OutstandingPaymentField = (props) => {
  const { record } = props;
  if (!!record.tax) {
    return <AmountWithTaxColorField {...props} />;
  }
  return <OutstandingPaymentInclShippingField {...props} />;
};

const withRecordContext = (WrapComp) => (props) => {
  const record = useRecordContext();
  return <WrapComp {...props} record={record} />;
};

export default withRecordContext(OutstandingPaymentField);
