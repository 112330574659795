import React, { useState } from "react";
// import { useForm } from "react-hook-form";
import {
  required,
  Button,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  useRefresh,
  Form,
  BooleanInput,
  useUpdateMany,
  useRecordContext,
  Create,
} from "react-admin";
import IconContentAdd from "@mui/icons-material/Add";
import IconCancel from "@mui/icons-material/Cancel";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { getPageId } from "../helpers/getPageId";
import UploadReceipt from "./UploadReceipt";
import { format, parse } from "../components/Payment/Edit";
import { ORDER_ITEM_NEW } from "../components/Constant";
import { useGetOutstandingPaymentInclShipping } from "./OutstandingPaymentInclShippingField";
import { isNaNOrNull } from "../helpers/methods";
import { Button as MatButton } from "@mui/material";
import DecimalInput from "../components/Common/DecimalInput";
import { useIsOrderEditable } from "../components/DataHooks/useIsOrderEditable";
import { OFFLINE } from "../components/Payment/types";

const hiddenStyle = { display: "none" };

const PaymentQuickCreateButton = () => {
  const pageId = getPageId();
  const refresh = useRefresh();
  const record = useRecordContext();
  const isOrderEditable = useIsOrderEditable();
  const { orderNumber, _id: orderId } = record;
  const [showDialog, setShowDialog] = useState(false);
  const [showSetPaidDialog, setShowSetPaidDialog] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [create, { isLoading: isCreatingPayment }] = useCreate();
  const notify = useNotify();
  //   const form = useForm();
  const balanceDecimal = useGetOutstandingPaymentInclShipping({
    record,
  });
  const { orderItems = [] } = record;
  const itemsInNew = orderItems.filter((i) => {
    return i.status === ORDER_ITEM_NEW;
  });
  const newItemIds = itemsInNew.map((i) => {
    return i.id;
  });
  const newItemComp = itemsInNew.map((i) => {
    return <li key={i._id}>{`item: ${i.description}, price: ${i.price}`}</li>;
  });

  const balance = balanceDecimal.times(100).toNumber();
  const defaultPaidAmount =
    isNaNOrNull(record.paidAmount) || record.paidAmount === 0
      ? balance
      : record.paidAmount;

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleOpenSetPaidClick = () => {
    setShowSetPaidDialog(true);
  };

  const handleCloseSetPaidClick = () => {
    setShowSetPaidDialog(false);
    refresh();
  };

  const handleSubmit = React.useCallback(
    async (values) => {
      const { paidAmount = 0 } = values;
      const isPaidBalance = paidAmount === defaultPaidAmount;

      if (isPaidBalance && itemsInNew.length > 0) {
        handleOpenSetPaidClick();
      }

      const onSuccess =
        isPaidBalance && itemsInNew.length > 0
          ? ({ data }) => {
              setShowDialog(false);
              notify(`Payment updated`, "info");
            }
          : ({ data }) => {
              setShowDialog(false);
              notify(`Payment updated`, "info");
              refresh();
            };

      create(
        "payments",
        {
          id: record._id,
          data: { ...values, receipt: { fileUrl: fileUrl } },
          previousData: record,
        },
        {
          onSuccess: onSuccess,
          onFailure: ({ error }) => {
            notify(error.message, "error");
          },
        }
      );
    },
    [
      create,
      defaultPaidAmount,
      fileUrl,
      itemsInNew.length,
      notify,
      record,
      refresh,
    ]
  );

  const [updateMany, { isLoading: loadingOrderItem }] = useUpdateMany(
    `orderItems`,
    { ids: newItemIds, data: { status: "paid" } },
    {
      onSuccess: () => {
        refresh();
        notify("Set paid successfully");
        setShowSetPaidDialog(false);
      },
      onFailure: (error) => notify("Failed to update", "warning"),
    }
  );

  const onSetPaid = React.useCallback(() => {
    updateMany();
  }, [updateMany]);

  return (
    <>
      <Button
        size="small"
        variant="contained"
        onClick={handleClick}
        label="ra.action.add"
        sx={{ width: 100 }}
        disabled={!isOrderEditable}
      >
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showSetPaidDialog}
        // onClose={handleCloseSetPaidClick}
        aria-label="Set new items As Paid?"
      >
        <DialogTitle>Set these items as Paid?</DialogTitle>
        <DialogContent>{<ul>{newItemComp}</ul>}</DialogContent>
        <DialogActions>
          <Button
            label="ra.action.cancel"
            onClick={handleCloseSetPaidClick}
            disabled={loadingOrderItem}
          >
            <IconCancel />
          </Button>
          <MatButton
            variant="contained"
            color="primary"
            onClick={onSetPaid}
            disabled={loadingOrderItem}
          >
            Set Paid
          </MatButton>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Add payment receipt"
      >
        <DialogTitle>Add payment receipt</DialogTitle>
        <DialogContent>
          <UploadReceipt setFileUrl={setFileUrl} orderNumber={orderNumber} />
        </DialogContent>

        <Create>
          <Form
            defaultValues={{
              page: pageId,
              order: orderId,
              receipt: { fileUrl },
              paid: true,
              paidAmount: defaultPaidAmount,
              type: OFFLINE,
            }}
            onSubmit={handleSubmit}
          >
            <>
              <DialogContent>
                <TextInput
                  style={hiddenStyle}
                  disabled
                  source="receipt.fileUrl"
                  // validate={required()}
                  fullWidth
                />
                <DecimalInput
                  source="paidAmount"
                  label="Paid amount"
                  validate={required()}
                  fullWidth
                  format={format}
                  parse={parse}
                  helperText={`Auto filled with outstanding balance`}
                />
                <BooleanInput source="paid" />
                <TextInput source="remarks" />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={isCreatingPayment}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  alwaysEnable={!isCreatingPayment}
                  disabled={isCreatingPayment}
                />
              </DialogActions>
            </>
          </Form>
        </Create>
      </Dialog>
    </>
  );
};

export default PaymentQuickCreateButton;
