import React from "react";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import {
  useExportMaxRecord,
  useGetExportEndpoint,
} from "../components/Exports/hooks";
import {
  useCreateExport,
  useGetExportCount,
} from "../components/Exports/service";
import { useNotify } from "react-admin";
import { PENDING, MAX_JOB_COUNT } from "../components/Exports/Constant";
import { Tooltip } from "@mui/material";

const ExportButtonBatchJob = (props) => {
  const { filter, sort, resource } = props;
  const notify = useNotify();
  const url = useGetExportEndpoint({ filter, sort, resource });
  const limit = useExportMaxRecord();
  const {
    data: pendingCount,
    isLoading: isLoadingPending,
    mutate: mutateCount,
  } = useGetExportCount([["status_in", PENDING]]);

  const { trigger, isMutating, error, data } = useCreateExport(undefined, {
    onError: (e) => {
      notify(e, "warning");
      mutateCount();
    },
    onSuccess: (e) => {
      notify(`Success. View in menu -> Exports for detail.`);
      mutateCount();
    },
  });

  if (pendingCount && pendingCount >= MAX_JOB_COUNT) {
    return (
      <Tooltip title={`Reached max pending jobs, try again later.`}>
        <span>
          <Button disabled startIcon={<DownloadIcon />}>
            Export
          </Button>
        </span>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={`Max ${limit} rows`}>
      <span>
        <Button
          disabled={isMutating || !!error || isLoadingPending}
          // id="export-menu-button"
          // aria-controls={open ? "basic-menu" : undefined}
          // aria-haspopup="true"
          // aria-expanded={open ? "true" : undefined}
          onClick={() => {
            trigger({ endpoint: url, totalRecords: limit });
          }}
          startIcon={<DownloadIcon />}
        >
          Export
        </Button>
      </span>
    </Tooltip>
  );
};

export default ExportButtonBatchJob;
