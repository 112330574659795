import React from "react";
import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  NumberField,
  BooleanField,
  Pagination,
  AutocompleteArrayInput,
  BooleanInput,
  // useResourceContext,
  // BulkDeleteButton,
} from "react-admin";
import classnames from "classnames";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

import { makeStyles } from "@mui/styles";

// import classnames from "classnames";
import { Drawer, useMediaQuery } from "@mui/material";
import ListActions from "./ListActions";

// import { getPageId } from "../../helpers/getPageId";
import { exporter } from "./Exporter";
// import BulkUpdateActiveButton from "./BulkUpdateActiveButton";
import { useProfile } from "../DataHooks/useProfile";

import PaymentEdit from "./Edit";
import PaymentShow from "./Show";
import { useAvailableCurrencies } from "../useAvailableCurrencies";
import { OFFLINE, BILLPLZ, HITPAY, CREDIT } from "./types";
import PaymentPaidAmount from "./PaymentPaidAmount";
import CustomPickerInput from "../OrderItem/CustomPickerInput";
import CustomDateField from "../Common/CustomDateField";

const SHOW_PATH = "/payments/:id/show";
const EDIT_PATH = "/payments/:id";
const paymentTypes = [
  { id: OFFLINE, name: OFFLINE },
  { id: BILLPLZ, name: BILLPLZ },
  { id: HITPAY, name: HITPAY },
  { id: CREDIT, name: CREDIT },
];

// const BulkActionButtons = (props) => (
//   <>
//     <BulkUpdateActiveButton {...props} />
//     {/* Add the default bulk delete action */}
//     <BulkDeleteButton {...props} />
//   </>
// );

const defaultFilters = [
  <AutocompleteArrayInput
    alwaysOn
    label="Type"
    source="type"
    choices={paymentTypes}
  />,
  <BooleanInput label="Paid" source="paid" defaultValue={true} />,
  <CustomPickerInput source="createdAt_gte" label="CreatedAt start" />,
  <CustomPickerInput source="createdAt_lte" label="CreatedAt end" />,
  <CustomPickerInput source="updatedAt_gte" label="UpdatedAt start" />,
  <CustomPickerInput source="updatedAt_lte" label="UpdatedAt end" />,
];
// const DefaultFilters = (props) => (
//   <Filter {...props}>
//     <AutocompleteArrayInput
//       alwaysOn
//       label="Type"
//       source="type"
//       choices={paymentTypes}
//     />
//     <BooleanInput label="Paid" source="paid" defaultValue={true} />
//     <CustomPickerInput source="createdAt_gte" label="CreatedAt start" />
//     <CustomPickerInput source="createdAt_lte" label="CreatedAt end" />
//     <CustomPickerInput source="updatedAt_gte" label="UpdatedAt start" />
//     <CustomPickerInput source="updatedAt_lte" label="UpdatedAt end" />
//   </Filter>
// );

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(["all"], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 400,
  },
  drawerPaper: {
    zIndex: 100,
  },
}));

const PaymentList = (props) => {
  const classes = useStyles();
  const { profile } = useProfile();
  // const resource = useResourceContext();
  const location = useLocation();
  const navigate = useNavigate();

  const matchEdit = matchPath(
    {
      path: EDIT_PATH,
    },
    location.pathname
  );
  const matchShow = matchPath(
    {
      path: SHOW_PATH,
    },
    location.pathname
  );
  const isMatch = !!(
    (matchEdit && matchEdit.params && matchEdit.params.id !== "create") ||
    (matchShow && matchShow.params && matchShow.params.id !== "create")
  );

  const handleClose = React.useCallback(() => {
    navigate("/payments");
  }, [navigate]);

  const { isMultiCurrency } = useAvailableCurrencies();
  // const pageId = getPageId();

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  if (!profile) {
    return null;
  }

  return (
    <div className={classes.root}>
      <List
        {...props}
        className={classnames(
          classes.list
          // {
          //   [classes.listWithDrawer]: isMatch,
          // }
        )}
        // bulkActionButtons={<BulkActionButtons />}
        actions={<ListActions />}
        exporter={exporter}
        filter={{ page: profile.page._id }}
        filters={defaultFilters}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} />}
        sort={{ field: "createdAt", order: "DESC" }}
      >
        {isSmall ? (
          <SimpleList
            linkType={paymentRowClickMobile}
            primaryText={(record) => <span>{record.order.customerName}</span>}
            secondaryText={(record) => <span>{record.type}</span>}
            tertiaryText={(record) => (
              <span>{record.paid ? "paid" : "unpaid"}</span>
            )}
          />
        ) : (
          <Datagrid
            rowClick={paymentRowClick}
            isRowSelectable={(record) =>
              record && record.type && record.type === OFFLINE
            }
          >
            <TextField
              source="order.customerName"
              label={`Facebook Name`}
              sortable={false}
            />
            <TextField source="order.shippingName" label={`Shipping Name`} />
            <TextField
              source="order.orderNumber"
              label={`order number`}
              sortable={false}
            />
            <TextField
              source="order.status"
              label={`order status`}
              sortable={false}
            />
            <TextField source="type" />
            <PaymentPaidAmount source="paidAmount" />
            <TextField source="remarks" />
            <NumberField
              source="order.invoiceNumber"
              label={`INV No.`}
              sortable={false}
            />
            <BooleanField source="paid" />
            {isMultiCurrency && (
              <TextField source="currencyCode" label={"Currency"} />
            )}
            <CustomDateField source="createdAt" showTime />
            <CustomDateField source="updatedAt" showTime />
          </Datagrid>
        )}
      </List>
      <Drawer
        variant="persistent"
        open={isMatch}
        anchor="right"
        onClose={handleClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
        {isMatch && matchEdit && (
          <PaymentEdit
            id={matchEdit.params.id}
            onCancel={handleClose}
            {...props}
          />
        )}
        {isMatch && matchShow && (
          <PaymentShow
            id={matchShow.params.id}
            onCancel={handleClose}
            {...props}
          />
        )}
      </Drawer>
    </div>
  );
};
const paymentRowClickMobile = (record) =>
  record.type === BILLPLZ || record.type === HITPAY || record.type === CREDIT
    ? "show"
    : "edit";
const paymentRowClick = (id, resource, record) =>
  record.type === BILLPLZ || record.type === HITPAY || record.type === CREDIT
    ? "show"
    : "edit";

export default PaymentList;
