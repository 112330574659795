// import React from "react";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";

import useSWRMutation from "swr/mutation";
import { useNotify } from "react-admin";

import { useProfile } from "./useProfile";
import { fetchWithFBToken } from "../Common/swrMiddlewares";
import { sendRequest } from "../../helpers/fetchHandleError";

export const useContactFbProfile = (psid) => {
  const { profile } = useProfile();

  const { data, error } = useSWRImmutable(
    profile && psid
      ? `${process.env.REACT_APP_GRAPH_API_URL}/${psid}?fields=name,first_name,id,last_name,profile_pic`
      : null,
    { use: [fetchWithFBToken], revalidateOnFocus: false, errorRetryCount: 2 }
  );

  const { data: pictureData, error: pictureDataError } = useSWRImmutable(
    profile && psid && error
      ? `${process.env.REACT_APP_GRAPH_API_URL}/${psid}?fields=picture,name`
      : null,
    { use: [fetchWithFBToken], revalidateOnFocus: false, errorRetryCount: 2 }
  );

  const { data: conversationData, error: conversationDataError } =
    useSWRImmutable(
      profile && psid && pictureDataError
        ? `${process.env.REACT_APP_GRAPH_API_URL}/me/conversations?user_id=${psid}&fields=participants{id,name,username}`
        : null,
      { use: [fetchWithFBToken], revalidateOnFocus: false, errorRetryCount: 2 }
    );

  if (data) {
    return data;
  } else if (pictureData) {
    return pictureData;
  } else if (
    conversationData &&
    conversationData.data[0] &&
    conversationData.data[0].participants &&
    conversationData.data[0].participants.data
  ) {
    return conversationData.data[0].participants.data.find(
      (person) => person.id === psid
    );
  }

  return undefined;
};

export const useGetFbProfileImage = (psid = "") => {
  const { profile } = useProfile();

  const { data, error } = useSWRImmutable(
    profile && psid
      ? `${process.env.REACT_APP_GRAPH_API_URL}/${psid}?fields=name,first_name,id,last_name,profile_pic`
      : null,
    { use: [fetchWithFBToken], revalidateOnFocus: false, errorRetryCount: 2 }
  );

  const { data: pictureData, error: pictureError } = useSWRImmutable(
    profile && psid && error
      ? `${process.env.REACT_APP_GRAPH_API_URL}/${psid}?fields=picture`
      : null,
    { use: [fetchWithFBToken], revalidateOnFocus: false, errorRetryCount: 2 }
  );

  if (data && data.profile_pic) {
    return data.profile_pic;
  } else if (
    pictureData &&
    pictureData.picture &&
    pictureData.picture.data &&
    pictureData.picture.data.url
  ) {
    return pictureData.picture.data.url;
  }

  return "";
};

export const useContactIgProfile = (igsid, config) => {
  const { profile } = useProfile();

  return useSWRImmutable(
    profile && igsid
      ? `${process.env.REACT_APP_GRAPH_API_URL}/${igsid}?fields=name,username,profile_pic,follower_count&is_verified_user&is_user_follow_business&is_business_follow_user`
      : null,
    { use: [fetchWithFBToken], revalidateOnFocus: false, ...config }
  );
};

export const usePsidThreadOwner = (psid) => {
  const { profile } = useProfile();

  const threadOwner = useSWR(
    profile && psid
      ? `${process.env.REACT_APP_GRAPH_API_URL}/me/thread_owner/?recipient=${psid}`
      : null,
    {
      use: [fetchWithFBToken],
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  const isNotBoxify =
    threadOwner &&
    threadOwner.data &&
    threadOwner.data.data &&
    threadOwner.data.data[0] &&
    threadOwner.data.data[0].thread_owner.app_id !== "376041286589727";

  const isIdle1 =
    threadOwner &&
    threadOwner.data &&
    threadOwner.data.data &&
    threadOwner.data.data[0] &&
    !threadOwner.data.data[0].thread_owner.app_id &&
    !threadOwner.data.data[0].thread_owner.expiration;
  const isIdle2 =
    threadOwner &&
    threadOwner.data &&
    threadOwner.data.data &&
    threadOwner.data.data.length === 0;

  if (!threadOwner.data) {
    return threadOwner;
  }

  return {
    ...threadOwner,
    isNotBoxify,
    isBoxify: !isNotBoxify,
    isIdle: isIdle1 || isIdle2,
  };
};

export const useUpdateContact = (contactId, option = {}) => {
  const url = contactId
    ? `${process.env.REACT_APP_CMS_HOST}/contacts/${contactId}`
    : null;
  const notify = useNotify();
  return useSWRMutation(
    url,
    (url, config) => sendRequest(url, { ...config, method: "PUT" }),
    {
      onError: (e) => {
        notify(e.error.message, { type: "error" });
      },
      ...option,
    }
  );
};
