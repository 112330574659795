import React from "react";
import {
  // ExportButton,
  usePermissions,
  useDataProvider,
  useNotify,
  useListContext,
} from "react-admin";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import GetAppIcon from "@mui/icons-material/GetApp";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { exporter as customExporter } from "./CustomExporter";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ls from "local-storage";
import { useIsBusinessPlan } from "../../helpers/subscriptionPlan";
import ExportSettings from "./ExportSettings.js";
import { orderItemExportDefaultFields } from "../../helpers/ExportDefaultFields";
import { SELLER_ROLE } from "../../helpers/role";
// import DownloadIcon from "@mui/icons-material/GetApp";
import { useShippingMethod } from "../../components/useShippingMethod";
import { useProfile } from "../DataHooks/useProfile";

import flatten from "lodash/flatten";
import Loader from "../../components/Loader";
import { useGetKeywordsByIds } from "../DataHooks/useGetKeywordsByIds";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const CustomizedDialogs = (props) => {
  const { maxResults } = props;
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { filterValues, resource, sort } = useListContext(props);
  const [open, setOpen] = React.useState(false);
  const { permissions } = usePermissions();
  const [params, setParams] = React.useState({
    hasShippingCost: false,
    exportSummary: false,
  });
  const [orderItems, setOrderItems] = React.useState([]);
  const [keywordIds, setkeywordIds] = React.useState([]);
  const isBusinessPlan = useIsBusinessPlan();
  // const [isItemsReady, setIsItemsReady] = React.useState(false);
  // const [items, setItems] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [shippingOptions] = useShippingMethod({ showAll: true });
  const { profile } = useProfile();

  const onToggleHandler = React.useCallback(
    (e) => {
      setParams({ ...params, [e.target.name]: !params[e.target.name] });
    },
    [setParams, params]
  );

  const fetchDataSequence = React.useCallback(async () => {
    // const perPage = maxResults > 5000 ? 5000 : maxResults; // this will split 5000
    const perPage = maxResults;
    const paginations = parseInt(maxResults / perPage);
    let error = false;
    let totals = [];
    for (let i = 1; i <= paginations; i++) {
      const data = await dataProvider
        .getList(resource, {
          sort: sort,
          filter: filterValues,
          pagination: { page: i, perPage: perPage },
        })
        .then(({ data }) => {
          return data;
        })
        .catch((error) => {
          // console.error(error);
          notify("ra.notification.http_error", "warning");
          return null;
        });

      if (data === null) {
        error = true;
        break;
      } else if (data.length < perPage) {
        totals.push(data);
        break;
      } else {
        totals.push(data);
      }
    }
    if (error) {
      return null;
    }
    return flatten(totals);
  }, [dataProvider, filterValues, maxResults, notify, resource, sort]);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const hasAtLeastOneKeyword = React.useMemo(() => {
    if (orderItems.length > 0) {
      return orderItems.find((item) => !!item.keyword);
    }
    return [];
  }, [orderItems]);

  const {
    data: keywords,
    error: keywordsError,
    isLoading: keywordsIsLoading,
  } = useGetKeywordsByIds(keywordIds);

  const handleClick = React.useCallback(async () => {
    setIsLoading(true);
    const items = await fetchDataSequence(); // here we fetch the order items only upon click
    const keywordsIds = items
      .filter((i) => {
        return !!i.keyword;
      })
      .map((i) => {
        return i.keyword.id;
      });
    setOrderItems(items);
    setkeywordIds(keywordsIds);
    setIsLoading(false);
  }, [fetchDataSequence]);

  React.useEffect(() => {
    if (orderItems.length > 0) {
      if (
        !hasAtLeastOneKeyword ||
        (hasAtLeastOneKeyword && !keywordsIsLoading && !keywordsError)
      ) {
        const flattenKeywords = flatten(keywords);
        if (!orderItems) {
          alert("Error, please try again later");
          return;
        }
        const cacheFieldObject = ls.get("itemExportParams");
        const defaultFieldObject = {
          ...orderItemExportDefaultFields,
          ...cacheFieldObject,
        };
        const { MDYDateFormat = true, ...otherFieldObject } =
          defaultFieldObject;
        const keys = Object.keys(otherFieldObject);
        const itemExportFields = keys
          .filter((k) => otherFieldObject[k])
          .filter((k) => {
            // only seller can see cost and profit
            if (permissions !== SELLER_ROLE) {
              return ["totalCost", "totalProfit"].indexOf(k) < 0;
            }
            return true;
          });
        customExporter({
          invoiceNumberPrefix: profile.invoiceNumberPrefix,
          invoiceNumberLength: profile.invoiceNumberLength,
          hasShippingCost: params.hasShippingCost,
          exportSummary: params.exportSummary,
          itemExportFields,
          orderItems: orderItems,
          keywords: flattenKeywords,
          shippingOptions,
          MDYDateFormat,
        });
        setOrderItems([]);
        notify("Export orderitems file success.");
      }
    }
  }, [
    handleClose,
    hasAtLeastOneKeyword,
    keywords,
    keywordsError,
    keywordsIsLoading,
    notify,
    orderItems,
    orderItems.length,
    params.exportSummary,
    params.hasShippingCost,
    permissions,
    profile,
    shippingOptions,
  ]);
  return (
    <div>
      <Button
        startIcon={<GetAppIcon />}
        color="primary"
        onClick={() => setOpen(true)}
      >
        Export
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="orderitem-dialog-title"
        open={open}
      >
        <DialogTitle id="orderitem-dialog-title" onClose={handleClose}>
          Customize export(*Export Max = {maxResults} orderItems)
        </DialogTitle>
        <DialogContent dividers>
          <FormControlLabel
            control={
              <Checkbox
                checked={params.hasShippingCost}
                onChange={onToggleHandler}
                name="hasShippingCost"
                color="primary"
              />
            }
            label="Export shipping cost as item"
          />
        </DialogContent>
        <DialogContent dividers>
          <FormControlLabel
            control={
              <Checkbox
                checked={params.exportSummary}
                onChange={onToggleHandler}
                name="exportSummary"
                color="primary"
              />
            }
            label="Export summary"
          />
        </DialogContent>
        <DialogContent dividers>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{`Select fields ${
                !isBusinessPlan ? `(Required upgrade to business plan)` : ``
              }`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ExportSettings />
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions>
          <span onClick={handleClose}>
            <Button
              startIcon={<GetAppIcon />}
              color="primary"
              onClick={handleClick}
              // disabled={items.length === 0}
            >
              Export
            </Button>
          </span>
        </DialogActions>
      </Dialog>
      <Loader open={isLoading}>
        <span> {`Fetching data...`}</span>
      </Loader>
    </div>
  );
};
export default CustomizedDialogs;
