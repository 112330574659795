import React from "react";
import { DateTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";
import setSeconds from "date-fns/setSeconds";
import { useController } from "react-hook-form";

// import { getOrderItemSearchStartDate } from "../../helpers/methods";

const CustomPickerInput = (props) => {
  const { source, label, options = {} } = props;
  const input = useController({ name: source });

  const onChangeHandler = React.useCallback(
    (date) => {
      //this is to set the seconds always zero
      if (Date.parse(date)) {
        const ts = setSeconds(new Date(date), 0);
        input.field.onChange(ts.toISOString());
      }
      //  else {
      //   input.field.onChange(getOrderItemSearchStartDate().toISOString());
      // }
      input.field.onBlur();
    },
    [input]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        {...input.field}
        {...options}
        label={label}
        onChange={onChangeHandler}
        showToolbar
        renderInput={(params) => <TextField {...params} />}
        componentsProps={{
          actionBar: { actions: ["today"] },
        }}
      />
    </LocalizationProvider>
  );
};

export default CustomPickerInput;
