import React from "react";
import {
  useListContext,
  TopToolbar,
  ExportButton,
  FilterButton,
} from "react-admin";
import { useMaxResultsByPlan } from "../../helpers/subscriptionPlan";

const ListActions = () => {
  const { sort, resource, filterValues, total } = useListContext();

  const maxResults = useMaxResultsByPlan();

  return (
    <TopToolbar>
      <FilterButton />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={sort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

export default ListActions;
