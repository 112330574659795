import React from "react";
import { useInput } from "react-admin";
import TextField from "@mui/material/TextField";

const CustomTextInput = (props) => {
  const {
    parse = (v) => v,
    format = (v) => v,
    validate,
    helperText,
    ...rest
  } = props;

  const {
    field,
    isRequired,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
  } = useInput({
    validate,
    ...rest,
  });
  const [value, setValue] = React.useState(format(field.value));
  const onChange = (event) => {
    setValue(event.target.value); // UI state
    field.onChange(parse(event.target.value)); // data send back to hook form
  };
  return (
    <TextField
      {...field}
      value={value}
      onChange={onChange}
      label={props.label ? props.label : props.source}
      required={isRequired}
      error={(isTouched || isSubmitted) && invalid}
      helperText={error ? error.message : helperText}
      {...rest}
    />
  );
};

export default CustomTextInput;
