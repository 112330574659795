import React from "react";
import { compose } from "redux";

import { useProfile } from "../components/DataHooks/useProfile";

export const HOBBYIST = 10;
export const BUSINESS = 20;
export const ENTERPRISE = 30;

export const useSubscriptionPlan = () => {
  const { profile } = useProfile();

  if (!profile) {
    return [HOBBYIST]; //default when loading
  }
  return [profile.page.subscriptionPlan];
};

export const defineTier = (requireTier) => (WrappedComponent) => (props) => {
  const [tier] = useSubscriptionPlan();
  if (tier >= requireTier) {
    return <WrappedComponent {...props} />;
  } else {
    return "Upgrade to enable this feature.";
  }
};

export const useIsHobbyistPlan = () => {
  const [tier] = useSubscriptionPlan();
  return tier <= HOBBYIST;
};

export const useIsBusinessPlan = () => {
  const [tier] = useSubscriptionPlan();
  const { profile } = useProfile();
  if (!profile) {
    return false; //default when loading
  }
  const { page = {} } = profile;
  const { isTrial = false, trialEndDate } = page;
  const trialValid = isTrial && new Date(trialEndDate) >= new Date();
  return tier >= BUSINESS || trialValid;
};

export const useIsEnterprisePlan = () => {
  const [tier] = useSubscriptionPlan();
  return tier >= ENTERPRISE;
};

export const useMaxResultsByPlan = () => {
  const business = useIsBusinessPlan();
  const enterprise = useIsEnterprisePlan();
  const maxResults = enterprise ? 10000 : business ? 5000 : 500;
  return maxResults;
};

//this includes business and trial
export const withBusiness = (content) => (WrappedComponent) => (props) => {
  const valid = useIsBusinessPlan();
  // const [tier] = useSubscriptionPlan();
  // const { page = {} } = profile;
  // const { isTrial = false, trialEndDate } = page;
  // const trialValid = isTrial && new Date(trialEndDate) >= new Date();
  if (valid) {
    return <WrappedComponent {...props} />;
  } else {
    return content;
  }
};

export const withBusinessOnlyShow = withBusiness(
  "Upgrade to business tier enable this feature."
);

export const withBusinessElseNull = withBusiness(null);

export const withBusinessOnly = compose(withBusinessOnlyShow);
export const withBusinessOnlyElseHide = compose(withBusinessElseNull);

export const withEnterpriseOnly = compose(defineTier(ENTERPRISE));
//withBusinessOnlyShow
