import React, { useState, useCallback, useEffect } from "react";
import {
  useListContext,
  useNotify,
  useDelete,
  useUnselectAll,
  useRefresh,
} from "react-admin";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  Grid,
  TextField,
} from "@mui/material";
import useSWR from "swr";

import { uniqueByValue } from "../../helpers/methods";
import { useFBProfile } from "../DataHooks/fbPageProfile";
import { fetchWithFBToken } from "../Common/swrMiddlewares";
import { useMimicFBComment } from "../LiveWindow/sharedMethods";
import { useCustomAllRecords } from "../Common/useCustomAllRecords";

const BulkConvertToOrders = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll("waitlists");
  const [deleteOne, { isLoading: isDeleting, error: errorDelete }] =
    useDelete();

  const { data: FBdata, error } = useFBProfile();
  const { selectedIds } = useListContext();
  const allRecords = useCustomAllRecords();

  const waitlists = allRecords.filter((record) =>
    selectedIds.includes(record._id)
  );

  const waitlistsKeywords = waitlists.map((w) => w.key).filter(uniqueByValue);
  const [openDialog, setOpenDialog] = useState(false);
  const [useWaitlistKeyword, setUseWaitlistKeyword] = useState(true);
  const [failedRequest, setFailedRequest] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("pending");
  const [manualKeyword, setManualKeyword] = useState("");

  const initialUrl =
    FBdata && FBdata.id
      ? `${process.env.REACT_APP_GRAPH_API_URL}/${FBdata.id}/posts?limit=20&fields=id,created_time,is_hidden,is_published,message`
      : null;
  const { trigger: postOrders } = useMimicFBComment(undefined, {
    onError: () => {},
  });
  const { data: postData, error: postError } = useSWR(
    initialUrl ? initialUrl : null,
    { use: [fetchWithFBToken], revalidateOnFocus: false }
  );

  const handleClose = useCallback(() => {
    unselectAll();
    setOpenDialog(false);
    setFailedRequest([]);
    setStatus("pending");
    refresh();
  }, [refresh, unselectAll]);

  const handleStart = useCallback(async () => {
    const post = postData && postData.data && postData.data[0].id; //get a real post id
    setLoading(true);
    await Promise.all(
      waitlists.map(async (w) => {
        const payload = {
          data: {
            orders: [
              {
                originalComment: "original",
                newMessage: useWaitlistKeyword
                  ? `${w.key} + ${w.quantity}`
                  : `${manualKeyword} + ${w.quantity}`,
                psid: w.contact.psid,
                customerName: w.contact.name,
                commentId: "", //leave commentId empty
                parentId: post,
                _useKeyword: true, //a flag for nodered to filter
              },
            ],
          },
        };
        try {
          await postOrders(payload);
          //TODO: maybe send message using OTN token
          await deleteOne("waitlists", { id: w.id, previousData: w });
        } catch (e) {
          setFailedRequest((prev) => [...prev, w]);
          return;
        }
      })
    );
    setLoading(false);
    setStatus("completed");
  }, [
    postData,
    waitlists,
    useWaitlistKeyword,
    manualKeyword,
    postOrders,
    deleteOne,
  ]);

  //to nofity success
  useEffect(() => {
    if (!loading && status === "completed") {
      if (failedRequest.length === 0) {
        notify(
          `Successful convert all waitlists, you shall see them in orders shortly`,
          {
            type: "info",
          }
        );
      } else {
        console.log(failedRequest);
      }
    }
  }, [failedRequest, loading, notify, status]);

  return (
    <>
      <Button onClick={() => setOpenDialog(true)}>Convert</Button>
      <Dialog
        open={openDialog}
        fullWidth
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Convert Waitlists into Orders</DialogTitle>
        <DialogContent>
          <p>
            Please ensure that the keyword used has sufficient stock, is active,
            and matches the minimum and maximum quantity requirements. The
            system will treat the waitlist as comments.
          </p>
          <Grid
            component="label"
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item>Use keyword from: </Grid>
            <Grid item>Manual Assign</Grid>
            <Grid item>
              <Switch
                checked={useWaitlistKeyword}
                onChange={() => setUseWaitlistKeyword((prev) => !prev)}
              />
            </Grid>
            <Grid item>Waitlist</Grid>
          </Grid>
          {useWaitlistKeyword && (
            <>
              <p>Please make sure the following keywords are valid</p>
              <ul>
                {waitlistsKeywords.map((k, index) => (
                  <li key={`${k}-${index}`}>{k}</li>
                ))}
              </ul>
            </>
          )}
          {!useWaitlistKeyword && (
            <>
              <p>Please make sure the selected keyword is valid</p>
              <TextField
                value={manualKeyword}
                onChange={(e) => setManualKeyword(e.target.value)}
                label="Keyword"
              />
              <ul>
                {waitlists.map((w) => (
                  <li key={w._id}>
                    {w.contact.name} - {w.quantity}
                  </li>
                ))}
              </ul>
            </>
          )}
          {failedRequest.length > 0 && (
            <>
              <p style={{ color: "red" }}>
                The following requests failed, please retry later
              </p>
              <ul>
                {failedRequest.map((w) => (
                  <li key={w.id}>
                    {w.contact.name} - {w.key}
                  </li>
                ))}
              </ul>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleStart}
            variant="contained"
            color="primary"
            autoFocus
            disabled={
              loading ||
              failedRequest.length > 0 ||
              status === "completed" ||
              (!useWaitlistKeyword && !manualKeyword.trim())
            }
          >
            Start
          </Button>
          <Button
            variant="contained"
            onClick={handleClose}
            color="secondary"
            disabled={loading || isDeleting}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BulkConvertToOrders;
