import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@mui/styles";
import Avatar from "@mui/material/Avatar";

const styles = {
  row: {
    display: "flex",
    justifyContent: "center",
  },
  avatar: {
    margin: 10,
  },
  bigAvatar: {
    width: 60,
    height: 60,
  },
};

function ImageAvatars(props) {
  const { classes, src, alt, source, record } = props;
  return (
    <div className={classes.row}>
      {/* <Avatar alt="Remy Sharp" src={src} className={classes.avatar} /> */}
      <Avatar
        alt={alt}
        src={
          source && record
            ? `${source}`.split(".").reduce((o, i) => o[i], record)
            : src
        }
        className={classNames(classes.avatar, classes.bigAvatar)}
      />
    </div>
  );
}

ImageAvatars.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ImageAvatars);
