import React, { useEffect } from "react";
import { BooleanInput, required, SelectInput } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { useShippingMethod } from "../components/useShippingMethod";
// import NumberInputNoWheel from "../components/Common/NumberInputNoWheel";
// import CustomSelectInput from "../components/Common/CustomSelectInput";
import Grid from "@mui/material/Grid";
import DecimalInput from "../components/Common/DecimalInput";

const Shipping = (props) => {
  const watchShippingOption = useWatch({ name: "shippingOption" });
  const [shippingOptions] = useShippingMethod({ showAll: true });
  const { setValue } = useFormContext();

  useEffect(() => {
    //set shipping cost to zero when change shipping option
    setValue("shippingCost", 0);
  }, [setValue, watchShippingOption]);

  return (
    <span>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {/* <CustomSelectInput
            source="shippingOption"
            choices={shippingOptions}
            label="Shipping Option"
            sx={{ minWidth: 100 }}
            onChange={() => {
              setValue("shippingCost", 0);
            }}
          /> */}
          <SelectInput
            source="shippingOption"
            choices={shippingOptions}
            label="Shipping Option"
            sx={{ minWidth: 100 }}
          />
        </Grid>
        <Grid item xs={4}>
          <DecimalInput
            source="shippingCost"
            defaultValue={0}
            validate={required()}
            parse={(v) => Number(v)}
          />
        </Grid>
      </Grid>
      <span>
        Shipping fee will <b>not be auto-calculated</b> here
      </span>
      <br />
      <BooleanInput
        label="Override shipping cost; 锁定邮费"
        source="overrideShippingCost"
      />
    </span>
  );
};

export default Shipping;
