import { stripSpecialChars } from "../../helpers/stripSpecialChars";

export const checkStock = (v, record) => {
  return v < record.stockLeft ? "Stock must larger than stockLeft" : undefined;
};

export const stripSpecialtoUpper = (v) => {
  const v1 = stripSpecialChars(v);
  return v1.toUpperCase();
};

export const DISCOUNT_TYPE_VALUE = "value";
export const DISCOUNT_TYPE_PERCENTAGE = "percentage";

export const discountTypes = [
  DISCOUNT_TYPE_VALUE,
  DISCOUNT_TYPE_PERCENTAGE,
].map((v) => ({ id: v, name: v }));
