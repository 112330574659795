import React, { useState, useCallback } from "react";
import { useNotify, useListContext } from "ra-core";
import { useUnselectAll } from "react-admin";
import { useProfile, withProfile } from "../DataHooks/useProfile";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getFormData } from "../../helpers/methods";
import { fetchHandleError } from "../../helpers/fetchHandleError";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import HelpIcon from "@mui/icons-material/Help";
import Loader from "../Loader";
import { useFbLiveVideo } from "../DataHooks/useFbLiveVideo";
import { useCustomAllRecords } from "../Common/useCustomAllRecords";

const BulkPinCommentTemplate = (props) => {
  const notify = useNotify();
  const { resource, selectedIds } = useListContext();
  const allRecords = useCustomAllRecords();
  const unselectAll = useUnselectAll(resource);
  const { profile } = useProfile();
  const { keywordTemplate, params } = profile;
  const [displayText, setDisplayText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const liveVideo = useFbLiveVideo();
  const [loading, setLoading] = useState(false);
  const [useKeywordDescription, setUseKeywordDecription] = useState(true);

  const [addTimeStamp, setAddTimeStamp] = React.useState(false);
  //use updatedtime because you can create video before publishing it
  const liveStartedTime = liveVideo ? new Date(liveVideo.updated_time) : null;
  const timeStamp = new Date(Date.now() - liveStartedTime)
    .toISOString()
    .substr(11, 8);

  const handleClick = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const onTextChange = React.useCallback((event) => {
    setDisplayText(event.target.value);
  }, []);

  const handleDialogClose = React.useCallback(() => {
    unselectAll();
    setIsOpen(false);
  }, [unselectAll, setIsOpen]);

  const handlePostComment = React.useCallback(async () => {
    setLoading(true);
    setIsOpen(false);
    const data = getFormData({
      message: (addTimeStamp ? `${timeStamp} ` : "") + displayText,
    });
    await fetch(
      `${process.env.REACT_APP_GRAPH_API_URL}/${liveVideo.id}/comments?access_token=${profile.page.token}`,
      {
        method: "post",
        body: data,
      }
    )
      .then(fetchHandleError)
      .then((resp) => {
        notify("Post to live successful");
      })
      .catch((e) => {
        alert("Error posting comment");
      });
    setLoading(false);
    setIsOpen(true);
  }, [
    displayText,
    liveVideo,
    profile.page.token,
    notify,
    setLoading,
    addTimeStamp,
    timeStamp,
  ]);

  const showMsg = React.useCallback(() => {
    notify("Copied successful");
    handleDialogClose();
  }, [handleDialogClose, notify]);

  React.useEffect(() => {
    if (keywordTemplate && isOpen) {
      const results = selectedIds
        .map((id) => {
          return allRecords.find((singleData) => {
            return singleData.id === id;
          });
        })
        .map((keyword, index) => {
          const { key = "", description = "", price = "" } = keyword;

          if (index === 0) {
            return keywordTemplate
              .replace(/{{key}}/g, key)
              .replace(/{{description}}/g, description)
              .replace(/{{currencyCode}}/g, params.currency)
              .replace(/{{price}}/g, price);
          } else {
            return useKeywordDescription
              ? `${key}+? for ${description} @${params.currency} ${price}`
              : `${key}+? @${params.currency} ${price}`;
          }
        });
      setDisplayText(results.join("\n"));
    }
  }, [
    allRecords,
    isOpen,
    keywordTemplate,
    params,
    selectedIds,
    useKeywordDescription,
  ]);

  return (
    <>
      <Button onClick={handleClick}>
        <FileCopyIcon />
        Comment templ
      </Button>
      <Dialog
        open={isOpen}
        aria-labelledby="responsive-dialog-title"
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle>{`Bulk Pin Comment Template`}</DialogTitle>
        <DialogContent>
          {liveVideo && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={addTimeStamp}
                    onChange={() => {
                      setAddTimeStamp(!addTimeStamp);
                    }}
                    name="Add TimeStamp"
                  />
                }
                label="Add TimeStamp"
              />
              <Tooltip
                title={`Adding timestamp can help your customers to jump to the selected timestamp during live replay`}
              >
                <HelpIcon color="primary" fontSize="small" />
              </Tooltip>
              <br />
              <TextField value={timeStamp} disabled={true}></TextField>
            </>
          )}
          <TextField
            fullWidth={true}
            multiline
            value={displayText}
            onChange={onTextChange}
          ></TextField>
        </DialogContent>
        <DialogActions>
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={useKeywordDescription}
                  onChange={() => {
                    setUseKeywordDecription((prev) => !prev);
                  }}
                  name="Use Keyword Description"
                />
              }
              label={
                <>
                  <span>Use Keyword Description</span>
                  <Tooltip title={`Toggle description display`}>
                    <HelpIcon color="primary" fontSize="small" />
                  </Tooltip>
                </>
              }
            />
          </>
          {liveVideo && (
            <Button
              variant="contained"
              onClick={handlePostComment}
              color="primary"
              autoFocus
            >
              Post to live
            </Button>
          )}
          <CopyToClipboard
            text={(addTimeStamp ? `${timeStamp} ` : "") + displayText}
            onCopy={showMsg}
          >
            <Button variant="contained" color="primary" autoFocus>
              Copy
            </Button>
          </CopyToClipboard>
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Loader open={loading} />
    </>
  );
};
export default withProfile(BulkPinCommentTemplate);
