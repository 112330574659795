import React from "react";
import { SaveButton, Toolbar, useRedirect } from "react-admin";

const transformSave = ({ contact, ...otherProps }) => {
  if (!!contact) {
    return { contact, ...otherProps };
  } else {
    return { ...otherProps };
  }
};

const OrderToolbar = (props) => {
  const redirectTo = useRedirect();

  return (
    <Toolbar {...props}>
      <SaveButton
        type="button"
        label={`Save & show`}
        // redirect="show"
        mutationOptions={{
          onSuccess: (record) => {
            redirectTo("show", "orders", record.id, record);
          },
        }}
        // submitOnEnter={true}
        alwaysEnable
        transform={transformSave}
      />
      <SaveButton
        type="button"
        label={`Save & list`}
        // redirect="list"
        mutationOptions={{
          onSuccess: () => {
            redirectTo("list", "orders");
          },
        }}
        // submitOnEnter={false}
        alwaysEnable
        variant="outlined"
        transform={transformSave}
      />
    </Toolbar>
  );
};

export default OrderToolbar;
