import React, { useState } from "react";
import { fetchHandleError } from "../../helpers/fetchHandleError";
import { useGetToken } from "./useGetToken";
import { useThrottleCall } from "./useThrottleCall";

export const useRemovePrefix = (prefix = "[PO]") => {
  const [faileds, setFaileds] = useState([]);

  const { setProcess, requests, processing, status, terminate } =
    useThrottleCall();
  const token = useGetToken();

  const removePrefix = React.useCallback(
    async (prefixItems) => {
      const requestCalls = prefixItems.map((item) => {
        const payload = {
          description: item.description.replace(prefix, ""),
          // description: item.description,
        };
        return () =>
          fetch(`${process.env.REACT_APP_CMS_HOST}/orderitems/${item.id}`, {
            method: "PUT",
            body: JSON.stringify(payload),
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-type": "application/json; charset=UTF-8",
            },
          })
            .then(fetchHandleError)
            .catch((e) => {
              setFaileds((prev) => [...prev, item]);
            });
      });
      setProcess(requestCalls);
    },
    [prefix, setProcess, token]
  );
  return { removePrefix, requests, processing, status, terminate, faileds };
};
