// import React, { useState, useEffect } from "react";
// import { useProfile } from "./useProfile";
import { useProfile } from "./DataHooks/useProfile";

export const useProfileParams = () => {
  const { profile, isLoading, isError } = useProfile();

  if (isLoading || isError) {
    return [[]];
  }
  return [profile.params];
};

// export const useProfileParams = () => {
//   const [res] = useProfile();
//   if (res.fetching || res.error) {
//     return [[]];
//   } else if (res && res.data && res.data.profile && res.data.profile.params) {
//     return [res.data.profile.params];
//   }
// };

//competitor flag
export const useIsComptr = () => {
  const [params] = useProfileParams();
  return params && params.isComptr;
};
