import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  ImageField,
  BooleanField,
  useRecordContext,
  useRefresh,
} from "react-admin";
import Rating from "@mui/material/Rating";

import ContactShowActions from "./ContactShowActions";
// import OrderList from "./OrderList";
import OrderList2 from "./OrderList2";
import ChannelField from "./ChannelField";
import { ContactCredit } from "./ContactCredit";
import {
  useContactIgProfile,
  useUpdateContact,
  useContactFbProfile,
} from "../DataHooks/useGetFbProfileImage";
import { CONTACT_CHANNEL_INSTAGRAM } from "../Constant";

const ContactRating = () => {
  const record = useRecordContext();
  return <Rating value={record.rating / 2} precision={0.5} readOnly />;
};

const CustomImageField = (props) => {
  const record = useRecordContext();
  const refresh = useRefresh();

  const isInstagram =
    record && record.channel && record.channel === CONTACT_CHANNEL_INSTAGRAM;
  const isFacebook = !isInstagram;

  const { trigger, isMutating, data } = useUpdateContact(record.id, {
    dedupingInterval: 15 * 60 * 1000,
    onSuccess: () => {
      refresh();
    },
  });

  const fbProfile = useContactFbProfile(isFacebook ? record.psid : "");

  const { data: igProfile } = useContactIgProfile(
    isInstagram ? record.psid : undefined,
    {
      onSuccess: (data) => {
        if (
          record.name !== data.username ||
          record.profilePic !== data.profile_pic
        ) {
          trigger({
            profilePic: data.profile_pic,
            name: data.username,
          });
        }
      },
    }
  );

  React.useEffect(() => {
    if (fbProfile) {
      if (
        record.name !== fbProfile.name ||
        record.profilePic !== fbProfile.profile_pic
      ) {
        trigger({ profilePic: fbProfile.profile_pic, name: fbProfile.name });
      }
    }
  }, [fbProfile]);

  // React.useEffect(() => {
  //   if (igProfile && !isMutating) {
  //     if (
  //       record.name !== igProfile.username ||
  //       record.profilePic !== igProfile.profile_pic
  //     ) {
  //       trigger({
  //         profilePic: igProfile.profile_pic,
  //         name: igProfile.username,
  //       });
  //     }
  //   }
  // }, [igProfile, isMutating]);

  return (
    <ImageField
      {...props}
      source="profilePic"
      title="profilePic"
      sx={{
        "& .RaImageField-image": {
          width: 140,
          height: 140,
        },
      }}
    />
  );
};

const ContactShow = (props) => {
  return (
    <Show actions={<ContactShowActions />} title="Contact" {...props}>
      <SimpleShowLayout>
        <CustomImageField source="profilePic" />
        <TextField source="name" />
        <BooleanField source="isBlacklisted" />
        <TextField source="remarks" />
        <TextField source="phoneNumber" />
        <ChannelField source="channel" label="Channel" />
        <ContactRating label={`Rating`} />
        <ContactCredit label="Available Credit" />
        <TextField source="psid" />
        <OrderList2 />
      </SimpleShowLayout>
    </Show>
  );
};

export default ContactShow;
