import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  // EditButton,
  // RichTextField,
  // SelectField,
  ArrayField,
  Datagrid,
  BooleanField,
  NumberField,
} from "react-admin";
import CustomDateField from "../Common/CustomDateField";
// import { useAvailableCurrencies } from "../useAvailableCurrencies";

const orderRowClick = (id, resource, record) => {
  return `/orders/${record._id}/show`;
};

const VoucherShow = (props) => {
  // const { isMultiCurrency } = useAvailableCurrencies();

  return (
    <Show title="Show voucher" {...props}>
      <SimpleShowLayout>
        <TextField source="code" />
        <TextField source="discountType" />
        <NumberField source="value" />
        <NumberField source="maxDiscount" />
        <NumberField source="minPurchaseAmount" />
        <NumberField source="maxUsage" />
        <NumberField source="maxUsagePerCustomer" />
        <CustomDateField source="expiredAt" showTime />
        <CustomDateField source="updatedAt" showTime />
        <BooleanField source="isActive" />
        <BooleanField
          source="discountAfterShipping"
          label="Discount Include Shipping"
        />
        <ArrayField source="orders">
          <Datagrid rowClick={orderRowClick}>
            <TextField source="customerName" />
            <TextField source="orderNumber" />
            <TextField source="status" />
            <CustomDateField source="createdAt" showTime />
            <CustomDateField source="updatedAt" showTime />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

export default VoucherShow;
