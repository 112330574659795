import { isString } from "../../helpers/methods";
import { useGetToken } from "../DataHooks/useGetToken";
import { useProfile } from "../DataHooks/useProfile";

const whitelistUrls = [
  `${process.env.REACT_APP_CMS_HOST}/pages/login`,
  `${process.env.REACT_APP_FB_LOGIN_CMS_HOST}/auth/facebook/callback`,
  `${process.env.REACT_APP_GRAPH_API_URL}`,
];

export const fetchWithBearerToken = (useSWRNext) => {
  return (key, fetcher, config) => {
    const isWhitelisted =
      key && isString(key) && whitelistUrls.find((v) => key.indexOf(v) > -1);

    const isFbRequest =
      key &&
      isString(key) &&
      key.indexOf(process.env.REACT_APP_GRAPH_API_URL) > -1;

    const token = useGetToken();

    if (isWhitelisted) {
      return useSWRNext(key, fetcher, config);
    }

    const extendedFetcher = (url, requestConfig) => {
      return fetcher(url, {
        ...requestConfig,
        headers: {
          ...(requestConfig && requestConfig.headers && requestConfig.headers),
          ...(!isFbRequest &&
            token && {
              Authorization: `Bearer ${token}`,
            }),
        },
      });
    };

    return useSWRNext(token ? key : null, extendedFetcher, config);
  };
};

export const fetchWithFBToken = (useSWRNext) => {
  return (key, fetcher, config) => {
    const { profile } = useProfile();

    const extendedFetcher = (url, requestConfig) => {
      const headers =
        requestConfig && requestConfig.headers && requestConfig.headers;

      const newHeaders = headers
        ? Object.keys(headers)
            .filter((k) => k.toLowerCase() !== "authorization")
            .reduce((acc, cur) => {
              acc[cur] = headers[cur];
              return acc;
            }, {})
        : {};
      return fetcher(`${url}&access_token=${profile.page.token}`, {
        ...requestConfig,
        headers: {
          ...newHeaders,
        },
      });
    };

    // Execute the hook with the new fetcher.
    return useSWRNext(key && profile ? key : null, extendedFetcher, config);
  };
};
