import React from "react";
import { useProfile } from "./DataHooks/useProfile";

import { NOW, LATER, SELF_COLLECT } from "./Constant";

const defaultOptions = [
  {
    id: "",
    name: "None",
    cost: 0,
  },
  { id: NOW, name: "Deliver now/立即出货", cost: 0 },
  { id: LATER, name: "Later/迟些发", cost: 0 },
  { id: SELF_COLLECT, name: "Self collect/自提/面交", cost: 0 },
];

export const useShippingMethod = (props = {}) => {
  const { showAll = false } = props;
  const { profile } = useProfile();

  if (
    !!profile &&
    profile.shippingMethods &&
    profile.shippingMethods.length > 0
  ) {
    const methods = profile.shippingMethods.filter(
      (m) => showAll || (!showAll && !m.hidden)
    );
    return [methods];
  } else {
    return [defaultOptions];
  }
};
