import React from "react";
import { useRecordContext } from "react-admin";

import { COMPLETED } from "./Constant";
import ExportMenu from "./ExportMenu";

const DownloadField = (props) => {
  const record = useRecordContext();

  if (record && record.status && record.status === COMPLETED) {
    return <ExportMenu />;
  }
  return null;
};

export default DownloadField;
