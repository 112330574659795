import React from "react";
// import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useIsBusinessPlan } from "../../helpers/subscriptionPlan";
import { withTooltipBusinessTier } from "../../helpers/withTooltipBusinessTier";

import { useIsComptr } from "../useProfileParams";

import BatchSend from "../BatchSend";

import KbLinkButton from "../Learn/KbLinkButton";

const BusinessButton = withTooltipBusinessTier(Button);

const SpecialTools = (props) => {
  const isBusinessPlan = useIsBusinessPlan();
  const isComptr = useIsComptr();

  return (
    <div>
      <Grid container spacing={3} justify="flex-start">
        {/* {!isComptr && (
          <Grid item xs={6} sm={3}>
            <BatchSend />
            <KbLinkButton
              url={`https://learn.boxify.io/kb/25-09-20-upgradetion-of-fb-button-mass-fb-send/`}
            />
          </Grid>
        )} */}
        {!isComptr && (
          <Grid item xs={6} sm={3}>
            <Button to="/live-window" variant="outlined" component={Link}>
              Live window
            </Button>
            <KbLinkButton
              url={`https://learn.boxify.io/kb/the-functions-of-the-live-window/`}
            />
          </Grid>
        )}

        <Grid item xs={6} sm={3}>
          <BusinessButton
            color="primary"
            disabled={!isBusinessPlan}
            to="/bulk-upload-tracking-number"
            variant="outlined"
            component={Link}
          >
            Bulk upload tracking #
          </BusinessButton>
          <KbLinkButton
            url={`https://learn.boxify.io/kb/5-08-20-bulk-upload-tracking-number/`}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <BusinessButton
            color="primary"
            disabled={!isBusinessPlan}
            to="/bulk-upload-keywords"
            variant="outlined"
            component={Link}
          >
            Bulk upload keywords
          </BusinessButton>
          <KbLinkButton
            url={`https://learn.boxify.io/kb/12-8-2020-bulk-upload-keywords/`}
          />
        </Grid>
        {!isComptr && (
          <Grid item xs={6} sm={3}>
            <BusinessButton
              color="primary"
              disabled={!isBusinessPlan}
              to="/combine-orders"
              variant="outlined"
              component={Link}
            >
              Combine Orders
            </BusinessButton>
            <KbLinkButton
              url={`https://learn.boxify.io/kb/combine-orders-into-one/`}
            />
          </Grid>
        )}
        <Grid item xs={6} sm={3}>
          <Button
            color="primary"
            to="/marketing-messages"
            variant="outlined"
            component={Link}
          >
            Marketing Messages
          </Button>
        </Grid>
        {!isComptr && (
          <Grid item xs={6} sm={3}>
            <BusinessButton
              color="primary"
              disabled={!isBusinessPlan}
              to="/ig-comment-manager"
              variant="outlined"
              component={Link}
            >
              IG comment manager
            </BusinessButton>
          </Grid>
        )}
        <Grid item xs={6} sm={3}>
          <BusinessButton
            color="primary"
            disabled={!isBusinessPlan}
            to="/fb-comment-manager"
            variant="outlined"
            component={Link}
          >
            FB comment manager
          </BusinessButton>
        </Grid>
        <Grid item xs={6} sm={3}>
          <BusinessButton
            color="primary"
            disabled={!isBusinessPlan}
            to="/fb-posts-manager"
            variant="outlined"
            component={Link}
          >
            FB posts manager
          </BusinessButton>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Button
            color="primary"
            to="/fb-conversations"
            variant="outlined"
            component={Link}
          >
            FB conversation manager
          </Button>
        </Grid>
        {!isComptr && (
          <Grid item xs={6} sm={3}>
            <Button
              color="primary"
              to="/ig-conversations"
              variant="outlined"
              component={Link}
            >
              IG conversation manager
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default SpecialTools;
