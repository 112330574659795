import React, { useState } from "react";
import { useController } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

// import { DownshiftTag } from "../DownshiftInput";
import DownshiftTag from "../Tags/DownshiftInputTag";
import useDebounce from "../UseDebounce";
import { useProfile } from "../DataHooks/useProfile";
import { escapeRegExp } from "../../helpers/methods";

import useSWR from "swr";
import { useFormContext, useWatch } from "react-hook-form";
import uniqBy from "lodash/uniqBy";

// const BoundedTextField = (props) => {
//   const [open, setOpen] = React.useState(false);

//   // const tagNameInput = useController({ name: "tagName" });
//   return (
//     <DownshiftTag
//       label="Tag"
//       // component={DownshiftKeyword}
//       placeholder="A001"
//       validate={(v) => (v ? undefined : new Error("Tag is required"))}
//     ></DownshiftTag>
//   );
//   return (
//     <>
//       {/* <Field
//         {...tagNameInput.field}
//         name="tag"
//         label="Tag"
//         source="tags"
//         component={DownshiftTag}
//         validate={(v) => (v ? undefined : new Error("Tag is required"))}
//       /> */}
//     </>
//   );
// };

// export default BoundedTextField;

const AutoCompleteTag = (props) => {
  const { setValue } = useFormContext();
  const tags = useWatch({ name: "tags" });
  const [open, setOpen] = React.useState(false);
  // const [options, setOptions] = React.useState([]);
  const { profile } = useProfile();

  // const loading = open && options.length === 0;
  const [tagName, setTagName] = useState("");
  const debouncedSearchTerm = useDebounce(tagName, 800);

  const target =
    profile && profile.page && profile.page._id && debouncedSearchTerm
      ? `${
          process.env.REACT_APP_CMS_HOST
        }/tags/?label_contains=${encodeURIComponent(
          escapeRegExp(debouncedSearchTerm)
        )}&page=${profile.page._id}&_sort=updatedAt:DESC&_start=0&_limit=20`
      : null;
  const { data, error, isValidating } = useSWR(target, {});

  // const tags = data ? data : [];

  // React.useEffect(() => {
  //   let active = true;

  //   if (!loading) {
  //     return undefined;
  //   }

  //   (async () => {
  //     await sleep(1e3); // For demo purposes.

  //     if (active) {
  //       setOptions([...topFilms]);
  //     }
  //   })();

  //   return () => {
  //     active = false;
  //   };
  // }, [loading]);

  // React.useEffect(() => {
  //   if (!open) {
  //     setOptions([]);
  //   }
  // }, [open]);

  return (
    <Autocomplete
      fullWidth
      // sx={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={(e) => setTagName(e.target.value)}
      onChange={(event, newValue) => {
        if (newValue) {
          setValue(
            "tags",
            uniqBy([...tags, newValue], ({ _id }) => _id),
            { shouldDirty: true }
          );
        }
      }}
      isOptionEqualToValue={(option, value) => option._id === value._id}
      getOptionLabel={(option) => option.label}
      options={data ? data : []}
      loading={isValidating}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Tag label"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isValidating ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default AutoCompleteTag;
