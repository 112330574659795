import { useEffect, useState } from "react";
import uniqBy from "lodash/uniqBy";
import { useListContext } from "react-admin";

export const useCustomAllRecords = () => {
  const [allRecords, setAllRecords] = useState([]);

  const { data } = useListContext();
  useEffect(() => {
    //React admin 4.3.2 useListContext's data only returns the viewing page data hence this is to aggregate them all
    //spreading sequence is important so it keeps the latest and remove oldest
    setAllRecords((v) => uniqBy([...data, ...v], ({ _id }) => _id));
  }, [data]);

  return allRecords;
};
