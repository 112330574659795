import { unparse as convertToCSV } from "papaparse/papaparse.min";
import { downloadCSVWithBom } from "../../helpers/methods";

export const exporter = (contacts) => {
  const contactsForExport = contacts.map((contact) => {
    const { __v, _id, page, createdAt, updatedAt, ...contactForExport } =
      contact; // omit backlinks and author

    return {
      ...contactForExport,
      createdAt: new Date(createdAt).toLocaleString(),
      updatedAt: new Date(updatedAt).toLocaleString(),
    };
  });
  const csv = convertToCSV({
    data: contactsForExport,
    fields: [
      "id",
      "psid",
      "name",
      "phoneNumber",
      "isBlacklisted",
      "email",
      "rating",
      "remarks",
      "createdAt",
      "updatedAt",
    ],
  });
  downloadCSVWithBom(csv, `contacts_${new Date().toLocaleDateString()}`); // download as 'vouchers.csv` file
};
