import React from "react";
import Decimal from "decimal.js-light";

import { ORDER_ITEM_CANCELLED } from "../components/Constant";
import { useRecordContext } from "react-admin";

const OrderTotalWeightOnOrderItems = (props) => {
  const record = useRecordContext();
  const { orderItems = [] } = record;
  const totalWeight = calculateWeight(orderItems);
  return <span>{totalWeight}</span>;
};

export const calculateWeight = (orderItems = []) => {
  const totalWeight = orderItems
    .filter((i) => i.status !== ORDER_ITEM_CANCELLED)
    .reduce((acc, cur) => {
      return acc.plus(cur.weight);
    }, new Decimal(0))
    .toFixed(2, Decimal.ROUND_HALF_UP);
  return totalWeight;
};
export default OrderTotalWeightOnOrderItems;
