import React from "react";
import { TextField, useRecordContext } from "react-admin";
import { CONTACT_CHANNEL_FACEBOOK } from "../Constant";

export const withDefaultChannel = (WrapComp) => (props) => {
  const record = useRecordContext();
  if (record.channel) {
    return <WrapComp {...props} record={record} />;
  }
  return (
    <WrapComp
      {...props}
      record={{ ...record, channel: CONTACT_CHANNEL_FACEBOOK }}
    />
  );
};

const ChannelField = withDefaultChannel(TextField);

export default ChannelField;
