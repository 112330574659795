import { uniq } from "lodash";
import useSWRInfinite from "swr/infinite";

const MAX_CONCURRENT = 100;

export const useGetKeywordsByIds = (ids = [], option) => {
  const uniqKeywordIds = uniq(ids);

  const getKey = (pageIndex, previousPageData) => {
    const startIndex = pageIndex * MAX_CONCURRENT;
    if (uniqKeywordIds.length > 0 && uniqKeywordIds.length > startIndex) {
      const str = uniqKeywordIds
        .slice(startIndex, startIndex + MAX_CONCURRENT)
        .map((id) => `_id_in=${id}`)
        .join("&");
      return `${process.env.REACT_APP_CMS_HOST}/keywords?${str}`;
    }
    return null;
  };

  // const { data, error, isLoading, isValidating, mutate, size, setSize } =
  return useSWRInfinite(getKey, {
    initialSize: Math.floor(uniqKeywordIds.length / MAX_CONCURRENT) + 1,
    revalidateOnFocus: false,
    revalidateFirstPage: false,
    ...option,
  });
};
