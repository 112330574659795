import React, { useState, useRef, useEffect } from "react";
import "whatwg-fetch";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
// import SendIcon from "@mui/icons-material/Send";
import PrintIcon from "@mui/icons-material/Print";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Badge from "@mui/material/Badge";
import Loadable from "react-loadable";
import CircularProgress from "@mui/material/CircularProgress";

// import Container from "@mui/material/Container";
// import { connect } from "react-redux";
import ReactToPrint, { useReactToPrint } from "react-to-print";

import {
  EditButton,
  useUpdate,
  useRefresh,
  useNotify,
  useRecordContext,
} from "react-admin";
// import Cookies from "../helpers/Cookies";
import DeliveryOrder from "./DeliveryOrder";
import ThermalReceipt from "./ThermalReceipt";
import Invoice from "./Invoice";
// import NinjaVanWaybillTemplate from "./NinjaVan/NinjaVanWaybillTemplate";
import { useProfile } from "./DataHooks/useProfile";

import CustomLinkButton from "../Order/CustomLinkButton";
import FbSend from "./Order/FbSend";
import { fetchHandleError } from "../helpers/fetchHandleError";
import ManualSendButton from "./Order/ManualSendButton";
import { useNinjaVanOptions } from "./NinjaVan/hooks";

import ConfirmationDialog from "./Order/ConfirmationDialog";
import { useIsBusinessPlan } from "../helpers/subscriptionPlan";
import { HiddenSpan, PrintDiv } from "./Common/StyledComponents";
import { useGetOrderItemsDetails } from "./DataHooks/useGetOrderItemsDetails";
import { useIsShowImageInDo } from "./DataHooks/useIsShowImageInDo";
// import { CANCELLED, SHIPPED } from "./Constant";
import { useIsOrderEditable } from "./DataHooks/useIsOrderEditable";

const LoadableNinjaVanSingleSubmit = Loadable({
  loader: () => import("./NinjaVan/SingleSubmit"),
  loading: () => "Loading",
});

const LoadableNinjaVanPrintAWBButton = Loadable({
  loader: () => import("./NinjaVan/PrintAWBButton"),
  loading: () => "Loading",
});

export const fetchSendMessenger = ({
  token,
  orderNumber,
  standardMessaging = true,
}) =>
  fetch(
    `${process.env.REACT_APP_CMS_HOST}/orders/${orderNumber}/notify-customer?standardMessaging=${standardMessaging}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  ).then(fetchHandleError);

const cardActionStyle = {
  zIndex: 2,
  display: "flex",
  padding: 0,
  flexWrap: "wrap",
  justifyContent: "flex-end",
};

const OrderShowActions = () => {
  const { profile, isLoading, isError } = useProfile();
  const currentResource = window.location.hash.split("/")[1]; //eg '#/activeorders/63105a226f426d017f50961d'
  const data = useRecordContext();
  const isOrderEditable = useIsOrderEditable();
  const ninjaVanOptions = useNinjaVanOptions(
    process.env.REACT_APP_NINJAVAN_ENV
  );
  const isBusinessPlan = useIsBusinessPlan();
  const showImageInDO = useIsShowImageInDo();

  if (isLoading || isError) {
    return null;
  }

  const { params: rawParams, logo } = profile;
  const params = { ...rawParams, showImageInDO };
  // const [disabled, setDisabled] = useState(false);

  const contactPhoneNumber = data && data.contact && data.contact.phoneNumber;
  const shippingPhoneNumber = data && data.shippingPhoneNumber;
  const whatsAppPhoneNumber = contactPhoneNumber
    ? contactPhoneNumber
    : shippingPhoneNumber;
  const psid = data && data.contact && data.contact.psid;

  return (
    <CardActions style={cardActionStyle}>
      <EditButton disabled={!isOrderEditable} resource={currentResource} />
      {/* Add your custom actions */}
      {params && params.hoeThyeForm && isBusinessPlan && (
        <CustomLinkButton record={data} />
      )}
      {data && ninjaVanOptions.length > 0 && (
        <>
          <LoadableNinjaVanSingleSubmit record={data} />
          <LoadableNinjaVanPrintAWBButton records={[data]} />
        </>
      )}
      {params && params.deliveryOrderUseInvoiceLabel && data && (
        <PrintDeliveryOrder useInvoice={true} data={data} resource={"orders"} />
      )}
      {data && <PrintDeliveryOrder data={data} resource={"orders"} />}
      {isBusinessPlan && params && params.enableThermalReceipt && data && (
        <PrintThermalReceipt data={data} resource={"orders"} />
      )}
      {data && data.contact && data.contact.canReply && <FbSend data={data} />}
      {data && data.contact && <ManualSendButton psid={psid} order={data} />}
      {whatsAppPhoneNumber && (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://wa.me/${whatsAppPhoneNumber}`}
        >
          <Badge color="secondary" badgeContent={`beta`}>
            <WhatsAppIcon />
          </Badge>
        </a>
      )}
    </CardActions>
  );
};

const PrintThermalReceipt = ({ data, resource }) => {
  const componentRefThermal = useRef();
  const { profile } = useProfile();
  const { params, logo } = profile;

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <Button color="secondary">
            <PrintIcon />
            Thermal
          </Button>
        )}
        content={() => componentRefThermal.current}
        documentTitle={`Thermal ${data.orderNumber} ${data.customerName}`
          .split(" ")
          .join("_")}
      />
      {data && (
        <HiddenSpan>
          <PrintDiv ref={componentRefThermal}>
            <div className="page-break"></div>
            <ThermalReceipt logo={logo} {...params} record={data} />
          </PrintDiv>
        </HiddenSpan>
      )}
    </>
  );
};

const PrintDeliveryOrder = ({ useInvoice = false, data, resource }) => {
  const componentRefDO = useRef();
  const componentRefINV = useRef();
  const isOrderEditable = useIsOrderEditable();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const { profile } = useProfile();
  const { params, logo } = profile;

  const [isPrinting, setIsPrinting] = useState(false);

  // We store the resolve Promise being used in `onBeforeGetContent` here
  const promiseResolveRef = useRef(null);

  const {
    data: orderItemsDetails,
    error: orderItemsDetailsError,
    isValidating,
  } = useGetOrderItemsDetails(isPrinting ? data.id : undefined);

  useEffect(() => {
    if (isPrinting && orderItemsDetails && !orderItemsDetailsError) {
      promiseResolveRef.current();
    }
  }, [isPrinting, orderItemsDetails, orderItemsDetailsError]);

  const refresh = useRefresh();
  const notify = useNotify();
  const [update, { loading, error }] = useUpdate(
    resource,
    { id: data._id, data: { deliveryOrderPrinted: true }, previousData: data },
    {
      onSuccess: () => {
        refresh();
        notify("Set delivery order printed successful", "info", {}, true);
      },
      onFailure: (error) =>
        notify("Set delivery order printed failed", "warning"),
      mutationMode: "pessimistic",
    }
  );
  const afterPrintHandler = React.useCallback(() => {
    setOpenConfirmDialog(true);
  }, [setOpenConfirmDialog]);

  const onOk = React.useCallback(() => {
    setOpenConfirmDialog(false);
    update();
  }, [update]);

  const onCancel = React.useCallback(() => {
    setOpenConfirmDialog(false);
  }, []);

  const handlePrint = useReactToPrint({
    content: () =>
      useInvoice ? componentRefINV.current : componentRefDO.current,
    documentTitle: `Order ${data.orderNumber} ${data.customerName}`
      .split(" ")
      .join("_"),
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
      });
    },
    onAfterPrint: () => {
      // Reset the Promise resolve so we can print again
      promiseResolveRef.current = null;
      setIsPrinting(false);
      if (isOrderEditable) {
        afterPrintHandler();
      }
    },
  });

  return (
    <>
      <Button color="secondary" onClick={handlePrint}>
        {!isValidating && <PrintIcon />}
        {isValidating && <CircularProgress size="1em" />}
        {data.deliveryOrderPrinted
          ? useInvoice
            ? `Inv (Already printed)`
            : `DO (Already printed)`
          : useInvoice
          ? "Inv"
          : `DO`}
      </Button>
      {data && orderItemsDetails && !useInvoice && (
        <HiddenSpan>
          <PrintDiv ref={componentRefDO}>
            <div className="page-break"></div>
            <DeliveryOrder
              logo={logo}
              {...params}
              record={{ ...data, orderItems: orderItemsDetails }}
            />
          </PrintDiv>
        </HiddenSpan>
      )}
      {data && orderItemsDetails && useInvoice && (
        <HiddenSpan>
          <PrintDiv ref={componentRefINV}>
            <div className="page-break"></div>
            <Invoice
              logo={logo}
              {...params}
              record={{ ...data, orderItems: orderItemsDetails }}
            />
          </PrintDiv>
        </HiddenSpan>
      )}
      <ConfirmationDialog
        title={`Confirmation`}
        content={
          `Set order to printed ?` +
          (useInvoice ? `(DO and Inv sharing the same flag)` : "")
        }
        open={openConfirmDialog}
        onCancel={onCancel}
        onOk={onOk}
      />
    </>
  );
};

export default OrderShowActions;
