import React from "react";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import { makeStyles } from "@mui/styles";

import Box from "@mui/material/Box";
import Decimal from "decimal.js-light";

import {
  printDate,
  getSum,
  printMonthDay,
  isNaNOrNull,
  getTotalWeight,
} from "../helpers/methods";
import { useShippingMethod } from "./useShippingMethod";
import AmountWithTaxField from "../Order/AmountWithTaxField";
import DeliveryOrderNumber from "./DeliveryOrderNumber";
import {
  DiscountValue,
  TotalInclShippingWithDiscount,
} from "./Order/DeliveryOrderFields";
import "./Order/BulkPrintDeliveryOrder/pagebreak.css";
import { replaceImageSource } from "../helpers/withReplaceImageSource";
import ItemDescriptionWithImageForDo from "../Order/ItemDescriptionWithImageForDo";
import { getCreditPayment } from "./Credit/hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

const thickWidth = { width: "70%" };

const CompanyLogo = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Avatar {...props} className={classes.large} />
    </div>
  );
};

const ShippingOptionDeliveryOrderField = ({ shippingOption }) => {
  const [shippingOptions] = useShippingMethod({ showAll: true });
  const found = shippingOptions.find((opt) => opt.id === shippingOption);
  if (!found) {
    return shippingOption;
  }
  const { name = "" } = found;
  return <span>{name}</span>;
};

const comboRegex = /(.*)x(\d{1,}$)/i;
const getUnitPriceFromSubtotal = ({ price, quantity = "", ...others }) => {
  const unit = (price * 100) / parseInt(quantity) / 100;
  if (!isNaN(unit)) {
    //it has proper quantity
    return {
      unitPrice: unit.toFixed(2),
      price,
      quantity,
      ...others,
    };
  } else {
    return {
      unitPrice: "",
      price,
      quantity,
      ...others,
    };
  }
};

class ComponentToPrint extends React.Component {
  render() {
    const {
      record,
      companyName = "",
      companyRegistration = "",
      companyAddress = "",
      headerLine1 = "",
      headerLine2 = "",
      headerLine3 = "",
      logo = "",
      // deliveryOrderWithPrice = false,
      deliveryOrderShowTotalWeight = false,
      deliveryOrderWithLegacyStyle = false,
      // deliveryOrderDisableUnitPrice = false,
      sortItemsAlphabetically = false,
      currency = "",
      // deliveryOrderUseInvoiceLabel = false,
      invoiceFooter = "",
      showImageInDO = false,
    } = this.props;
    const {
      orderItems = [],
      orderNumber,
      customerName,
      shippingAddress1 = "",
      shippingAddress2 = "",
      shippingCity = "",
      shippingName = "",
      shippingPhoneNumber = "",
      shippingPostcode = "",
      shippingState = "",
      shippingOption = "",
      shippingCost: shippingCostStr = "",
      description = "",
      notes = "",
      trackingNumber,
      createdAt,
      invoiceNumber,
      voucher = {},
      tax,
      payments = [],
    } = record;
    const shippingCost = isNaNOrNull(shippingCostStr)
      ? ""
      : new Decimal(shippingCostStr).toFixed(2);

    const beforeSortFiltered = orderItems.filter(
      (i) => i.status !== "cancelled"
    );

    const filtered = sortItemsAlphabetically
      ? beforeSortFiltered.sort(function compare(a, b) {
          if (a.description.toUpperCase() > b.description.toUpperCase())
            return 1;
          else if (a.description.toUpperCase() < b.description.toUpperCase())
            return -1;
          else return 0;
        })
      : beforeSortFiltered;
    const totalItemAmount = getSum(filtered);
    const totalItemWeight = getTotalWeight(filtered);
    const creditApplied = getCreditPayment(payments);

    const itemWithDescriptions = filtered
      .reduce((acc, cur, idx, src) => {
        const {
          description = "",
          createdAt,
          price: priceStr = "",
          keyword = {},
        } = cur;
        const price = isNaNOrNull(priceStr)
          ? "0.00"
          : new Decimal(priceStr).toFixed(2);
        const spacelessDescription = description.replace(/\s/g, " ");
        const matches = comboRegex.exec(spacelessDescription);
        if (matches) {
          const [fullString, productDescription, quantity] = matches;
          if (deliveryOrderWithLegacyStyle) {
            return [
              ...acc,
              {
                description: `${productDescription} x${quantity}`,
                quantity,
                createdAt,
                price,
                keyword,
              },
            ];
          }
          const splitted = productDescription.split(/,|，/).map((str, idx) => {
            if (idx === 0) {
              return {
                description: deliveryOrderWithLegacyStyle
                  ? `${str} x${quantity}`
                  : `${str}`,
                createdAt,
                price,
                quantity,
                keyword,
              };
            }
            return { description: `- ${str.trim()}`, createdAt };
          });
          return [...acc, ...splitted];
        } else {
          const splitted = spacelessDescription
            .split(/,|，/)
            .map((str, idx) => {
              if (idx === 0) {
                return { description: str.trim(), createdAt, price };
              } else {
                return { description: `- ${str.trim()}`, createdAt };
              }
            });
          return [...acc, ...splitted];
        }
      }, [])
      .map(getUnitPriceFromSubtotal);

    const totalQuantity = itemWithDescriptions.reduce((acc, cur) => {
      const quantity = cur.quantity;
      const qty = isNaNOrNull(parseInt(quantity)) ? 0 : parseInt(quantity);
      return acc + qty;
    }, 0);

    const title2 = tax ? "Tax Invoice" : "Invoice";
    const title3 = "Invoice number";
    return (
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <Box
            // mt={`2rem`}
            >
              <Grid
                container
                justifyContent="center"
                alignContent="center"
                alignItems="flex-start"
                // spacing={1}
              >
                <Grid item>
                  <CompanyLogo
                    alt="Company logo"
                    src={replaceImageSource(logo)}
                  />
                </Grid>
                <Grid item>
                  {/* <div>{companyRegistration}</div> */}
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <h1>{companyName}</h1>
                    </Grid>
                    <Grid item> {companyRegistration}</Grid>
                    <Grid item>
                      <h5>{companyAddress}</h5>
                    </Grid>
                    <Grid item>{headerLine1}</Grid>
                    <Grid item>{headerLine2}</Grid>
                    <Grid item>{headerLine3}</Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
            //  ml={`2rem`}
            >
              <h4>Shipping address</h4>
              <div>{shippingName}</div>
              <div>{shippingPhoneNumber}</div>
              <div>{shippingAddress1}</div>
              <div>{shippingAddress2}</div>
              <div>{shippingCity}</div>
              <div>{shippingPostcode}</div>
              <div>{shippingState}</div>
              <br />
              <div>
                {`Shipping option: `}{" "}
                <ShippingOptionDeliveryOrderField
                  shippingOption={shippingOption}
                />
              </div>
              {shippingCost && <div>{`Shipping fee: ${shippingCost}`}</div>}
              {voucher && voucher.code && (
                <div>{`Voucher: ${voucher.code}`}</div>
              )}
              {deliveryOrderShowTotalWeight && (
                <div>{`Total Weight: ${totalItemWeight} kg`}</div>
              )}
              {trackingNumber && (
                <div>{`Tracking Number: ${trackingNumber}`}</div>
              )}
            </Box>
            {/* <Paper>xs=6</Paper> */}
          </Grid>
          <Grid item xs={6}>
            <Box
            // mr={`2rem`}
            >
              <h4>{title2}</h4>
              <TableContainer component={Paper}>
                <Table aria-label="delivery order" size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell align="left">{title3}</TableCell>
                      <TableCell align="left">
                        <DeliveryOrderNumber invoiceNumber={invoiceNumber} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">Order #</TableCell>
                      <TableCell align="left">{orderNumber}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">Nickname</TableCell>
                      <TableCell align="left">{customerName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">Order date</TableCell>
                      <TableCell align="left">{printDate(createdAt)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">Dispatch date</TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">Desc</TableCell>
                      <TableCell align="left">{description}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">Order notes</TableCell>
                      <TableCell align="left">{notes}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
            // mx="2rem"
            >
              <TableContainer component={Paper}>
                <Table border={1} aria-label="item table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox" size="small">
                        #
                      </TableCell>
                      <TableCell style={thickWidth}>Description</TableCell>
                      <TableCell
                        padding="none"
                        align="center"
                      >{`Unit Price (${currency})`}</TableCell>
                      {!deliveryOrderWithLegacyStyle && (
                        <TableCell padding="none" align="center">
                          {`Qty (${totalQuantity})`}
                        </TableCell>
                      )}

                      <TableCell
                        padding="none"
                        align="center"
                      >{`Subtotal (${currency})`}</TableCell>
                      <TableCell>Ordered</TableCell>
                      {/* <TableCell >Qty delivered</TableCell> */}
                      <TableCell align="center">{`Check & note`}</TableCell>
                      {/* <TableCell>note</TableCell> */}

                      {/* <TableCell align="right"></TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {itemWithDescriptions.map(
                      (
                        {
                          description,
                          createdAt,
                          quantity = "",
                          price = "",
                          unitPrice,
                          keyword = {},
                        },
                        idx
                      ) => (
                        <TableRow key={idx}>
                          <TableCell align="center" padding="none" size="small">
                            {idx + 1}
                          </TableCell>

                          {!showImageInDO && (
                            <TableCell>{description}</TableCell>
                          )}

                          {showImageInDO && (
                            <TableCell>
                              <ItemDescriptionWithImageForDo
                                description={description}
                                images={
                                  keyword && keyword.images
                                    ? keyword.images
                                    : null
                                }
                              />
                            </TableCell>
                          )}

                          <TableCell>{unitPrice}</TableCell>

                          {!deliveryOrderWithLegacyStyle && (
                            <TableCell padding="checkbox" size="small">
                              {quantity}
                            </TableCell>
                          )}
                          <TableCell>{price}</TableCell>
                          <TableCell>{printMonthDay(createdAt)}</TableCell>
                          {/* <TableCell ></TableCell> */}
                          <TableCell></TableCell>
                          {/* <TableCell></TableCell> */}
                        </TableRow>
                      )
                    )}
                    {!tax && (
                      <>
                        <TableRow>
                          <TableCell
                            padding="checkbox"
                            size="small"
                          ></TableCell>
                          {/* <TableCell></TableCell> */}
                          {!deliveryOrderWithLegacyStyle && (
                            <TableCell></TableCell>
                          )}
                          <TableCell align="right" colSpan={2}>
                            Subtotal
                          </TableCell>
                          <TableCell align="right">{totalItemAmount}</TableCell>
                        </TableRow>
                        {creditApplied > 0 && (
                          <TableRow>
                            <TableCell
                              padding="checkbox"
                              size="small"
                            ></TableCell>
                            {!deliveryOrderWithLegacyStyle && (
                              <TableCell></TableCell>
                            )}
                            <TableCell align="right" colSpan={2}>
                              Credit Applied
                            </TableCell>
                            <TableCell align="right">
                              -{creditApplied}
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell
                            padding="checkbox"
                            size="small"
                          ></TableCell>
                          {/* <TableCell></TableCell> */}
                          {!deliveryOrderWithLegacyStyle && (
                            <TableCell></TableCell>
                          )}
                          <TableCell align="right" colSpan={2}>
                            Shipping
                          </TableCell>
                          <TableCell align="right">{shippingCost}</TableCell>
                        </TableRow>
                        {voucher && voucher.code && (
                          <TableRow>
                            <TableCell
                              padding="checkbox"
                              size="small"
                            ></TableCell>
                            {!deliveryOrderWithLegacyStyle && (
                              <TableCell></TableCell>
                            )}
                            <TableCell align="right" colSpan={2}>
                              Discount
                            </TableCell>
                            <TableCell align="right">
                              {`-`}
                              <DiscountValue record={record} />
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell
                            padding="checkbox"
                            size="small"
                          ></TableCell>
                          {/* <TableCell></TableCell> */}
                          {!deliveryOrderWithLegacyStyle && (
                            <TableCell></TableCell>
                          )}
                          <TableCell align="right" colSpan={2}>
                            Total
                          </TableCell>
                          <TableCell align="right">
                            <TotalInclShippingWithDiscount record={record} />
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                    {/* {filtered.map((item, idx) => (
                      <TableRow key={item._id}>
                        <TableCell padding="checkbox" size="small">
                          {idx + 1}
                        </TableCell>
                        <TableCell>{item.description}</TableCell>
                        <TableCell ></TableCell>
                        <TableCell ></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    ))} */}
                    {tax && (
                      <>
                        {creditApplied > 0 && (
                          <TableRow>
                            <TableCell
                              padding="checkbox"
                              size="small"
                            ></TableCell>
                            <TableCell align="right" colSpan={3}>
                              Credit Applied
                            </TableCell>
                            <TableCell align="right">
                              -{creditApplied}
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell
                            padding="checkbox"
                            size="small"
                          ></TableCell>
                          <TableCell align="right" colSpan={3}>
                            Shipping
                          </TableCell>
                          <TableCell align="right">{shippingCost}</TableCell>
                        </TableRow>

                        {voucher && voucher.code && (
                          <TableRow>
                            <TableCell
                              padding="checkbox"
                              size="small"
                            ></TableCell>
                            <TableCell align="right" colSpan={3}>
                              Discount
                            </TableCell>
                            <TableCell align="right">
                              {`-`}
                              <DiscountValue record={record} />
                            </TableCell>
                          </TableRow>
                        )}

                        <TableRow>
                          <TableCell
                            padding="checkbox"
                            size="small"
                          ></TableCell>
                          <TableCell align="right" colSpan={3}>
                            Total before tax
                          </TableCell>
                          <TableCell align="right">
                            <AmountWithTaxField
                              record={record}
                              source="totalBeforeTax"
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell
                            padding="checkbox"
                            size="small"
                          ></TableCell>
                          <TableCell align="right" colSpan={3}>
                            {tax.label}
                          </TableCell>
                          <TableCell align="right">
                            <AmountWithTaxField
                              record={record}
                              source="taxAmount"
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell
                            padding="checkbox"
                            size="small"
                          ></TableCell>
                          <TableCell align="right" colSpan={3}>
                            Total after tax
                          </TableCell>
                          <TableCell align="right">
                            <AmountWithTaxField
                              record={record}
                              source="totalAfterTax"
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
          {!!invoiceFooter.trim() && (
            <Grid container justifyContent="center">
              <Grid item>
                <Box p={2} />
                <h4>{invoiceFooter}</h4>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    );
  }
}

export default ComponentToPrint;
