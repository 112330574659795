import React from "react";
import { useListContext } from "react-admin";
import TextField from "@mui/material/TextField";
import { useFormContext } from "react-hook-form";
import useDebounce from "../UseDebounce";

const validate = (v) => {
  const trimmed = v && v.trim();
  if (!trimmed) {
    return true;
  } else if (trimmed && trimmed.length > 1) {
    return true;
  }

  return false;
};

const Comp = (props) => {
  const { source, label } = props;
  const { setValue: setFormValue } = useFormContext();
  const { filterValues } = useListContext();
  const [value, setValue] = React.useState(
    filterValues.descriptionSearch_containss
      ? decodeURIComponent(filterValues.descriptionSearch_containss)
      : ""
  );
  const debouncedSearchTerm = useDebounce(value, 800);

  React.useEffect(() => {
    setFormValue(
      source,
      encodeURIComponent(debouncedSearchTerm.trim().toUpperCase())
    );
  }, [debouncedSearchTerm, setFormValue, source]);

  return (
    <TextField
      label={`${label}(min 2 letters)`}
      value={value}
      variant="filled"
      error={!validate(debouncedSearchTerm)}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};
export default Comp;
