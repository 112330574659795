import React from "react";
import { getCookie } from "../../helpers/Cookies";

export const useGetToken = (cacheBuster = "") => {
  const memoizedToken = React.useMemo(() => {
    if (cacheBuster || true) {
      return getCookie("token");
    }
  }, [cacheBuster]);
  return memoizedToken;
};
