import React from "react";
import { useProfile } from "../DataHooks/useProfile";

import { pz } from "../../helpers/methods";

const Comp = (props) => {
  const { profile } = useProfile();

  const { invoiceNumber } = props;
  const { invoiceNumberPrefix = "FBL", invoiceNumberLength } = profile;

  const DeliveryOrderNumber = `${invoiceNumberPrefix}${pz(
    invoiceNumber,
    invoiceNumberLength
  )}`;
  return <>{DeliveryOrderNumber}</>;
};

export default Comp;
