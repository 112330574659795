import React from "react";
import { TextInput, Create, SimpleForm, required } from "react-admin";

import AttachSelections from "../AutocompleteAttach/AttachSelections";
import { getPageId } from "../../helpers/getPageId";

const SelectionCreate = (props) => {
  const pageId = getPageId();

  return (
    <Create {...props} permissions="Seller" redirect="list">
      <SimpleForm defaultValues={{ page: pageId }}>
        <TextInput source="label" validate={required()} />
        <TextInput multiline source="description" validate={required()} />
        <AttachSelections source="variants" searchFieldName="label" />
      </SimpleForm>
    </Create>
  );
};

export default SelectionCreate;
