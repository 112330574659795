import React, { useCallback } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "@mui/material/Button";
import { useNotify, useRecordContext } from "react-admin";

// import PropTypes from "prop-types";

const keys = [
  //"shippingName",
  //"shippingPhoneNumber",
  "shippingAddress1",
  "shippingAddress2",
  "shippingCity",
  "shippingVillage",
  "shippingSubDistrict",
  "shippingProvince",
  "shippingPostcode",
  "shippingState",
];

const allKeys = [
  "shippingName",
  "shippingPhoneNumber",
  "shippingAddress1",
  "shippingAddress2",
  "shippingCity",
  "shippingVillage",
  "shippingSubDistrict",
  "shippingProvince",
  "shippingPostcode",
  "shippingState",
];

const ButtonCopyAddress = ({}) => {
  const record = useRecordContext();
  const notify = useNotify();
  const showMsg = useCallback(() => {
    notify("Copied successful");
  }, []);
  const text = keys
    .filter((k) => !!record[k])
    .map((k) => record[k])
    .join(",\n");

  const alltext = allKeys
    .filter((k) => !!record[k])
    .map((k) => record[k])
    .join(",\n");
  return (
    <span>
      <CopyToClipboard text={text} onCopy={showMsg}>
        <Button variant="contained">Copy address</Button>
      </CopyToClipboard>
      <CopyToClipboard text={alltext} onCopy={showMsg}>
        <Button variant="contained">Copy all</Button>
      </CopyToClipboard>
    </span>
  );
};

export default ButtonCopyAddress;
