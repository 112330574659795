import React from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Typography from "@mui/material/Typography";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import queryString from "query-string";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import { getCookie } from "../../helpers/Cookies";
import { fetchHandleError } from "../../helpers/fetchHandleError";
import CardIcon from "./CardIcon";
import { CANCELLED } from "../Constant";
// import CardIcon from "./CardIcon";
import LaunchIcon from "@mui/icons-material/Launch";
import { Box } from "@mui/material";

const Comp = () => {
  const [dateRangeData, setDateRangeData] = React.useState({
    orders: [],
    orderItems: [],
  });
  const [selectedDate, handleDateChange] = React.useState(
    startOfDay(new Date())
  );

  // const translate = useTranslate();

  React.useEffect(() => {
    const token = getCookie("token");
    const abortController = new AbortController();
    fetch(
      `${
        process.env.REACT_APP_CMS_HOST
      }/pages/dashboard?startDate=${selectedDate.toISOString()}&endDate=${endOfDay(
        selectedDate
      ).toISOString()}`,
      {
        signal: abortController.signal,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then(fetchHandleError)
      .then((resp) => {
        //temp workaround to merge  currency until all objcts populated currency
        const mergedDetails = resp.orderItems
          .reduce((acc, cur) => {
            return [...acc, ...cur.detail];
          }, [])
          .reduce((stats, cur) => {
            const found = stats.find((s) => s.status === cur.status);
            if (!found) {
              stats.push(cur);
              return stats;
            } else {
              //found
              // found.orderCount += cur.orderCount;
              found.orderItemCount += cur.orderItemCount;
              found.priceSum += cur.priceSum;
              // found.shippingCostSum += cur.shippingCostSum;
              return stats;
            }
          }, []);
        const currencyCode = resp.orderItems.reduce(
          (v, cur) => (!!cur._id.currencyCode ? cur._id.currencyCode : v),
          null
        );
        if (mergedDetails.length > 0) {
          setDateRangeData({
            orderItems: [
              {
                _id: {
                  currencyCode: currencyCode,
                },
                detail: mergedDetails,
              },
            ],
          });
        } else {
          setDateRangeData({
            orderItems: [],
          });
        }

        // setDateRangeData(resp);
      })
      .catch((e) => {});
    return () => abortController.abort();
  }, [selectedDate]);

  const statistics = dateRangeData.orderItems
    .map((d) => {
      const currencyCode = d._id.currencyCode;
      const revenueStat = d.detail
        .filter((v) => [CANCELLED].indexOf(v.status) === -1)
        .reduce(
          (stat, cur) => {
            stat.orderItemCount = stat.orderItemCount + cur.orderItemCount;
            stat.priceSum = stat.priceSum + cur.priceSum;
            return stat;
          },
          {
            orderItemCount: 0,
            priceSum: 0,
          }
        );

      return { currencyCode, revenueStat };
    })
    .map(({ currencyCode, revenueStat }) => {
      const revenue = revenueStat.priceSum.toLocaleString(undefined, {
        style: "currency",
        currency: currencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
      return {
        currencyCode,
        revenueStat: { ...revenueStat, priceSum: revenue },
      };
    });
  return (
    <>
      <Box
        sx={{
          flex: "1",
        }}
      >
        <CardIcon Icon={AttachMoneyIcon} bgColor="#31708f" />
        <Card
          sx={{
            overflow: "inherit",
            textAlign: "right",
            paddingRight: "1em",
          }}
        >
          <Typography color="textSecondary">Day sales</Typography>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`/#/orderItems?${queryString.stringify({
              displayedFilters: JSON.stringify({
                createdAt_lte: true,
              }),
              filter: JSON.stringify({
                createdAt_gte: selectedDate.toISOString(),
                createdAt_lte: endOfDay(selectedDate).toISOString(),
                status_in: ["new", "paid"],
              }),
              order: "DESC",
              page: 1,
              perPage: 25,
              sort: "createdAt",
            })}`}
          >
            <LaunchIcon />
          </a>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={`Select date`}
              value={selectedDate}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          {statistics.map((stat, idx) => {
            return (
              <React.Fragment key={idx}>
                <Typography variant="h5" component="h2">
                  {stat.revenueStat.priceSum}
                </Typography>
                {/* <Typography>{`${stat.revenueStat.orderCount} orders`}</Typography> */}
                <Typography>{`~${stat.revenueStat.orderItemCount} items`}</Typography>
              </React.Fragment>
            );
          })}
          {statistics.length === 0 && <Typography>No orders</Typography>}
        </Card>
      </Box>
    </>
  );
};

export default Comp;
