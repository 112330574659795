export const orderItemExportDefaultFields = {
  orderItemId: false,
  orderNumber: true,
  customerId: false,
  customerName: true,
  description: true,
  qty: true,
  keyword: true,
  status: true,
  channel: false,
  unitPrice: true,
  price: true,
  unitWeight: true,
  weight: true,
  orderItemTags: true,
  totalCost: true,
  totalProfit: true,
  orderStatus: true,
  sku: false,
  categories: false,
  variants: false,
  createdAtDate: true,
  createdAtTime: true,
  updatedAtDate: true,
  updatedAtTime: true,
  invoiceNumber: true,
  shippingName: false,
  shippingAddress1: false,
  shippingAddress2: false,
  shippingCity: false,
  shippingCountry: false,
  shippingPhoneNumber: false,
  shippingPostcode: false,
  shippingState: false,
  email: false,
  trackingNumber: false,
  notes: false,
  MDYDateFormat: true,
  orderShippingOption: false,
};

export const ninjaVanDefaultConfig = {
  "NINJA PACK ID": "",
  NAME: "{orderShippingName}",
  EMAIL: "{orderShippingEmail}",
  INSTRUCTIONS: "",
  CONTACT: "{orderShippingPhoneNumber}",
  "ADDRESS 1": "{orderShippingAddress1}",
  "ADDRESS 2": "{orderShippingAddress2}",
  "PARCEL DESCRIPTION": "",
  "IS DANGEROUS GOOD": "false",
  POSTCODE: "{orderShippingPostcode}",
  "CASH ON DELIVERY": "",
};

export const easyParcelDefaultConfig = {
  //"No*": "",
  Category: "",
  "Parcel Content*": "",
  "Parcel Value (RM)*": "{orderTotalValue}",
  "Weight (kg)*": "{orderTotalWeight}",
  "Pick Up Date*": "{exportDate}",
  "Sender Name*": "",
  "Sender Company": "",
  "Sender Contact*": "",
  "Sender Alt Contact": "",
  "Sender Email": "",
  "Sender Address*": "",
  "Sender Postcode*": "",
  "Sender City*": "",
  "Receiver Name*": "{orderShippingName}",
  "Receiver Company": "",
  "Receiver Contact*": "{orderShippingPhoneNumber}",
  "Receiver Alt Contact": "",
  "Receiver Email": "{orderShippingEmail}",
  "Receiver Address*": "{orderFullAddress}",
  "Receiver Postcode*": "{orderShippingPostcode}",
  "Receiver City*": "{orderShippingCity}",
  "Courier Company": "",
  "Alternative Courier Company": "",
  "Tracking SMS (RM0.20)": "",
  "Drop Off At Courier Branch": "",
  Reference: "{orderNumber}",
};

export const skynetDefaultConfig = {
  "RecipientName*": "{orderShippingName}",
  CompanyName: "",
  "Phone*": "{orderShippingPhoneNumber}",
  "Address1*": "{orderShippingAddress1}",
  Address2: "{orderShippingAddress2}",
  "PostCode*": "{orderShippingPostcode}",
  "City*": "{orderShippingCity}",
  "State*": "{orderShippingState}",
  "Country*": "{orderShippingCountry}",
  ShipperRef: "{orderNumber}",
  "Contents*": "",
  "ShipmentType*": "",
  "Pieces*": "{orderTotalPieces}",
  "Weight (kg)*": "{orderTotalWeight}",
  "Value (RM)*": "{orderTotalValue}",
  "Length(cm)*": "",
  "Width(cm)*": "",
  "Height(cm)*": "",
};

export const autoCountDefaultConfig = {
  DocNo: "{invoiceNumber}",
  DocDate: "{exportDate}",
  DebtorCode: "",
  DebtorName: "",
  Ref: "{orderNumber}",
  OurInvoiceNo: "",
  Description: "",
  DisplayTerm: "",
  SalesAgent: "",
  InvAddr1: "{orderShippingAddress1}",
  InvAddr2: "{orderShippingAddress2}",
  InvAddr3: "{orderShippingState}",
  InvAddr4: "",
  Phone1: "{orderShippingPhoneNumber}",
  Fax1: "",
  Attention: "{orderShippingName}",
  BranchCode: "",
  DeliverAddr1: "",
  DeliverAddr2: "",
  DeliverAddr3: "",
  DeliverAddr4: "",
  DeliverPhone1: "",
  DeliverFax1: "",
  DeliverContact: "",
  SalesExemptionNo: "",
  SalesExemptionExpiryDate: "",
  Footer1Param: "",
  Footer1Amt: "",
  Footer1TaxType: "",
  Footer2Param: "",
  Footer2Amt: "",
  Footer2TaxType: "",
  Footer3Param: "",
  Footer3Amt: "",
  Footer3TaxType: "",
  CurrencyCode: "",
  CurrencyRate: "",
  Tax: "",
  PostToStock: "",
  PostToGL: "",
  Note: "",
  Remark1: "",
  Remark2: "",
  Remark3: "{orderShippingNotes}",
  Remark4: "",
  Cancelled: "",
  RefDocNo: "",
  MemberNo: "",
  SalesLocation: "",
  Footer1Tax: "",
  Footer2Tax: "",
  Footer3Tax: "",
  ToTaxCurrencyRate: "",
  CalcDiscountOnUnitPrice: "",
  TaxDocNo: "",
  Reason: "",
  InclusiveTax: "",
  Footer1TaxRate: "",
  Footer2TaxRate: "",
  Footer3TaxRate: "",
  TaxDate: "",
  IsRoundAdj: "",
  RoundingMethod: "",
  Numbering: "",
  ItemCode: "{itemSKU}",
  Location: "",
  BatchNo: "",
  DetailDescription: "{itemDescription}",
  FurtherDescription: "",
  ProjNo: "",
  DeptNo: "",
  UOM: "",
  UserUOM: "",
  Qty: "{itemQuantity}",
  FOCQty: "",
  UnitPrice: "{itemUnitPrice}",
  UnitCost: "",
  FOCUnitCost: "",
  Discount: "",
  DiscountAmt: "",
  TaxType: "",
  SubTotal: "{itemSubTotal}",
  BonusPoint: "",
  PrintOut: "",
  CalcByPercent: "",
  AddToSubTotal: "",
  AccNo: "",
  YourPONo: "",
  YourPODate: "",
  GoodsReturn: "",
  TaxableAmt: "",
  TaxAdjustment: "",
  TaxExportCountry: "",
  TaxRate: "",
  LocalTaxAdjustment: "",
};

export const posLajuDefaultConfig = {
  "Sender Name": "",
  "Sender Email": "",
  "Sender Contact No": "",
  "Sender Address": "",
  "Sender Postcode": "",
  "Receiver Name": "{orderShippingName}",
  "Receiver Email": "{orderShippingEmail}",
  "Receiver Contact No": "{orderShippingPhoneNumber}",
  "Receiver Address": "{orderFullAddress}",
  "Receiver Postcode": "{orderShippingPostcode}",
  "Item Weight(kg)": "{orderTotalWeight}",
  "Item Width (cm)": "",
  "Item Length (cm)": "",
  "Item Height (cm)": "",
  Category: "",
  "Item Description": "",
  "Parcel Notes": "",
  "Sender Ref No": "{orderNumber}",
  "Insurance (MYR)": "",
};

export const gdexDefaultConfig = {
  "Company Name": "",
  "Receiver Name*": "{orderShippingName}",
  "Receiver Mobile*": "{orderShippingPhoneNumber}",
  "Receiver Alt Mobile": "",
  "Receiver Address 1*": "{orderShippingAddress1}",
  "Receiver Address 2": "{orderShippingAddress2}",
  "Receiver Address 3": "",
  "Receiver Postcode*": "{orderShippingPostcode}",
  "Receiver City": "{orderShippingCity}",
  "Receiver State": "{orderShippingState}",
  "Shipment Type*": "",
  "Shipment Content": "",
  "Shipment Value (RM)": "{orderTotalValue}",
  "Total Pieces*": "{orderTotalPieces}",
  "Shipment Weight (kg)*": "{orderTotalWeight}",
  "Shipment Length (cm)": "",
  "Shipment Width (cm)": "",
  "Shipment Height (cm)": "",
  "Dangerous Goods*": "",
  "Customer Note 1": "{orderNumber}",
  "Customer Note 2": "",
  "Order ID": "",
  "Enhanced Liability": "",
};

export const kexExpressDefaultConfig = {
  CneeName: "",
  CneeAdd1: "{orderShippingAddress1}",
  CneeAdd2: "{orderShippingAddress2}",
  CneeAdd3: "",
  CneeAdd4: "",
  CneeDestCode: "{orderShippingPostcode}",
  CneeContact: "{orderShippingName}",
  CneeTel: "{orderShippingPhoneNumber}",
  Weight: "{orderTotalWeight}",
  Pcs: "{orderTotalPieces}",
  Remark: "{orderNumber}",
  DeclaredCurrency: "MYR",
  DeclaredValue: "",
  "Remark 2": "",
  "Remark 3": "",
  COD: "",
  Alert: "",
};

const orderExportDefaultFields = {
  orderNumber: true,
  orderChannel: false,
  totalExcludeShipping: true,
  shippingCost: true,
  totalIncludeShipping: true,
  discountValue: true,
  totalAfterDiscount: true,
  receivedPayment: false,
  outstandingAmount: false,
  totalBeforeTax: false,
  tax: false,
  totalAfterTax: false,
  taxableAmount: false,
  totalCost: false,
  totalProfit: false,
  customerName: true,
  shippingName: true,
  contactRemarks: false,
  customerPSID: false,
  orderItems: true,
  shippingOption: true,
  createdAtDate: true,
  createdAtTime: true,
  updatedAtDate: true,
  updatedAtTime: true,
  description: true,
  shippingAddress1: true,
  shippingAddress2: true,
  shippingCity: true,
  shippingCountry: true,
  shippingPhoneNumber: true,
  shippingPostcode: true,
  shippingState: true,
  contactId: false,
  email: true,
  status: true,
  trackingNumber: true,
  notes: true,
  doNumber: true,
  voucherCode: true,
  weight: true,
  deliveryOrderPrinted: false,
  paymentLink: false,
  MDYDateFormat: true,
};

export default orderExportDefaultFields;
