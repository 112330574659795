import React, { useState } from "react";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { makeStyles } from "@mui/styles";

import Chip from "@mui/material/Chip";

import { Grid } from "./Grid";
import { SortablePhoto } from "./SortablePhoto";
import { Photo } from "./Photo";

const useStyles = makeStyles((theme) => ({
  deleteIcon: {
    color: "red",
  },
  bottpmLeft: {
    // left: "80%",
    bottom: "10%",
    position: "relative",
  },
  topLeft: {
    position: "relative",
    top: "10%",
    left: "10%",
  },
}));

const CustomButtonChip = (props) => {
  const { onDelete = () => {}, ...otherProps } = props;
  const [showDelete, setshowDelete] = useState(false);
  return (
    <Chip
      {...otherProps}
      onMouseLeave={(e) => {
        setshowDelete(false);
      }}
      onMouseEnter={(e) => {
        setshowDelete(true);
      }}
      onDelete={showDelete ? onDelete : undefined}
    />
  );
};

const Gallery = (props) => {
  const classes = useStyles();
  const { items = [], onSortHandler = () => {}, onRemove = () => {} } = props;
  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={items} strategy={rectSortingStrategy}>
        <Grid columns={6}>
          {items.map(({ name: url, type }, index) => (
            <span key={url}>
              <SortablePhoto url={url} index={index} />
              <span className={classes.bottpmLeft}>
                <CustomButtonChip
                  label={`${index + 1} ${
                    type && type === "video" ? "(video)" : ""
                  }`}
                  onDelete={() => {
                    onRemove(url);
                  }}
                />
              </span>
            </span>
          ))}
        </Grid>
      </SortableContext>

      <DragOverlay adjustScale={true}>
        {activeId ? (
          <Photo
            url={activeId}
            index={items.find(({ name }) => name === activeId.name)}
            // index={items.indexOf(activeId)}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );

  function handleDragStart(event) {
    setActiveId(event.active.id);
  }

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = items.findIndex(({ name }) => name === active.id);
      const newIndex = items.findIndex(({ name }) => name === over.id);
      const newArr = arrayMove(items, oldIndex, newIndex);
      onSortHandler(newArr);
    }

    setActiveId(null);
  }

  function handleDragCancel() {
    setActiveId(null);
  }
};

export default Gallery;
