import React from "react";
import { useUnselectAll, useRefresh, useListContext } from "react-admin";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { makeStyles } from "@mui/styles";

import { getCookie } from "../../helpers/Cookies";
import { fetchHandleError } from "../../helpers/fetchHandleError";
import Loader from "../Loader";

const MAX_CONCURRENT = 10;

const useStyles = makeStyles((theme) => ({
  red: { color: "red" },
}));

const Comp = (props) => {
  const { resource, selectedIds = [] } = useListContext();
  const token = getCookie("token");
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [requests, setRequests] = React.useState([]);
  const [pending, setPending] = React.useState(0);
  const [error, setError] = React.useState(false);
  const refresh = useRefresh();
  const unselectAll = useUnselectAll(resource);

  React.useEffect(() => {
    if (pending === 0 && requests.length > 0 && !error) {
      const processSlice = requests.slice(0, MAX_CONCURRENT);
      const nextSlice = requests.slice(MAX_CONCURRENT);
      setPending(processSlice.length);
      setRequests(nextSlice);

      processSlice.map((id) =>
        fetch(`${process.env.REACT_APP_CMS_HOST}/${resource}/${id}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then(fetchHandleError)
          .then((resp) => {
            setPending((prev) => prev - 1);
          })
          .catch((e) => {
            setError(true);
            setRequests([]);
            setPending(0);
            alert(`Delete error, stop now. ${e}`);
          })
      );
    }
  }, [pending, setPending, requests, setRequests]);

  React.useEffect(() => {
    if (loading && pending === 0 && requests.length === 0) {
      unselectAll();
      refresh();
      setLoading(false);
    }
  }, [refresh, loading, requests, pending]);

  return (
    <>
      <Button
        className={classes.red}
        label="Delete"
        onClick={() => {
          if (window.confirm("Are you sure ?")) {
            setRequests(selectedIds);
            setLoading(true);
          }
        }}
      >
        <DeleteIcon />
        DELETE
      </Button>
      <Loader open={pending > 0}>
        {`DO NOT CLOSE THIS WINDOW !\nDeleting..., ${
          pending + requests.length
        } left`}
      </Loader>
    </>
  );
};

export default Comp;
