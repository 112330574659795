import { unparse as convertToCSV } from "papaparse/papaparse.min";
import Decimal from "decimal.js-light";
import groupBy from "lodash/groupBy";
import { comboRegex, sanitizePunctuation } from "../../helpers/methods";
import { format } from "date-fns";

import { downloadCSVWithBom, pz } from "../../helpers/methods";

export const exporter = ({
  invoiceNumberPrefix = "FB",
  invoiceNumberLength = 8,
  hasShippingCost = false,
  exportSummary = false,
  itemExportFields = [],
  orderItems = [],
  keywords = [],
  shippingOptions = [],
  MDYDateFormat = true,
}) => {
  let cache = {};
  const dateFormatter = MDYDateFormat
    ? (d) => format(new Date(d), "MM/dd/yyyy")
    : (d) => format(new Date(d), "dd/MM/yyyy");

  const dataForExport = orderItems
    .filter((item) => item.order !== null)
    .reduce((acc, cur) => {
      const { order } = cur;
      if (cache[order.orderNumber]) {
        acc.push(cur);
      } else {
        cache[order.orderNumber] = true;
        const { shippingCost = 0, shippingOption = "" } = order;
        const selectedShippingOption = shippingOptions.find(
          (o) => o.id === shippingOption
        );
        if (hasShippingCost) {
          acc.push({
            order,
            createdAt: order.createdAt,
            updatedAt: order.updatedAt,
            description: `shipping_${
              selectedShippingOption ? selectedShippingOption.name : "unknown"
            }`,
            price: shippingCost,
            status: "",
            cost: shippingCost,
            weight: 0,
            unitWeight: 0,
          });
        }
        acc.push(cur);
      }
      return acc;
    }, [])
    .map((item) => {
      const {
        // __v,
        // updatedAt,
        // _id,
        // id,
        // page,
        id,
        createdAt,
        order,
        // psid,
        description,
        keyword = { id: undefined },
        cost = 0,
        tags = [],
        channel = "",
        // quantity,
        // ...others
      } = item;
      const selectedShippingOption = shippingOptions.find(
        (o) => o.id === order.shippingOption
      );
      const matchedKeyword =
        keyword && keyword.id
          ? keywords.find((k) => k.id === keyword.id)
          : undefined;
      const orderItemDescription = description.replace(/\s/g, " ").trim();

      const matches = comboRegex.exec(orderItemDescription);
      let descriptionToExport = orderItemDescription;
      let quantityToExport = 1;
      if (matches) {
        const [fullString, productDescription, quantity] = matches;
        descriptionToExport = productDescription.replace(/\s/g, " ").trim();
        quantityToExport = quantity;
      }
      const createdAtDate = new Date(createdAt);
      const itemPriceDecimal = new Decimal(item.price);
      const profit = itemPriceDecimal.minus(cost).toNumber();
      const tagsLabel = tags.map((tag) => {
        return tag.label;
      });

      return {
        orderShippingOption: selectedShippingOption
          ? selectedShippingOption.name
          : "unknown",
        orderItemId: id,
        orderNumber: order.orderNumber,
        customerId: order.contact ? order.contact : "",
        customerName: order.customerName,
        description: descriptionToExport,
        qty: quantityToExport,
        keyword: keyword && keyword.key ? keyword.key : "",
        orderStatus: order.status,
        price: item.price,
        totalCost: cost,
        totalProfit: profit,
        unitCost: cost / quantityToExport,
        status: item.status,
        createdAtDate: dateFormatter(createdAtDate),
        createdAtTime: createdAtDate.toLocaleTimeString(),
        unitPrice: item.price / quantityToExport,
        unitWeight: item.weight / quantityToExport,
        weight: item.weight,
        updatedAtDate: dateFormatter(item.updatedAt),
        updatedAtTime: new Date(item.updatedAt).toLocaleTimeString(),
        sku: keyword && keyword.sku ? keyword.sku : "",
        categories: matchedKeyword
          ? matchedKeyword.categories
              .map((c) => {
                return c.label;
              })
              .join()
          : "",
        variants: matchedKeyword
          ? matchedKeyword.variants
              .map((v) => {
                return v.label;
              })
              .join()
          : "",
        invoiceNumber: `${invoiceNumberPrefix}${pz(
          order.invoiceNumber,
          invoiceNumberLength
        )}`,
        shippingName: order.shippingName,
        shippingAddress1: sanitizePunctuation(order.shippingAddress1),
        shippingAddress2: sanitizePunctuation(order.shippingAddress2),
        shippingCity: order.shippingCity,
        shippingCountry: order.shippingCountry,
        shippingPhoneNumber: order.shippingPhoneNumber,
        shippingPostcode: order.shippingPostcode,
        shippingState: order.shippingState,
        email: order.email,
        trackingNumber: order.trackingNumber,
        notes: order.notes,
        orderItemTags: tagsLabel,
        channel: channel,
      };
    });
  if (exportSummary) {
    const itemsWithUnitPrice = dataForExport.map((item) => {
      const unitPriceDecimal = new Decimal(item.price).dividedBy(item.qty);
      return {
        ...item,
        unitPrice: unitPriceDecimal.toNumber(),
      };
    });
    const grouped = groupBy(
      itemsWithUnitPrice,
      (item) => `${item.description}_${item.unitPrice}`
    );

    const summaryToExport = Object.keys(grouped).map((k) => {
      const withSumQty = grouped[k].reduce(
        (acc, { description, qty, unitPrice }) => {
          return {
            description,
            unitPrice,
            qty: acc.qty + parseInt(qty),
          };
        },
        {
          qty: 0,
        }
      );
      return {
        ...withSumQty,
        total: new Decimal(withSumQty.unitPrice)
          .times(withSumQty.qty)
          .toNumber(),
      };
    });
    const summaryCsv = convertToCSV(summaryToExport, { quotes: true });
    downloadCSVWithBom(
      summaryCsv,
      `orderItems_Summary_${dateFormatter(new Date())}`
    );
  }

  const csv = convertToCSV(
    {
      data: dataForExport,
      fields: itemExportFields,
    },
    { quotes: true }
  );
  downloadCSVWithBom(csv, `orderItems_${dateFormatter(new Date())}`); // download as 'orderItems.csv` file
};
