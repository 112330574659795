import React, { useEffect, useMemo } from "react";
import uniqBy from "lodash/uniqBy";
import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  // SelectInput,
  TextInput,
  NumberField,
  Show,
  // SimpleShowLayout,
  TabbedShowLayout,
  Tab,
  ImageField,
  // BulkDeleteButton,
  Pagination,
  // ReferenceManyField,
  ArrayField,
  // SingleFieldList,
  SelectField,
  BooleanField,
  BooleanInput,
  EmailField,
  AutocompleteArrayInput,
  useUnselectAll,
  useResourceContext,
  useRecordContext,
  // useListContext,
} from "react-admin";
// import { useController } from "react-hook-form";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
// import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import Loadable from "react-loadable";

// import { getPageId } from "./helpers/getPageId";

import BulkUpdateStatusButton from "./components/BulkUpdateStatusButton";
import BulkPrintDeliveryOrder from "./components/Order/BulkPrintDeliveryOrder";
import BulkPrintInvoice from "./components/Order/BulkPrintInvoice";
import OrderDiscountField from "./components/Order/OrderDiscountField";
import BulkBlackList from "./components/Order/BulkBlackList.js";
import BulkDelete from "./components/Order/BulkDelete";
import ContactChannelField from "./components/Order/ContactChannelField";

import BulkUpdateOrderOthers from "./components/BulkUpdateOrderOthers";
import PreorderToolBox from "./components/Order/PrefixRemove";
import ImageReceipt from "./components/ImageReceipt";

import MyOrderShowActions from "./components/OrderShowActions";
import MsgField from "./components/MsgField";
import {
  CANCELLED_ORDERS,
  PAID_ORDERS,
  WAITING_FOR_PAYMENT_ORDERS,
  READY_TO_SHIP_ORDERS,
  ACTIVE_ORDERS,
  READY_TO_SHIP_NOW,
  READY_TO_SHIP_SELF_COLLECT,
  NOW,
  LATER,
  SELF_COLLECT,
  ERROR_PAYMENT_MISMATCH,
  WAITING_FOR_PAYMENT,
  WAITING_FOR_ONLINE_PAYMENT,
  LABEL_WAITING_FOR_ONLINE_PAYMENT,
  LABEL_ERROR_PAYMENT_MISMATCH,
  ORDER_ITEM_CANCELLED,
  ORDER_ITEM_NEW,
  ORDER_ITEM_PAID,
  MANUAL_CHANNEL,
  COMMENT_CHANNEL,
  WEBSTORE_CHANNEL,
  NO_STACK,
  ACTIVE,
} from "./components/Constant";
import ButtonCopyAddress from "./Order/ButtonCopyAddress";
import PaymentQuickCreateButton from "./Order/PaymentQuickCreateButton";
import PaymentStatus from "./components/Payment/PaymentStatus";
import PaymentEdit from "./Order/PaymentEdit";
import PaymentDelete from "./Order/PaymentDelete";
import PaymentView from "./Order/PaymentView";
import ReconciledPayment from "./Order/ReconciledPayment";
import OrderTotalWeightOnOrderItems from "./Order/OrderTotalWeightOnOrderItems";
import OutstandingBalanceInclShippingField from "./Order/OutstandingPaymentInclShippingField";

import PaymentPaidAmount from "./components/Payment/PaymentPaidAmount";

// import OrderItemActions from "./components/OrderItem/OrderItemActions";
// import EmptyOrderItem from "./components/OrderItem/EmptyOrderItem";

import OrderListActions from "./Order/OrderListActions";
// import { exporter } from "./Order/Exporter";
import { withShowIfExist } from "./helpers/withShowIfExist";
// import { withDisabledIfCancelled } from "./helpers/withDisabled";

import ContactLink from "./Order/ContactLink";
import RelatedOrders from "./Order/RelatedOrders";

// import InboxLink from "./Order/InboxLink";
import TotalInclShipping from "./Order/TotalInclShipping";
import MerchandiseTotal from "./Order/MerchandiseTotal";
import WeightTotal from "./Order/WeightTotal";
import ShippingCostField from "./Order/ShippingCostField";
import OrderItemList from "./Order/OrderItemList";

import { useShippingMethod } from "./components/useShippingMethod";
import { useAvailableCurrencies } from "./components/useAvailableCurrencies";
import CopyTextOnClick from "./components/Order/CopyTextOnClick";
import ShippingOptionByName from "./components/Order/ShippingOptionByName";
import RatingInput from "./components/Contact/RatingInput";
import { StateProvider, store } from "./Order/ListSelectable";
import { OFFLINE } from "./components/Payment/types";
import MessengerChat from "./components/MessengerChat/MessengerChat";
import AmountWithTaxField, {
  withTaxWillHide,
} from "./Order/AmountWithTaxField";
import { useNinjaVanOptions } from "./components/NinjaVan/hooks";
import { useProfile } from "./components/DataHooks/useProfile";
import { useIsSuperAdminMode } from "./components/DataHooks/superAdminMode";
import TextLinkField from "./components/Common/TextLinkField";
import { CustomSrcImageField } from "./helpers/withReplaceImageSource";
import CustomPickerInput from "./components/OrderItem/CustomPickerInput";
import TextInputNoWheel from "./components/Common/TextInputNoWheel";
import CustomDateField from "./components/Common/CustomDateField";
import CreditPayment from "./Order/CreditPayment";

const NinjaVanBulkSubmit = Loadable({
  loader: () => import("./components/NinjaVan/BulkSubmit"),
  loading: () => "Loading",
});

const LoadableBulkPrintAWBButton = Loadable({
  loader: () => import("./components/NinjaVan/BulkPrintAWBButton"),
  loading: () => "Loading",
});

const CustomTotalInclShipping = withTaxWillHide(TotalInclShipping);

export const withoutActiveStatuses = [
  { id: "waiting_for_payment", name: "Waiting for payment" },
  { id: "paid", name: "Paid" },
  { id: "ready_to_ship", name: "Ready to ship" },
  { id: "shipped", name: "Shipped" },
  { id: "on_hold", name: "On hold" },
  { id: "preorder", name: "Preorder" },
  { id: NO_STACK, name: "No stack" },
  { id: "locked", name: "Locked" },
  { id: "cancelled", name: "Cancelled" },
];

export const statuses = [
  { id: ACTIVE, name: "Active" },
  ...withoutActiveStatuses,
];

const onlinePaymentStatuses = [
  { id: WAITING_FOR_ONLINE_PAYMENT, name: LABEL_WAITING_FOR_ONLINE_PAYMENT },
  { id: ERROR_PAYMENT_MISMATCH, name: LABEL_ERROR_PAYMENT_MISMATCH },
];

const activeStatuses = [
  { id: "active", name: "Active" },
  { id: "waiting_for_payment", name: "Waiting for payment" },
  { id: "paid", name: "Paid" },
];

export const orderItemStatuses = [
  { id: ORDER_ITEM_NEW, name: "New" },
  { id: ORDER_ITEM_PAID, name: "Paid" },
  { id: ORDER_ITEM_CANCELLED, name: "Cancelled" },
];

export const orderItemChannels = [
  { id: MANUAL_CHANNEL, name: "manual" },
  { id: COMMENT_CHANNEL, name: "comment" },
  { id: WEBSTORE_CHANNEL, name: "webstore" },
];

// export const shippingOptions = [
//   { id: NOW, name: "Deliver now/立即出货" },
//   { id: LATER, name: "Later/迟些发" },
//   { id: SELF_COLLECT, name: "Self collect/自提/面交" }
// ];

export const OrderTitle = ({ record }) => {
  return <span>Order {record ? `${record.customerName}` : ""}</span>;
};

const tinyFont = { fontSize: "10px" };

const OrderPagination = (props) => {
  const isSuperAdmin = useIsSuperAdminMode();
  if (isSuperAdmin) {
    return (
      <Pagination
        {...props}
        rowsPerPageOptions={[10, 25, 50, 100, 200, 1000]}
      />
    );
  }
  return <Pagination {...props} rowsPerPageOptions={[10, 25, 50, 100, 200]} />;
};

// const OrderItemPagination = props => (
//   <Pagination {...props} rowsPerPageOptions={[30, 60, 90, 120]} />
// );

const OrderBulkActionButtons = (props) => {
  const isSuperAdmin = useIsSuperAdminMode();
  const ninjaVanOptions = useNinjaVanOptions(
    process.env.REACT_APP_NINJAVAN_ENV
  );
  const { profile } = useProfile();
  const currentPath = window.location.hash;
  const isReadyToShip = currentPath.indexOf(`/${READY_TO_SHIP_ORDERS}`) > -1;
  const isAllOrders = currentPath.indexOf(`/orders`) > -1;

  return (
    <>
      {/* {isReadyToShip && <$ {...props} />} */}
      {((isAllOrders &&
        profile &&
        profile.params &&
        profile.params.enableAdminDeleteOrders) ||
        (isAllOrders && isSuperAdmin)) && <BulkDelete {...props} />}
      <BulkUpdateOrderOthers />
      <BulkBlackList />
      <BulkPrintDeliveryOrder {...props} />
      <BulkPrintInvoice {...props} />
      <BulkUpdateStatusButton label="update status" />
      {isReadyToShip && ninjaVanOptions.length > 0 && (
        <NinjaVanBulkSubmit {...props} />
      )}
      {ninjaVanOptions.length > 0 && <LoadableBulkPrintAWBButton {...props} />}
      <PreorderToolBox {...props} />
      {/* Add the default bulk delete action */}
      {/* <BulkDeleteButton {...props} /> */}
    </>
  );
};

const dateOptions = {
  disableFuture: true,
};
const dateFilters = [
  { source: "createdAt_gte", label: "CreatedAt start" },
  { source: "createdAt_lte", label: "CreatedAt end" },
  { source: "updatedAt_gte", label: "UpdatedAt start" },
  { source: "updatedAt_lte", label: "UpdatedAt end" },
].map(({ source, label }) => (
  <CustomPickerInput
    key={source}
    source={source}
    label={label}
    options={dateOptions}
  />
));

const CustomDatagrid = (props) => {
  const { state } = React.useContext(store);
  const newChildren = React.Children.toArray(props.children).filter(
    (child, i) => {
      if (child.props && child.props.source) {
        return state[child.props.source];
      } else return true;
    }
  );
  return <Datagrid {...props}>{newChildren}</Datagrid>;
};

export const OrderList = (props) => {
  const resource = useResourceContext();
  const [shippingOptions] = useShippingMethod({ showAll: true });
  const shippingOptions2 = useMemo(() => {
    const options = shippingOptions.concat([
      { id: NOW, name: "Deliver now/立即出货" },
    ]);
    return uniqBy(options, ({ id }) => id);
  }, [shippingOptions]);
  const unselectAll = useUnselectAll(resource);
  const { isMultiCurrency } = useAvailableCurrencies();
  let newProps = null;
  switch (resource) {
    case `${PAID_ORDERS}`:
      newProps = {
        ...props,
        title: "Paid orders",
        resource: "orders",
        filter: {
          q: "",
          status_in: ["paid"],
        },
        // filters: paidOrderFilter,
      };
      break;
    case `${WAITING_FOR_PAYMENT_ORDERS}`:
      newProps = {
        ...props,
        title: "Waiting payment orders",
        resource: "orders",
        filter: {
          q: "",
          status_in: [WAITING_FOR_PAYMENT, WAITING_FOR_ONLINE_PAYMENT],
        },
        // filters: paidOrderFilter,
      };
      break;
    case `${READY_TO_SHIP_ORDERS}`:
      newProps = {
        ...props,
        title: "Ready to ship orders",
        resource: "orders",
        // filter: { q: "" },
        filter: { q: "", status_in: ["ready_to_ship"] },
        // filters: allReadyToShipFilter,
      };
      break;
    case `${ACTIVE_ORDERS}`:
      newProps = {
        ...props,
        title: "Active orders",
        resource: "orders",
        filter: {
          q: "",
          status_in: ["active", "paid", "waiting_for_payment", "on_hold"],
        },
        // filters: activeOrderFilter,
      };
      break;
    default:
      // this supports search with q.
      newProps = { ...props };
  }
  //IMPORTANT notes
  // const pageId = getPageId();

  // if (newProps.filter) {
  //   newProps.filter.page = pageId;
  // } else {
  //   newProps.filter = {
  //     page: pageId,
  //   };
  // }
  useEffect(() => {
    return () => {
      unselectAll();
    };
  }, [unselectAll]);

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const theFilters = [
    <TextInput
      label="Customer name"
      key="customerName_contains"
      source="customerName_contains"
      alwaysOn
    />,
    <TextInput
      label="Order Number"
      key="orderNumber_contains"
      source="orderNumber_contains"
    />,
    <TextInputNoWheel
      type="number"
      label="DO/INV Number"
      key="invoiceNumber_eq"
      source="invoiceNumber_eq"
    />,
    <TextInput
      label="Tracking Number"
      key="trackingNumber_contains"
      source="trackingNumber_contains"
    />,
    <TextInput
      label="Description"
      key="description_contains"
      source="description_contains"
    />,
    ...(resource === "orders"
      ? [
          <AutocompleteArrayInput
            source="status_in"
            key="status_in"
            label={`Order status`}
            choices={[...statuses, ...onlinePaymentStatuses]}
            alwaysOn
          />,
        ]
      : []),
    <AutocompleteArrayInput
      source="shippingOption_in"
      key="shippingOption_in"
      choices={shippingOptions2}
      alwaysOn
    />,
    <BooleanInput
      label="DO/INV Printed"
      key="deliveryOrderPrinted_eq"
      source="deliveryOrderPrinted_eq"
      defaultValue={false}
    />,
    <TextInputNoWheel
      type="number"
      label="Postcode"
      key="shippingPostcode_contains"
      source="shippingPostcode_contains"
    />,
    ...dateFilters,
  ];

  return (
    <StateProvider>
      <List
        debounce={2000}
        {...newProps}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<OrderListActions filter={newProps.filter} />}
        filters={theFilters}
        pagination={<OrderPagination />}
      >
        {isSmall ? (
          <SimpleList
            linkType="show"
            primaryText={(record) => record.customerName}
            secondaryText={(record) =>
              `status: ${record.status}, ship: ${record.shippingOption || ""}`
            }
            tertiaryText={(record) => (
              <>
                <span style={tinyFont}>createdAt</span>
                <br />
                {`${new Date(record.createdAt).toLocaleDateString()}`}
              </>
            )}
          />
        ) : (
          <CustomDatagrid
            rowClick={(id) => `/${resource}/${id}/show`}
            bulkActionButtons={<OrderBulkActionButtons />}
          >
            <TextField
              source="contact.id"
              label={"customerId"}
              sortable={false}
            />
            <TextLinkField
              source="customerName"
              resourceName="orders"
              resourceKeyId="id"
              label="Nickname"
            />
            <TextField
              source="contact.remarks"
              label={"Contact remarks"}
              sortable={false}
            />
            <TextField source="status" />
            <SelectField
              source="shippingOption"
              label="Shipping option"
              choices={shippingOptions}
            />
            <MerchandiseTotal
              source="merchandiseTotal"
              label="Merchandise total"
              sortable={false}
            />
            <OutstandingBalanceInclShippingField
              source="outstandingPayment"
              // label="Outstanding payment"
              sortable={false}
            />
            <WeightTotal source="weight" label="Weight" sortable={false} />
            <NumberField source="shippingPostcode" label="Postcode" />

            {isMultiCurrency && (
              <TextField source="currencyCode" label={"Currency"} />
            )}
            <BooleanField
              source="deliveryOrderPrinted"
              label="DO printed"
              valueLabelTrue="Yes"
              valueLabelFalse="No"
            />
            <CustomDateField source="createdAt" showTime />
            <CustomDateField source="updatedAt" showTime />
            <TextField source="email" label={"Email"} />
            <TextField source="description" label={"Order description"} />
            <TextField source="notes" label={"Customer notes"} />
            <NumberField source="invoiceNumber" label="invoiceNumber" />
            <TextField source="orderNumber" label={"Order Number"} />
            <BooleanField
              source="overrideShippingCost"
              label="Shipping Cost Overriden"
              valueLabelTrue="Yes"
              valueLabelFalse="No"
            />
            <TextField source="shippingAddress1" label={"shippingAddress1"} />
            <TextField source="shippingAddress2" label={"shippingAddress2"} />
            <TextField source="shippingCity" label={"shippingCity"} />
            <TextField source="shippingCost" label={"shippingCost"} />
            <TextField source="shippingCountry" label={"shippingCountry"} />
            <TextField source="shippingName" label={"shippingName"} />
            <TextField
              source="shippingPhoneNumber"
              label={"shippingPhoneNumber"}
            />
            <TextField source="shippingProvince" label={"shippingProvince"} />
            <TextField source="shippingState" label={"shippingState"} />
            <TextField
              source="shippingSubDistrict"
              label={"shippingSubDistrict"}
            />
            <TextField source="shippingVillage" label={"shippingVillage"} />
            <TextField source="trackingNumber" label={"trackingNumber"} />
          </CustomDatagrid>
        )}
      </List>
    </StateProvider>
  );
};

// const AmountPaid = ({ source, record = {} }) => {
//   const amountPaid =
//     record.orderItems &&
//     record.orderItems.filter &&
//     getSum(record.orderItems.filter((i) => i.status === "paid"));
//   return <span>{amountPaid}</span>;
// };

const TextFieldShowIfExist = withShowIfExist(TextField);
const CopyTextOnClickShowIfExists = withShowIfExist(CopyTextOnClick);
const ImageFieldShowIfExist = withShowIfExist(ImageField);

// const OrderItemTextField = withDisabledIfCancelled(TextField);
// const OrderItemNumberField = withDisabledIfCancelled(NumberField);
// const OrderItemDateField = withDisabledIfCancelled(DateField);

const ContactRatingInput = () => {
  const record = useRecordContext();
  const { contact = null } = record;
  if (!contact) {
    return null;
  }
  const { rating = 0 } = contact;
  return <RatingInput key={rating} record={contact} />;
};

const OrderShowLayout = () => {
  const record = useRecordContext();
  const [shippingOptions] = useShippingMethod({ showAll: true });

  if (!record) {
    return null;
  }
  const hasTax = record && record.tax;
  return (
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="orderNumber" />
        <TextField source="invoiceNumber" />
        <ContactChannelField source="contact.channel" label="Channel" />
        <ContactLink source="customerName" label="Nickname" />
        <ImageField
          source="contact.profilePic"
          sx={{
            "& .RaImageField-image": {
              width: 140,
              height: 140,
            },
          }}
        />
        <TextField source="contact.remarks" />
        <ContactRatingInput />
        <RelatedOrders />
        <TextField source="description" />
        <TextField source="currencyCode" />
        <OrderItemList label="Items" />
        <TextField source="status" label="Order status" />
        <SelectField
          source="shippingOption"
          label="Shipping option"
          // choices={newShippingOptions}
          choices={shippingOptions}
        />
        <ShippingCostField label="Shipping Cost 运费" />
        <MerchandiseTotal label="Merchandise Total(excl. shipping) 货品总价(不含运费)" />
        <CustomTotalInclShipping label="Merchandise Total incl. shipping 货品总价(含运费)" />
        <OrderDiscountField label="Discount" />
        {hasTax && (
          <TextFieldShowIfExist source="tax.internalLabel" record={record} />
        )}
        {hasTax && (
          <AmountWithTaxField source="totalBeforeTax" record={record} />
        )}
        {hasTax && <AmountWithTaxField source="taxAmount" record={record} />}
        {hasTax && (
          <AmountWithTaxField source="totalAfterTax" record={record} />
        )}
        <ReconciledPayment label="Total reconciled payment" />
        <CreditPayment label="Total credit payment" />
        {/* <AmountWithTaxColorField
    source="outstandingPayment"
    label="Outstanding payment with tax"
  /> */}
        <OutstandingBalanceInclShippingField
          source="outstandingPayment"
          label="Outstanding payment (欠款)"
        />
        <OrderTotalWeightOnOrderItems label="Weight (kg)" />
        <BooleanField
          source="deliveryOrderPrinted"
          valueLabelTrue="Yes"
          valueLabelFalse="No"
        />
        <TextField source="trackingNumber" />
        <TextField source="notes" />
        <CustomDateField source="createdAt" showTime />
        <CustomDateField source="updatedAt" showTime />
      </Tab>
      <Tab label="shipping">
        <ButtonCopyAddress />
        <CopyTextOnClick source="shippingName" label="Shipping Name" />
        <CopyTextOnClick
          source="shippingPhoneNumber"
          label="Shipping Phone Number"
        />
        <CopyTextOnClick source="shippingAddress1" label="Shipping Address 1" />
        <CopyTextOnClick source="shippingAddress2" label="Shipping Address 2" />
        <CopyTextOnClickShowIfExists
          source="shippingVillage"
          label={false}
          customLabel="Shipping Village"
        />
        <CopyTextOnClickShowIfExists
          source="shippingSubDistrict"
          label={false}
          customLabel="District/Kecamatan"
        />
        <CopyTextOnClickShowIfExists
          source="shippingCity"
          label={false}
          customLabel="Shipping City/Kota"
        />
        <CopyTextOnClickShowIfExists
          source="shippingProvince"
          label={false}
          customLabel="Province/Provinsi"
        />
        <CopyTextOnClick source="shippingState" label="Shipping State" />
        <CopyTextOnClick source="shippingPostcode" label="Shipping Postcode" />
        <ShippingOptionByName label="Shipping Option" />
        <EmailField source="email" />
        <TextField source="shippingCountry" />
      </Tab>
      <Tab label="receipt">
        <ImageFieldShowIfExist
          source="paymentReceipt"
          src="name"
          customLabel="Legacy receipts"
          label={false}
        />
        <PaymentQuickCreateButton />
        <OutstandingBalanceInclShippingField
          source="outstandingPayment"
          label="Outstanding payment (欠款)"
        />
        <ReconciledPayment label="Total reconciled payment" />
        <CreditPayment label="Total credit payment" />
        <ArrayField source="payments" label="Receipts">
          <Datagrid bulkActionButtons={false}>
            <ImageReceipt />
            <TextField source="type" />
            <PaymentStatus />
            <PaymentPaidAmount source="paidAmount" />
            <TextField source="remarks" />
            <CustomDateField source="createdAt" showTime />
            <CustomDateField source="updatedAt" showTime />
            <PaymentEdit orderData={record} />
            <PaymentDelete />
            <PaymentView />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label="Chat(BETA)">
        <MessengerChat />
      </Tab>
      <Tab label="Photo">
        <CustomSrcImageField source="image" src="name" />
      </Tab>
      <Tab label="Msg">
        <MsgField source="orderNumber" />
      </Tab>
    </TabbedShowLayout>
  );
};

export const OrderShow = (props) => {
  // const { record } = useShowController(props);
  const unselectAll = useUnselectAll("orderItems");
  // const { location } = props;
  const newProps = { ...props, resource: "orders" };
  React.useEffect(() => {
    return () => unselectAll();
  }, [unselectAll]);
  return (
    <Show title="Order view" actions={<MyOrderShowActions />} {...newProps}>
      <OrderShowLayout />
    </Show>
  );
};
