import React from "react";
import { Layout } from "react-admin";
import MyMenu from "./index";
import MyAppBar from "./AppBar";
import MyError from "./MyError";

const MyLayout = (props) => (
  <Layout
    {...props}
    menu={MyMenu}
    appBar={MyAppBar} //error={MyError}
  />
);

export default MyLayout;
