import React from "react";
import { useRecordContext } from "react-admin";
import { OFFLINE } from "./types";

export const withShowIfOfflinePayment = (WrappedComp) => (props) => {
  const record = useRecordContext();
  if (record.type === OFFLINE) {
    return <WrappedComp {...props} />;
  }
  return null;
};
