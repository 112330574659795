import React from "react";
import Tooltip from "@mui/material/Tooltip";
import styled from "styled-components";
import Decimal from "decimal.js-light";

const OutstandingSpan = styled.span`
  background-color: ${(props) => props.color || "white"};
`;

//https://stackoverflow.com/questions/17525215/calculate-color-values-from-green-to-red
function percentageToHsl(percentage, hue0, hue1) {
  var hue = percentage * (hue1 - hue0) + hue0;
  return "hsl(" + hue + ", 100%, 50%)";
}

const CustomerOwe = (props) => {
  const { contact } = props;

  const outstandingAmountDecimal =
    contact.orders &&
    contact.orders.reduce((acc, cur) => {
      return acc.plus(cur.outstandingAmount);
    }, new Decimal(0));

  const orderItemPriceSumDecimal =
    contact.orders &&
    contact.orders.reduce((acc, cur) => {
      return acc.plus(cur.orderItemPriceSum);
    }, new Decimal(0));
  const owePercentageDecimal = orderItemPriceSumDecimal.isZero()
    ? new Decimal(0)
    : outstandingAmountDecimal.dividedBy(orderItemPriceSumDecimal).todp(4);

  return (
    <Tooltip
      title={`of items total ${orderItemPriceSumDecimal.toNumber()} (${owePercentageDecimal
        .times(100)
        .toNumber()}%)`}
    >
      <OutstandingSpan
        color={percentageToHsl(owePercentageDecimal.toNumber(), 120, 0)}
      >
        {outstandingAmountDecimal.toFixed(0)}
      </OutstandingSpan>
    </Tooltip>
  );
};

export default CustomerOwe;
