import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import { useNotify } from "react-admin";

import { useProfile } from "../DataHooks/useProfile";
import { fetchHandleError } from "../../helpers/fetchHandleError";
import { usePaymentMessage } from "../MsgField";

const ManualSendButton = (props) => {
  const notify = useNotify();
  const { psid = "", order = null } = props;
  const text = usePaymentMessage(order);
  const { profile } = useProfile();
  const [link, setLink] = React.useState("");

  React.useEffect(() => {
    if (profile && profile.page && profile.page.token && psid) {
      fetch(
        `${process.env.REACT_APP_GRAPH_API_URL}/me/conversations?access_token=${profile.page.token}&user_id=${psid}`
      )
        .then(fetchHandleError)
        .then((data) => {
          if (data && data.data[0] && data.data[0].link) {
            setLink(`https://www.facebook.com${data.data[0].link}`);
          }
        })
        .catch((e) => {
          console.log("No found");
        });
    }
  }, [psid, profile]);

  const handleClick = React.useCallback(() => {
    window.open(link, "_blank");
  }, [link]);

  return (
    <CopyToClipboard
      text={text}
      onCopy={() => {
        notify("Message copied, opening inbox...");
      }}
    >
      <Button disabled={!link || !text} color="secondary" onClick={handleClick}>
        <SendIcon />
        Manual
      </Button>
    </CopyToClipboard>
  );
};

export default ManualSendButton;
