import React, { useCallback, useState, useContext } from "react";
import { useNotify } from "react-admin";
import { makeStyles } from "@mui/styles";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { createComment, createOrderItem } from "./sharedMethods";
import { useProfile } from "../DataHooks/useProfile";

import {
  store,
  ADD_ITEM_DESC,
  ADD_SPEC_DESC,
  SET_UNIT_PRICE,
  SET_LOADING,
  RESET,
} from "./store";
import { getCookie } from "../../helpers/Cookies";
// import { withEnableFlag } from "./withEnableFlag";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const ControlPanel = (props) => {
  const classes = useStyles();
  const { dispatch, state } = useContext(store);
  const { itemDesc, specDesc, unitPrice, video, orders } = state;
  const { profile } = useProfile();

  const notify = useNotify();
  const token = getCookie("token");
  const [startLoading, setStartLoading] = useState(false);
  const [endLoading, setEndLoading] = useState(false);

  const onChangeItem = useCallback(
    (e) => dispatch({ type: ADD_ITEM_DESC, payload: e.target.value }),
    [dispatch]
  );
  const onChangeSpec = useCallback(
    (e) => dispatch({ type: ADD_SPEC_DESC, payload: e.target.value }),
    [dispatch]
  );
  const onChangeUnitPrice = useCallback(
    (e) => {
      dispatch({ type: SET_UNIT_PRICE, payload: e.target.value });
    },
    [dispatch]
  );

  const postNameList = useCallback(() => {
    const nameList = orders
      .map((o) => `${o.customerName}+${o.quantity}`)
      .join(", ");
    const value = `${itemDesc}\n${nameList}`;
    createComment({ value, video, profile })
      .then(() => {
        notify("Name list posted");
      })
      .catch(() => {
        notify("Post name list failed", "warning");
      });
  }, [video, profile, orders, itemDesc, notify]);

  const postStartComment = useCallback(() => {
    setStartLoading(true);
    const value = `---START---\n${itemDesc}\nPrice ${unitPrice}\n${specDesc}`;
    createComment({ value, video, profile })
      .then(() => {
        notify("Successful");
        setStartLoading(false);
      })
      .catch(() => {
        notify("Error", "warning");
        setStartLoading(false);
      });
  }, [notify, itemDesc, unitPrice, specDesc, video, profile]);

  const postEndComment = useCallback(() => {
    setEndLoading(true);
    const value = `---END---\n${itemDesc}\n`;
    createComment({ value, video, profile })
      .then(() => {
        notify("Successful");
        setEndLoading(false);
      })
      .catch(() => {
        notify("Error", "warning");
        setEndLoading(false);
      });
  }, [video, profile, notify, itemDesc]);

  const submitOrders = useCallback(() => {
    dispatch({ type: SET_LOADING, payload: true });
    const newOrders = orders.map((o) => ({
      ...o,
      description: `${o.description} x${o.quantity}`,
    }));
    const payload = {
      data: {
        orders: newOrders,
      },
    };
    createOrderItem({ token, payload })
      .then((resp) => {
        notify("Orders submitted successfully");
        postNameList();
        dispatch({ type: RESET });
        dispatch({ type: SET_LOADING, payload: false });
      })
      .catch((e) => {
        notify("Error: submit order failed", "warning");
        dispatch({ type: SET_LOADING, payload: false });
      });
  }, [dispatch, token, orders, notify]);

  return (
    <div>
      <form className={classes.root} noValidate autoComplete="off">
        <TextField
          label="Item"
          required
          onChange={onChangeItem}
          value={itemDesc}
        />
        <TextField
          label="Unit Price"
          type="number"
          placeholder="e.g. 22"
          onChange={onChangeUnitPrice}
          value={unitPrice}
        />
        <TextField
          label="Specifications"
          multiline
          rows="4"
          placeholder="a*1, b*2"
          variant="outlined"
          onChange={onChangeSpec}
          value={specDesc}
        />
      </form>
      <ButtonGroup color="primary" aria-label="outlined primary button group">
        <Button onClick={postStartComment} disabled={!itemDesc || startLoading}>
          Start
        </Button>
        <Button onClick={postEndComment} disabled={!itemDesc || endLoading}>
          End
        </Button>
        <Button disabled={orders.length === 0} onClick={submitOrders}>
          Submit
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default ControlPanel;
