import React from "react";
import Avatar from "@mui/material/Avatar";

import { withProfile } from "../DataHooks/useProfile";
import { replaceImageSource } from "../../helpers/withReplaceImageSource";

const Logo = (props) => {
  const { profile } = props;

  return <Avatar src={replaceImageSource(profile.logo)} />;
};

export default withProfile(Logo);
