import React, { useCallback, useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone, { getFilesFromEvent } from "react-dropzone-uploader";
import imageCompression from "browser-image-compression";
import { usePresignedUrl } from "../components/DataHooks/fileUpload";
import { useNotify } from "react-admin";

// const s3Bucket = "https://img-customer.boxify.io/";

const resize = async (e) => {
  const files = getFilesFromEvent(e);
  const options = {
    maxSizeMB: 0.2,
    maxWidthOrHeight: 1920,
  };

  const promises = files.map((f) => {
    const isPdf = f.name.toLowerCase().split(".pdf").length > 1;
    try {
      if (!isPdf && f.size > 200000) {
        return imageCompression(f, options);
      }
      return f;
    } catch (error) {
      // console.log(error);
      return f;
    }
  });
  const compressedFiles = await Promise.all(promises);
  return compressedFiles;
};

const Uploader = (props) => {
  const { setFileUrl, orderNumber } = props;
  const notify = useNotify();

  const { trigger: getPresignedUploadParams } = usePresignedUrl();

  const [upload, setUpload] = useState([]);
  // const [files, setFiles] = useState([]);

  // specify upload params and url for your files
  const getUploadParams = useCallback(
    async (fileWithMeta) => {
      const { file, meta } = fileWithMeta;
      const date = new Date();
      const timestamp = date.getTime();
      const metaName = encodeURIComponent(meta.name);
      const fileName = `receipts/${orderNumber}/${timestamp}-${metaName}`;
      const [{ getUrl, putUrl }] = await getPresignedUploadParams([fileName]);
      try {
        setUpload((upload) => [
          {
            name: getUrl,
            originalName: meta.name,
            id: meta.id,
            uploaded: false,
          },
        ]);

        return {
          method: "PUT",
          body: file,
          meta: { getUrl },
          url: putUrl,
        };
        // return {
        //   fields: {
        //     key: fileName,
        //     "Content-Type": meta.type,
        //   },
        //   url: s3Bucket,
        // };
      } catch (e) {
        notify(`Failed to upload, please try again later.`, { type: "error" });
      }
    },
    [getPresignedUploadParams, notify, orderNumber]
  );

  // called every time a file's `status` changes
  const handleChangeStatus = (fileWithMeta, status) => {
    const { meta } = fileWithMeta;
    // console.log(status, meta, file, props);
    if (status === "done") {
      setUpload((upload) =>
        upload.map((f) => {
          if (f.id === meta.id) {
            setFileUrl(encodeURI(f.name));
            return {
              ...f,
              uploaded: true,
            };
          }
          return f;
        })
      );
    }
  };

  const completed = upload.filter((f) => f.uploaded === true);
  const inProgress = upload.filter((f) => f.uploaded === false);
  return (
    <>
      <Dropzone
        // styles={{
        //   dropzone: { width: width * 0.85, height: width * 0.65 },
        //   dropzoneActive: { borderColor: "green" }
        // }}
        maxFiles={1}
        inputContent="Upload payment receipt"
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        getFilesFromEvent={resize}
        multiple={false}
        canRemove={false}
      />
      <ul>
        {inProgress.length > 0 &&
          inProgress.map((c) => (
            <li key={c.id}>{`${c.originalName} uploading.....`}</li>
          ))}
        {completed.length > 0 &&
          completed.map((c) => (
            <li key={c.id}>{`${c.originalName} uploaded successfully`}</li>
          ))}
      </ul>
    </>
  );
};

export default Uploader;
