import React from "react";
import { useTranslate, Show, SimpleShowLayout, TextField } from "react-admin";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

import { useClickAway } from "../../helpers/useClickAway";
import OrderLink from "./OrderLink";
import PaymentStatus from "./PaymentStatus";
import BillplzReceiptLink from "./BillplzReceiptLink";
import PaymentPaidAmount from "./PaymentPaidAmount";
import CustomDateField from "../Common/CustomDateField";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 40,
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "1em",
  },
  form: {
    [theme.breakpoints.up("xs")]: {
      width: 400,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      marginTop: -30,
    },
  },
  inlineField: {
    display: "inline-block",
    width: "50%",
  },
  success: {
    color: "green",
  },
  failed: {
    color: "red",
  },
}));

export const PaymentShow = (props) => {
  const { onCancel } = props;
  const translate = useTranslate();
  const classes = useStyles();
  const wrapperRef = React.useRef(null);
  const [clickAway] = useClickAway(wrapperRef);
  React.useEffect(() => {
    if (clickAway) {
      onCancel();
    }
  }, [clickAway, onCancel]);
  return (
    <div className={classes.root} ref={wrapperRef}>
      <div className={classes.title}>
        <Typography variant="h6">{translate("Payment show")}</Typography>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <Show title={`Show`} {...props}>
        <SimpleShowLayout toolbar={null}>
          <TextField source="name" />
          <TextField source="type" />
          <TextField source="billId" label={`Bill ID`} />
          <PaymentPaidAmount source="paidAmount" />
          <PaymentStatus />
          <OrderLink />
          <BillplzReceiptLink />
          <CustomDateField source="createdAt" showTime />
          <CustomDateField source="updatedAt" showTime />
        </SimpleShowLayout>
      </Show>
    </div>
  );
};

export default PaymentShow;
