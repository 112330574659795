import React from "react";
import Button from "@mui/material/Button";
import { EditButton, TopToolbar, useRecordContext } from "react-admin";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

const ContactShowActions = () => {
  const record = useRecordContext();
  return (
    <TopToolbar>
      <EditButton />
      {record && (
        <Button
          color="primary"
          target="_blank"
          rel="noopener noreferrer"
          to={{
            pathname: "/activeorders/create",
            hash: `${record._id}`,
          }}
          component={Link}
        >
          <AddIcon />
          New order
        </Button>
      )}
    </TopToolbar>
  );
};

export default ContactShowActions;
