import React, { useCallback, useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useDataProvider } from "react-admin";

import useDebounce from "../UseDebounce";
import { getPageId } from "../../helpers/getPageId";

const Comp = (props) => {
  const { field, onChange } = props;
  const { name, searchFieldName = "label" } = field;
  const pageId = getPageId();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = React.useState("");
  const debouncedSearchTerm = useDebounce(inputValue, 800);

  const getOptionsDelayed = useCallback(
    (text) => {
      setLoading(true);
      const trimmed = text.trim();

      dataProvider
        .getList(name, {
          sort: { field: "createdAt", order: `DESC` },
          filter: {
            page: pageId,
            ...(trimmed && { [`${searchFieldName}_contains`]: trimmed }),
          },
          pagination: { page: 1, perPage: 200 },
        })
        .then(({ data }) => {
          setOptions(data);
          setLoading(false);
        })
        .catch((error) => {
          // console.error(error);
          setLoading(false);
          // notify("ra.notification.http_error", "warning");
        });
    },
    [dataProvider, pageId, name, searchFieldName]
  );

  useEffect(() => {
    getOptionsDelayed(debouncedSearchTerm);
  }, [debouncedSearchTerm, getOptionsDelayed]);

  return (
    <Autocomplete
      sx={{ width: 300 }}
      options={options}
      value={null}
      onChange={onChange}
      getOptionLabel={(option) => `${option.label}: ${option.description}`}
      // disable filtering on client
      filterOptions={(x) => x}
      loading={loading}
      onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
      renderInput={(params) => <TextField {...params} label={name} />}
    />
  );
};

export default Comp;
