import React, { useState, useEffect, useCallback } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import differenceInHours from "date-fns/differenceInHours";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";
import CircularProgress from "@mui/material/CircularProgress";
import SendIcon from "@mui/icons-material/Send";
import queryString from "query-string";
import { SWRConfig } from "swr";
import useSWRImmutable from "swr/immutable";

import { withProfile } from "../DataHooks/useProfile";
import { fetchSendMessenger } from "../OrderShowActions";
import { useGetToken } from "../DataHooks/useGetToken";
import {
  useTakeThreadControl,
  useReleaseThreadControl,
} from "../DataHooks/threadControl";
import { uniqueByValue } from "../../helpers/methods";
import { NO_STACK } from "../Constant";

const MAX_CONCURRENT_COUNT = 4;
const MAX_ORDERS_LIMIT = 5000;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const sendableStatuses = [
  { id: "active", name: "Active" },
  { id: "waiting_for_payment", name: "Waiting for payment" },
  { id: "paid", name: "Paid" },
  { id: "on_hold", name: "On Hold" },
  { id: "preorder", name: "Preorder" },
  { id: NO_STACK, name: "No Stack" },
  { id: "locked", name: "Locked" },
];

const BatchSend = (props) => {
  const { profile, open, setOpen } = props;
  const [forceSend, setForceSend] = useState(false);
  const [sending, setSending] = useState(false);
  const [pending, setPending] = useState(0);
  const [totalcount, setTotalcount] = useState(0);
  const [badcount, setBadcount] = useState(0);
  const [failedOrders, setFailedOrders] = useState([]);
  const [orders, setOrders] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [startGetOrders, setStartGetOrders] = useState(false);
  const [errors, setErrors] = useState([]);
  // const [processOrders, setProcessOrders] = useState([]);
  const { trigger: take } = useTakeThreadControl();
  const { trigger: release } = useReleaseThreadControl();
  const token = useGetToken();

  const { data, error } = useSWRImmutable(
    startGetOrders
      ? `${process.env.REACT_APP_CMS_HOST}/orders/?_sort=_id:DESC&page=${
          profile.page._id
        }&_limit=${MAX_ORDERS_LIMIT}&${queryString.stringify({
          status_in: selectedStatuses,
        })}`
      : null,
    {
      revalidateOnFocus: false,
      refreshInterval: 0,
    }
  );

  const reset = useCallback(() => {
    setSending(false);
    setOrders([]);
    setPending(0);
    setTotalcount(0);
    setBadcount(0);
    setFailedOrders([]);
    setErrors([]);
  }, []);
  const handleClose = useCallback(() => {
    reset();
    setOpen(false);
  }, [reset, setOpen]);

  const startSend = useCallback(() => {
    setSending(true);
  }, []);

  useEffect(() => {
    if (data) {
      const canReplyOrders = data
        .filter(({ contact }) => contact && contact.canReply)
        .map((o) => ({
          orderNumber: o.orderNumber,
          updatedAt: o.updatedAt,
          contact: o.contact,
          customerName: o.customerName,
          id: o.id,
        }));
      setOrders(canReplyOrders);
      setTotalcount(canReplyOrders.length);
      setBadcount(data.length - canReplyOrders.length);
    }
  }, [data]);

  useEffect(() => {
    if (sending && pending <= 0 && orders.length > 0) {
      const list = orders.slice(0, MAX_CONCURRENT_COUNT - 1);
      list.map(({ orderNumber, updatedAt, contact, customerName, id }) => {
        return fetchSendMessenger({ token, orderNumber })
          .then((resp) => setPending((prev) => prev - 1))
          .catch(async (e) => {
            if (
              forceSend ||
              e.indexOf(
                "Message failed to send because another app is controlling this thread now"
              ) > -1
            ) {
              await take({
                recipient: {
                  id: `${contact.psid}`,
                },
              }).catch((e) => {});
              await fetchSendMessenger({
                token,
                orderNumber,
                standardMessaging: !forceSend,
              })
                .then((resp) => {
                  setPending((prev) => prev - 1);
                })
                .catch((e) => {
                  setPending((prev) => prev - 1);
                  setFailedOrders((prev) => [
                    ...prev,
                    { orderNumber, customerName, id },
                  ]);
                  setErrors((prev) => [...prev, e]);
                });
              release({
                recipient: {
                  id: `${contact.psid}`,
                },
              });
            } else {
              setPending((prev) => prev - 1);
              setFailedOrders((prev) => [
                ...prev,
                { orderNumber, customerName, id },
              ]);
            }
          });
      });
      setPending((prev) => prev + list.length);
      setOrders(orders.slice(list.length));
    }
  }, [token, orders, sending, pending, forceSend, take, release]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedStatuses(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Mass Send Shopping Cart</DialogTitle>
        <DialogContent>
          <div>
            <li>
              This will send out shopping cart messages for the order status you
              selected
            </li>
            <li>
              Maximum {MAX_ORDERS_LIMIT} each send operation, please split up
              the operation if you have more than {MAX_ORDERS_LIMIT} orders
            </li>
            <li>
              "active" orders will be updated to "waiting_for_payment" upon
              successful operation
            </li>
            <li>
              "waiting_for_payment" & "paid" & "preorder" & "no_stack" orders
              will remain their status
            </li>
          </div>
          <br />
          <div>
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="multiple-chip-label">Status</InputLabel>
              <Select
                labelId="multiple-chip-label"
                id="multiple-chip"
                multiple
                value={selectedStatuses}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {sendableStatuses.map((status) => (
                  <MenuItem key={status.id} value={status.id}>
                    <Checkbox
                      checked={selectedStatuses.indexOf(status.id) > -1}
                    />
                    <ListItemText primary={status.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={() => setStartGetOrders(true)}
            >
              Get Orders
            </Button>
          </div>
          <div style={{ visibility: startGetOrders ? "visible" : "hidden" }}>
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!!profile.params.disableForceSendCart}
                  checked={forceSend}
                  onChange={() => {
                    setForceSend((prev) => !prev);
                  }}
                  name="forceSend"
                />
              }
              label={`Force send (May risk Messenger block) ${
                profile.params.disableForceSendCart ? "Disabled by Admin" : ""
              }`}
            />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://learn.boxify.io/kb/monitor-your-page-quality-insights-to-prevent-the-blocked-or-banned-by-facebbok/"
            >
              Learn more
            </a>
            {!data && !error && <CircularProgress />}
            {error && <div>Error, something went wrong.</div>}
            {errors.length > 0 && (
              <>
                <p>Errors</p>
                <ul>
                  {errors.filter(uniqueByValue).map((e, idx) => {
                    return <li key={idx}>{e}</li>;
                  })}
                </ul>
              </>
            )}
            <div>{`Require customer to inbox or ice break first:  ${badcount}\n`}</div>
            <div>
              {" "}
              {`${totalcount - orders.length} of ${totalcount} processed`}
            </div>
            <div> {`${failedOrders.length} failed`}</div>
            {failedOrders.length > 0 && (
              <>
                <p>Failed Orders</p>
                <ul>
                  {failedOrders.map(
                    ({ orderNumber, customerName, id }, idx) => {
                      return (
                        <li key={idx}>
                          <a href={`/#/orders/${id}/show`}>
                            {orderNumber} - {customerName}
                          </a>
                        </li>
                      );
                    }
                  )}
                </ul>
              </>
            )}
            <DialogContentText id="alert-dialog-description">
              Please keep screen active while sending
            </DialogContentText>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
          <Button
            disabled={!sending || orders.length <= 0}
            variant="contained"
            onClick={reset}
            color="primary"
            autoFocus
          >
            Stop
          </Button>
          <Button
            disabled={sending}
            variant="contained"
            onClick={startSend}
            color="primary"
            autoFocus
          >
            Start
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const BatchSendWithProfile = withProfile(BatchSend);
const TheBatchSendButton = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          setOpen(true);
        }}
      >
        <SendIcon />
        Mass FB send
      </Button>
      {open && (
        <SWRConfig value={{ provider: () => new Map() }}>
          <BatchSendWithProfile setOpen={setOpen} open={open} />
        </SWRConfig>
      )}
    </>
  );
};

// export default withProfile(BatchSend);
export default TheBatchSendButton;
