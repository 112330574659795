import { unparse as convertToCSV } from "papaparse/papaparse.min";
import groupBy from "lodash/groupBy";
import Decimal from "decimal.js-light";
import { format } from "date-fns";

import {
  getSum,
  pz,
  sanitizePunctuation,
  comboRegex,
  downloadCSVWithBom,
} from "../helpers/methods";
import { useGetDiscountValue } from "../components/Voucher/useGetDiscount";
import { calculateWeight } from "./OrderTotalWeightOnOrderItems";
import { ORDER_ITEM_CANCELLED } from "../components/Constant";
import { useGetOutstandingPaymentInclShipping } from "./OutstandingPaymentInclShippingField";
import { useReconciledPayment } from "./ReconciledPayment";
import { useTotalInclShipping } from "./TotalInclShipping";
import { getTaxCalculation } from "../components/Taxation/withTaxCalculation";
import {
  CONTACT_CHANNEL_FACEBOOK,
  CONTACT_CHANNEL_OFFLINE,
} from "../components/Constant.js";

export const exporter = ({
  keywordsDetails = [],
  exportTopSpenders = false,
  separateItemsIntoLines = false,
  exportSummary = false,
  invoiceNumberPrefix = "FB",
  invoiceNumberLength = 8,
  shippingOptions = [],
  orders = [],
  orderExportFields = [],
  MDYDateFormat = true,
}) => {
  let ordersForExport = [];
  let itemsForExport = [];
  let itemsSummary = [];

  const dateFormatter = MDYDateFormat
    ? (d) => format(new Date(d), "MM/dd/yyyy")
    : (d) => format(new Date(d), "dd/MM/yyyy");

  orders.forEach((order) => {
    const {
      isLegacy,
      updatedAt,
      _id,
      id,
      contact,
      page,
      orderItems,
      createdAt,
      invoiceNumber,
      shippingOption,
      voucher,
      shippingAddress1,
      shippingAddress2,
      payments,
      deliveryOrderPrinted,
      ...orderForExport
    } = order;

    const filtered = orderItems.filter(
      (i) => i.status !== ORDER_ITEM_CANCELLED
    );
    const weight = calculateWeight(orderItems);
    const totalExcludeShipping = getSum(filtered);
    const totalIncludeShipping = useTotalInclShipping(order);
    const selected = shippingOptions.find((o) => o.id === shippingOption);
    const voucherCode = voucher && voucher.code ? voucher.code : "";
    const discountValue = useGetDiscountValue(order);
    const totalAfterDiscount = new Decimal(totalIncludeShipping)
      .minus(discountValue)
      .toFixed(2);
    const newShippingAddress1 = sanitizePunctuation(shippingAddress1);
    const newShippingAddress2 = sanitizePunctuation(shippingAddress2);
    const receivedPayment = useReconciledPayment(payments);
    const taxCalculation = !!order.tax ? getTaxCalculation(order) : null;

    const outstandingAmount = !!taxCalculation
      ? taxCalculation["outstandingPayment"]
      : useGetOutstandingPaymentInclShipping({
          record: order,
        }).toFixed(2);
    const totalCost = filtered.reduce((total, item) => {
      return total + item.cost;
    }, 0);
    const totalProfit = totalAfterDiscount - totalCost;
    const orderChannel = contact
      ? contact.channel
        ? contact.channel
        : CONTACT_CHANNEL_FACEBOOK
      : CONTACT_CHANNEL_OFFLINE;
    if (!separateItemsIntoLines || exportTopSpenders) {
      const itemDescriptions = filtered.reduce((acc, cur, idx, src) => {
        const { description = "", createdAt, updatedAt } = cur;
        const comboRegex = /(.*)(x\d{1,}$)/i;
        const matches = comboRegex.exec(description.replace(/\s/g, " "));

        const orderedAt = dateFormatter(createdAt);
        if (matches) {
          const [fullString, productDescription, quantity] = matches;
          const splitted = productDescription.split(",").map((str, idx) => {
            if (idx === 0) {
              return `${orderedAt}: ${str} ${quantity}`;
            }
            return str && `- ${str.trim()}`;
          });
          return [...acc, ...splitted];
        } else {
          const splitted = description
            .replace(/\s/g, " ")
            .split(",")
            .map((str) => str && `${orderedAt}: ${str.trim()}`);
          return [...acc, ...splitted];
        }
      }, []);
      ordersForExport.push({
        ...orderForExport,
        contactRemarks: contact && contact.remarks ? contact.remarks : "",
        shippingAddress1: newShippingAddress1,
        shippingAddress2: newShippingAddress2,
        contactId: contact ? contact.id : "",
        customerPSID: contact ? contact.psid : "",
        orderChannel: orderChannel,
        orderItems: itemDescriptions.join("\n"),
        createdAtDate: dateFormatter(createdAt),
        createdAtTime: new Date(createdAt).toLocaleTimeString(),
        updatedAtDate: dateFormatter(updatedAt),
        updatedAtTime: new Date(updatedAt).toLocaleTimeString(),
        totalExcludeShipping,
        totalIncludeShipping,
        discountValue,
        totalAfterDiscount,
        totalBeforeTax: taxCalculation ? taxCalculation["totalBeforeTax"] : "",
        tax: taxCalculation ? taxCalculation["taxAmount"] : "",
        totalAfterTax: taxCalculation ? taxCalculation["totalAfterTax"] : "",
        taxableAmount: taxCalculation ? taxCalculation["taxableAmount"] : "",
        deliveryOrderPrinted,
        totalCost,
        totalProfit,
        receivedPayment,
        outstandingAmount,
        paymentLink: `${process.env.REACT_APP_CUSTOMER_URL}/#/order/${order.orderNumber}`,
        doNumber: `${invoiceNumberPrefix}${pz(
          invoiceNumber,
          invoiceNumberLength
        )}`,
        shippingOption: selected ? selected.name : "",
        voucherCode,
        weight,
      });
    }

    if (separateItemsIntoLines) {
      filtered.forEach((item, i) => {
        const spacelessDescription = item.description
          .replace(/\s/g, " ")
          .trim();
        const matches = comboRegex.exec(spacelessDescription);
        let fullString = "";
        let productDescription = spacelessDescription;
        let quantity = 1;
        if (matches) {
          fullString = matches[0];
          productDescription = matches[1];
          quantity = matches[2];
        }
        const itemOrderedAt = dateFormatter(item.createdAt);
        const tagsLabel = item && item.tags ? item.tags.join() : "";
        const keyword = keywordsDetails.find((key) => key.id === item.keyword);
        itemsForExport.push({
          ...order,
          orderItems: productDescription,
          itemCreatedAt: itemOrderedAt,
          itemUnitPrice: item.unitPrice,
          itemChannel: item.channel,
          itemCost: item.cost || 0,
          itemProfit: item.price - (item.cost || 0),
          contactRemarks: contact && contact.remarks ? contact.remarks : "",
          shippingAddress1: newShippingAddress1,
          shippingAddress2: newShippingAddress2,
          deliveryOrderPrinted: deliveryOrderPrinted,
          totalExcludeShipping: i > 0 ? "" : totalExcludeShipping,
          shippingCost: i > 0 ? "" : order.shippingCost,
          totalIncludeShipping: i > 0 ? "" : totalIncludeShipping,
          discountValue: i > 0 ? "" : discountValue,
          voucherCode: i > 0 ? "" : voucherCode,
          totalAfterDiscount: i > 0 ? "" : totalAfterDiscount,
          totalBeforeTax: taxCalculation
            ? taxCalculation["totalBeforeTax"]
            : "",
          tax: taxCalculation ? taxCalculation["taxAmount"] : "",
          totalAfterTax: taxCalculation ? taxCalculation["totalAfterTax"] : "",
          weight: i > 0 ? "" : weight,
          itemWeight: item.weight,
          doNumber: `${invoiceNumberPrefix}${pz(
            invoiceNumber,
            invoiceNumberLength
          )}`,
          contactId: contact ? contact.id : "",
          customerPSID: contact ? contact.psid : "",
          receivedPayment: i > 0 ? "" : receivedPayment,
          outstandingAmount: i > 0 ? "" : outstandingAmount,
          totalCost: i > 0 ? "" : totalCost,
          totalProfit: i > 0 ? "" : totalProfit,
          itemSubtotal: item.price,
          itemQuantity: quantity,
          shippingOption: selected ? selected.name : "",
          itemTags: tagsLabel,
          itemKeyword: keyword ? keyword.key : "",
          itemSku: keyword && keyword.sku ? keyword.sku : "",
          paymentLink: `${process.env.REACT_APP_CUSTOMER_URL}/order/${order.orderNumber}`,
          createdAtDate: dateFormatter(createdAt),
          createdAtTime: new Date(createdAt).toLocaleTimeString(),
          updatedAtDate: dateFormatter(updatedAt),
          updatedAtTime: new Date(updatedAt).toLocaleTimeString(),
        });
      });
    }

    if (exportSummary) {
      filtered.forEach((item) => {
        const comboRegex = /(.*)(x\d{1,}$)/i;
        const matches = comboRegex.exec(item.description.replace(/\s/g, " "));
        if (matches) {
          const [fullString, productDescription, quantity] = matches;
          itemsSummary.push({
            ...item,
            description: productDescription.trim(),
          });
        } else
          itemsSummary.push({
            ...item,
            description: item.description.replace(/\s/g, " ").trim(),
          });
      });
    }
  });

  const csv = separateItemsIntoLines
    ? convertToCSV(
        {
          data: itemsForExport,
          fields: orderExportFields,
        },
        {
          quotes: true,
        }
      )
    : convertToCSV(
        {
          data: ordersForExport,
          fields: orderExportFields,
        },
        {
          quotes: true,
        }
      );
  downloadCSVWithBom(csv, `orders ${dateFormatter(new Date())}`);

  if (exportTopSpenders) {
    const grouped = groupBy(
      ordersForExport,
      (o) => `${o.contactId}_${o.customerName}`
    );
    var result = Object.keys(grouped).map((key) => {
      const orders = grouped[key];
      const keyNName = key.split("_");
      const totalAmount = orders.reduce((accum, currentOrder) => {
        return accum.plus(currentOrder.totalExcludeShipping);
      }, new Decimal(0));
      const totalAfterDiscount = orders.reduce((accum, currentOrder) => {
        return accum.plus(currentOrder.totalAfterDiscount);
      }, new Decimal(0));
      const totalIncludeShipping = orders.reduce((accum, currentOrder) => {
        return accum.plus(currentOrder.totalIncludeShipping);
      }, new Decimal(0));

      const orderBreakdown = orders.map((o) => {
        return `${o.orderNumber} --->${o.status} ----> ${o.totalExcludeShipping}`;
      });
      return {
        contactId: keyNName[0],
        customerName: keyNName[1],
        totalExcludeShipping: totalAmount.toFixed(2),
        totalIncludeShipping: totalIncludeShipping.toFixed(2),
        totalAfterDiscount: totalAfterDiscount.toFixed(2),
        numberOfOrders: orders.length,
        orderBreakdown: orderBreakdown.join("\n"),
      };
    });
    const topSpendersCsv = convertToCSV(result);
    downloadCSVWithBom(
      topSpendersCsv,
      `Top Spenders ${dateFormatter(new Date())}`
    );
  }

  if (exportSummary) {
    const grouped = groupBy(
      itemsSummary,
      (i) => `${i.description}_${i.unitPrice}`
    );

    const summaryToExport = Object.keys(grouped).map((k) => {
      const withSumQty = grouped[k].reduce(
        (acc, { description, quantity, unitPrice }) => {
          return {
            description,
            unitPrice,
            quantity: acc.quantity + parseInt(quantity),
          };
        },
        {
          quantity: 0,
        }
      );

      return {
        ...withSumQty,
        totalPrice: new Decimal(withSumQty.unitPrice)
          .times(withSumQty.quantity)
          .toNumber(),
      };
    });
    const summaryCsv = convertToCSV(summaryToExport, { quotes: true });
    downloadCSVWithBom(
      summaryCsv,
      `orders_Summary_${dateFormatter(new Date())}}`
    );
  }
};
