import React from "react";
import get from "lodash/get";
import { useRecordContext } from "react-admin";
import useSWR from "swr";

const UserField = (props) => {
  const record = useRecordContext();
  const userId = get(record, "user");

  return <span {...props}>{userId.username}</span>;
};

export default UserField;
