import React from "react";
import { Admin, Resource, memoryStore } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { SWRConfig } from "swr";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PaymentIcon from "@mui/icons-material/Payment";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ReceiptIcon from "@mui/icons-material/Receipt";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import ContactsIcon from "@mui/icons-material/Contacts";
import RedeemIcon from "@mui/icons-material/Redeem";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import StorefrontIcon from "@mui/icons-material/Storefront";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import HistoryIcon from "@mui/icons-material/History";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { OrderList, OrderShow } from "./orders";
import OrderEdit from "./Order/Edit";
import OrderCreate from "./Order/Create";
import { OrderItemList, OrderItemEdit } from "./orderItems";
import {
  KeywordCreate,
  KeywordList,
  KeywordShow,
  KeywordEdit,
} from "./components/Keyword";
import { VariantList, VariantCreate, VariantEdit } from "./components/Variant";
import {
  SelectionList,
  SelectionCreate,
  SelectionEdit,
} from "./components/Selection";
import {
  CategoryList,
  CategoryCreate,
  CategoryEdit,
} from "./components/Category";
import { TagCreate, TagList, TagEdit } from "./components/Tags";
import { WaitlistsList, WaitlistsShow } from "./components/Waitlists";

import { VoucherList, VoucherCreate, VoucherShow } from "./components/Voucher";

import {
  // KeywordCreate,
  PaymentList,
  // PaymentShow,
  // PaymentEdit,
} from "./components/Payment";

import { ContactList, ContactShow, ContactEdit } from "./components/Contact";

import Dashboard from "./components/Dashboard";
// import LogisticApp from "./LogisticApp";
// import simpleRestProvider from "./ra-strapi-rest";
import authProvider from "./authProvider";
import {
  // makeFetchWithToken,
  fetcher,
} from "./helpers/fetchHandleError";

// import { PickupList, PickupEdit } from "./pickups";
import {
  ACTIVE_ORDERS,
  // CANCELLED_ORDERS,
  PAID_ORDERS,
  WAITING_FOR_PAYMENT_ORDERS,
  READY_TO_SHIP_ORDERS,
  // READY_TO_SHIP_NOW,
  // READY_TO_SHIP_SELF_COLLECT,
} from "./components/Constant";
import Login from "./components/Login/LoginPage";
import customRoutes from "./components/customRoutes";
import dataProvider from "./components/DataProvider";
import MyLayout from "./components/Menu/Layout";
import englishMessages from "./i18n/en";
import { withNewBadge } from "./helpers/withNewBadge";
import { withValidPlanShow } from "./components/DataHooks/useSubscription";
import { fetchWithBearerToken } from "./components/Common/swrMiddlewares";
import ExportsList from "./components/Exports/List";
import CreditLogsList from "./components/Credit/CreditLogsList";

const HourglassFullIconWithNew = withNewBadge(HourglassFullIcon);
const VContactList = withValidPlanShow(ContactList);
const VKeywordList = withValidPlanShow(KeywordList);
const VOrderList = withValidPlanShow(OrderList);
const VWaitlistsList = withValidPlanShow(WaitlistsList);
const VOrderItemList = withValidPlanShow(OrderItemList);
const VDashboard = withValidPlanShow(Dashboard);
const VCreditLogsList = withValidPlanShow(CreditLogsList);

// const EmptyDash = (props) => (
//   <div>Dashboard temporarily disabled, will be back soon.</div>
// );

const i18nProvider = polyglotI18nProvider(
  (locale) => {
    // if (locale === "fr") {
    //   return import("./i18n/fr").then((messages) => messages.default);
    // }

    // Always fallback on english
    return englishMessages;
  },
  "en",
  { allowMissing: true }
);

const App = () => {
  // const fetchWithToken = React.useCallback(
  //   (args) => {
  //     const f = makeFetchWithToken(token);
  //     return f(args);
  //   },
  //   [token]
  // );

  return (
    <StyledEngineProvider injectFirst>
      <SWRConfig
        value={{
          // refreshInterval: 300000,
          // fetcher: fetchWithToken,
          fetcher,
          use: [fetchWithBearerToken],
        }}
      >
        <Admin
          store={memoryStore()} //disable store persistent
          loginPage={Login}
          layout={MyLayout}
          authProvider={authProvider}
          dashboard={VDashboard}
          dataProvider={dataProvider}
          i18nProvider={i18nProvider}
          disableTelemetry
        >
          {customRoutes}
          <Resource
            name="contacts"
            options={{ label: "Contacts" }}
            icon={ContactsIcon}
            list={VContactList}
            show={ContactShow}
            edit={ContactEdit}
          />
          <Resource
            name={ACTIVE_ORDERS}
            icon={ShoppingCartIcon}
            options={{ label: "Active orders" }}
            show={OrderShow}
            list={VOrderList}
            edit={OrderEdit}
            create={OrderCreate}
          />
          <Resource
            title="Paid orders"
            icon={PaymentIcon}
            name={PAID_ORDERS}
            options={{ label: "Paid orders" }}
            list={VOrderList}
            show={OrderShow}
            edit={OrderEdit}
          />
          <Resource
            name="payments"
            icon={ReceiptIcon}
            options={{ label: "Payments" }}
            // show={PaymentShow}
            list={PaymentList}
            // edit={PaymentEdit}
            // create={KeywordCreate}
          />
          <Resource
            title="Waiting for payment orders"
            icon={ScheduleIcon}
            name={WAITING_FOR_PAYMENT_ORDERS}
            options={{ label: "Waiting for payment" }}
            list={OrderList}
            show={OrderShow}
            edit={OrderEdit}
          />
          <Resource
            title="All Ready to ship"
            icon={LocalShippingIcon}
            name={READY_TO_SHIP_ORDERS}
            options={{ label: "All Ready to ship" }}
            list={VOrderList}
            show={OrderShow}
            edit={OrderEdit}
          />
          <Resource
            name={`orders`}
            title="Orders"
            options={{ label: "All orders" }}
            show={OrderShow}
            list={VOrderList}
            edit={OrderEdit}
            create={OrderCreate}
          />
          <Resource
            name="orderItems"
            options={{ label: "Order Items" }}
            icon={ListAltIcon}
            edit={OrderItemEdit}
            // create={OrderItemCreate}
            list={VOrderItemList}
          />
          <Resource
            name="keywords"
            icon={SpellcheckIcon}
            options={{ label: "Keywords" }}
            show={KeywordShow}
            list={VKeywordList}
            edit={KeywordEdit}
            create={KeywordCreate}
          />
          <Resource
            name="variants"
            icon={StorefrontIcon}
            options={{ label: "Variants" }}
            list={VariantList}
            edit={VariantEdit}
            create={VariantCreate}
          />
          <Resource
            name="selections"
            icon={StorefrontIcon}
            options={{ label: "Selections" }}
            list={SelectionList}
            edit={SelectionEdit}
            create={SelectionCreate}
          />
          <Resource
            name="categories"
            icon={StorefrontIcon}
            options={{ label: "Categories" }}
            list={CategoryList}
            edit={CategoryEdit}
            create={CategoryCreate}
          />
          <Resource
            name="vouchers"
            icon={RedeemIcon}
            options={{ label: "Vouchers" }}
            show={VoucherShow}
            list={VoucherList}
            create={VoucherCreate}
          />
          <Resource
            name="tags"
            icon={LocalOfferIcon}
            options={{ label: "Tags" }}
            list={TagList}
            edit={TagEdit}
            create={TagCreate}
          />
          <Resource
            name="waitlists"
            icon={HourglassFullIconWithNew}
            options={{ label: "Waitlists" }}
            list={VWaitlistsList}
            show={WaitlistsShow}
          />
          <Resource
            name="exports"
            icon={FileDownloadIcon}
            options={{ label: "Exports" }}
            list={ExportsList}
            show={WaitlistsShow}
          />
          <Resource
            name="creditlogs"
            icon={HistoryIcon}
            options={{ label: "Credit history" }}
            list={VCreditLogsList}
            show={false}
          />
        </Admin>
      </SWRConfig>
    </StyledEngineProvider>
  );
};

export default App;
