import React from "react";
import { usePermissions } from "react-admin";
import { SELLER_ROLE } from "./role";

export const withRolePermission = (role) => (WrappedComponent) => (props) => {
  const { permissions } = usePermissions();
  if (permissions === role) {
    return <WrappedComponent {...props} />;
  }
  return null;
};

export const withAdminOnly = withRolePermission(SELLER_ROLE);
// export const withAssistantOnly = withRolePermission(ASSISTANT_ROLE);
