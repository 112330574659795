import React from "react";
import { DownshiftKeyword } from "../DownshiftInput";
// import TextField from "@mui/material/TextField";

const AutoCompleteKeyword = (props) => {
  return (
    <DownshiftKeyword
      name="key"
      label="Keyword"
      component={DownshiftKeyword}
      placeholder="A001"
      validate={(v) => (v ? undefined : new Error("Keyword is required"))}
    ></DownshiftKeyword>
  );
  // return (
  //   <TextField
  //     name="key"
  //     label="Keyword"
  //     component={DownshiftKeyword}
  //     placeholder="Keyword"
  //     validate={(v) => (v ? undefined : new Error("Keyword is required"))}
  //   />
  // );
};

export default AutoCompleteKeyword;
