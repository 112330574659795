import React from "react";
import {
  useRecordContext,
  useRefresh,
  useNotify,
  usePermissions,
} from "react-admin";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ButtonGroup from "@mui/material/ButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import Loader from "../Loader";
import { useIsBusinessPlan } from "../../helpers/subscriptionPlan";
import { Box, Tooltip } from "@mui/material";
import { useUpdateCredit } from "../DataHooks/credit";
import { useProfile } from "../DataHooks/useProfile";
import { SELLER_ROLE } from "../../helpers/role";

export const ContactCredit = (props) => {
  const record = useRecordContext();
  const { credit = 0 } = record;
  const refresh = useRefresh();
  const notify = useNotify();
  const { permissions } = usePermissions();
  const { profile, isLoading, isError } = useProfile();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [increment, setIncrement] = React.useState(true);
  const [amount, setAmount] = React.useState(0);
  const creditStr = (Number(credit) / 100).toFixed(2);
  const amountStr = (Number(amount) / 100).toFixed(2);

  const isBusiness = useIsBusinessPlan();
  const { trigger, isMutating } = useUpdateCredit(record.id, {
    onSuccess: () => {
      notify(
        `Credit successfully ${increment ? "added" : "decreased"}`,
        "info",
        {},
        true
      );
      refresh();
      setAmount("");
    },
    onError: () => {
      notify("Operation failed, please try again.", "warning");
      setAmount("");
    },
  });
  const onSubmit = React.useCallback(() => {
    setOpenDialog(false);
    trigger({
      credit: `${increment ? "+" : "-"}${amount}`,
    });
  }, [amount, increment, trigger]);

  if (isLoading || isError) {
    return null;
  }
  const { params } = profile;
  const { onlySellerEditCredit = false } = params;
  const canEdit = onlySellerEditCredit ? permissions === SELLER_ROLE : true;

  if (!isBusiness) {
    return (
      <>
        <span>{creditStr}</span>
        <Tooltip
          title={`Please uptier to business plan to enable this feature`}
        >
          <span>
            <Button disabled>
              <EditIcon />
            </Button>
          </span>
        </Tooltip>
      </>
    );
  }

  if (!canEdit) {
    return (
      <>
        <span>{creditStr}</span>
        <Tooltip title={`Only admin can edit credit`}>
          <span>
            <Button disabled>
              <EditIcon />
            </Button>
          </span>
        </Tooltip>
      </>
    );
  }

  const isOverDeduct = !increment && parseInt(amount) > credit;

  return (
    <>
      <span>{creditStr}</span>
      <span> </span>
      <ButtonGroup size="small" variant="contained">
        <Button
          onClick={() => {
            setIncrement(true);
            setOpenDialog(true);
          }}
        >
          <AddIcon />
        </Button>
        <Button
          onClick={() => {
            setIncrement(false);
            setOpenDialog(true);
          }}
        >
          <RemoveIcon />
        </Button>
      </ButtonGroup>
      <Dialog open={openDialog} variant="outlined">
        <DialogTitle>{`${
          increment ? "Increase (+)" : "Decrease (-) "
        } credit`}</DialogTitle>
        <DialogContent>
          <br />
          <TextField
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            onKeyDown={(e) => {
              if (/^\d+$/.test(e.key)) {
                const newValue = `${amount}${e.key}`;
                setAmount(Number(newValue));
              } else if (e.key === "Backspace") {
                const strArr = `${amount}`.split("");
                strArr.pop();
                const str = strArr.join("");
                setAmount(Number(str));
              }
            }}
            placeholder="0.00"
            value={amountStr}
            helperText={
              isNaN(amount) || isNaN(record.credit)
                ? ""
                : increment
                ? `Result: $${(Number(record.credit) + Number(amount)) / 100}`
                : `Result: $${(Number(record.credit) - Number(amount)) / 100}`
            }
          />
          <br />
          {isOverDeduct && (
            <Box sx={{ color: "red" }}>
              Max deductible is {Math.abs(record.credit)}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              setOpenDialog(false);
              setAmount("");
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={onSubmit}
            disabled={!amount || isOverDeduct || parseInt(amount) === 0}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Loader open={isMutating}>{`Processing...`}</Loader>
    </>
  );
};
