import React from "react";
import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  BooleanField,
  Pagination,
  //Filter,
  TextInput,
  NumberField,
  BooleanInput,
  minLength,
  maxLength,
  AutocompleteInput,
} from "react-admin";
// import Autocomplete from "@mui/material/Autocomplete";
import { useMediaQuery } from "@mui/material";
import ContactLinkField from "./ContactLinkField";
import { exporter } from "./Exporter";
import ListActions from "./ListActions";
import CustomNameSearch from "./CustomNameSearch";
import BulkBlacklistContact from "./BulkBlacklistContact";
// import { getPageId } from "../../helpers/getPageId";
import ChannelField from "./ChannelField";

import {
  CONTACT_CHANNEL_INSTAGRAM,
  CONTACT_CHANNEL_FACEBOOK,
} from "../Constant";
import CustomPickerInput from "../OrderItem/CustomPickerInput";
// import CustomSelectInput from "../Common/CustomSelectInput";
import TextInputNoWheel from "../Common/TextInputNoWheel";
import CustomDateField from "../Common/CustomDateField";

const platformTypes = [
  { id: CONTACT_CHANNEL_FACEBOOK, name: CONTACT_CHANNEL_FACEBOOK },
  { id: CONTACT_CHANNEL_INSTAGRAM, name: CONTACT_CHANNEL_INSTAGRAM },
];

const BulkActionButtons = (props) => (
  <>
    <BulkBlacklistContact {...props} />
  </>
);

const dateFilters = [
  { source: "createdAt_gte", label: "CreatedAt start" },
  { source: "createdAt_lte", label: "CreatedAt end" },
  { source: "updatedAt_gte", label: "UpdatedAt start" },
  { source: "updatedAt_lte", label: "UpdatedAt end" },
].map(({ source, label }) => (
  <CustomPickerInput key={source} source={source} label={label} />
));
const tinyFont = { fontSize: "10px" };

const contactFilters = [
  <CustomNameSearch label="Name" source="name_contains" alwaysOn />,
  <AutocompleteInput
    alwaysOn
    label="Channel"
    source="channel"
    choices={platformTypes}
    parse={(v) => (v === "fb" ? null : v)}
  />,
  <TextInput
    label="Customer ID"
    source="_id"
    validate={[minLength(24), maxLength(24)]}
  />,
  <TextInputNoWheel
    type="number"
    label="Customer FB PSID"
    source="psid"
    validate={[minLength(15)]}
  />,
  <TextInput label="Remarks" source="remarks_contains" />,
  <TextInput label="Phone number" source="phoneNumber_contains" />,
  <BooleanInput
    label="Blacklisted"
    source="isBlacklisted_eq"
    defaultValue={false}
  />,
  ...dateFilters,
];
const ContactList = (props) => {
  // const pageId = getPageId();

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <List
      debounce={2000}
      {...props}
      hasCreate={false}
      // filter={{ page: pageId }}
      exporter={exporter}
      actions={<ListActions />}
      filters={contactFilters}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} />}
      sort={{ field: "createdAt", order: "DESC" }}
    >
      {isSmall ? (
        <SimpleList
          linkType="show"
          primaryText={(record) => record.name}
          secondaryText={(record) => ``}
          tertiaryText={(record) => (
            <>
              <span style={tinyFont}>createdAt</span>
              <br /> {`${new Date(record.createdAt).toLocaleDateString()}`}
            </>
          )}
        />
      ) : (
        <Datagrid rowClick="show" bulkActionButtons={<BulkActionButtons />}>
          <ContactLinkField />
          <ChannelField source="channel" />
          <TextField source="phoneNumber" />
          <BooleanField source="isBlacklisted" />
          <TextField source="remarks" />
          <NumberField source="rating" />
          <CustomDateField source="createdAt" />
          <CustomDateField source="updatedAt" />
        </Datagrid>
      )}
    </List>
  );
};

export default ContactList;
