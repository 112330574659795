import React, { useState, useCallback } from "react";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {
  useRefresh,
  useNotify,
  useListContext,
  useUnselectAll,
  useDataProvider,
} from "react-admin";
import { useForm, FormProvider } from "react-hook-form";
import Loader from "../Loader";
import { useCustomAllRecords } from "../Common/useCustomAllRecords";
import ProductImages from "./ProductImages";

const MAX_CONCURRENT = 10;

const CompButton = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  return (
    <>
      <Button startIcon={<EditIcon />} onClick={handleClick}>
        {`Images`}
      </Button>
      {isOpen && <Comp {...props} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
};

const Comp = (props) => {
  const { isOpen, setIsOpen } = props;
  const { resource, selectedIds } = useListContext();
  const allRecords = useCustomAllRecords();
  // const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requests, setRequests] = useState([]);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll(resource);
  const dataProvider = useDataProvider();

  const formMethods = useForm({
    defaultValues: {
      images: [],
    },
  });

  React.useEffect(() => {
    return () => unselectAll();
  }, [unselectAll]);

  const handleDialogClose = useCallback(() => {
    unselectAll();
    setIsOpen(false);
  }, [setIsOpen, unselectAll]);

  const handleConfirm = async ({ images }) => {
    const updateDatas = selectedIds
      .map((id) => {
        return allRecords.find((singleData) => {
          return singleData.id === id;
        });
      })
      .map((d) => ({
        id: d.id,
        images,
      }));
    setRequests(updateDatas);
  };

  React.useEffect(() => {
    const update = async () => {
      const processSlice = requests.slice(0, MAX_CONCURRENT);
      const nextSlice = requests.slice(MAX_CONCURRENT);
      try {
        setLoading(true);
        await Promise.all(
          processSlice.map(({ id, ...others }) =>
            dataProvider.update("keywords", {
              id,
              data: { ...others },
            })
          )
        );
        setRequests(nextSlice);
        if (nextSlice.length === 0) {
          setLoading(false);
          notify("Keywords updated successfully", "info");
          unselectAll();
          refresh();
          setIsOpen(false);
        }
      } catch (e) {
        setRequests([]);
        setLoading(false);
        unselectAll();
        refresh();
        notify("Keywords update failed.", "warning");
        setIsOpen(false);
      }
    };
    if (requests.length > 0) {
      update();
    }
  }, [dataProvider, notify, refresh, requests, setIsOpen, unselectAll]);

  if (loading) {
    return (
      <Loader open>
        <span> {`Processing ${requests.length} updates`}</span>
      </Loader>
    );
  }

  return (
    <>
      <Dialog
        //fullScreen={true}
        fullWidth
        maxWidth="md"
        open={isOpen}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>{"Bulk upload images"}</DialogTitle>
        <form
          className="form"
          onSubmit={formMethods.handleSubmit(handleConfirm)}
        >
          <FormProvider {...formMethods}>
            <>
              <DialogContent>
                <ProductImages />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose} color="secondary">
                  Cancel
                </Button>
                <Button type="submit" variant="contained">
                  Save
                </Button>
              </DialogActions>
            </>
          </FormProvider>
        </form>
      </Dialog>
    </>
  );
};

export default CompButton;
