import React from "react";
const Comp = ({ source, record = {}, src }) => {
  const { paid, url } = record;
  // const classes = useStyles();
  // const bull = <span className={classes.bullet}>•</span>;
  if (paid) {
    // const date = new Date(paidAt);
    return (
      <a href={url} target="_blank" rel="noopener noreferrer" size="small">
        See Receipt
      </a>
    );
  }
  return null;
};

export default Comp;
