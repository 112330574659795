import React, { useState } from "react";
import { makeStyles } from "@mui/styles";

import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { RandomReveal } from "react-random-reveal";
import classNames from "classnames";

import { useGetFbProfileImage } from "../DataHooks/useGetFbProfileImage";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: "auto",
  },
  hidden: {
    display: "none",
  },
});

const MediaCard = (props) => {
  const { value } = props;
  const {
    from: { id, name = "?" },
  } = value;
  const classes = useStyles();
  const [complete, setComplete] = useState(false);
  const fbProfileImageUrl = useGetFbProfileImage(id);

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          component="img"
          className={classNames({
            [classes.media]: complete,
            [classes.hidden]: !complete,
          })}
          src={fbProfileImageUrl}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            <RandomReveal
              isPlaying
              duration={10}
              revealDuration={0.5}
              characters={name}
              onComplete={() => {
                setComplete(true);
              }}
            />
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default MediaCard;
