import React from "react";
import { StateProvider } from "./store";
import { StateProvider as CommentProvider } from "./commentStore";
import { withProfile } from "../DataHooks/useProfile";

import Main from "./Main";

const Wrapper = (props) => {
  return (
    <CommentProvider>
      <StateProvider>
        <Main />
      </StateProvider>
    </CommentProvider>
  );
};

export default withProfile(Wrapper);
