import React, { useEffect } from "react";
import {
  useRefresh,
  useNotify,
  useUnselectAll,
  useListContext,
} from "react-admin";
import { MenuItem, Select, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import flatten from "lodash/flatten";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import Loader from "../../Loader";
import { useGetToken } from "../../DataHooks/useGetToken";
import { fetchHandleError } from "../../../helpers/fetchHandleError";
import { useThrottleCall, COMPLETED } from "../../DataHooks/useThrottleCall";
import { uniqueByValue } from "../../../helpers/methods";
import { useCustomAllRecords } from "../../Common/useCustomAllRecords";
import { statuses as orderStatuses } from "../../../orders";

const useStyles = makeStyles((theme) => ({
  redText: {
    color: "red",
  },
}));

const SplitPreorder = (props) => {
  const classes = useStyles();
  const { resource, selectedIds } = useListContext();
  const allRecords = useCustomAllRecords();
  const token = useGetToken();
  const [isOpen, setIsOpen] = React.useState(false);
  // const [processing, setProcessing] = React.useState(false);
  const [selectedOrders, setSelectedOrders] = React.useState([]);
  const [prefixItems, setPrefixItems] = React.useState([]); // this is [[],[],[]]
  const [failedRequests, setFailedRequests] = React.useState([]);
  const [splitSingleItem, setSplitSingleItem] = React.useState(false);
  const [prefix, setPrefix] = React.useState("[PO]");
  const [orderStatus, setOrderStatus] = React.useState("preorder");
  const { setProcess, requests, processing, status, terminate } =
    useThrottleCall();

  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll(resource);

  const handleClick = React.useCallback(() => {
    setIsOpen(true);
  }, []);

  React.useEffect(() => {
    if (allRecords.length > 0) {
      const orders = selectedIds.map((id) => {
        return allRecords.find((singleData) => {
          return singleData.id === id;
        });
      });
      const processPrefixItems = orders.map((o) => {
        const items = o.orderItems;
        const filteredItems = items.filter(({ description = "" }) =>
          description.startsWith(prefix)
        );
        return filteredItems;
      });
      setSelectedOrders(orders);
      setPrefixItems(processPrefixItems);
    }
  }, [allRecords, prefix, selectedIds]);

  const handleDialogClose = React.useCallback(() => {
    setIsOpen(false);
    unselectAll();
    setFailedRequests([]);
  }, [unselectAll]);

  const handleConfirm = React.useCallback(() => {
    const requestCalls = [];
    selectedOrders.forEach((o, index) => {
      const onlyPrefixItems =
        o.orderItems.length === prefixItems[index].length &&
        o.orderItems.length !== 0;
      const notContainingPO = prefixItems[index].length === 0;
      if (notContainingPO) {
        return;
      }
      if (onlyPrefixItems) {
        if (
          !splitSingleItem ||
          (splitSingleItem && prefixItems[index].length === 1)
        ) {
          //if only PO items and no splitSingleItem => change status
          //OR if splitSingleItem AND only one PO item => change status
          requestCalls.push(() =>
            fetch(`${process.env.REACT_APP_CMS_HOST}/orders/${o.id}`, {
              method: "PUT",
              body: JSON.stringify({ status: orderStatus }),
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
              },
            })
              .then(fetchHandleError)
              .catch((e) => {
                //TODO catch failed request
                setFailedRequests((prev) => [...prev, { updateStatus: o.id }]);
              })
          );
        } else {
          const payloads = prefixItems[index].map((i) => {
            return {
              status: orderStatus,
              orderItems: [i.id],
            };
          });
          //pop last item to keep one item in order and change the order status
          const lastPayload = payloads.pop();
          requestCalls.push(() =>
            fetch(`${process.env.REACT_APP_CMS_HOST}/orders/${o.id}`, {
              method: "PUT",
              body: JSON.stringify({ status: orderStatus }),
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
              },
            })
              .then(fetchHandleError)
              .catch((e) => {
                setFailedRequests((prev) => [...prev, { updateStatus: o.id }]);
              })
          );
          payloads.forEach((payload) => {
            requestCalls.push(() =>
              fetch(`${process.env.REACT_APP_CMS_HOST}/orders/split`, {
                method: "POST",
                body: JSON.stringify(payload),
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-type": "application/json; charset=UTF-8",
                },
              })
                .then(fetchHandleError)
                .catch((e) => {
                  setFailedRequests((prev) => [...prev, payload]);
                })
            );
          });
        }
      } else if (!onlyPrefixItems) {
        //if PO mixed with ready stock, normal split
        const payloads = splitSingleItem
          ? prefixItems[index].map((i) => {
              return {
                status: orderStatus,
                orderItems: [i.id],
              };
            })
          : [
              {
                status: orderStatus,
                orderItems: prefixItems[index].map((i) => i.id),
              },
            ];
        payloads.forEach((payload) => {
          requestCalls.push(() =>
            fetch(`${process.env.REACT_APP_CMS_HOST}/orders/split`, {
              method: "POST",
              body: JSON.stringify(payload),
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
              },
            })
              .then(fetchHandleError)
              .catch((e) => {
                setFailedRequests((prev) => [...prev, payload]);
              })
          );
        });
      }
    });
    setProcess(requestCalls);
  }, [
    orderStatus,
    prefixItems,
    selectedOrders,
    setProcess,
    splitSingleItem,
    token,
  ]);

  useEffect(() => {
    if (status === COMPLETED && failedRequests.length === 0) {
      notify("Successful");
      refresh();
      setIsOpen(false);
    } else if (failedRequests.length > 0) {
      notify("Some splitting failed, please retry again later.", {
        type: "error",
      });
      terminate();
    }
  }, [failedRequests.length, notify, refresh, setIsOpen, status, terminate]);

  const failedItemsIds = flatten(failedRequests.map((req) => req.orderItems));

  const failedOrders = selectedOrders.filter((order) => {
    return order.orderItems.find((item) =>
      failedItemsIds.find((id) => id === item._id)
    );
  });
  const failedOrderNumbers = failedOrders
    .map((order) => order.orderNumber)
    .filter(uniqueByValue);

  return (
    <>
      <Button label="SplitPreorder" onClick={handleClick}>
        <CallSplitIcon />
        Split By Prefix
      </Button>
      <Dialog open={isOpen} aria-labelledby="responsive-dialog-title">
        <DialogTitle>
          Are you sure to split items with <b>{prefix}</b> prefix into new{" "}
          <b>{orderStatus}</b> order?
        </DialogTitle>
        <DialogContent>
          <>
            <TextField
              value={prefix}
              onChange={(e) => setPrefix(e.target.value)}
              label="Prefix you wish to split"
            />
            <Select
              value={orderStatus}
              onChange={(e) => {
                setOrderStatus(e.target.value);
              }}
            >
              {orderStatuses.map((c) => (
                <MenuItem key={c.id} value={c.id}>
                  {c.name}
                </MenuItem>
              ))}
            </Select>
            <ul>
              {selectedOrders.map((o, idx) => {
                return (
                  <li
                    key={o.orderNumber}
                  >{`${o.orderNumber} => ${prefixItems[idx].length} items`}</li>
                );
              })}
            </ul>
            {failedOrderNumbers.length > 0 && (
              <div>
                <p className={classes.redText}>Failed orders</p>
                <ul>
                  {failedOrderNumbers.map((orderNumber, idx) => {
                    return <li key={orderNumber}>{`${orderNumber}`}</li>;
                  })}
                </ul>
              </div>
            )}
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={splitSingleItem}
                    onChange={() => setSplitSingleItem(!splitSingleItem)}
                  />
                }
                label={
                  <>
                    Split 1 prefix into 1 order
                    <br />
                    <span className={classes.redText}>
                      Warning: Splitting into multiple orders. Check this box
                      only if you understand the risks.
                    </span>
                  </>
                }
              />
            </FormGroup>
          </>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleConfirm}
            color="primary"
            autoFocus
            disabled={
              processing ||
              prefixItems.filter((items) => items && items.length > 0)
                .length === 0 ||
              failedOrderNumbers.length > 0 ||
              prefix.length === 0
            }
          >
            SPLIT
          </Button>
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Loader open={processing}>
        {`DO NOT CLOSE THIS WINDOW !\n Processing...`}
      </Loader>
    </>
  );
};

export default SplitPreorder;
