import React from "react";
import Loadable from "react-loadable";
import {
  TopToolbar,
  CreateButton,
  useListContext,
  FilterButton,
  usePermissions,
} from "react-admin";
import { READY_TO_SHIP_ORDERS } from "../components/Constant";
import { useProfileParams } from "../components/useProfileParams";
// import ExportButtonCustom from "./ExportButtonCustom";
import {
  useMaxResultsByPlan,
  useIsEnterprisePlan,
  // useIsHobbyistPlan,
} from "../helpers/subscriptionPlan";

import ListSelectableFields from "./ListSelectable";
import ExportButtonBatchJob from "./ExportButtonBatchJob";
// import { useIsSuperAdminMode } from "../components/DataHooks/superAdminMode";
// import { ASSISTANT_ROLE } from "../helpers/role";

const LoadableMillionExportButton = Loadable({
  loader: () => import("./MillionExporter"),
  loading: () => "Loading",
});

const LoadableAutoCountExportButton = Loadable({
  //nowme
  loader: () => import("./AutoCountExporter"),
  loading: () => "Loading",
});

const LoadableRZYExportButton = Loadable({
  //geraldine.sg
  loader: () => import("./RZYExporter"),
  loading: () => "Loading",
});

const LoadableMFBEasyParcelExportButton = Loadable({
  //my fashion boutique
  loader: () => import("./MFBEasyParcelExporter"),
  loading: () => "Loading",
});

const LoadableAnchantoExportButton = Loadable({
  //nowme
  loader: () => import("./AnchantoExporter"),
  loading: () => "Loading",
});

const LoadableOasisAutoCountExportButton = Loadable({
  loader: () => import("./OasisAutoCountExporter.js"),
  loading: () => "Loading",
});

const ListActions = (props) => {
  const { filter } = props;
  const { total, sort, filterValues, resource } = useListContext();
  const currentPath = window.location.hash;
  const isCanCreate =
    currentPath.indexOf(`/orders`) > -1 ||
    currentPath.indexOf(`/activeorders`) > -1;
  const isAllOrders = currentPath.indexOf(`/orders`) > -1;
  const isReadyToShip = currentPath.indexOf(`/${READY_TO_SHIP_ORDERS}`) > -1;
  const [params] = useProfileParams();
  const {
    enableMillionExport = false,
    enableNowmeAutocountExport = false,
    enableOasisAutocountExport = false,
    enableRZYExport = false,
    enableMFBEasyParcel = false,
    enableAnchantoExport = false,
  } = params;
  const maxResults = useMaxResultsByPlan();
  const isEnterprisePlan = useIsEnterprisePlan();
  // const isHobbyistPlan = useIsHobbyistPlan();
  // const isSuperAdmin = useIsSuperAdminMode();
  const { permissions } = usePermissions();
  // const isAssistant = permissions === ASSISTANT_ROLE;

  return (
    <TopToolbar>
      <FilterButton />
      {isCanCreate && <CreateButton />}

      <ExportButtonBatchJob
        disabled={total === 0}
        resource={resource}
        sort={sort}
        filter={{ ...filterValues, ...filter }}
      />

      {/* {isAssistant && (
        <ExportButtonCustom
          disabled={total === 0}
          resource={resource}
          sort={sort}
          filter={{ ...filterValues, ...filter }}
          maxResults={maxResults}
        />
      )} */}
      {/* Add your custom actions */}
      {isAllOrders && enableMillionExport && isEnterprisePlan && (
        <LoadableMillionExportButton
          disabled={total === 0}
          resource={resource}
          sort={sort}
          label={`Million`}
          maxResults={maxResults}
        />
      )}

      {(isReadyToShip || isAllOrders) &&
        enableRZYExport &&
        isEnterprisePlan && (
          <LoadableRZYExportButton
            disabled={total === 0}
            resource={resource}
            sort={sort}
            filter={{ ...filterValues, ...filter }}
            label={`RZY`}
            maxResults={maxResults}
          />
        )}

      {(isReadyToShip || isAllOrders) &&
        enableMFBEasyParcel &&
        isEnterprisePlan && (
          <LoadableMFBEasyParcelExportButton
            disabled={total === 0}
            resource={resource}
            sort={sort}
            filter={{ ...filterValues, ...filter }}
            label={`EasyParcel`}
            maxResults={maxResults}
          />
        )}

      {(isReadyToShip || isAllOrders) &&
        enableAnchantoExport &&
        isEnterprisePlan && (
          <LoadableAnchantoExportButton
            disabled={total === 0}
            resource={resource}
            sort={sort}
            filter={{ ...filterValues, ...filter }}
            label={`Anchanto`}
            maxResults={maxResults}
          />
        )}

      {isAllOrders && enableOasisAutocountExport && isEnterprisePlan && (
        <LoadableOasisAutoCountExportButton
          disabled={total === 0}
          resource={resource}
          sort={sort}
          label={`AutoCount`}
          maxResults={maxResults}
        />
      )}

      {isAllOrders && enableNowmeAutocountExport && isEnterprisePlan && (
        <LoadableAutoCountExportButton
          disabled={total === 0}
          resource={resource}
          sort={sort}
          label={`AutoCount`}
          maxResults={maxResults}
        />
      )}

      <ListSelectableFields />
      {/* <Button
      onClick={() => {
        alert("Your custom action");
      }}
      label="Show calendar"
    >
      <IconEvent />
    </Button> */}
    </TopToolbar>
  );
};

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};

export default ListActions;
