import React, { useState, useCallback } from "react";
import Button from "@mui/material/Button";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  useRefresh,
  useNotify,
  useUnselectAll,
  useListContext,
} from "react-admin";

import { statuses as orderStatuses } from "../../orders";
import { getCookie } from "../../helpers/Cookies";
import { fetchHandleError } from "../../helpers/fetchHandleError";
import Loader from "../Loader";
import { CANCELLED } from "../Constant";

const minWidth120 = { minWidth: 120 };
const SplitOrderDialog = (props) => {
  const { data } = useListContext();
  const { resource, selectedIds } = props;
  const [isOpen, setIsOpen] = useState(false);
  //   const [value, setValue] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll(resource);
  const token = getCookie("token");

  const handleClick = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const handleDialogClose = useCallback(() => {
    setIsOpen(false);
    unselectAll();
  }, [unselectAll]);

  const handleConfirm = () => {
    const result = window.confirm(`Are you sure?`);

    if (result === true) {
      setIsOpen(false);
      setLoading(true);
      const payload = {
        status: orderStatus,
        orderItems: selectedIds,
      };
      fetch(`${process.env.REACT_APP_CMS_HOST}/orders/split`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then(fetchHandleError)
        .then((resp) => {
          setLoading(false);
          refresh();
          window.open(`/#/orders/${resp._id}/show`, "_blank");
          handleDialogClose();
          notify(`Split successful`);
        })
        .catch((e) => {
          setLoading(false);
          handleDialogClose();
          notify(`Split error`, "warning");
        });
    }
  };

  return (
    <>
      <Button startIcon={<CallSplitIcon />} label="Split" onClick={handleClick}>
        Split new
      </Button>
      <Dialog
        fullWidth
        maxWidth={"sm"}
        open={isOpen}
        aria-labelledby="split-responsive-dialog-title"
      >
        <DialogTitle id="split-responsive-dialog-title">
          {"Split to new order"}
        </DialogTitle>
        <DialogContent>
          <FormControl style={minWidth120}>
            <InputLabel id="split-select-label">New order status</InputLabel>
            <Select
              labelId="split-select-label"
              autoWidth
              value={orderStatus}
              onChange={(e) => {
                setOrderStatus(e.target.value);
              }}
              // onClose={handleDialogClose}
              inputProps={{
                name: "status",
              }}
            >
              {orderStatuses
                .filter(({ id }) => id !== CANCELLED)
                .map((c) => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <ol>
            {data
              .filter(({ _id }) => selectedIds.includes(_id))
              .map(({ description, _id }) => (
                <li key={_id}>{description}</li>
              ))}
          </ol>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button
            disabled={!orderStatus || loading}
            onClick={handleConfirm}
            variant="contained"
          >
            Split
          </Button>
        </DialogActions>
      </Dialog>
      <Loader open={loading}></Loader>
    </>
  );
};

export default SplitOrderDialog;
