import React, { useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import isSameDay from "date-fns/isSameDay";
import differenceInHours from "date-fns/differenceInHours";
import differenceInDays from "date-fns/differenceInDays";
import useSWRInfinite from "swr/infinite";
import Box from "@mui/material/Box";
import ReactPlayer from "react-player/lazy";

import {
  // MainContainer,
  // Sidebar,
  // ConversationList,
  // Conversation,
  Avatar,
  MessageGroup,
  Message,
  ChatContainer,
  ConversationHeader,
  MessageList,
  MessageInput,
  // VoiceCallButton,
  // VideoCallButton,
  // InfoButton,
  // TypingIndicator,
  ArrowButton,
  MessageSeparator,
  InputToolbox,
} from "@chatscope/chat-ui-kit-react";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import reverse from "lodash/reverse";
import useSWR from "swr";

import { useProfile } from "../DataHooks/useProfile";
// import { fetcher } from "../../helpers/fetchHandleError";
import { useIsBusinessPlan } from "../../helpers/subscriptionPlan";
import KbLinkButton from "../Learn/KbLinkButton";
import {
  CONTACT_CHANNEL_INSTAGRAM,
  CONTACT_CHANNEL_FACEBOOK,
} from "../Constant";
import { fetchWithFBToken } from "../Common/swrMiddlewares";
import { usePsidThreadOwner } from "../DataHooks/useGetFbProfileImage";
import ThreadControlButton from "./ThreadControlButton";
import ReleaseButton from "./ReleaseButton";
import ProfileDetailModal from "./ProfileDetailModal";

const LIMIT = 50;

const Messages = (props) => {
  const { contact } = props;
  const {
    profilePic,
    psid,
    name,
    remarks,
    channel = CONTACT_CHANNEL_FACEBOOK,
  } = contact;
  const [openProfileDetail, setOpenProfileDetail] = useState(false);
  const [useHumanAgentTag, setUseHumanAgentTag] = React.useState(false);
  const { profile, isLoading, isError } = useProfile();
  const isBusinessPlan = useIsBusinessPlan();
  const isInstagram = channel === CONTACT_CHANNEL_INSTAGRAM;
  const platform = isInstagram ? "&platform=instagram" : "";

  const { data: latest, error: latestError } = useSWR(
    psid && profile && profile.page && profile.page.token
      ? `${process.env.REACT_APP_GRAPH_API_URL}/me/conversations?user_id=${psid}${platform}&fields=id,link,is_subscribed,can_reply,messages{from,message,created_time,attachments},unread_count`
      : null,

    { use: [fetchWithFBToken], revalidateOnFocus: false }
  );

  const {
    data: threadOwner,
    mutate: threadOwnerMutate,
    isNotBoxify,
    isIdle,
  } = usePsidThreadOwner(psid);

  const getKey2 = (pageIndex, previousPageData) => {
    if (!latest || !profile) {
      return null;
    } else if (previousPageData && !previousPageData.paging.next) {
      return null;
    } // reached the end
    else if (pageIndex === 0) {
      return `${process.env.REACT_APP_GRAPH_API_URL}/${
        latest.data[0].id
      }/messages?limit=${LIMIT}&fields=from,message,created_time,attachments${
        isInstagram ? ",story" : ""
      }`;
    }

    return `${process.env.REACT_APP_GRAPH_API_URL}/${
      latest.data[0].id
    }/messages?limit=${LIMIT}&fields=from,message,created_time,attachments${
      isInstagram ? ",story" : ""
    }&after=${previousPageData.paging.cursors.after}`; // SWR key
  };

  const { data, error, mutate, size, setSize } = useSWRInfinite(getKey2, {
    use: [fetchWithFBToken],
  });

  const onSendHandler = React.useCallback(
    (innerHtml, textContent, innerText) => {
      const payload = useHumanAgentTag
        ? {
            recipient: {
              id: `${psid}`,
            },
            messaging_type: "MESSAGE_TAG",
            tag: "HUMAN_AGENT",
            message: {
              text: innerText,
            },
          }
        : {
            // messaging_type: "<MESSAGING_TYPE>",
            recipient: {
              id: `${psid}`,
            },
            message: {
              text: innerText,
            },
          };
      fetch(
        `${process.env.REACT_APP_GRAPH_API_URL}/me/messages?access_token=${profile.page.token}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      )
        // .then(fetchHandleError)
        .then((response) => {
          if (response.ok) {
            // Or what ever you want to check
            return Promise.resolve(response.json()); // This will end up in SUCCESS part
          }
          return Promise.resolve(response.json()).then((responseInJson) => {
            // This will end up in ERROR part
            return Promise.reject(responseInJson); //  responseInJson.message = "Some nasty error message!"
          });
        })
        .then((resp) => {
          mutate();
        })
        .catch((e) => {
          alert(`Error ${e.error.message}`);
        });
    },
    [profile, psid, useHumanAgentTag, mutate]
  );

  const flattenData = data ? data.map((d) => d.data) : [];
  const messages = data ? [].concat(...flattenData) : [];

  const isLoadingInitialData = !data && !error;

  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === "undefined");

  const isEmpty = data?.[0]?.length === 0;

  const isReachingEnd =
    isEmpty ||
    (flattenData && flattenData[flattenData.length - 1]?.length < LIMIT);

  const latestIncomingMsg = messages.find((msg) => msg.from.id === psid);
  const isWithin24H =
    latestIncomingMsg &&
    differenceInHours(new Date(), new Date(latestIncomingMsg.created_time)) <
      24;

  const isWithin7Days =
    latestIncomingMsg &&
    differenceInDays(new Date(), new Date(latestIncomingMsg.created_time)) < 7;

  React.useEffect(() => {
    if (!isInstagram && isWithin24H) {
      const t = setTimeout(() => {
        fetch(
          `${process.env.REACT_APP_GRAPH_API_URL}/me/messages?access_token=${profile.page.token}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              recipient: {
                id: `${psid}`,
              },
              sender_action: "mark_seen",
            }),
          }
        ).catch((e) => {});
      }, 5000);
      return () => {
        clearTimeout(t);
      };
    }
  }, [profile, psid, isWithin24H, isInstagram]);

  if (!data && !error) {
    return <p>loading</p>;
  }
  if (latestError) {
    return <div>{latestError.message}</div>;
  }

  let renderMessages = [];
  reverse(messages.map((m) => m)).map((msg, idx, source) => {
    const {
      from: { name, id: senderPsid },
      message,
      created_time,
      id,
      attachments,
      story,
    } = msg;
    const firstEl = idx === 0;
    const lastEl = idx === source.length - 1;
    let position = "single";

    if (source.length === 1) {
      // do nothing since only single message
    } else if (firstEl) {
      if (senderPsid === source[idx + 1].from.id) {
        position = "first";
      }
    } else if (lastEl) {
      if (senderPsid === source[idx - 1].from.id) {
        position = "last";
      }
    } else {
      if (
        (senderPsid === source[idx + 1].from.id) ===
        source[idx - 1].from.id
      ) {
        position = "normal";
      } else if (
        (senderPsid === source[idx - 1].from.id) !==
        source[idx + 1].from.id
      ) {
        position = "last";
      }
    }
    const element = (
      <MessageGroup
        key={id}
        direction={senderPsid === psid ? "incoming" : "outgoing"}
        sender={name}
        sentTime={new Date(created_time).toLocaleString()}
      >
        {(position === "last" || position === "single") &&
          senderPsid === psid && <Avatar src={profilePic} name={name} />}

        {story && story.reply_to && (
          <MessageGroup.Header>{`Replied to your story ${
            story.reply_to.link ? "" : "(Content expired)"
          }`}</MessageGroup.Header>
        )}

        {story && story.mention && (
          <MessageGroup.Header>{`Mentioned you in their story
          ${
            story.mention.link ? "" : "(Content expired)"
          }`}</MessageGroup.Header>
        )}

        <MessageGroup.Messages>
          {story && story.reply_to && story.reply_to.link && (
            <a
              href={story.reply_to.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Message.ImageContent
                key={story.reply_to.link}
                src={story.reply_to.link}
                alt="preview"
                width={200}
              ></Message.ImageContent>
            </a>
          )}

          {story && story.mention && story.mention.link && (
            <a
              href={story.mention.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Message.ImageContent
                key={story.mention.link}
                src={story.mention.link}
                alt="preview"
                width={200}
              ></Message.ImageContent>
            </a>
          )}

          <Message
            key={id}
            model={{
              message: message,
              // sentTime: new Date(created_time).toLocaleString(),
              // sender: name,
              position: position,
              avatarSpacer: position === "first" || position === "normal",
            }}
          >
            {}
          </Message>
          {attachments &&
            attachments.data &&
            attachments.data
              .filter((attach) => !!attach.image_data)
              .map((attach) => {
                return (
                  <Message.ImageContent
                    key={attach.image_data.url}
                    src={attach.image_data.url}
                    alt="preview"
                    width={200}
                  ></Message.ImageContent>
                );
              })}

          {attachments &&
            attachments.data &&
            attachments.data
              .filter((attach) => !!attach.video_data)
              .map((attach) => {
                return (
                  <Message.CustomContent key={attach.video_data.url}>
                    <ReactPlayer
                      controls
                      url={attach.video_data.url}
                      light={attach.video_data.preview_url}
                      width={300}
                    />
                  </Message.CustomContent>
                );
              })}
        </MessageGroup.Messages>
        <MessageGroup.Footer>
          {new Date(created_time).toLocaleTimeString()}
        </MessageGroup.Footer>
      </MessageGroup>
    );

    if (
      firstEl ||
      !isSameDay(new Date(created_time), new Date(source[idx - 1].created_time))
    ) {
      renderMessages.push(
        <MessageSeparator
          key={created_time}
          content={new Date(created_time).toLocaleString()}
        />
      );
    }

    renderMessages.push(element);
    return element;
  });

  return (
    <>
      <ProfileDetailModal
        open={openProfileDetail}
        setOpen={setOpenProfileDetail}
        psid={psid}
        name={name}
        profilePic={profilePic}
      />
      <ChatContainer>
        <ConversationHeader>
          <Avatar
            src={profilePic}
            onClick={() => {
              setOpenProfileDetail(true);
            }}
          />
          <ConversationHeader.Content userName={name} info={remarks} />
          <ConversationHeader.Actions>
            {/* <VoiceCallButton /> */}
            {/* <VideoCallButton /> */}
            {/* <InfoButton /> */}
          </ConversationHeader.Actions>
        </ConversationHeader>

        <MessageList
          loading={messages.length === 0}
          loadingMore={isLoadingMore}
          // typingIndicator={<TypingIndicator content="Emily is typing" />}
        >
          <MessageSeparator>
            {!isReachingEnd && (
              <ArrowButton
                direction="up"
                labelPosition="left"
                border
                onClick={() => {
                  setSize(size + 1);
                }}
              >
                Load previous
              </ArrowButton>
            )}
          </MessageSeparator>
          {renderMessages}
        </MessageList>
        {!isBusinessPlan && (
          <MessageInput
            disabled
            attachButton={false}
            sendButton={false}
            value="Please uptier to Business plan to enable this."
          />
        )}
        {((isBusinessPlan && isWithin24H) ||
          (isBusinessPlan &&
            !isWithin24H &&
            isWithin7Days &&
            useHumanAgentTag)) && (
          <MessageInput
            attachButton={false}
            placeholder="Type message here"
            onSend={onSendHandler}
          />
        )}
        {isBusinessPlan && !isWithin24H && !useHumanAgentTag && (
          <MessageInput
            disabled
            attachButton={false}
            sendButton={false}
            value={`Out of 24H messaging window. ${
              isWithin7Days
                ? "Use human agent tag only for content related to customer inquiry and you must comply to Messenger policy."
                : "Out of 7 days window, unable to reply here."
            }<br>`}
          />
        )}
        <InputToolbox>
          {!isIdle && isNotBoxify && isBusinessPlan && (
            <Box marginX={1}>
              <ThreadControlButton
                psid={psid}
                onSuccess={() => {
                  threadOwnerMutate();
                }}
              />
            </Box>
          )}
          {!isIdle && (
            <Box marginX={1}>
              <ReleaseButton
                psid={psid}
                onSuccess={() => {
                  threadOwnerMutate();
                }}
              />
            </Box>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={useHumanAgentTag}
                disabled={isWithin24H || !isWithin7Days}
                onChange={(event) => {
                  setUseHumanAgentTag(event.target.checked);
                }}
                name="useHumanAgent"
                color="primary"
              />
            }
            label={
              <span>
                Human agent tag
                <KbLinkButton
                  url={`https://developers.facebook.com/docs/messenger-platform/send-messages/message-tags/`}
                />
              </span>
            }
          />
        </InputToolbox>
      </ChatContainer>
    </>
  );
};

export default Messages;
