import React, { useEffect } from "react";
import { required, minValue, maxValue, useRecordContext } from "react-admin";
import Decimal from "decimal.js-light";
import TextInputNoWheel from "../Common/TextInputNoWheel";
import { useFormContext, useWatch } from "react-hook-form";

const QuantityInput = (props) => {
  const { useKeyword = false } = props;
  const originalRecord = useRecordContext();
  const { setValue, formState } = useFormContext();
  const keyword = useWatch({ name: "keyword" });
  const quantity = useWatch({ name: "quantity" });
  const recalculateStock = useWatch({ name: "recalculateStock" });

  const originalRecordQuantity = isNaN(originalRecord.quantity)
    ? 0
    : parseInt(originalRecord.quantity);

  const localMaxStock =
    keyword && !isNaN(keyword.stockLeft)
      ? keyword.stockLeft + originalRecordQuantity
      : 0;

  useEffect(() => {
    if (keyword && quantity && formState.dirtyFields.quantity) {
      const { weight = 0, cost = 0, price = 0, stockLeft = 0 } = keyword;
      const qty = Number(quantity);

      if (qty <= 0 || isNaN(qty)) {
        //purposely do nothing here
        // setValue("price", "");
        // setValue("weight", "");
        // setValue("cost", "");
      } else {
        const quantityDecimal = new Decimal(qty);
        const newPrice = quantityDecimal.times(price).toNumber();
        const newWeight = quantityDecimal.times(Number(weight)).toNumber();
        const newCost = quantityDecimal.times(Number(cost)).toNumber();
        setValue("quantity", qty);
        setValue("price", newPrice);
        setValue("weight", newWeight);
        setValue("cost", newCost);
      }
    }
  }, [
    formState.dirtyFields.quantity,
    formState.touchedFields,
    keyword,
    quantity,
    setValue,
  ]);

  return (
    <TextInputNoWheel
      type="number"
      source="quantity"
      label="Qty"
      disabled={useKeyword && !keyword}
      helperText={recalculateStock ? `Remaining stock ${localMaxStock}` : ""}
      validate={[
        required(),
        minValue(1, "At least 1"),
        recalculateStock
          ? maxValue(localMaxStock, `Out of stock, ${localMaxStock} left`)
          : undefined,
      ]}
    />
  );
};

export default QuantityInput;
