import React from "react";
import { useNotify } from "react-admin";
import { makeStyles } from "@mui/styles";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import IconButton from "@mui/material/IconButton";
// import AddBoxIcon from "@mui/icons-material/AddBox";
import QuickAddDropdown from "./QuickAddDropdown";
import {
  store,
  SET_COMMENT_SCROLL,
  SET_LOADING,
  SET_QUICK_CREATE_TARGET,
} from "./store";
import { getCookie } from "../../helpers/Cookies";
import { createOrderItem } from "./sharedMethods";

const comboRegex = /(.*)x(\d{1,}$)/i;

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const QuickAddModal = (props) => {
  const classes = useStyles();
  const token = getCookie("token");
  const notify = useNotify();

  const { dispatch, state } = React.useContext(store);
  const { loading, quickCreateTarget } = state;
  const { id: commentId, from, message } = quickCreateTarget;

  const [itemDesc, setItemDesc] = React.useState("");
  const [price, setPrice] = React.useState(0);

  const handleClose = React.useCallback(() => {
    dispatch({ type: SET_QUICK_CREATE_TARGET, payload: null });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch({ type: SET_COMMENT_SCROLL, payload: false });
    return () => {
      dispatch({ type: SET_COMMENT_SCROLL, payload: true });
    };
  }, []);

  const submitOrders = React.useCallback(() => {
    dispatch({ type: SET_LOADING, payload: true });
    const matches = comboRegex.exec(itemDesc);
    let quantity = 1;
    let description = itemDesc;

    if (matches) {
      const [fullString, productDescription, qty] = matches;
      quantity = parseInt(qty);
    }

    const payload = {
      data: {
        orders: [
          {
            description,
            quantity,
            price,
            psid: from.id,
            customerName: from.name,
            commentId,
            // status,
          },
        ],
      },
    };
    createOrderItem({ token, payload })
      .then((resp) => {
        notify("Quick create successfully");
        dispatch({ type: SET_LOADING, payload: false });
        handleClose();
      })
      .catch((e) => {
        notify("Error: quick create failed", "warning");
        dispatch({ type: SET_LOADING, payload: false });
      });
  }, [
    token,
    itemDesc,
    price,
    // status,
    dispatch,
    notify,
    handleClose,
    from,
    commentId,
  ]);

  return (
    <>
      <Dialog
        open={!!quickCreateTarget}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {`Quick create for ${from.name}`}
        </DialogTitle>
        <DialogContent>
          {/* <TextField
            autoFocus
            margin="dense"
            label="Item description"
            fullWidth
          /> */}
          <form className={classes.root} noValidate autoComplete="off">
            <QuickAddDropdown
              itemDesc={itemDesc}
              setItemDesc={setItemDesc}
              price={price}
              setPrice={setPrice}
            />
            <TextField
              label={`Price`}
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
            {/* <FormControl>
              <InputLabel id="demo-controlled-open-select-label">
                Status
              </InputLabel>
              <Select
                // labelId="demo-controlled-open-select-label"
                // id="demo-controlled-open-select"
                label={`Status`}
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
              >
                <MenuItem value={"new"}>New</MenuItem>
                <MenuItem value={"paid"}>Paid</MenuItem>
                <MenuItem value={"cancelled"}>Cancelled</MenuItem>
              </Select>
            </FormControl> */}
          </form>
          <DialogContentText>{`Comment: ${message}`}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" disabled={loading}>
            Cancel
          </Button>
          <Button onClick={submitOrders} color="primary" disabled={loading}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default QuickAddModal;
