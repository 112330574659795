import React from "react";
import { useNotify, useRecordContext } from "react-admin";
import CircularProgress from "@mui/material/CircularProgress";

import { getCookie } from "../../helpers/Cookies";
import { fetchHandleError } from "../../helpers/fetchHandleError";
import Button from "@mui/material/Button";
import { useProfile } from "../DataHooks/useProfile";

const PostNameListButton = () => {
  const { profile } = useProfile();
  const record = useRecordContext();
  const [processing, setProcessing] = React.useState(false);

  const notify = useNotify();
  const generateNamelist = React.useCallback(
    (data) => {
      const validItems = data.filter((item) => {
        return !!item.commentId;
      });

      const post = validItems[0].commentId.split("_")[0];
      return {
        post: post,
        text:
          `${record.key}: ${record.description}\n` +
          validItems
            .map((item) => {
              return `${item.order.customerName} +${item.quantity}`;
            })
            .join(", "),
      };
    },
    [record.description, record.key]
  );

  const postComments = React.useCallback(
    (postContent) => {
      const { post, text } = postContent;
      return fetch(
        `https://graph.facebook.com/${
          profile.page.psid
        }_${post}/comments?access_token=${
          profile.page.token
        }&message=${encodeURIComponent(text)}`,
        {
          method: "POST",
        }
      ).then(fetchHandleError);
    },
    [profile.page.psid, profile.page.token]
  );
  const onClick = React.useCallback(async () => {
    const jwtToken = getCookie("token");
    setProcessing(true);
    try {
      const resp = await fetch(
        `${process.env.REACT_APP_CMS_HOST}/orderItems?keyword=${
          record.id
        }&status_in=new&status_in=paid&commentId_null=false&_limit=-1&createdAt_gte=${new Date(
          new Date().getTime() - 3600000
        ).toISOString()}`,
        //1 hour
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      ).then(fetchHandleError);

      if (resp.length === 0) {
        notify("No bidders found last hour", "info");
        setProcessing(false);
        return;
      }
      const result = generateNamelist(resp);
      const data2 = await postComments(result);
      notify("Namelist posted successful", "info");

      setProcessing(false);
    } catch (e) {
      notify(e.error.message, { type: "error" });
      setProcessing(false);
    }
  }, [generateNamelist, notify, postComments, record.id]);
  return (
    <Button variant="contained" disabled={processing} onClick={onClick}>
      {processing ? `PROCESSING...` : `Comment namelist to LIVE video`}
      {processing && <CircularProgress color="inherit" size="2em" />}
    </Button>
  );
};
export default PostNameListButton;
