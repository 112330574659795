import Decimal from "decimal.js-light";
import { ORDER_ITEM_CANCELLED } from "../components/Constant";
import { downloadCSV } from "react-admin";
import setSeconds from "date-fns/setSeconds";
import startOfToday from "date-fns/startOfToday";
import subDays from "date-fns/subDays";

export const fetch_retry = (url, options, n) =>
  fetch(url, options).catch(function (error) {
    if (n === 1) throw error;
    return fetch_retry(url, options, n - 1);
  });

export const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
};

export const printDate = (date) =>
  new Date(date).toLocaleString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

export const printMonthDay = (date) =>
  new Date(date).toLocaleString("en-US", {
    day: "numeric",
    month: "short",
  });

export const getSum = (items) => {
  const v = items.reduce((total, i) => total.plus(i.price), new Decimal(0));
  return v.toFixed(2);
};

export const getTotalWeight = (items) => {
  const v = items
    .filter((item) => !isNaNOrNull(item.weight))
    .reduce((total, i) => total.plus(i.weight), new Decimal(0));
  return v.toFixed(2);
};

export const getNonCancelledItems = ({ status }) =>
  status !== ORDER_ITEM_CANCELLED;

export const isArray = (o) => {
  return Object.prototype.toString.call(o) === "[object Array]";
};

export const isString = (value) => {
  return typeof value === "string" || value instanceof String;
};

/** ES2015+ -- ultra-fast
@param n <Number> what to pad left with
@param z <Number> (zeros :) ) width which pad to
@param s <String> (sign :) ) char to pad with, of course may be some like that 'oO'
@return <String>
@example
	pz(7, 15, 'oO')
	// ==> "oOoOoOoOoOoOoO7"

	pz(-1005007, 20)
	// ==> "-00000000000001005007"
*/
/** v2, do the same but can work in carton boxes
 */
export const pz = (n, z, s) => {
  var z = z || 2,
    s = s || "0";
  return (n + "").length <= z
    ? ["", "-"][+(n < 0)] + (s.repeat(z) + Math.abs(n)).slice(-1 * z)
    : n + "";
};

export const getFormData = (object) =>
  Object.keys(object).reduce((formData, key) => {
    formData.append(key, object[key]);
    return formData;
  }, new FormData());

export const dateDiffInDays = (a, b) => {
  // Discard the time and time-zone information.
  const diffTime = b - a;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export const getRandomInt = (max) => {
  return Math.floor(Math.random() * Math.floor(max));
};

export const removeSpace = (str) => str.replace(/\s/g, "");

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export const shuffle = (a) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

export const comboRegex = /(.*)x(\d{1,}$)/i;

export const isNaNOrNull = (v) => isNaN(v) || v === null || v === "";

export const checkForHexRegExp = new RegExp("^[0-9a-fA-F]{24}$");

export const sanitizePunctuation = (str) => {
  if (!str) {
    return "";
  }
  return str.replace(/[，,]/g, ",");
};

export const downloadCSVWithBom = (csv, name) => {
  const csvWithBom = "\uFEFF" + csv;
  downloadCSV(csvWithBom, name);
};

export const getOrderItemSearchStartDate = () =>
  setSeconds(subDays(startOfToday(), 45), 0);

export const uniqueByValue = (v, idx, arr) => arr.indexOf(v) === idx;

// Map RHF's dirtyFields over the `data` received by `handleSubmit` and return the changed subset of that data.
export const dirtyValues = (dirtyFields, allValues) => {
  // If *any* item in an array was modified, the entire array must be submitted, because there's no way to indicate
  // "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues;
  // Here, we have an object
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      dirtyValues(dirtyFields[key], allValues[key]),
    ])
  );
};

export const getMaxZero = (v) => Math.max(0, v);
export const getMaxZeroD = (v) => new Decimal(getMaxZero(v));
