import React from "react";
import {
  required,
  TextInput,
  useCreate,
  useNotify,
  useRefresh,
  SelectInput,
  FormDataConsumer,
} from "react-admin";
import { useForm, FormProvider } from "react-hook-form";
import { getPageId } from "../../helpers/getPageId";
import AutoCompleteDescription from "./AutoCompleteDescription";
import QuantityInput from "./QuantityInput";
import CombineDescriptionWithQuantity from "./CombineDescriptionWithQuantity";
import { ORDER_ITEM_NEW, ORDER_ITEM_PAID } from "../Constant";
import TextInputNoWheel from "../Common/TextInputNoWheel";
import Button from "@mui/material/Button";

const choices = [ORDER_ITEM_NEW, ORDER_ITEM_PAID].map((c) => ({
  id: c,
  name: c,
}));

const CreateOrderItemByFreeText = ({ currency, orderId, onClose }) => {
  const pageId = getPageId();
  const refresh = useRefresh();
  const [create, { isLoading }] = useCreate();
  const notify = useNotify();

  const formMethods = useForm({
    defaultValues: {
      page: pageId,
      order: orderId,
      currencyCode: currency,
      status: ORDER_ITEM_NEW,
    },
  });

  const handleSubmit = async ({ item, ...values }) => {
    create(
      "orderitems",
      { data: values },
      {
        onSuccess: ({ data }) => {
          notify(`Order item created`, "info");
          refresh();
          onClose();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  return (
    <form className="form" onSubmit={formMethods.handleSubmit(handleSubmit)}>
      <FormProvider {...formMethods}>
        <>
          <AutoCompleteDescription />
          <br />
          <span style={{ color: "red" }}>
            *No need to put quantity in item description
          </span>
          <br />
          <QuantityInput />
          <CombineDescriptionWithQuantity />
          <FormDataConsumer>
            {({ formData }) => {
              return (
                <>
                  <TextInputNoWheel
                    type="number"
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleSubmit(formData);
                      }
                    }}
                    source="price"
                    validate={required()}
                    fullWidth
                  />
                  <TextInputNoWheel
                    type="number"
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleSubmit(formData);
                      }
                    }}
                    source="weight"
                    validate={required()}
                    fullWidth
                    defaultValue={0}
                  />
                  <TextInputNoWheel
                    type="number"
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleSubmit(formData);
                      }
                    }}
                    source="cost"
                    validate={required()}
                    fullWidth
                    defaultValue={0}
                  />
                </>
              );
            }}
          </FormDataConsumer>
          <br />
          {/* <span style={{ color: "red" }}>
            *You can only edit these after item created
          </span> */}
          <SelectInput
            emptyValue={null}
            defaultValue={ORDER_ITEM_NEW}
            fullWidth
            source="status"
            choices={choices}
          />
          <TextInput disabled source="currencyCode" />
          <div>
            <Button type="submit" variant="contained" disabled={isLoading}>
              Save
            </Button>
          </div>
        </>
      </FormProvider>
    </form>
  );
};

export default CreateOrderItemByFreeText;
