import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  // EditButton,
  // RichTextField,
  BooleanField,
  NumberField,
} from "react-admin";
import { useAvailableCurrencies } from "../useAvailableCurrencies";
import CopyTemplateButton from "./CopyTemplateButton";
import ShowProductImages from "./ShowProductImages";
import PostNameListButton from "./PostNameListButton";
import { useProfile } from "../DataHooks/useProfile";
import CustomChipArrayField from "../CustomChipArrayField";
import CustomDateField from "../Common/CustomDateField";

const KeywordShow = (props) => {
  const { profile } = useProfile();
  const { isMultiCurrency } = useAvailableCurrencies();

  return (
    <Show title="Show keyword" {...props}>
      <SimpleShowLayout>
        <TextField source="description" />
        <TextField source="key" />
        <TextField source="sku" label="SKU" />
        {isMultiCurrency && (
          <TextField source="currencyCode" label="Currency" />
        )}
        <NumberField source="price" />
        <NumberField source="webPrice" />
        <NumberField source="stock" />
        <NumberField source="stockLeft" />
        <NumberField source="weight" label="Weight(Kg)" />
        <NumberField source="cost" />
        <NumberField source="minQuantity" />
        <NumberField source="maxQuantity" />
        <TextField source="detail" />
        <CustomDateField source="createdAt" showTime />
        <CustomDateField source="updatedAt" showTime />
        <BooleanField source="isActive" />
        <BooleanField source="allowBackorder" />
        <BooleanField source="allowInWeb" />
        <CustomChipArrayField source="categories" label="Categories" />
        <CustomChipArrayField source="variants" label="Variants" />
        <CopyTemplateButton />
        {profile && <PostNameListButton />}
        <ShowProductImages />
      </SimpleShowLayout>
    </Show>
  );
};

export default KeywordShow;
