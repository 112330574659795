import React from "react";
import Chip from "@mui/material/Chip";
import { useRecordContext } from "react-admin";

import { isArray } from "../helpers/methods";

const Comp = (props) => {
  const { source, chipLabel = "label" } = props;
  const record = useRecordContext();
  const onClick = React.useCallback((event) => event.stopPropagation(), []);
  const elements = record[source];
  if (elements && isArray(elements)) {
    return (
      <span>
        {elements.map((e) => (
          <Chip
            key={e.id}
            label={e[chipLabel]}
            component="a"
            onClick={onClick}
            href={`/#/${source}/${e.id}/show/`}
            target="_blank"
            rel="noopener noreferrer"
            clickable
            size="small"
          />
        ))}
      </span>
    );
  }
  return null;
};

export default Comp;
