import { stripSpecialChars } from "../../helpers/stripSpecialChars";

export const checkStock = (v, record) => {
  return Number(v) < Number(record.stockLeft)
    ? "Stock must larger than stockLeft"
    : undefined;
};

export const keywordParser = (v) => {
  const v1 = stripSpecialChars(v);
  return v1.toUpperCase();
};

export const convertToInteger = (v) => {
  const v1 = Number(v);
  if (v.trim() === "") {
    return v;
  } else if (isNaN(v1)) {
    return 0;
  } else {
    return Math.round(v1);
  }
};

export const pwpHelperText = `Add "[PWP]" to exclude from free shipping calculation`;
