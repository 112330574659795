import React, { useState, useCallback, useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useSWRConfig } from "swr";
import useSWRImmutable from "swr/immutable";

import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Input from "@mui/material/Input";
import AddIcon from "@mui/icons-material/Add";
import ReplyIcon from "@mui/icons-material/Reply";
import stc from "string-to-color";
import {
  store,
  ADD_ORDER,
  SET_QUICK_CREATE_TARGET,
  SET_REPLY_COMMENT_TO,
  SET_EDIT_COMMENT,
} from "./store";
// import { store as commentStore, ADD_CONTACT } from "./commentStore";
import { SizeContext, DEFAULT_HEIGHT } from "./SizeContext";
import BlacklistButton from "./BlacklistButton";
import RatingButton from "./RatingButton";
import CustomerOwe from "./CustomerOwe";
import { withBusinessOnlyElseHide } from "../../helpers/subscriptionPlan";
import { ModeEdit } from "@mui/icons-material";
import { CACHE_CONTACTS } from "../Constant";

const CustomerOweTiered = withBusinessOnlyElseHide(CustomerOwe);

const useStyles = makeStyles({
  input: {
    width: 22,
  },
  textWrap: { width: "80%", "overflow-wrap": "anywhere" },
});

const useNameStyles = makeStyles({
  root: {
    color: (props) => stc(props.color),
  },
});

const Comment = (props) => {
  const classes = useStyles();
  const { cache } = useSWRConfig();
  const { dispatch, state } = useContext(store);
  const { unitPrice, itemDesc, specDesc, page, enableCustomerInfo } = state;
  const { comment, index } = props;
  const { data: contact } = useSWRImmutable(
    `${CACHE_CONTACTS}/${comment.from.id}`,
    (key) => cache.get(key)
  );

  const { setSize, listRef } = React.useContext(SizeContext);
  // const { dispatch: commentDispatch, state: commentState } =
  //   useContext(commentStore);
  // const { contactHashTable } = commentState;
  // const contact = contactHashTable[comment.from.id]
  //   ? contactHashTable[comment.from.id]
  //   : undefined;

  const root = React.useRef();

  const {
    id: commentId,
    from,
    message,
    parent,
    can_comment: canComment,
  } = comment;
  const nameClass = useNameStyles({ color: from.id });

  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    const size = root.current.getBoundingClientRect().height;
    setSize(index, size);
    if (size > DEFAULT_HEIGHT) {
      listRef.current.resetAfterIndex(index, true);
    }
  }, [index, listRef, setSize]);

  const onChangeQuantity = useCallback(
    (e) => {
      setQuantity(e.target.value.replace(/\D/, ""));
    },
    [setQuantity]
  );

  const onKeyDown = useCallback(
    (e) => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault(),
    []
  );

  const onAdd = useCallback(() => {
    const payload = {
      quantity,
      description: `${itemDesc} ${specDesc}`,
      psid: from.id,
      customerName: from.name,
      commentId,
    };
    dispatch({ type: ADD_ORDER, payload });
  }, [dispatch, from, commentId, quantity, itemDesc, specDesc]);

  const onReplyCommentTo = useCallback(() => {
    const payload = {
      customerName: from.name,
      commentId,
    };
    dispatch({ type: SET_REPLY_COMMENT_TO, payload });
  }, [dispatch, from, commentId]);

  const isPageComment = from.id === page.id;
  return (
    <ListItem ContainerComponent="div" divider ref={root}>
      <ListItemText
        primary={
          <>
            <strong className={nameClass.root}>{from.name}</strong>
            {enableCustomerInfo && contact && contact.psid && (
              <>
                <BlacklistButton
                  contact={contact}
                  // onSuccess={(data) => {
                  //   const { isBlacklisted = false } = data;
                  //   commentDispatch({
                  //     type: ADD_CONTACT,
                  //     payload: {
                  //       [data.psid]: { ...contact, isBlacklisted },
                  //     },
                  //   });
                  // }}
                />
                <RatingButton
                  record={contact}
                  // onSuccess={(data) => {
                  //   const { rating = 0 } = data;
                  //   commentDispatch({
                  //     type: ADD_CONTACT,
                  //     payload: { [data.psid]: { ...contact, rating } },
                  //   });
                  // }}
                ></RatingButton>
                <CustomerOweTiered contact={contact} />
              </>
            )}
          </>
        }
        secondary={
          <Typography
            className={classes.textWrap}
            variant="body1"
            color="textPrimary"
          >
            {/* {message} */}
            {parent && parent.from && parent.from.name
              ? `@${parent.from.name} ${message}`
              : message}
          </Typography>
        }
      />
      {!isPageComment && (
        <ListItemSecondaryAction>
          <Input
            id="outlined-number"
            type="number"
            margin="dense"
            className={classes.input}
            value={quantity}
            pattern="\d+"
            onChange={onChangeQuantity}
            onKeyDown={onKeyDown}
          />
          <IconButton
            onClick={onAdd}
            aria-label="add n"
            edge="end"
            size="small"
            disabled={!itemDesc}
          >
            <AddIcon />
          </IconButton>
          {/* <IconButton aria-label="custom add" edge="end">
            <AddBoxIcon />
          </IconButton> */}
          <IconButton
            aria-label="custom add"
            edge="end"
            onClick={() => {
              dispatch({ type: SET_QUICK_CREATE_TARGET, payload: comment });
            }}
          >
            <AddBoxIcon />
          </IconButton>
          <IconButton
            disabled={!canComment}
            onClick={onReplyCommentTo}
            aria-label="reply comment to"
            edge="end"
            size="small"
          >
            <ReplyIcon />
          </IconButton>
          <IconButton
            aria-label="edit comment"
            edge="end"
            onClick={() => {
              dispatch({ type: SET_EDIT_COMMENT, payload: comment });
            }}
          >
            <ModeEdit />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default Comment;
