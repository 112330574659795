import React, { useCallback } from "react";
import {
  SimpleForm,
  Edit,
  TextInput,
  BooleanInput,
  SelectInput,
  required,
  Toolbar,
  SaveButton,
  DeleteButton,
  // useRecordContext,
} from "react-admin";

import {
  checkStock,
  keywordParser,
  // convertToInteger,
  pwpHelperText,
} from "./common";
import { useAvailableCurrencies } from "../useAvailableCurrencies";
import { useIsBusinessPlan } from "../../helpers/subscriptionPlan";
import ProductImages from "./ProductImages";
import AttachSelections from "../AutocompleteAttach/AttachSelections";
import DecimalInput, { PositiveDecimalInput } from "../Common/DecimalInput";
import IntegerInput, { PositiveIntegerInput } from "../Common/IntegerInput";
// import NumberInputNoWheel from "../Common/NumberInputNoWheel";
// import TextInputNoWheel from "../Common/TextInputNoWheel";

const KeywordEditToolbar = (props) => {
  // const record = useRecordContext();
  // const { stockLeft } = record;

  return (
    <Toolbar {...props}>
      <SaveButton />
      <DeleteButton mutationMode="undoable" />
    </Toolbar>
  );
};

export const KeywordEdit = (props) => {
  const { availableCurrencies, defaultCurrency, isMultiCurrency } =
    useAvailableCurrencies();
  const isBusinessPlan = useIsBusinessPlan();

  const transform = useCallback((data, { previousData, ...rest }) => {
    const { stockLeft, stock, ...others } = data;
    const { stockLeft: pStockLeft, stock: pStock } = previousData;
    return {
      ...others,
      ...(stockLeft !== pStockLeft && { stockLeft }),
      ...(stock !== pStock && { stock }),
    };
  }, []);

  return (
    <Edit transform={transform} title="Edit keyword" {...props}>
      <SimpleForm toolbar={<KeywordEditToolbar />}>
        <TextInput
          multiline
          source="description"
          validate={required()}
          helperText={pwpHelperText}
        />
        <TextInput source="key" parse={keywordParser} validate={required()} />
        <TextInput source="sku" label="SKU" />
        {isMultiCurrency && (
          <SelectInput
            defaultValue={defaultCurrency}
            validate={required()}
            source="currencyCode"
            choices={availableCurrencies.map((c) => ({ id: c, name: c }))}
          />
        )}
        <DecimalInput source="price" validate={required()} />
        <DecimalInput source="webPrice" validate={required()} />
        <PositiveIntegerInput source="stockLeft" validate={required()} />
        <PositiveIntegerInput
          source="stock"
          validate={[checkStock, required()]}
        />
        <PositiveDecimalInput
          source="weight"
          label="Weight(Kg)"
          validate={required()}
        />
        <DecimalInput source="cost" validate={required()} />
        <TextInput source="detail" label="Detail" multiline />
        {isBusinessPlan && (
          <IntegerInput
            source="minQuantity"
            defaultValue={1}
            validate={required()}
            helperText="0 or -1 means no limit"
          />
        )}
        {isBusinessPlan && (
          <IntegerInput
            source="maxQuantity"
            defaultValue={-1}
            validate={required()}
            helperText="0 or -1 means no limit"
          />
        )}
        <BooleanInput source="isActive" />
        <BooleanInput source="allowBackorder" />
        <BooleanInput source="allowInWeb" />
        <AttachSelections source="categories" searchFieldName="label" />
        <AttachSelections source="variants" searchFieldName="label" />
        <ProductImages />
      </SimpleForm>
    </Edit>
  );
};

export default KeywordEdit;
