import React from "react";
import Decimal from "decimal.js-light";
import {
  useTotalInclShipping,
  getTotalInclShipping,
} from "./TotalInclShipping";
import {
  getGetDiscountValue,
  useGetDiscountValue,
} from "../components/Voucher/useGetDiscount";

export const getTotalInclShippingAfterDiscount = (record) => {
  const totalInclShipping = getTotalInclShipping(record);
  const discountValue = getGetDiscountValue(record);
  const balanceDecimal = new Decimal(totalInclShipping).minus(discountValue);
  return balanceDecimal.toFixed(2);
};

export const useTotalInclShippingAfterDiscount = (record) => {
  const totalInclShipping = useTotalInclShipping(record);
  const discountValue = useGetDiscountValue(record);
  const balance = React.useMemo(() => {
    const balanceDecimal = new Decimal(totalInclShipping).minus(discountValue);
    return balanceDecimal.toFixed(2);
  }, [totalInclShipping, discountValue]);
  return balance;
};
