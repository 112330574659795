import React from "react";

import {
  getTaxInclWithShippingTax,
  getTaxInclWithoutShippingTax,
} from "./TaxIncl";
import {
  getTaxExclWithShippingTax,
  getTaxExclWithoutShippingTax,
} from "./TaxExcl";

export const getTaxCalculation = (record) => {
  if (record && record.tax && record.tax.itemIncludesTax) {
    const calculation = record.tax.shippingIncludesTax
      ? getTaxInclWithShippingTax({
          record,
          taxRate: record.tax.rate,
        })
      : getTaxInclWithoutShippingTax({
          record,
          taxRate: record.tax.rate,
        });
    return calculation;
  } else if (record && record.tax && !record.tax.itemIncludesTax) {
    const calculation = record.tax.shippingIncludesTax
      ? getTaxExclWithShippingTax({
          record,
          taxRate: record.tax.rate,
        })
      : getTaxExclWithoutShippingTax({
          record,
          taxRate: record.tax.rate,
        });
    return calculation;
  }
  return null;
};

export const withTaxCalculation = (WrapComp) => (props) => {
  const { record } = props;
  //itemIncludesTax defines the order is taxable and whether tax is included or excluded
  //shippingIncludesTax defines the shipping is taxable or not only
  //shippingRate will be ignored
  const calculation = getTaxCalculation(record);
  if (!calculation) {
    return null;
  }
  return <WrapComp {...props} calculation={calculation} />;
  // return <WrapComp {...props} calculation={undefined} />;
};
