import React, { useState, useCallback } from "react";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  useRefresh,
  useNotify,
  useUnselectAll,
  useListContext,
  useDataProvider,
} from "react-admin";

import { statuses as orderStatuses } from "../../orders";
import { ORDER_ITEM_NEW, CANCELLED } from "../Constant";
import { uniqueByValue } from "../../helpers/methods";
import Loader from "../Loader";
import { useCustomAllRecords } from "../Common/useCustomAllRecords";

const MAX_CONCURRENT = 10;

const BulkUpdateStatusButton = (props) => {
  const dataProvider = useDataProvider();
  const allRecords = useCustomAllRecords();
  const [requests, setRequests] = React.useState([]);
  const [pending, setPending] = React.useState(0);
  const [blacklist, setBlacklist] = useState(false);
  const { resource, selectedIds } = useListContext();
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState("");

  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll(resource);
  const [recalculateStock, setRecalculateStock] = useState(true);

  const handleRecalculateStock = useCallback(() => {
    setRecalculateStock((v) => !v);
  }, [setRecalculateStock]);

  const handleClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleDialogClose = useCallback(() => {
    unselectAll();
    setIsOpen(false);
    setRecalculateStock(false);
    setValue("");
    setBlacklist(false);
  }, [unselectAll]);

  React.useEffect(() => {
    if (pending === 0 && requests.length > 0) {
      const processSlice = requests.slice(0, MAX_CONCURRENT);
      const nextSlice = requests.slice(MAX_CONCURRENT);
      setPending(processSlice.length);
      setRequests(nextSlice);
      dataProvider
        .updateMany(resource, {
          ids: processSlice,
          data: { status: value },
        })
        .then((resp) => {
          setPending(0);
          if (nextSlice.length === 0) {
            refresh();
            notify(`Orders updated`);
            unselectAll();
          }
        })
        .catch((e) => {
          unselectAll();
          setRequests([]);
          setPending(0);
          notify("Error: orders not updated, stop now", "warning");
        });
      if (value === CANCELLED && blacklist) {
        const contactIds = processSlice
          .map((id) => id.split("?")[0])
          .map((id) => allRecords.find(({ _id }) => id === _id))
          .filter((o) => o && o.contact)
          .map((o) => o.contact.id)
          .filter(uniqueByValue);

        dataProvider.updateMany(`contacts`, {
          ids: contactIds,
          data: { isBlacklisted: true },
        });
      }
    }
  }, [
    pending,
    setPending,
    requests,
    setRequests,
    value,
    refresh,
    notify,
    unselectAll,
    resource,
    dataProvider,
    blacklist,
    allRecords,
  ]);
  const selectedRecords = allRecords.filter((record) =>
    selectedIds.includes(record._id)
  );

  const unpaidItemOrders = selectedRecords.filter((order) => {
    const newItems = order.orderItems.filter(
      (item) => item.status === ORDER_ITEM_NEW
    );
    return newItems.length > 0;
  });

  const handleConfirm = React.useCallback(() => {
    const selectedWithFlagIds =
      value === CANCELLED && recalculateStock
        ? selectedIds.map((id) => `${id}?recalculateStock=true`)
        : selectedIds;
    setRequests(selectedWithFlagIds);
    setIsOpen(false);
  }, [value, recalculateStock, selectedIds]);

  const enableUpdate = !!orderStatuses.find(({ id }) => id === value);

  return (
    <>
      <Button variant="outlined" label="Update status" onClick={handleClick}>
        <EditIcon />
        status
      </Button>
      <Dialog
        // fullScreen={false}
        open={isOpen}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Select order status"}
        </DialogTitle>
        <DialogContent>
          <Select
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {orderStatuses.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.name}
              </MenuItem>
            ))}
          </Select>
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={recalculateStock}
                onChange={handleRecalculateStock}
                name="recalculateStock"
                disabled={value !== CANCELLED}
              />
            }
            label="Recalculate stock"
          />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={blacklist}
                onChange={(e) => {
                  if (e.target.checked) {
                    const result = window.confirm(
                      `Blacklisted can comment but cannot place order.\n Are you sure?`
                    );
                    if (result) {
                      setBlacklist(e.target.checked);
                    }
                  } else {
                    setBlacklist(e.target.checked);
                  }
                }}
                name="blacklist"
                disabled={value !== CANCELLED}
              />
            }
            label="Blacklist customer"
          />
          {unpaidItemOrders.length > 0 && (
            <>
              <DialogContentText>{`These orders contains unpaid items`}</DialogContentText>
              <ul>
                {unpaidItemOrders.map((o, idx) => (
                  <li key={idx}>{o.customerName}</li>
                ))}
              </ul>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={!enableUpdate}
            onClick={handleConfirm}
            color="primary"
            autoFocus
          >
            Update
          </Button>
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Loader open={pending > 0 || requests.length > 0}>
        {`DO NOT CLOSE THIS WINDOW !\n Processing..., ${requests.length} left`}
      </Loader>
    </>
  );
};

export default BulkUpdateStatusButton;
