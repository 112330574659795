import React, { useState, useCallback } from "react";
import Button from "@mui/material/Button";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import DialogActions from "@mui/material/DialogActions";
import { useForm, FormProvider } from "react-hook-form";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import {
  useRefresh,
  useNotify,
  useListContext,
  useUnselectAll,
  useDataProvider,
} from "react-admin";
import Loader from "../Loader";
import AutoCompleteTag from "./AutoCompleteTag";
import { useCustomAllRecords } from "../Common/useCustomAllRecords";

const OVERWRITE = "overwrite";
const APPEND = "append";
const REMOVE = "remove";

const BulkUpdateTagsButton = (props) => {
  const {
    resource,
    selectedIds,
  } = props;
  const allRecords = useCustomAllRecords();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll(resource);
  const dataProvider = useDataProvider();

  const selectedRecords = allRecords.filter((record) =>
    selectedIds.includes(record._id)
  );

  const formMethods = useForm({
    defaultValues: {
      tagName: "",
      tags: [],
      temp_selectedTag: null,
      tagUpdateRadio: OVERWRITE,
    },
  });
  const {
    setValue,
    reset,
    //  register,
    watch,
    // handleSubmit
  } = formMethods;

  const [tags, tagUpdateRadio] = watch(["tags", "tagUpdateRadio"]);

  React.useEffect(() => {
    return () => {
      unselectAll();
    };
  }, [reset, resource, unselectAll]);

  const handleClick = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const handleDialogClose = useCallback(() => {
    setIsOpen(false);
    reset();
    unselectAll();
  }, [reset, unselectAll]);

  const onSubmit = React.useCallback(
    async ({ tagUpdateRadio, tags }) => {
      const updateDatas =
        tagUpdateRadio === APPEND
          ? selectedRecords.map((d) => {
              const newTags = [...d.tags, ...tags];
              return {
                id: d.id,
                tags: newTags,
              };
            })
          : tagUpdateRadio === OVERWRITE
          ? selectedIds.map((id) => ({
              id: id,
              tags: tags,
            }))
          : tagUpdateRadio === REMOVE
          ? selectedIds.map((id) => ({
              id: id,
              tags: [],
            }))
          : [];
      try {
        setLoading(true);
        const responses = await Promise.all(
          updateDatas.map(({ id, tags }) =>
            dataProvider.update("orderItems", { id, data: { tags } })
          )
        );
        setLoading(false);
        notify("Order item Tags updated successfully", "info");
        handleDialogClose();
        refresh();
      } catch (e) {
        setLoading(false);
        handleDialogClose();
        notify("Order item Tags update failed.", "warning");
      }
    },
    [
      dataProvider,
      handleDialogClose,
      notify,
      refresh,
      selectedIds,
      selectedRecords,
    ]
  );

  if (loading) {
    return <Loader open></Loader>;
  }

  return (
    <form className="form" onSubmit={formMethods.handleSubmit(onSubmit)}>
      <Button
        startIcon={<LocalOfferIcon />}
        label="Update tag"
        onClick={handleClick}
      >
        Tag
      </Button>
      <FormProvider {...formMethods}>
        <>
          <Dialog
            fullWidth
            maxWidth={"sm"}
            open={isOpen}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"Update tags"}
            </DialogTitle>

            <>
              <DialogContent>
                {tagUpdateRadio !== REMOVE && (
                  <div>
                    <AutoCompleteTag />
                    <ul>
                      <p>Selected tags</p>
                      {tags.map((tag) => (
                        <li key={tag.label}>{tag.label}</li>
                      ))}
                    </ul>
                  </div>
                )}

                <br />
                <FormControl>
                  <FormLabel>Action</FormLabel>
                  <RadioGroup
                    value={tagUpdateRadio}
                    // {...register("tagUpdateRadio")}
                    onChange={(e) => {
                      setValue("tagUpdateRadio", e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value={OVERWRITE}
                      control={<Radio />}
                      label="Overwrite"
                    />
                    <FormControlLabel
                      value={APPEND}
                      control={<Radio />}
                      label="Append"
                    />
                    <FormControlLabel
                      value={REMOVE}
                      control={<Radio />}
                      label="Remove"
                    />
                  </RadioGroup>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose} color="secondary">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    onSubmit({ tagUpdateRadio, tags });
                  }}
                  disabled={tagUpdateRadio !== REMOVE && tags.length < 1}
                >
                  Update
                </Button>
              </DialogActions>
            </>
          </Dialog>
        </>
      </FormProvider>
    </form>
  );
};

export default BulkUpdateTagsButton;
