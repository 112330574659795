import * as React from "react";
import ls from "local-storage";
import { usePermissions } from "react-admin";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";

import { orderItemExportDefaultFields } from "../../helpers/ExportDefaultFields";
import { SELLER_ROLE } from "../../helpers/role";
import { useIsBusinessPlan } from "../../helpers/subscriptionPlan";

const ExportSettingsButton = () => {
  const { permissions } = usePermissions();
  const isBusinessPlan = useIsBusinessPlan();
  const isSeller = permissions === SELLER_ROLE;
  const [itemExportParams, setItemExportParams] = React.useState(
    orderItemExportDefaultFields
  );
  const onToggleHandler = React.useCallback(
    (e) => {
      const newState = {
        ...itemExportParams,
        [e.target.name]: !itemExportParams[e.target.name],
      };
      setItemExportParams(newState);
      ls.set("itemExportParams", newState);
    },
    [setItemExportParams, itemExportParams]
  );

  const onSelectAll = React.useCallback(() => {
    setItemExportParams((prev) => {
      const keys = Object.keys(prev);
      const allTrue = keys.filter((k) => !prev[k]).length === 0;
      const newState = keys.reduce((acc, k) => {
        acc[k] = !allTrue;
        return acc;
      }, {});
      ls.set("itemExportParams", newState);
      return newState;
    });
  }, []);

  React.useEffect(() => {
    if (!isBusinessPlan) {
      return;
    }

    const p = ls.get("itemExportParams");
    if (!!p) {
      setItemExportParams((prev) => ({
        ...prev,
        ...p,
      }));
    }
  }, []);

  const keys = Object.keys(itemExportParams);
  const filtered = keys.filter((k) => {
    // only seller can see cost and profit
    if (!isSeller) {
      return ["totalCost", "totalProfit"].indexOf(k) < 0;
    }
    return true;
  });
  const allTrue = filtered.filter((k) => !itemExportParams[k]).length === 0;

  return (
    <>
      <div>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!isBusinessPlan}
                  checked={allTrue}
                  onChange={onSelectAll}
                  color="primary"
                />
              }
              label={`Select all`}
            />
          </Grid>
          {filtered.map((k) => {
            return (
              <Grid item xs={4} key={k}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!isBusinessPlan}
                      checked={itemExportParams[k]}
                      onChange={onToggleHandler}
                      name={k}
                      color="primary"
                    />
                  }
                  label={k}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </>
  );
};

export default ExportSettingsButton;
