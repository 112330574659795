import { unparse as convertToCSV } from "papaparse/papaparse.min";
import { downloadCSVWithBom } from "../../helpers/methods";

export const exporter = (vouchers) => {
  const vouchersForExport = vouchers.map((voucher) => {
    const {
      __v,
      updatedAt,
      _id,
      page,
      expiredAt,
      createdAt,
      orders = [],
      discountAfterShipping,
      ...voucherForExport
    } = voucher; // omit backlinks and author

    const orderNumbers = orders.map((o) => o.orderNumber);
    const customerNames = orders.map((o) => o.customerName);

    return {
      ...voucherForExport,
      discountIncludeShipping: discountAfterShipping,
      expiredAt: new Date(expiredAt).toLocaleString(),
      createdAt: new Date(createdAt).toLocaleString(),
      updatedAt: new Date(updatedAt).toLocaleString(),
      used: orderNumbers.length,
      orderNumbers: orderNumbers.join("\n"),
      customerNames: customerNames.join("\n"),
    };
  });
  const csv = convertToCSV({
    data: vouchersForExport,
    fields: [
      "code",
      "value",
      "discountType",
      "maxDiscount",
      "maxUsage",
      "maxUsagePerCustomer",
      "discountIncludeShipping",
      "isActive",
      "expiredAt",
      "createdAt",
      "updatedAt",
      "used",
      "orderNumbers",
      "customerNames",
    ], // voucher fields in the export
  });
  downloadCSVWithBom(csv, `vouchers_${new Date().toLocaleDateString()}`); // download as 'vouchers.csv` file
};
