import React from "react";
import { useRecordContext } from "react-admin";
import Messages from "./Messages";
import { useContactIgProfile } from "../DataHooks/useGetFbProfileImage";
import { CONTACT_CHANNEL_INSTAGRAM } from "../Constant";

const style = {
  height: "70vh",
};

const InstagramWrapper = (props) => {
  const { data: selectedCustomer, error } = useContactIgProfile(
    props.contact.psid
  );
  if (!selectedCustomer && !error) {
    return <span>Loading</span>;
  }
  return (
    <Messages
      contact={{
        ...props.contact,
        profilePic: selectedCustomer ? selectedCustomer.profile_pic : "",
      }}
    />
  );
};

const Wrapper = (props) => {
  const record = useRecordContext();
  if (
    record &&
    record.contact &&
    record.contact.psid &&
    record.contact.channel &&
    record.contact.channel === CONTACT_CHANNEL_INSTAGRAM
  ) {
    return (
      <div style={style}>
        <InstagramWrapper contact={record.contact} />
      </div>
    );
  } else if (record && record.contact && record.contact.psid) {
    return (
      <div style={style}>
        <Messages contact={record.contact} />
      </div>
    );
  }
  return <div>This order does not have a contact.</div>;
};

export default Wrapper;
