import React from "react";
import useSWR from "swr";
import CircularProgress from "@mui/material/CircularProgress";

export const useGetTaxes = () => {
  const target = `${process.env.REACT_APP_CMS_HOST}/taxes/`;
  const { data, error, mutate } = useSWR(target, {
    revalidateOnFocus: false,
  });

  return {
    taxes: data,
    tax: data ? data.find(({ isActive }) => isActive) : null,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export const withTaxes = (WrappedComponent) => (props) => {
  const { taxes, isLoading, isError } = useGetTaxes();
  if (taxes) {
    return <WrappedComponent taxes={taxes} {...props} />;
  } else if (isLoading) {
    return <CircularProgress size="1rem" />;
  } else if (isError) {
    return "Failed to load";
  }
  return <WrappedComponent {...props} />;
};

export const withTax = (WrappedComponent) => (props) => {
  const { taxes, isLoading, isError } = useGetTaxes();

  if (taxes) {
    const tax = taxes.find(({ isActive }) => isActive);
    return <WrappedComponent tax={tax} {...props} />;
  } else if (isLoading) {
    return <CircularProgress size="1rem" />;
  } else if (isError) {
    return "Failed to load";
  }
  return <WrappedComponent {...props} />;
};
