import React, { useState, useCallback } from "react";
import { makeStyles } from "@mui/styles";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import AddComment from "@mui/icons-material/AddComment";
import { useProfile } from "../DataHooks/useProfile";
import { fetchHandleError } from "../../helpers/fetchHandleError";
import { getFormData } from "../../helpers/methods";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginBottom: "5em",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const AddCommentComp = (props) => {
  const { video } = props;
  const classes = useStyles();
  const [value, setValue] = useState("");
  const { profile } = useProfile();

  const onChangeHandler = useCallback((e) => setValue(e.target.value), []);
  const onClickAdd = useCallback(() => {
    const data = getFormData({ message: value });
    fetch(
      `${process.env.REACT_APP_GRAPH_API_URL}/${video.id}/comments?access_token=${profile.page.token}`,
      {
        method: "post",
        body: data,
      }
    )
      .then(fetchHandleError)
      .then((resp) => {})
      .catch((e) => {
        alert("Error posting comment");
      });
    setValue("");
  }, [profile, value, video]);
  return (
    <Paper component="div" className={classes.root}>
      <InputBase
        className={classes.input}
        multiline
        placeholder="yada yada..."
        inputProps={{ "aria-label": "Add comment" }}
        onChange={onChangeHandler}
        value={value}
      />
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        color="primary"
        disabled={!value.trim()}
        className={classes.iconButton}
        onClick={onClickAdd}
      >
        <AddComment />
      </IconButton>
    </Paper>
  );
};

export default AddCommentComp;
