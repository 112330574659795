import { useCallback } from "react";
import ls from "local-storage";
import {
  // useIsBusinessPlan,
  // useIsEnterprisePlan,
  useMaxResultsByPlan,
} from "../../helpers/subscriptionPlan";
import { useProfile } from "../DataHooks/useProfile";
import orderExportDefaultFields from "../../helpers/ExportDefaultFields";
import { usePermissions } from "react-admin";
import { SELLER_ROLE } from "../../helpers/role";
import { exporter as customExporter } from "../../Order/Exporter";
import { useShippingMethod } from "../useShippingMethod";
import { isArray } from "../../helpers/methods";

// const defaultSortStr = "_id:DESC";

export const useExportMaxRecord = () => {
  const maxResult = useMaxResultsByPlan();
  return maxResult * 3;
};

export const useGetExportEndpoint = (props) => {
  const { filter, sort = {}, resource } = props;
  const maxResult = useExportMaxRecord();
  const { profile } = useProfile();
  // const isBusiness = useIsBusinessPlan();
  // const isEnterprise = useIsEnterprisePlan();
  const { field = "_id", order = "DESC" } = sort;
  if (!resource || !profile) {
    return "";
  }
  const filters = Object.entries(filter)
    .filter(([key]) => key !== "q") //filter away the q query string
    .reduce((acc, cur) => {
      const [k, v] = cur;
      if (v && isArray(v)) {
        const arr = v.map((singleValue) => {
          return [k, singleValue];
        });
        return [...acc, ...arr];
      }
      return [...acc, cur];
    }, [])
    .map((v) => v.join("="));
  const sortStr = `_sort=${field}:${order},_id:DESC`;
  const criterias = [
    sortStr,
    `page=${profile.page._id}`,
    ...filters,
    `_start=0`,
    `_limit=${maxResult}`,
  ];
  const queryStr = criterias.join("&");
  return `${process.env.REACT_APP_CMS_HOST}/${resource}?${queryStr}`;
};

//_sort=createdAt:DESC,_id:DESC
//"http://localhost:1337/orders?_sort=_id:DESC&_start=0&_limit=5000&createdAt_gte=2019-01-01T00:00:00.000Z&createdAt_lte=2024-01-01T00:00:00.000Z&status_in=active&status_in=paid&status_in=waiting_for_payment&status_in=on_hold&page=5dc2309e5fccd600e07b3d26",

/*
<ExportButtonCustom
        disabled={total === 0}
        resource={resource}
        sort={sort}
        filter={{ ...filterValues, ...filter }}
        maxResults={maxResults}
      />
      */

export const useHandleNormalExport = () => {
  const { permissions } = usePermissions();
  const { profile } = useProfile();
  const [shippingOptions] = useShippingMethod({ showAll: true });

  const handleNormalExport = useCallback(
    ({ orders = [], params = {}, keywords = [] }) => {
      // let keywordsDetails = [];
      const cacheFieldObject = ls.get("exportParams");
      const defaultFieldObject = {
        ...orderExportDefaultFields,
        ...cacheFieldObject,
      };
      const { MDYDateFormat = true, ...otherFieldObject } = defaultFieldObject;
      const keys = Object.keys(otherFieldObject);
      const orderExportFields = keys
        .filter((k) => otherFieldObject[k])
        .filter((k) => {
          // only seller can see cost and profit
          if (permissions !== SELLER_ROLE) {
            return ["totalCost", "totalProfit"].indexOf(k) < 0;
          }
          return true;
        });

      if (params.separateItemsIntoLines) {
        const n = orderExportFields.indexOf("orderItems");
        orderExportFields.splice(
          n + 1,
          0,
          "itemQuantity",
          "itemChannel",
          "itemCreatedAt",
          "itemWeight",
          "itemUnitPrice",
          "itemSubtotal",
          "itemTags",
          "itemKeyword",
          "itemSku"
        );
        if (permissions === SELLER_ROLE) {
          orderExportFields.splice(n + 1, 0, "itemCost", "itemProfit");
        }
        // const keywordsArray = [];
        // orders.forEach((order) => {
        //   order.orderItems.forEach((item) => {
        //     if (item.keyword) {
        //       keywordsArray.push(item.keyword);
        //     }
        //   });
        // });
        // const uniqueKeywordIds = keywordsArray.filter(uniqueByValue);
        // keywordsDetails = await fetchKeywordsSequence(uniqueKeywordIds);
      }
      // setIsLoading(false);
      customExporter({
        keywordsDetails: keywords,
        exportTopSpenders: params.exportTopSpenders,
        invoiceNumberPrefix: profile.invoiceNumberPrefix,
        invoiceNumberLength: profile.invoiceNumberLength,
        shippingOptions: shippingOptions,
        separateItemsIntoLines: params.separateItemsIntoLines,
        exportSummary: params.exportSummary,
        orders: orders,
        orderExportFields,
        MDYDateFormat,
      });
    },
    [permissions, profile, shippingOptions]
  );

  return { handleNormalExport };
};
