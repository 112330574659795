import React from "react";
import Button from "@mui/material/Button";

import { useReleaseThreadControl } from "../DataHooks/threadControl";

const ReleaseButton = (props) => {
  const { psid, onSuccess } = props;
  const { trigger: release, isMutating } = useReleaseThreadControl(undefined, {
    onSuccess,
  });

  return (
    <Button
      onClick={() => {
        release({
          recipient: {
            id: `${psid}`,
          },
        });
      }}
      disabled={isMutating}
      size="small"
      color="primary"
      variant="contained"
    >
      Release
    </Button>
  );
};

export default ReleaseButton;
