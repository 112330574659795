import React, { createContext, useReducer, useMemo } from "react";

export const ADD_COMMENT = "ADD_COMMENT";
export const CLEAR_COMMENT = "CLEAR_COMMENT";
export const ADD_CONTACT = "ADD_CONTACT";

const initialState = {
  comments: [],
  contacts: [],
  contactHashTable: {},
};

export const store = createContext(initialState);
const { Provider } = store;

export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case ADD_COMMENT:
        return {
          ...state,
          comments: [...state.comments].concat(action.payload),
        };
      case CLEAR_COMMENT:
        return {
          ...state,
          comments: [],
        };
      case ADD_CONTACT:
        return {
          ...state,
          contactHashTable: { ...state.contactHashTable, ...action.payload },
          // contacts: [...state.contacts].concat(action.payload),
        };
      default:
        throw new Error();
    }
  }, initialState);

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return <Provider value={value}>{children}</Provider>;
};
