import React from "react";
import queryString from "query-string";
import QrReader from "react-qr-reader";
import { useNotify, useDataProvider, useRedirect } from "react-admin";
import { useLocation } from "react-router-dom";

import { useProfile } from "../DataHooks/useProfile";
import { withBusinessOnlyShow } from "../../helpers/subscriptionPlan";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Comp = (props) => {
  const cameraRef = React.useRef();
  const notify = useNotify();
  // const [result, setResult] = React.useState("");
  const [order, setOrder] = React.useState(null);
  const redirect = useRedirect();
  const { profile } = useProfile();
  let query = useQuery();

  const dataProvider = useDataProvider();

  const getOrder = React.useCallback(
    (orderNumber) => {
      dataProvider
        .getList("orders", {
          pagination: { page: 1, perPage: 1 },
          sort: { field: "createdAt", order: "DESC" },
          filter: { orderNumber, page: profile.page._id },
        })
        .then(({ data }) => {
          if (data.length > 0) {
            const found = data[0];
            redirect(`/orders/${found._id}/show`);
          } else {
            notify("Not found", "warning");
            setOrder(null);
          }
        })
        .catch((error) => {
          notify("Error", "warning");
        });
    },
    [dataProvider, setOrder, redirect, profile]
  );

  const handleError = React.useCallback((err) => {
    console.error(err);
    notify("Error scanning !", "warning");
  });

  const handleScan = React.useCallback(
    (data) => {
      if (data) {
        const parsedHash = queryString.parse(data);
        const firstKey = Object.keys(parsedHash)[0];
        setOrder(parsedHash[firstKey]);
      }
    },
    [setOrder]
  );

  React.useEffect(() => {
    const cam = cameraRef;
    return () => {
      if (cam && cam.current && cam.current.stopCamera) {
        cam.current.stopCamera();
      }
    };
  }, [cameraRef]);

  React.useEffect(() => {
    const orderNumber = query.get("order");
    if (!!orderNumber) {
      //find order here
      getOrder(orderNumber);
    }
  }, []);

  React.useEffect(() => {
    if (order) {
      getOrder(order);
    }
  }, [order]);

  if (order !== null) {
    return `loading...`;
  }
  return (
    <div>
      <QrReader
        ref={cameraRef}
        delay={400}
        onError={handleError}
        onScan={handleScan}
        style={{ width: "100%" }}
      />
    </div>
  );
};
export default withBusinessOnlyShow(Comp);
