import React from "react";
import { useController } from "react-hook-form";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import Chip from "@mui/material/Chip";

const OrderItemTags = (props) => {
  const tagsInput = useController({ name: "tags", defaultValue: [] });

  const onRemoveTag = React.useCallback(
    (targetTag) => {
      const confirmation = window.confirm("Confirm to delete tag ?");
      if (confirmation) {
        const tags = tagsInput.field.value;
        const newTags = tags.filter((tag) => tag.id !== targetTag.id);
        tagsInput.field.onChange(newTags);
      }
    },
    [tagsInput.field]
  );
  return (
    <span>
      {tagsInput.field.value.map((tag, idx) => {
        return (
          <span key={idx}>
            <Chip
              icon={<LocalOfferIcon />}
              label={<a href={`/#/tags/${tag.id}/show/`}> {tag.label}</a>}
              onDelete={() => {
                onRemoveTag(tag);
              }}
            />
          </span>
        );
      })}
    </span>
  );
};

export default OrderItemTags;
