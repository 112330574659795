import React, { useEffect } from "react";
import { useLogout } from "react-admin";

import { useShouldLogout } from "../DataHooks/user";

const AutoLogout = (props) => {
  const logout = useLogout();
  const shouldLogout = useShouldLogout();

  useEffect(() => {
    if (shouldLogout) {
      logout();
    }
  }, [logout, shouldLogout]);

  return null;
};

export default AutoLogout;
