import React from "react";
import { useRecordContext } from "react-admin";
import { replaceImageSource } from "../../helpers/withReplaceImageSource";

import FullNameField from "../Contact/FullNameField";

const Comp = (props) => {
  const record = useRecordContext();
  // const { record = {}, ...otherProps } = props;
  const { description = "", images = [] } = record;
  const newRecord = {
    ...record,
    profilePic: images.length > 0 ? replaceImageSource(images[0].name) : "",
  };
  return <FullNameField record={newRecord} {...props} />;
};

export default Comp;
