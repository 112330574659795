import React, { useState, useCallback } from "react";
import { useDataProvider } from "react-admin";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Decimal from "decimal.js-light";
import LoadingButton from "@mui/lab/LoadingButton";

import { useRefresh, useNotify, useUnselectAll } from "react-admin";
import { orderItemStatuses } from "../../orders";
import { ORDER_ITEM_CANCELLED, ORDER_ITEM_NEW } from "../Constant";
import { comboRegex } from "../../helpers/methods";
import { uniqueByValue } from "../../helpers/methods";
import Loader from "../Loader";
import { useCustomAllRecords } from "../Common/useCustomAllRecords";

const MAX_CONCURRENT = 10;

const BulkUpdateOrderItem = (props) => {
  const dataProvider = useDataProvider();
  const { resource, selectedIds } = props;
  const [updateErrors, setupdateErrors] = useState([]);
  const [enabler, setEnabler] = React.useState({
    description: false,
    status: false,
    recalculateStock: true,
    blacklistCustomer: false,
    checkedPrice: false,
    unitPrice: false,
    unitWeight: false,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [statusField, setStatusField] = useState(ORDER_ITEM_NEW);
  const [descriptionField, setDescriptionField] = useState("");
  // const [quantityField, setQuantityField] = useState(1);
  const [unitPrice, setUnitPrice] = useState(0);
  const [unitWeight, setUnitWeight] = useState(0);
  const [loading, setLoading] = useState(false);

  const [requests, setRequests] = React.useState([]);
  const [pending, setPending] = React.useState(0);

  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll(resource);

  const allRecords = useCustomAllRecords();

  const toggleHandler = useCallback(
    (event) => {
      setEnabler({ ...enabler, [event.target.name]: event.target.checked });
    },
    [enabler]
  );

  const handleClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleDialogClose = useCallback(() => {
    unselectAll();
    refresh();
    setLoading(false);
    setIsOpen(false);
    setupdateErrors([]);
  }, [refresh, unselectAll]);

  React.useEffect(() => {
    return () => unselectAll();
  }, [resource, unselectAll]);

  const handleConfirm = React.useCallback(() => {
    // const fields = Object.keys(enabler).filter((f) => !!enabler[f]);
    const originals = allRecords.filter((record) =>
      selectedIds.includes(record._id)
    );
    const payloads = originals.map((d) => {
      let p = { id: d.id, internal: {} }; //internal is object only for code processing, do not submit this
      if (enabler.unitPrice) {
        p["unitPrice"] = unitPrice;
        const price = new Decimal(unitPrice).times(d.quantity).toFixed(2);
        p["price"] = price;
      }

      if (enabler.unitWeight) {
        const weight = new Decimal(unitWeight).times(d.quantity).toFixed(3);
        p["weight"] = weight;
      }

      if (enabler.status) {
        p["status"] = statusField;
        p.internal.blacklist =
          enabler.blacklistCustomer && statusField === ORDER_ITEM_CANCELLED;
        p.internal.contactId = (d.order && d.order.contact) || null;
        if (
          statusField === ORDER_ITEM_CANCELLED &&
          enabler.recalculateStock &&
          d.keyword //only can recalculateStock when have keyword
        ) {
          p.id = `${p.id}?recalculateStock=true`;
        }
      }
      //---- Start: use regex description----
      // if (enabler.description) {
      //   const { description = "" , quantity = 1} = d;
      //   const matches = comboRegex.exec(description);

      //   p["description"] = matches
      //     ? descriptionField + ` x${matches[2]}` //[2] is quantity
      //     : descriptionField + ` x1`;
      //   if (!matches) {
      //     //force qty to 1 if description doesn't contain qty(rare case)
      //     p["quantity"] = 1;
      //   }
      // }
      //---- END: use regex description----

      if (enabler.description) {
        const { quantity = 1 } = d;
        p["description"] = `${descriptionField} x${quantity}`;
      }

      return p;
    });
    setRequests(payloads);
    setLoading(true);
  }, [
    allRecords,
    descriptionField,
    enabler.blacklistCustomer,
    enabler.description,
    enabler.recalculateStock,
    enabler.status,
    enabler.unitPrice,
    enabler.unitWeight,
    selectedIds,
    statusField,
    unitPrice,
    unitWeight,
  ]);

  React.useEffect(() => {
    if (pending === 0 && requests.length > 0) {
      const processSlice = requests.slice(0, MAX_CONCURRENT);
      const nextSlice = requests.slice(MAX_CONCURRENT);
      setPending(processSlice.length);
      setRequests(nextSlice);
      processSlice.forEach(({ id, internal, ...values }) => {
        dataProvider
          .update("orderItems", { id: id, data: values })
          .then((resp) => {
            setPending((prev) => prev - 1);
          })
          .catch((e) => {
            setPending((prev) => prev - 1);
            setupdateErrors((prev) => [...prev, id]);
          });
      });
      const blacklistSlice = processSlice.filter(
        (p) => !!p.internal.blacklist && p.internal.contactId
      );
      if (blacklistSlice.length > 0) {
        const contactIds = blacklistSlice
          .map((p) => p.internal.contactId)
          .filter((c) => c) //filter out null
          .filter(uniqueByValue);
        dataProvider.updateMany(`contacts`, {
          ids: contactIds,
          data: { isBlacklisted: true },
        });
      }
    } else if (
      pending === 0 &&
      requests.length === 0 &&
      loading &&
      updateErrors.length === 0
    ) {
      setLoading(false);
      notify("Operation successful.");
      handleDialogClose();
    }
  }, [
    dataProvider,
    handleDialogClose,
    loading,
    notify,
    pending,
    requests,
    updateErrors.length,
  ]);

  const validate = useCallback(() => {
    if (enabler.unitPrice) {
      if (unitPrice === "" || isNaN(unitPrice)) {
        return "Please enter correct unit price.";
      }
    }
    if (enabler.unitWeight) {
      if (unitWeight === "" || isNaN(unitWeight)) {
        return "Please enter correct unit weight(KG).";
      }
    }
    if (enabler.status) {
      if (!statusField) {
        return "Please select status.";
      }
    }

    if (enabler.description) {
      if (!descriptionField) {
        return "Please enter description.";
      }
    }

    return null;
  }, [
    enabler.status,
    enabler.unitPrice,
    enabler.unitWeight,
    enabler.description,
    statusField,
    descriptionField,
    unitPrice,
    unitWeight,
  ]);
  const errorMsg = validate();

  return (
    <>
      <Button label="edit" onClick={handleClick}>
        <EditIcon />
        Edit
      </Button>
      <Dialog
        // fullScreen={false}
        open={isOpen}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>{"Select one field to edit"}</DialogTitle>
        <DialogContent>
          {updateErrors.length > 0 && (
            <p>Some errors happened, please recheck after.</p>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={enabler.description}
                onChange={toggleHandler}
                name="description"
              />
            }
            label="Description"
          />
          <TextField
            id="standard-text"
            label={`description`}
            type="text"
            value={descriptionField}
            onChange={(e) => {
              setDescriptionField(e.target.value);
            }}
            disabled={!enabler.description}
            required={enabler.description}
            error={descriptionField === "" && enabler.description}
          />
          <br />
          {/*<FormControlLabel
            control={
              <Checkbox
                checked={enabler.quantity}
                onChange={toggleHandler}
                name="quantity"
              />
            }
            label="Quantity(will update price automatically according to unit price)"
          />
          <TextField
            id="standard-text"
            label={`quantity`}
            type="number"
            value={quantityField}
            onChange={(e) => {
              setQuantityField(e.target.value);
            }}
            disabled={!enabler.quantity}
            required={enabler.quantity}
          />
          <br /> */}
          <FormControlLabel
            control={
              <Checkbox
                checked={enabler.unitPrice}
                onChange={toggleHandler}
                name="unitPrice"
              />
            }
            label="Unit price"
          />
          <TextField
            label={`unit price`}
            type="number"
            value={unitPrice}
            onChange={(e) => {
              setUnitPrice(e.target.value);
            }}
            disabled={!enabler.unitPrice}
            required={enabler.unitPrice}
            helperText={`Total price will auto reflect`}
          />
          <br />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={enabler.unitWeight}
                onChange={toggleHandler}
                name="unitWeight"
              />
            }
            label="Unit weight(KG)"
          />
          <TextField
            label={`unit weight(KG)`}
            type="number"
            value={unitWeight}
            onChange={(e) => {
              setUnitWeight(e.target.value);
            }}
            disabled={!enabler.unitWeight}
            required={enabler.unitWeight}
            helperText={`Total weight(KG) will auto reflect`}
          />
          <br />
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={enabler.status}
                onChange={toggleHandler}
                name="status"
              />
            }
            label="Status"
          />
          <Select
            value={statusField}
            onChange={(e) => {
              setStatusField(e.target.value);
            }}
            disabled={!enabler.status}
            required={enabler.status}
          >
            {orderItemStatuses.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.name}
              </MenuItem>
            ))}
          </Select>
          <FormControlLabel
            control={
              <Checkbox
                disabled={
                  !enabler.status || statusField !== ORDER_ITEM_CANCELLED
                }
                checked={enabler.recalculateStock}
                onChange={toggleHandler}
                name="recalculateStock"
              />
            }
            label="Recal. stock"
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={
                  !enabler.status || statusField !== ORDER_ITEM_CANCELLED
                }
                checked={enabler.blacklistCustomer}
                onChange={toggleHandler}
                name="blacklistCustomer"
              />
            }
            label="Blacklist customer"
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={handleConfirm}
            color="primary"
            disabled={
              !!errorMsg ||
              pending > 0 ||
              requests.length > 0 ||
              Object.keys(enabler).filter(
                (k) => !!enabler[k] && k !== "recalculateStock"
              ).length === 0
            }
          >
            Update
          </LoadingButton>
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Loader open={loading} />
    </>
  );
};

export default BulkUpdateOrderItem;
