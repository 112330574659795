import React from "react";
import {
  SimpleForm,
  Edit,
  TextInput,
  TextField,
  useTranslate,
  BooleanInput,
  required,
  SaveButton,
  Toolbar,
  FormDataConsumer,
} from "react-admin";
import Decimal from "decimal.js-light";

import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { makeStyles, useTheme } from "@mui/styles";
import Receipt from "./Receipt";
import OrderLink from "./OrderLink";
import TextInputNoWheel from "../Common/TextInputNoWheel";
import CustomDateField from "../Common/CustomDateField";
import { OFFLINE } from "./types";

export const format = (v) => {
  const test = Number(v);
  if (v === "") {
    return "";
  } else if (test === 0) {
    return v.toString();
  } else if (!isNaN(test)) {
    const v1 = new Decimal(v).dividedBy(100).todp(2).toNumber();
    return v1;
  }
  return "";
};

export const parse = (v) => {
  const test = Number(v);
  if (v === "") {
    return "";
  } else if (test === 0) {
    return 0;
  } else if (!isNaN(test)) {
    const v1 = new Decimal(v).times(100).toNumber();
    return v1;
  }
  return "";
};

const PaymentEditToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Save" redirect="/payments" />
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 40,
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "1em",
  },
  form: {
    [theme.breakpoints.up("xs")]: {
      width: 400,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      marginTop: -30,
    },
  },
  inlineField: {
    display: "inline-block",
    width: "50%",
  },
}));

export const PaymentEdit = (props) => {
  const { onCancel } = props;
  const translate = useTranslate();
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6">{translate("Payment edit")}</Typography>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <Edit title={`Edit`} {...props}>
        <SimpleForm toolbar={<PaymentEditToolbar />}>
          <TextField source="order.customerName" label={`Name`} />
          <Receipt source="receipt.fileUrl" src="receipt.fileUrl" />

          <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) => {
              const { type } = formData;
              const isOffline = type === OFFLINE;
              const disabled = !isOffline;
              const label = formData.paid ? "Paid" : "Unpaid";
              return (
                <>
                  <TextInputNoWheel
                    type="number"
                    source="paidAmount"
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        // handleSubmit(formData);
                        e.preventDefault();
                      }
                    }}
                    validate={required()}
                    fullWidth
                    format={format}
                    parse={parse}
                    disabled={disabled}
                  />
                  <BooleanInput
                    source="paid"
                    {...rest}
                    label={label}
                    disabled={disabled}
                  />
                  <TextInput source="remarks" disabled={disabled} />
                </>
              );
            }}
          </FormDataConsumer>
          <OrderLink />
          <CustomDateField source="createdAt" showTime />
          <CustomDateField source="updatedAt" showTime />
        </SimpleForm>
      </Edit>
    </div>
  );
};

export default PaymentEdit;
