import React from "react";
import { TopToolbar, FilterButton, useListContext } from "react-admin";

import { useMaxResultsByPlan } from "../../helpers/subscriptionPlan";
import ExportDialog from "./ExportDialog";
import AutoSyncButton from "../Keyword/AutoSyncButton";

const ListActions = (props) => {
  const maxResults = useMaxResultsByPlan();
  const { total } = useListContext();
  return (
    <TopToolbar>
      <FilterButton />
      <AutoSyncButton />
      <ExportDialog disabled={total === 0} maxResults={maxResults} />
      {/* Add your custom actions */}
      {/* <Button
      onClick={() => {
        alert("Your custom action");
      }}
      label="Show calendar"
    >
      <IconEvent />
    </Button> */}
    </TopToolbar>
  );
};

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};

export default ListActions;
