import React from "react";
import { CustomRoutes } from "react-admin";
import { Route } from "react-router-dom";
import Loadable from "react-loadable";

// import FacebookCallback from "./SocialLink/FacebookCallback";
import SpecialTools from "./SpecialTools";
import LiveWindow from "./LiveWindow";
import BulkUploadUpdateOrder from "./BulkUploadUpdateOrder";
import BulkUploadKeywords from "./BulkUploadKeywords";
import OrderScanner from "./OrderScanner/Scanner";
import MyProfile from "./MyProfile";
import Analytics from "./Analytics";
import CombineOrders from "./CombineOrders";
import MarketingMessages from "./MarketingMessages";

import SubscriptionBilling from "./SubscriptionBilling";
import { withValidPlanShow } from "./DataHooks/useSubscription";
// import NewFacebookCallback from "./SocialLink/NewFacebookCallback";

const VSpecialTools = withValidPlanShow(SpecialTools);
const VMyProfile = withValidPlanShow(MyProfile);
const VAnalytics = withValidPlanShow(Analytics);

const LoadableFBCommentExtractor = Loadable({
  loader: () => import("./CommentExtract/FBCommentExtractor"),
  loading: () => "Loading",
});

const LoadableFBPosts = Loadable({
  loader: () => import("./FBPosts/FacebookPagePosts"),
  loading: () => "Loading",
});

// const LoadableFBVideos = Loadable({
//   loader: () => import("./FBVideos/VideoList"),
//   loading: () => "Loading",
// });

const VFBCommentExtractor = withValidPlanShow(LoadableFBCommentExtractor);

const LoadableIGCommentExtractor = Loadable({
  loader: () => import("./CommentExtract/IGCommentExtractor"),
  loading: () => "Loading",
});
const VIGCommentExtractor = withValidPlanShow(LoadableIGCommentExtractor);

const LoadableFBConversations = Loadable({
  loader: () => import("./MessengerChat/Conversations"),
  loading: () => "Loading",
});

const LoadableFIGConversations = Loadable({
  loader: () => import("./MessengerChat/IGConversations"),
  loading: () => "Loading",
});

const NewFacebookCallback = Loadable({
  loader: () => import("./SocialLink/NewFacebookCallback"),
  loading: () => "Loading",
});

export default (
  <CustomRoutes>
    <Route path="/auth/facebook/callback" element={<NewFacebookCallback />} />,
    <Route path="/special-tools" element={<VSpecialTools />} />,
    <Route path="/live-window" element={<LiveWindow />} />,
    <Route path="/marketing-messages" element={<MarketingMessages />} />,
    <Route path="/fb-comment-manager" element={<VFBCommentExtractor />} />,
    <Route path="/ig-comment-manager" element={<VIGCommentExtractor />} />,
    <Route path="/fb-posts-manager" element={<LoadableFBPosts />} />,
    {/* <Route path="/fb-videos-manager" element={<LoadableFBVideos />} />, */}
    <Route
      path="/bulk-upload-tracking-number"
      element={<BulkUploadUpdateOrder />}
    />
    <Route path="/bulk-upload-keywords" element={<BulkUploadKeywords />} />
    <Route path="/combine-orders" element={<CombineOrders />} />
    <Route path="/settings" element={<VMyProfile />} />
    <Route path="/qr-scanner" element={<OrderScanner />} />
    <Route path="/analytics" element={<VAnalytics />} />
    <Route path="/billing" element={<SubscriptionBilling />} />
    <Route path="/fb-conversations" element={<LoadableFBConversations />} />
    <Route path="/ig-conversations" element={<LoadableFIGConversations />} />
  </CustomRoutes>
);
