import React from "react";
import Decimal from "decimal.js-light";
import { makeStyles } from "@mui/styles";

import { compose } from "redux";

import { withTaxCalculation } from "../components/Taxation/withTaxCalculation";

const useStyles = makeStyles({
  amber: { color: "orange" },
  red: { color: "red" },
  green: { color: "#4caf50" },
});

export const withTaxWillHide = (WrapComp) => (props) => {
  const { record } = props;
  if (record && record.tax) {
    return null;
  } else {
    return <WrapComp {...props} record={record} />;
  }
};

export const withTaxWillShow = (WrapComp) => (props) => {
  const { record } = props;
  if (record && record.tax) {
    return <WrapComp {...props} record={record} />;
  } else {
    return null;
  }
};

const withAmount = (WrapComp) => (props) => {
  const { calculation, source } = props;
  const amountDecimal = new Decimal(calculation[source]);
  return <WrapComp {...props} amount={amountDecimal.toFixed(2)} />;
};

const withColor = (WrapComp) => (props) => {
  const classes = useStyles();
  const { amount } = props;
  const amountDecimal = new Decimal(amount);
  const colorClass = amountDecimal.isZero()
    ? classes.green
    : amountDecimal.isPositive()
    ? classes.red
    : classes.amber;
  return (
    <WrapComp
      {...props}
      className={colorClass}
      amount={amountDecimal.toFixed(2)}
    />
  );
};

const SpanField = (props) => {
  const { className } = props;
  return <span className={className}>{props.amount}</span>;
};

export const AmountWithTaxColorField = compose(
  withTaxWillShow,
  withTaxCalculation,
  withAmount,
  withColor
)(SpanField);

export const AmountWithTaxField = compose(
  withTaxWillShow,
  withTaxCalculation,
  withAmount
)(SpanField);

export default AmountWithTaxField;
