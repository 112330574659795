import React, { useState } from "react";
import {
  required,
  Button,
  SaveButton,
  TextInput,
  useNotify,
  useRefresh,
  Form,
  FormDataConsumer,
  useRecordContext,
} from "react-admin";
import IconContentEdit from "@mui/icons-material/Edit";
import IconCancel from "@mui/icons-material/Cancel";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import CombineDescriptionWithQuantity from "./CombineDescriptionWithQuantity";
import EditItemDescription from "./EditItemDescription";
import QuantityInput from "./QuantityInput";
import { fetchHandleError } from "../../helpers/fetchHandleError";
import { getCookie } from "../../helpers/Cookies";
import {
  ORDER_ITEM_CANCELLED,
  ORDER_ITEM_NEW,
  ORDER_ITEM_PAID,
} from "../Constant";
import CustomSelectInput from "../Common/CustomSelectInput";
import RecalculateStockInput from "./RecalculateStockInput";
import TextInputNoWheel from "../Common/TextInputNoWheel";
import { useIsOrderEditable } from "../DataHooks/useIsOrderEditable";

const choices = [
  { id: ORDER_ITEM_NEW, name: ORDER_ITEM_NEW },
  { id: ORDER_ITEM_PAID, name: ORDER_ITEM_PAID },
  { id: ORDER_ITEM_CANCELLED, name: ORDER_ITEM_CANCELLED },
];

const OrderItemEditButton = (props) => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const { keyword = null } = record;
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const handleClick = React.useCallback(() => {
    setShowDialog(true);
  }, [setShowDialog]);

  const handleCloseClick = React.useCallback(() => {
    setShowDialog(false);
  }, [setShowDialog]);

  const handleSubmit = React.useCallback(
    (values) => {
      const token = getCookie("token");
      setLoading(true);
      setShowDialog(false);
      const { keyword, item, recalculateStock, ...otherValues } = values;
      fetch(
        `${process.env.REACT_APP_CMS_HOST}/orderitems/${record._id}${
          recalculateStock ? "?recalculateStock=true" : ""
        }`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify(otherValues),
        }
      )
        .then(fetchHandleError)
        .then(() => {
          setLoading(false);
          refresh();
          notify(`Order item updated`, "info");
        })
        .catch((err) => {
          setLoading(false);
          notify(err, "error");
        });
    },
    [notify, record._id, refresh]
  );

  return (
    <>
      <Button onClick={handleClick} label="ra.action.edit">
        <IconContentEdit />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Edit order item"
      >
        <DialogTitle>{`Edit order item ${
          !!keyword ? "(keyword)" : "(free text)"
        }`}</DialogTitle>
        <Form
          onSubmit={handleSubmit}
          defaultValues={{
            recalculateStock: !!keyword,
          }}
        >
          <DialogContent>
            <EditItemDescription />
            <FormDataConsumer>
              {({ formData }) => {
                return <QuantityInput useKeyword={!!formData.keyword} />;
              }}
            </FormDataConsumer>
            {!!keyword && <RecalculateStockInput />}
            <CombineDescriptionWithQuantity />
            <FormDataConsumer>
              {({ formData }) => {
                return (
                  <TextInputNoWheel
                    type="number"
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleSubmit(formData);
                      }
                    }}
                    source="price"
                    validate={required()}
                  />
                );
              }}
            </FormDataConsumer>
            <CustomSelectInput
              fullWidth
              label="Status"
              source="status"
              choices={choices}
            />
            <FormDataConsumer>
              {({ formData }) => {
                return (
                  <>
                    <TextInputNoWheel
                      type="number"
                      fullWidth
                      source="weight"
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          handleSubmit(formData);
                        }
                      }}
                      validate={required()}
                    />
                    <TextInputNoWheel
                      type="number"
                      fullWidth
                      source="cost"
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          handleSubmit(formData);
                        }
                      }}
                      validate={required()}
                    />
                  </>
                );
              }}
            </FormDataConsumer>
            <TextInput source="currencyCode" disabled label="Currency" />
          </DialogContent>
          <DialogActions>
            <Button
              label="ra.action.cancel"
              onClick={handleCloseClick}
              disabled={loading}
            >
              <IconCancel />
            </Button>
            <SaveButton alwaysEnable />
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

const withDisableEditOnCancelled = (WrappedComponent) => (props) => {
  const record = useRecordContext();
  const { status = ORDER_ITEM_CANCELLED } = record;
  const isOrderEditable = useIsOrderEditable();

  if (!isOrderEditable) {
    return (
      <Tooltip title="Completed order not editable">
        <span>
          <Button disabled label="ra.action.edit">
            <IconContentEdit />
          </Button>
        </span>
      </Tooltip>
    );
  }

  if (status === ORDER_ITEM_CANCELLED) {
    return (
      <Tooltip title="Cancelled are not editable">
        <span>
          <Button disabled label="ra.action.edit">
            <IconContentEdit />
          </Button>
        </span>
      </Tooltip>
    );
  }
  return <WrappedComponent {...props} />;
};

export default withDisableEditOnCancelled(OrderItemEditButton);
