import React, { useState } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import FacebookIcon from "@mui/icons-material/Facebook";
import Button from "@mui/material/Button";

const facebookColor = { backgroundColor: "#4267B2" };

const SocialLink = ({ provider }) => {
  const [disable, setDisable] = useState(false);
  return (
    <>
      <a
        href={`${process.env.REACT_APP_FB_LOGIN_CMS_HOST}/connect/${provider}`}
        className="link"
      >
        <Button
          style={facebookColor}
          disabled={disable}
          onClick={() => setDisable(true)}
          startIcon={
            disable ? <CircularProgress size={14} /> : <FacebookIcon />
          }
          fullWidth
          edge="start"
          variant="contained"
        >
          Login with Facebook
        </Button>
      </a>
      {/* <p>We'll never post anything without your permission.</p> */}
    </>
  );
};

SocialLink.propTypes = {
  provider: PropTypes.string.isRequired,
};

export default SocialLink;
