import React, { useState, useCallback } from "react";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

import {
  useRefresh,
  useNotify,
  useUnselectAll,
  useDataProvider,
} from "react-admin";

import { useIsBusinessPlan } from "../../helpers/subscriptionPlan";
import Loader from "../Loader";

const MAX_CONCURRENT = 10;

const BulkBlacklistContact = (props) => {
  const { resource, selectedIds } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isBlacklisted, setIsBlacklisted] = useState(false);
  const [pending, setPending] = useState(0);
  const [requests, setRequests] = useState([]);
  const isBusiness = useIsBusinessPlan();

  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll(resource);
  const dataProvider = useDataProvider();

  const onBlacklistChange = useCallback(() => {
    setIsBlacklisted((v) => !v);
  }, [setIsBlacklisted]);

  const handleClick = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const handleDialogClose = useCallback(() => {
    unselectAll();
    setIsOpen(false);
  }, [unselectAll, setIsOpen]);

  const handleConfirm = useCallback(() => {
    setRequests(selectedIds.map((id) => ({ id, isBlacklisted })));
    setIsOpen(false);
    unselectAll();
  }, [isBlacklisted, selectedIds, unselectAll]);

  React.useEffect(() => {
    if (!isBusiness) {
      return;
    }
    const process = async () => {
      const processSlice = requests.slice(0, MAX_CONCURRENT);
      const nextSlice = requests.slice(MAX_CONCURRENT);
      setPending(processSlice.length);
      setRequests(nextSlice);

      const responses = await Promise.all(
        processSlice.map(({ id, isBlacklisted }) =>
          dataProvider
            .update("contacts", { id, data: { isBlacklisted } })
            .then(() => false)
            .catch(() => "error")
        )
      );

      const hasError = responses.find((e) => e);
      if (hasError) {
        setRequests([]);
        setPending(0);
        unselectAll();
        setIsOpen(false);
        refresh();
        notify("Operation failed.", "warning");
      } else {
        setPending(0);
        refresh();
      }
    };

    if (requests.length > 0 && pending === 0) {
      process();
    }
  }, [
    dataProvider,
    isBusiness,
    notify,
    pending,
    refresh,
    requests,
    resource,
    unselectAll,
  ]);

  return (
    <>
      <Button onClick={handleClick} color="primary" startIcon={<EditIcon />}>
        Edit Blacklist
      </Button>
      <Dialog
        // fullScreen={false}
        open={isOpen}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>{`Update blacklist/unblacklist`}</DialogTitle>
        <DialogContent>
          {!isBusiness && <p>Uptier Business to enable this feature</p>}
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>Un-Blacklist</Grid>
            <Grid item>
              <Switch
                disabled={!isBusiness}
                checked={isBlacklisted}
                onChange={onBlacklistChange}
              />
            </Grid>
            <Grid item>Blacklist</Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleConfirm}
            color="primary"
            autoFocus
            disabled={!isBusiness}
          >
            Update
          </Button>
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Loader open={pending > 0 || requests.length > 0}>
        <span> {`Processing ${requests.length + pending} updates`}</span>
      </Loader>
    </>
  );
};

export default BulkBlacklistContact;
