import React from "react";
import { TextInput, Create, SimpleForm, required } from "react-admin";

const TagCreate = (props) => {
  return (
    <Create {...props} permissions="Seller" redirect="list">
      <SimpleForm>
        <TextInput
          source="label"
          //parse={keywordParser}
          label="Label"
          validate={required()}
        />
        <TextInput
          multiline
          source="description"
          //validate={required()}
        />
      </SimpleForm>
    </Create>
  );
};

export default TagCreate;
