import React from "react";
import { useRecordContext } from "react-admin";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { OFFLINE } from "../components/Payment/types";

const PaymentViewButton = (props) => {
  const record = useRecordContext();
  if (record.type === OFFLINE) {
    return (
      <a href={`/#/payments/${record._id}`}>
        <VisibilityIcon />
      </a>
    );
  }

  return (
    <a href={`/#/payments/${record._id}/show`}>
      <VisibilityIcon />
    </a>
  );
};

export default PaymentViewButton;
