import { unparse as convertToCSV } from "papaparse/papaparse.min";
import { downloadCSVWithBom } from "../../helpers/methods";

export const exporter = (waitlists) => {
  const waitlistsForExport = waitlists.map((waitlist) => {
    const {
      __v,
      _id,
      page,
      createdAt,
      updatedAt,
      contact = {},
      ...waitlistForExport
    } = waitlist; // omit backlinks and author

    return {
      ...waitlistForExport,
      customerId: contact._id,
      customerPsid: contact.psid,
      customerName: contact.name,
      createdAt: new Date(createdAt).toLocaleString(),
      updatedAt: new Date(updatedAt).toLocaleString(),
    };
  });
  const csv = convertToCSV({
    data: waitlistsForExport,
    fields: [
      "customerId",
      "customerPsid",
      "customerName",
      "description",
      "key",
      "quantity",
      "createdAt",
      "updatedAt",
    ], // waitlist fields in the export
  });
  downloadCSVWithBom(csv, `waitlists_${new Date().toLocaleDateString()}`); // download as 'vouchers.csv` file
};
