import React, { useState } from "react";
import { AppBar } from "react-admin";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

import Logo from "./Logo";
import ExpiryText from "./ExpiryText";
// import { store } from "../../store.js";
import MyUserMenu from "./MyUserMenu";
import {
  requiredFields,
  useCheckSubscribedApps,
  useDebugFbToken,
} from "./TokenValidator";
import AutoLogout from "./AutoLogout";
import LiveVideoChecker from "./LiveVideoChecker";

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
  red: {
    backgroundColor: "red",
  },
});

const myAlert = () =>
  alert(
    `Facebook permissions expired. Please logout Boxify, then "LOGIN WITH FACEBOOK".`
  );

const MyAppBar = (props) => {
  const classes = useStyles();
  // const { state } = useContext(store);

  const [isSubscribed, setIsSubscribed] = useState(true);
  const [isFbTokenValid, setIsFbTokenValid] = useState(true);

  //TODO: enable this code
  // const { data: subscribeApps } = useCheckSubscribedApps(undefined, {
  //   onSuccess: (data) => {
  //     if (data && data.data && data.data[0] && data.data[0].subscribed_fields) {
  //       const foundFields = requiredFields.filter(
  //         (f) => data.data[0].subscribed_fields.indexOf(f) > -1
  //       );
  //       if (foundFields.length < requiredFields.length) {
  //         setIsSubscribed(false);
  //         myAlert();
  //         return;
  //       } else {
  //         setIsSubscribed(true);
  //       }
  //     } else {
  //       setIsSubscribed(false);
  //       myAlert();
  //     }
  //   },
  // });

  const { data: fBTokenStatus } = useDebugFbToken(undefined, {
    onSuccess: (data) => {
      if (!data.data.is_valid) {
        myAlert();
        setIsFbTokenValid(false);
        return;
      }
      setIsFbTokenValid(true);
    },
  });

  if (!isFbTokenValid || !isSubscribed) {
    return (
      <AppBar
        sx={{ backgroundColor: "red" }}
        {...props}
        userMenu={<MyUserMenu />}
      >
        <Typography
          variant="h6"
          color="inherit"
          sx={{
            flex: 1,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
          id="react-admin-title"
        />
        <Typography variant="h6" color="inherit" id="react-admin-title">
          {`Facebook permissions expired. Please logout Boxify, then "LOGIN WITH FACEBOOK".`}
        </Typography>
        <AutoLogout />
        {/* {process.env.REACT_APP_LOAD_FC === "TRUE" && <FreshChatWidget />} */}
      </AppBar>
    );
  }

  return (
    <AppBar {...props} userMenu={<MyUserMenu />}>
      <Typography
        variant="h6"
        color="inherit"
        sx={{
          flex: 1,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
        id="react-admin-title"
      />
      <Logo />
      <span className={classes.spacer} />
      <ExpiryText />
      <LiveVideoChecker />
      <AutoLogout />
      {/* {process.env.REACT_APP_LOAD_FC === "TRUE" && <FreshChatWidget />} */}
      {/* {window.fcWidget && window.fcWidget.isOpen && <DraggableWidget />} */}
    </AppBar>
  );
};

export default MyAppBar;
