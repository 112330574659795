import React from "react";
import useSWR from "swr";
import MaterialTable from "@material-table/core";
import { useRecordContext } from "react-admin";
import { useProfile } from "../DataHooks/useProfile";
import { OutstandingPaymentField } from "../../Order/OutstandingPaymentInclShippingField";
import OrderSummary from "./OrderSummary";

const columns = [
  {
    title: "Order number",
    field: "orderNumber",
    render: (rowData) => {
      return <a href={`/#/orders/${rowData.id}/show`}>{rowData.orderNumber}</a>;
    },
  },
  {
    title: "Status",
    field: "status",
  },
  {
    title: "Outstanding payment",
    // field: "orderNumber",
    render: (rowData) => (
      <OutstandingPaymentField record={rowData} source="outstandingPayment" />
    ),
  },
  {
    title: "Created at",
    field: "createdAt",
    render: (rowData) => {
      return <span>{new Date(rowData.createdAt).toLocaleDateString()}</span>;
    },
  },
  {
    title: "Updated at",
    field: "updatedAt",
    render: (rowData) => {
      return <span>{new Date(rowData.updatedAt).toLocaleDateString()}</span>;
    },
  },
];

const OrderList2 = (props) => {
  // const { record } = props;
  const record = useRecordContext();
  const { profile } = useProfile();
  const { data: orders, error } = useSWR(
    profile && record && record._id
      ? `${process.env.REACT_APP_CMS_HOST}/orders?_sort=createdAt:DESC&_start=0&_limit=100&contact=${record._id}&page=${profile.page._id}`
      : null,
    {
      revalidateOnFocus: false,
    }
  );

  if (!orders && !error) {
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error when loading orders.</div>;
  }

  if (orders.length === 0) {
    return <p>No orders.</p>;
  }

  return (
    <>
      <div>
        <OrderSummary orders={orders} />
      </div>
      {orders && (
        <MaterialTable
          title="Orders"
          columns={columns}
          data={orders}
          options={{
            search: false,
            actionsColumnIndex: 4,
            paging: true,
            pageSize: 25,
            pageSizeOptions: [25, 50, 100, 150],
            padding: "dense",
            maxBodyHeight: 300,
            sorting: false,
          }}
        />
      )}
    </>
  );
};

export default OrderList2;
