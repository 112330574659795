import React, { useState, useEffect, useCallback } from "react";

const MAX_CONCURRENT = 10;

export const IDLE = "IDLE";
export const STARTED = "STARTED";
export const COMPLETED = "COMPLETED";

export const useThrottleCall = (props = {}) => {
  const {
    // terminateIfError = false,
    maxConcurrent = MAX_CONCURRENT,
  } = props;

  const [pending, setPending] = useState(0);
  const [requests, setRequests] = useState([]);
  const [status, setStatus] = useState(IDLE);

  useEffect(() => {
    const process = async () => {
      const processSlice = requests.slice(0, maxConcurrent);
      const nextSlice = requests.slice(maxConcurrent);
      setPending(processSlice.length);
      setRequests(nextSlice);

      const responses = await Promise.all(processSlice.map((f) => f()));
      setPending(0);
    };

    if (requests.length > 0 && pending === 0) {
      process();
    }
  }, [maxConcurrent, pending, requests]);

  const terminate = useCallback(() => {
    setRequests([]);
    setPending(0);
    setStatus(IDLE);
  }, []);

  const setProcess = useCallback((calls) => {
    setRequests(calls);
    setStatus(STARTED);
  }, []);

  useEffect(() => {
    if (status === STARTED && pending === 0 && requests.length === 0) {
      setStatus(COMPLETED);
    }
  }, [pending, requests.length, status]);

  return {
    setProcess,
    requests,
    terminate,
    pending,
    status,
    processing: requests.length > 0 || pending > 0,
  };
};
