import React from "react";
import Decimal from "decimal.js-light";
import { useRecordContext } from "react-admin";
import { CREDIT } from "../components/Payment/types";

const ReconciledPayment = (props) => {
  const record = useRecordContext();
  const { payments = [] } = record;
  const totalPaidAmount = useReconciledPayment(payments);
  return <span>{totalPaidAmount}</span>;
};

export const useReconciledPayment = (payments = []) => {
  const totalPaidAmountDecimal = payments
    .filter(({ type }) => type !== CREDIT)
    .filter((p) => p.paid === true)
    .reduce((acc, cur) => {
      return acc.plus(cur.paidAmount);
    }, new Decimal(0));
  const totalPaidAmount = totalPaidAmountDecimal.dividedBy(100).toFixed(2);
  return totalPaidAmount;
};

export default ReconciledPayment;
