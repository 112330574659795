import React from "react";
import { usePermissions } from "react-admin";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import differenceInDays from "date-fns/differenceInDays";
import startOfMonth from "date-fns/startOfMonth";

import ChartWith30Days from "./ChartWith30Days";
import {
  useIsEnterprisePlan,
  useIsBusinessPlan,
} from "../../helpers/subscriptionPlan";
import { SELLER_ROLE } from "../../helpers/role";
import { useAnalyticsToken } from "../DataHooks/useAnalyticsToken";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children} </Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const Comp = (props) => {
  const { data: analyticsToken, isLoading, error } = useAnalyticsToken();

  const classes = useStyles();
  const isEnterprisePlan = useIsEnterprisePlan();
  const isBusinessPlan = useIsBusinessPlan();
  const { permissions } = usePermissions();
  const isSeller = permissions === SELLER_ROLE;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Something went wrong.</div>;
  }

  const chartToken = analyticsToken.jwt;

  return (
    <div className={classes.root}>
      <h4>Analytics is a beta feature, feedbacks are welcome.</h4>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs charts"
        >
          <Tab label="Summary" {...a11yProps(0)} />
          <Tab label="Sales" {...a11yProps(1)} />
          <Tab label="Monthly" {...a11yProps(2)} />
          <Tab label="Special" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ChartWith30Days
          token={chartToken}
          chartId={process.env.REACT_APP_CHARTS_ID_ORDER_STATUS_BY_DAY}
        />
        <ChartWith30Days
          token={chartToken}
          chartId={process.env.REACT_APP_CHARTS_ID_NUMBER_OF_PAYMENTS}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {isSeller && (
          <ChartWith30Days
            token={chartToken}
            chartId={process.env.REACT_APP_CHARTS_ID_SALES_BY_ORDER_ITEM}
          />
        )}
        {isSeller && (
          <ChartWith30Days
            enableTimeSelector
            token={chartToken}
            chartId={process.env.REACT_APP_CHARTS_ID_SALES_ITEM_TABLE}
          />
        )}
        {isSeller && (
          <ChartWith30Days
            token={chartToken}
            chartId={process.env.REACT_APP_CHARTS_ID_PAYMENT_RECEIVED}
          />
        )}
        {isSeller && (
          <ChartWith30Days
            token={chartToken}
            chartId={process.env.REACT_APP_CHARTS_ID_SUM_REVENUE}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {!isBusinessPlan && <div>This is business plan feature</div>}
        {isBusinessPlan && isSeller && (
          <ChartWith30Days
            maxDays={200}
            initialDays={differenceInDays(new Date(), startOfMonth(new Date()))}
            token={chartToken}
            chartId={process.env.REACT_APP_CHARTS_ID_REVENUE_MONTH}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {!isEnterprisePlan && (
          <div>{`Top spender 30 days (Enterprise plan)`}</div>
        )}
        {isEnterprisePlan && (
          <ChartWith30Days
            token={chartToken}
            chartId={process.env.REACT_APP_CHARTS_ID_TOP_SPENDER_30_DAYS}
          />
        )}
      </TabPanel>
    </div>
  );
};

export default Comp;
