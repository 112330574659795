import React from "react";
import CustomTextInput from "./CustomTextInput";

const parse = (v) => Number(v);

const IntegerInput = (props) => {
  return (
    <CustomTextInput
      inputProps={{
        inputMode: "numeric",
        pattern: "^-?[0-9]*",
      }}
      parse={parse}
      {...props}
    />
  );
};

export const PositiveIntegerInput = (props) => {
  return (
    <CustomTextInput
      inputProps={{
        inputMode: "numeric",
        pattern: "[0-9]*",
      }}
      parse={parse}
      {...props}
    />
  );
};

export default IntegerInput;
