import React from "react";
import { SimpleForm, Edit, TextInput, required } from "react-admin";

export const TagEdit = (props) => {
  return (
    <>
      <Edit title="Edit Tag" {...props}>
        <SimpleForm>
          <TextInput source="label" validate={required()} />
          <TextInput multiline source="description" />
        </SimpleForm>
      </Edit>
      {/*<OrderItemList {...props} />*/}
    </>
  );
};

export default TagEdit;
