import React from "react";
import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  NumberField,
  BooleanField,
  Pagination,
  TextInput,
  BooleanInput,
  ReferenceArrayInput,
  SelectArrayInput,
  useRecordContext,
  // BulkDeleteButton,
  // useRefresh,
  // useNotify,
} from "react-admin";
import { makeStyles } from "@mui/styles";

import classnames from "classnames";
import { useMediaQuery } from "@mui/material";
// import { getPageId } from "../../helpers/getPageId";
import { exporter } from "./Exporter";
import BulkUpdateActiveButton from "./BulkUpdateActiveButton";
import BulkUpdateAllowBackorderButton from "./BulkUpdateAllowBackorderButton";
import BulkUpdateCategoryAndVariant from "./BulkUpdateCategoryAndVariant";
import BulkUploadProductImage from "./BulkUploadProductImage";

import { useAvailableCurrencies } from "../useAvailableCurrencies";
import ListActions from "./ListActions";
import CustomBulkDeleteButton from "./CustomBulkDeleteButton";
import CopyTemplateButton from "./CopyTemplateButton";
import { StateProvider, store } from "./ListSelectable";
import CustomChipArrayField from "../CustomChipArrayField";
import CustomItemDescription from "./CustomItemDescription";
import BulkPinCommentTemplate from "./BulkPinCommentTemplate";
import TextLinkField from "../Common/TextLinkField";
import CustomPickerInput from "../OrderItem/CustomPickerInput";
import CustomDateField from "../Common/CustomDateField";
import CustomIntegerInput from "../Common/CustomIntegerInput";
import BulkRemovePrefix from "./BulkRemovePrefix";

const DEBOUNCE = 2000;

const BulkActionButtons = (props) => (
  <>
    <BulkPinCommentTemplate {...props} />
    <BulkUploadProductImage {...props} />
    <BulkUpdateCategoryAndVariant {...props} />
    <BulkRemovePrefix {...props} />
    <BulkUpdateAllowBackorderButton {...props} />
    <BulkUpdateActiveButton {...props} />
    {/* Add the default bulk delete action */}
    <CustomBulkDeleteButton {...props} />
  </>
);

const tinyFont = { fontSize: "10px" };
const redStyle = { color: "red" };
const normalStyle = { color: "inherit" };

const useStyles = makeStyles({
  amber: { color: "#FFCC00" },
  red: { color: "red" },
});

const withStockStyle = (Wrapped) => (props) => {
  const classes = useStyles();
  const { source } = props;
  const record = useRecordContext();
  const className = classnames({
    [classes.amber]:
      record && record["stock"] / 4 > record[source] && record[source] > 0,
    [classes.red]: record && record[source] <= 0,
  });
  return <Wrapped {...props} className={className} />;
};

const withActiveStyle = (Wrapped) => (props) => {
  const classes = useStyles();
  const { source } = props;
  const record = useRecordContext();
  const className = classnames({
    [classes.red]: record && !record[source],
  });
  return <Wrapped {...props} className={className} />;
};

const StockLeftNumberField = withStockStyle(NumberField);
const ActiveBooleanField = withActiveStyle(BooleanField);

const defaultFilters = [
  <TextInput
    label="Keyword"
    source="key_contains"
    alwaysOn
    parse={(v) => v.toUpperCase()}
  />,
  <TextInput label="Item" source="description_contains" alwaysOn />,
  <BooleanInput label="Active" source="isActive" defaultValue={true} />,
  <BooleanInput
    label="Allow Backorder"
    source="allowBackorder"
    defaultValue={false}
  />,
  <BooleanInput
    label="Allow In Web"
    source="allowInWeb"
    defaultValue={false}
  />,
  <TextInput label="SKU" source="sku_contains" />,
  <ReferenceArrayInput
    label="Categories"
    source="categories"
    reference="categories"
    perPage={100}
  >
    <SelectArrayInput optionText="label" />
  </ReferenceArrayInput>,
  <ReferenceArrayInput
    label="Variants"
    source="variants"
    reference="variants"
    perPage={100}
  >
    <SelectArrayInput optionText="label" />
  </ReferenceArrayInput>,
  <CustomIntegerInput source="stockLeft_gte" label="Stock Left min" />,
  <CustomIntegerInput source="stockLeft_lte" label="Stock Left max" />,
  <CustomIntegerInput source="stock_gte" label="Stock min" />,
  <CustomIntegerInput source="stock_lte" label="Stock max" />,
  <CustomPickerInput source="createdAt_gte" label="CreatedAt start" />,
  <CustomPickerInput source="createdAt_lte" label="CreatedAt end" />,
  <CustomPickerInput source="updatedAt_gte" label="UpdatedAt start" />,
  <CustomPickerInput source="updatedAt_lte" label="UpdatedAt end" />,
];

const CustomDatagrid = (props) => {
  const { state } = React.useContext(store);
  const newChildren = React.Children.toArray(props.children).filter(
    (child, i) => {
      if (child.props && child.props.source) {
        return state[child.props.source];
      } else return true;
    }
  );
  return <Datagrid {...props}>{newChildren}</Datagrid>;
};

const KeywordList = (props) => {
  // const pageId = getPageId();
  const { isMultiCurrency } = useAvailableCurrencies();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <StateProvider>
      <List
        debounce={DEBOUNCE}
        {...props}
        actions={<ListActions />}
        exporter={exporter}
        // filter={{ page: pageId }}
        filters={defaultFilters}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} />}
        sort={{ field: "createdAt", order: "DESC" }}
      >
        {isSmall ? (
          <SimpleList
            // linkType="show"
            primaryText={(record) => (
              <span style={record.isActive ? normalStyle : redStyle}>
                {record.description}
                {!record.isActive && (
                  <span style={tinyFont}>{"    (inactive)"}</span>
                )}
              </span>
            )}
            secondaryText={(record) => (
              <span
                style={
                  record.stock / 4 > record.stockLeft ? redStyle : normalStyle
                }
              >{`${record.stockLeft} of ${record.stock} left`}</span>
            )}
            tertiaryText={(record) => (
              <>
                <span style={tinyFont}>createdAt</span>
                <br /> {`${new Date(record.createdAt).toLocaleDateString()}`}
              </>
            )}
          />
        ) : (
          <CustomDatagrid
            rowClick="show"
            bulkActionButtons={<BulkActionButtons />}
          >
            <CustomItemDescription
              source="description"
              label="Item description"
            />
            {/* <TextField source="key" label="Keyword" /> */}
            <TextLinkField
              source="key"
              label="Keyword"
              resourceName="keywords"
              resourceKeyId="id"
            />
            <TextField source="sku" label="SKU" />
            <ActiveBooleanField source="isActive" />
            {isMultiCurrency && (
              <TextField source="currencyCode" label="Currency" />
            )}
            <NumberField source="price" />
            <NumberField source="webPrice" />
            <NumberField source="stock" />
            <StockLeftNumberField source="stockLeft" />
            <ActiveBooleanField source="allowBackorder" />
            <NumberField source="weight" label="Weight(Kg)" />
            <NumberField source="cost" />
            <TextField source="detail" label="Detail" />
            <ActiveBooleanField source="allowInWeb" />
            <CustomChipArrayField source="categories" />
            <CustomChipArrayField source="variants" />
            <NumberField source="minQuantity" />
            <NumberField source="maxQuantity" />
            <CustomDateField source="createdAt" showTime />
            <CustomDateField source="updatedAt" showTime />
            <CopyTemplateButton />
          </CustomDatagrid>
        )}
      </List>
    </StateProvider>
  );
};

export default KeywordList;
