import React from "react";
import { comboRegex } from "../../helpers/methods";
import { useController, useFormContext } from "react-hook-form";
import { required, useRecordContext } from "react-admin";
import TextField from "@mui/material/TextField";

const extractProductDescription = (fullDescription) => {
  const spacelessDescription = fullDescription.replace(/\s/g, " ").trim();
  const matches = comboRegex.exec(spacelessDescription);
  if (matches) {
    const [fullString, productDescription, quantity] = matches;
    return productDescription.trim();
  } else {
    return fullDescription.trim();
  }
};
const EditItemDescription = ({}) => {
  const record = useRecordContext();
  const productDescription = extractProductDescription(record.description);
  const { setValue } = useFormContext();
  const { field } = useController({
    name: "item",
    defaultValue: productDescription,
    rules: { required: true },
  });

  React.useEffect(() => {
    if (record) {
      const productDescription = extractProductDescription(record.description);
      setValue("item", productDescription);
    }
  }, [record, setValue]);

  return (
    <TextField
      {...field}
      label="Item Description (no need quantity)"
      fullWidth
    />
  );
};

export default EditItemDescription;
