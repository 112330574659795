import React from "react";
import { useRecordContext } from "react-admin";
import { Link } from "react-router-dom";

const OrderLink = () => {
  const record = useRecordContext();
  const { order } = record;
  return <Link to={`/orders/${order.id}/show`}>View order</Link>;
};

export default OrderLink;
