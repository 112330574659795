import useSWR from "swr";

import { fetchWithFBToken } from "../Common/swrMiddlewares";

export const useFbLiveVideo = () => {
  //Use this for dev(status)
  // const target = `${process.env.REACT_APP_GRAPH_API_URL}/me/videos?fields=id,description,created_time,updated_time,live_status,title,status,permalink_url&limit=80`;

  const target = `${process.env.REACT_APP_GRAPH_API_URL}/me/videos?fields=id,description,created_time,updated_time,live_status,title,permalink_url&limit=80`;

  const { data } = useSWR(target, {
    revalidateOnFocus: false,
    refreshInterval: 30 * 60 * 1000,
    use: [fetchWithFBToken],
  });
  if (data) {
    const found = data.data.find(
      (v) => v.live_status && v.live_status === "LIVE"
      // (v) => v.live_status && v.live_status === "VOD"
      //(v) => v.status.video_status === "ready"
    );
    return found;
  }
  return undefined;
};
