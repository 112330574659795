import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "@mui/material/Button";
import { useNotify, useRecordContext } from "react-admin";

import { getSum } from "../../helpers/methods";
import { useProfile } from "../DataHooks/useProfile";

export const usePaymentMessage = (order = null) => {
  const { profile } = useProfile();

  const msg = React.useMemo(() => {
    if (profile && order && order.orderNumber) {
      const { paymentTemplate = null } = profile;
      const { orderNumber, orderItems, currencyCode } = order;
      const paymentLink = `${process.env.REACT_APP_CUSTOMER_URL}/#/order/${orderNumber}`;
      const newItems = orderItems.filter(({ status }) => status === "new");
      const orderItemsDescription = newItems
        .map((item) => item.description)
        .reduce((acc, cur, idx, src) => {
          const splitted = cur.split(",");
          return [...acc, ...splitted];
        }, [])
        .join("\n");
      const total = getSum(newItems);
      const orderText = paymentTemplate
        .split("{{currencyCode}}")
        .join(currencyCode)
        .split("{{description}}")
        .join(orderItemsDescription)
        .split("{{total}}")
        .join(total)
        .split("{{paymentLink}}")
        .join(paymentLink);
      return orderText;
    }
    return "";
  }, [profile, order]);
  return msg;
};

const MsgField = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const msg = usePaymentMessage(record);
  return (
    <span>
      <CopyToClipboard
        text={msg}
        onCopy={() => {
          notify("Successful");
        }}
      >
        <Button variant="contained">Copy</Button>
      </CopyToClipboard>
      <pre>{msg}</pre>
    </span>
  );
};

export default MsgField;
