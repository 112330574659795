import React from "react";
import {
  TextInput,
  Create,
  BooleanInput,
  // SelectInput,
  SimpleForm,
  required,
} from "react-admin";
import { getPageId } from "../../helpers/getPageId";
import AttachSelections from "../AutocompleteAttach/AttachSelections";
import { PositiveIntegerInput } from "../Common/IntegerInput";

const CategoryCreate = (props) => {
  const pageId = getPageId();

  return (
    <Create {...props} permissions="Seller" redirect="list">
      <SimpleForm defaultValues={{ page: pageId }}>
        <TextInput source="label" validate={required()} />
        <TextInput multiline source="description" validate={required()} />
        <PositiveIntegerInput source="position" validate={required()} />
        <BooleanInput source="allowInWeb" />
        <AttachSelections source="selections" searchFieldName="label" />
      </SimpleForm>
    </Create>
  );
};

export default CategoryCreate;
