import React from "react";
import { useController, useWatch, Controller } from "react-hook-form";
import Chip from "@mui/material/Chip";

import ResourceAutocompleteAttach from "./ResourceAutocompleteAttach";

const Comp = (props) => {
  const { source, searchFieldName } = props;
  const searchFieldNameInput = useController({ name: source });
  const values = useWatch({ name: source, defaultValue: [] });

  const onRemoveTag = React.useCallback(
    (targetTag) => {
      const newTags = values.filter((tag) => tag.id !== targetTag.id);
      searchFieldNameInput.field.onChange(newTags);
    },
    [searchFieldNameInput.field, values]
  );

  return (
    <>
      <span>
        <Controller
          name={source}
          control={searchFieldNameInput.control}
          render={({ onChange, ...props }) => (
            <ResourceAutocompleteAttach
              onChange={(e, newValue) => {
                const ids = values.map((s) => s.id);
                const selected = ids.indexOf(newValue.id) > -1;
                if (!selected) {
                  searchFieldNameInput.field.onChange([...values, newValue]);
                }
              }}
              {...props}
            />
          )}
        />
      </span>
      <div>
        {values.map((t) => (
          <span key={t.id}>
            <Chip
              label={
                <a
                  href={`/#/${source}/${t.id}/show/`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t[searchFieldName]}
                </a>
              }
              onDelete={() => {
                onRemoveTag(t);
              }}
            />
          </span>
        ))}
      </div>
    </>
  );
};

export default Comp;
