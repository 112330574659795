import React from "react";
import { useController } from "react-hook-form";
import TextField from "@mui/material/TextField";

const CustomIntegerInput = (props) => {
  const { source, label } = props;
  const { field } = useController({
    name: source,
  });

  return (
    <TextField
      {...field}
      label={label}
      inputProps={{
        inputMode: "numeric",
        pattern: "[0-9]*",
      }}
      onChange={(e) => {
        if (/^\d+$/.test(e.target.value) || e.target.value === "") {
          field.onChange(e.target.value);
        }
      }}
    />
  );
};

export default CustomIntegerInput;
