import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import HelpIcon from "@mui/icons-material/Help";
import { makeStyles } from "@mui/styles";

import { useTranslate } from "react-admin";

import Notification from "./Notification";
import {
  useIsEnterprisePlan,
  useIsBusinessPlan,
} from "../../helpers/subscriptionPlan";
import { useIsComptr } from "../useProfileParams";

const useStyles = makeStyles({
  media: {
    height: "16em",
  },
  button: {
    float: "right",
  },
});

// const mediaUrl = `https://marmelab.com/posters/beard-${
//   parseInt((Math.random() * 10).toString(), 10) + 1
// }.jpeg`;

const Welcome = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const isEnterprisePlan = useIsEnterprisePlan();
  const isBusinessPlan = useIsBusinessPlan();
  const isComptr = useIsComptr();

  return (
    <Card>
      {/* <CardMedia image={mediaUrl} className={classes.media} /> */}
      <CardContent>
        <Typography variant="h5" component="h2">
          {translate("pos.dashboard.welcome.title")}
          <span className={classes.button}>
            {isEnterprisePlan
              ? `Enterprise Plan`
              : isBusinessPlan
              ? `Business Plan`
              : `Hobbyist Plan`}
          </span>
        </Typography>
        {/* <p style={{ color: "red" }}>Dashboard will be disabled temporarily</p> */}
        <Typography component="p">
          {translate("pos.dashboard.welcome.subtitle")}
        </Typography>
        <Typography component="p"></Typography>
        {!isComptr && <Notification />}
      </CardContent>
      <CardActions style={{ justifyContent: "flex-end" }}>
        <Button href="https://learn.boxify.io/" target="_blank">
          <HelpIcon style={{ paddingRight: "0.5em" }} />
          {translate("pos.dashboard.welcome.aor_button")}
        </Button>
        {/* <Button href="https://wa.me/60122141203?text=I%20have%20a%20question">
          <CodeIcon style={{ paddingRight: "0.5em" }} />
          {translate("pos.dashboard.welcome.button2")}
        </Button> */}
      </CardActions>
    </Card>
  );
};

export default Welcome;
