// import React from "react";
import Decimal from "decimal.js-light";

import { getSum, getNonCancelledItems } from "../../helpers/methods";
import { DISCOUNT_TYPE_PERCENTAGE, DISCOUNT_TYPE_VALUE } from "./common";

export const useGetDiscountValue = (props) => {
  const { orderItems = [], voucher = null, shippingCost = 0 } = props;
  if (voucher === null) {
    return 0;
  }
  const items = orderItems.filter(getNonCancelledItems);
  const merchandiseTotal = getSum(items);
  const {
    value,
    discountType,
    maxDiscount = 0,
    discountAfterShipping,
  } = voucher;

  if (discountType === DISCOUNT_TYPE_PERCENTAGE) {
    const principalDecimal = new Decimal(merchandiseTotal).plus(
      discountAfterShipping ? shippingCost : 0
    );
    const principalValue = principalDecimal.toNumber();
    const discountValue = new Decimal(principalValue)
      .times(value)
      .dividedBy(100)
      .toFixed(2);
    const min = Math.min(
      maxDiscount > 0 ? Math.min(maxDiscount, principalValue) : principalValue,
      discountValue
    );
    return new Decimal(min).toFixed(2);
  }
  const merchandiseTotalInclShipping = new Decimal(merchandiseTotal)
    .plus(discountAfterShipping ? shippingCost : 0)
    .toNumber();

  return new Decimal(Math.min(value, merchandiseTotalInclShipping)).toFixed(2);
};

export const getGetDiscountValue = useGetDiscountValue;
