import React from "react";
import { useRecordContext } from "react-admin";
import { getSum } from "../helpers/methods";
import { ORDER_ITEM_NEW, ORDER_ITEM_PAID } from "../components/Constant";

const statuses = [ORDER_ITEM_NEW, ORDER_ITEM_PAID];

const Total = () => {
  const record = useRecordContext();
  const { orderItems = [] } = record;
  const items = orderItems.filter(
    ({ status }) => statuses.indexOf(status) > -1
  );
  const merchandiseTotal = getSum(items);
  return <span>{merchandiseTotal}</span>;
};
export default Total;
